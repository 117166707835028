import { PropsWithChildren, ReactElement } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import { Button } from '../Button';

export interface TabProps {
  active?: boolean;
  badgeNumber?: number;
  onClick?: () => void;
  variant?: 'primary' | 'secondary';
}

export const Tab = ({
  children,
  active,
  badgeNumber,
  onClick,
  variant = 'primary',
}: PropsWithChildren<TabProps>): ReactElement => (
  <Button
    variant="text"
    onClick={onClick}
    className={twMerge(
      'flex w-full justify-stretch text-nowrap rounded-sm border-b-3 border-rinseGray px-1.25 py-2.5 transition-colors',
      active && 'border-b-blueNuit',
    )}
  >
    <div className="flex flex-1 items-center justify-between">
      {!!badgeNumber && variant === 'secondary' && (
        <div className="mr-2 flex h-8.75 min-h-8.75 min-w-8.75 items-center justify-center rounded-ml bg-brightGray px-1.25 text-blueNuit">
          {badgeNumber}
        </div>
      )}
      <span
        data-testid="children-section"
        className={twJoin(
          'w-fit text-sm font-black leading-sm',
          active ? 'text-blueNuit' : 'text-primaryBlue',
        )}
      >
        {children}
      </span>
      {!!badgeNumber && variant === 'primary' && (
        <span
          data-testid="badgeNumber-section"
          className={twMerge(
            'flex size-5 items-center justify-center rounded-full bg-rinseGray text-sm font-black text-white transition-colors',
            active && 'bg-blueNuit',
          )}
        >
          {badgeNumber}
        </span>
      )}
    </div>
  </Button>
);
