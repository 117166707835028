import { UserAvatar } from '@/components';
import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { PageDetailsHeaderRow } from '@/components/PageDetailsHeaderRow/PageDetailsHeaderRow';
import { StatusCard } from '@/components/StatusCard/StatusCard';
import { queryClient } from '@/constants';
import { CompanyModel } from '@/modules/Companies/domain';
import { CompanyKeys, useImportRatesFile } from '@/modules/Companies/queries';
import { useGetCurrentUser } from '@/modules/Users/queries';
import {
  useDeleteProfileCompanyPicture,
  useUploadProfileCompanyPicture,
} from '@/modules/Users/queries/profile-picture';
import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { EditCompanyModal } from '../../../CompaniesPage/components/EditCompanyModal';
import { useLocation } from 'react-router-dom';

interface HeaderRowProps {
  company?: CompanyModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent>,
    id: string,
  ) => void;
  status: boolean;
  isLoading: boolean;
}

export const CompanyHeaderRow = ({
  company,
  handleStatus,
  status,
}: HeaderRowProps) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const isEditMode = queryParams.get('edit') === 'true';

  const [isOpen, setIsOpen] = useState(isEditMode);
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);
  const { mutate: importFile } = useImportRatesFile();

  const { data: currentUser } = useGetCurrentUser();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const selectedFile = e.target.files[0];
      handleImportFile(selectedFile);
    }
  };

  const handleImportFile = async (file: File) => {
    const formData = new FormData();
    formData.append('companyId', (company?.id ?? '').toString());
    formData.append('Rates', file);

    importFile(formData, {
      onError: () => {
        toast.error(t('error_importing_file'));
      },
      onSuccess: () => {
        toast.success(t('success_importing_file'));
      },
    });
  };

  const { mutate: uploadProfilePicture, isLoading: isUpdatingProfilePicture } =
    useUploadProfileCompanyPicture();

  const { mutate: deleteProfileImage, isLoading: isDeletingProfilePicture } =
    useDeleteProfileCompanyPicture();

  const handleSuccessRequest = useCallback(
    (toastKeyMsg: string) => {
      queryClient.invalidateQueries(CompanyKeys.ALL);
      toast.success(t(toastKeyMsg));
    },
    [t],
  );

  const isLoadingProfileImage = useMemo(
    () => isUpdatingProfilePicture || isDeletingProfilePicture,
    [isDeletingProfilePicture, isUpdatingProfilePicture],
  );

  const onUploadClick = useCallback(
    (file: File) => {
      if (!currentUser?.accountAdmin) {
        return;
      }
      const formData = new FormData();
      formData.append('EntityId', (company?.id ?? '').toString());
      formData.append('File', file);

      uploadProfilePicture(formData, {
        onSuccess: () => handleSuccessRequest('success_profile_image_updated'),
        onError: () => toast.error(t('error_updating_profile_image')),
      });
    },
    [
      company?.id,
      currentUser?.accountAdmin,
      handleSuccessRequest,
      t,
      uploadProfilePicture,
    ],
  );

  const onDeleteClick = useCallback(() => {
    if (!currentUser?.accountAdmin || !company?.profileImageFileId) {
      return;
    }

    deleteProfileImage(
      {
        entityId: company.id,
        fileId: company.profileImageFileId,
      },
      {
        onSuccess: () => handleSuccessRequest('success_profile_image_deleted'),
        onError: () => toast.error(t('error_deleting_profile_image')),
      },
    );
  }, [
    company?.id,
    company?.profileImageFileId,
    currentUser?.accountAdmin,
    deleteProfileImage,
    handleSuccessRequest,
    t,
  ]);

  const displayNif = useMemo(() => {
    if (!company?.nif) {
      return '';
    }
    return company.nif.toString().replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
  }, [company]);

  return (
    <div>
      <PageDetailsHeaderRow
        isLoading={!company}
        title={company?.name ?? ''}
        description={company?.organizationName}
        before={
          <UserAvatar
            size="medium-large"
            name={company?.name}
            allowEdit={currentUser?.accountAdmin}
            onUploadClick={onUploadClick}
            onDeleteClick={onDeleteClick}
            isLoading={isLoadingProfileImage}
            imageId={company?.profileImageFileId}
          />
        }
        actions={
          <>
            <label
              htmlFor="file-upload"
              className="flex h-10 items-center justify-center rounded-md border border-dashed border-gray-300 bg-white p-2"
            >
              <span className="cursor-pointer text-sm font-bold underline">
                {t('import_rates')}
              </span>
            </label>
            <input
              id="file-upload"
              type="file"
              accept=".xlsx,.xlsm,.xlsb,.xltx"
              onChange={handleFileChange}
              className="hidden"
            />
            <StatusCard status={status} handleStatus={handleStatus} />
          </>
        }
        onEditClick={handleModalState}
        details={
          <Container>
            <span className="flex flex-row gap-2.5" data-testid="details-info">
              <Card title={t('nif_number')}>{displayNif}</Card>
              <Card
                title={t('headquarters')}
              >{`${company?.address.line1} ${company?.address.line2} ${company?.address.postalCode} ${company?.address.city}, ${company?.address.country}`}</Card>
              <Card title={t('code')}>{company?.code}</Card>
            </span>
          </Container>
        }
      />
      {isOpen && company && (
        <EditCompanyModal
          isOpen={isOpen}
          id={company.id}
          handleClose={handleModalState}
        />
      )}
    </div>
  );
};
