import { useCreateAutomationRequest } from '@/modules/Automations/queries';
import { AutomationKey } from '@/modules/Automations/queries/keys';
import { AutomationRequestSchemaType } from '@/modules/Automations/types/automation-schema';
import { BusinessAreasKey } from '@/modules/BusinessAreas/queries/keys';
import { CompanyKeys } from '@/modules/Companies/queries';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { TaskKey } from '@/modules/Tasks/queries';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { RequestAutomationModal } from '../RequestAutomationModal';
import { ToolTypes } from '@/modules/Automations/types';

interface CreateRequestAutomationModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const CreateRequestAutomationModal = ({
  isOpen,
  handleClose,
}: CreateRequestAutomationModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateAutomationRequest();

  const handleConfirm = (data: AutomationRequestSchemaType) => {
    const tools = data.tools.map((toolId: any) => {
      switch (toolId) {
        case 1:
          return ToolTypes.sap;
        case 2:
          return ToolTypes.excel;
        case 3:
          return ToolTypes.word;
        case 4:
          return ToolTypes.outlook;
        default:
          return toolId;
      }
    });

    const convertedData = new FormData();
    convertedData.append('name', data.name || '');
    convertedData.append('owner', data.owner.toString() || '');
    convertedData.append('description', data.description || '');
    if (data.users && Array.isArray(data.users)) {
      data.users.forEach((userId) => {
        convertedData.append('users', userId.toString());
      });
    } else if (typeof data.users === 'string' && data.users.trim() !== '') {
      data.users.split(',').forEach((userId) => {
        convertedData.append('users', userId.trim());
      });
    }
    convertedData.append(
      'duration',
      data.duration ? data.duration.toString() : '0',
    );
    convertedData.append('frequency', data.frequency || '');
    convertedData.append('priority', data.priority || '');
    if (tools.length > 0) {
      convertedData.append('tools', tools || '[]');
    }
    convertedData.append('additionalInformation', 'test');
    convertedData.append(
      'businessAreaId',
      data.businessAreaId?.toString() || '',
    );

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_creating_request'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(AutomationKey.ALL);
        queryClient.invalidateQueries(OrganizationKeys.ALL);
        queryClient.invalidateQueries(CompanyKeys.ALL);
        queryClient.invalidateQueries(BusinessAreasKey.ALL);
        queryClient.invalidateQueries(TaskKey.ALL);

        handleClose();
        toast.success(t('success_creating_request'));
      },
    });
  };
  return (
    <RequestAutomationModal
      handleClose={handleClose}
      handleOnSubmit={handleConfirm}
      title={t('request_automation')}
      isOpen={isOpen}
      isLoading={isLoading}
    />
  );
};
