import { useCreateInfrastructureCostAmount } from '@/modules/Infrastructure/queries';
import { InfrastructureCostKey } from '@/modules/Infrastructure/queries/types';
import {
  AmountSchemaType,
  CreateAmountDto,
} from '@/modules/Infrastructure/types';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AmountModal } from '../AmountModal';

interface CreateAmountModalProps {
  isOpen: boolean;
  handleClose: () => void;
  infrastructureCostId: number;
  minDate?: Date;
}

export const CreateAmountModal = ({
  isOpen,
  handleClose,
  infrastructureCostId,
  minDate,
}: CreateAmountModalProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateInfrastructureCostAmount();

  const handleConfirm = (data: AmountSchemaType) => {
    const convertedData: CreateAmountDto = {
      infrastructureCostId: infrastructureCostId,
      amount: Number(data.amount),
      startDate: data.startDate,
      endDate: data.endDate,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_creating_infrastructure_amount'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(InfrastructureCostKey.ALL);
        handleClose();
        toast.success(t('success_creating_infrastructure_amount'));
      },
    });
  };

  return (
    <AmountModal
      handleOnSubmit={handleConfirm}
      handleClose={handleClose}
      isLoading={isLoading}
      type="create"
      isOpen={isOpen}
      title={t('create_infrastructure_amount')}
      minDate={minDate}
    />
  );
};
