import { ChevronLeft } from '@/components/Icons';
import {
  autoUpdate,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type DropdownProps = {
  isOpen: boolean;
  children?: ReactNode;
  inputValue?: string;
  placeholder?: string;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  error?: string;
  hiddenClassName?: string;
  buttonClassMame?: string;
  containerClassName?: string;
  placement?: Placement;
  setIsOpen: (isOpen: boolean) => void;
  onOutsideClick?: () => void;
};

export const Dropdown = ({
  isOpen,
  size = 'small',
  disabled = false,
  error,
  children,
  placeholder,
  inputValue,
  hiddenClassName,
  buttonClassMame,
  containerClassName,
  placement,
  setIsOpen,
  onOutsideClick,
}: DropdownProps): ReactElement => {
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    placement,
    onOpenChange: (nextOpen, _, reason) => {
      setIsOpen(nextOpen);

      if (reason === 'outside-press') {
        onOutsideClick?.();
      }
    },
    whileElementsMounted: autoUpdate,
    middleware: [shift(), offset(4)],
  });

  const dismiss = useDismiss(context);

  const { getFloatingProps, getReferenceProps } = useInteractions([dismiss]);

  return (
    <div
      ref={refs.setReference}
      {...getReferenceProps()}
      className={twMerge('relative rounded-md bg-white', containerClassName)}
    >
      <button
        className={twMerge(
          'relative min-w-fit text-sm font-normal',
          isOpen && 'rounded-b-none',
          buttonClassMame,
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex min-w-24 items-center justify-between gap-2 px-2 py-2">
          <span
            className={twMerge(
              'overflow-hidden text-ellipsis whitespace-nowrap text-sm',
              !inputValue && !disabled && 'text-primary-300 italic',
              inputValue && !disabled && 'text-primary-600',
              disabled && 'text-primary-200',
              (size === 'medium' || size === 'large') && 'text-base',
            )}
          >
            {inputValue ?? placeholder ?? 'Select an option'}
          </span>
          <ChevronLeft
            className={twMerge(
              ' size-3 -rotate-90 text-blueNuit transition-transform',
              isOpen && 'rotate-90',
            )}
          />
        </div>
      </button>
      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            className={twMerge(
              'border-primary-500 bg-primary-50 z-10 overflow-auto rounded-md rounded-t-none border border-t-0 bg-white text-sm shadow',
              error && 'border-red-500',
              hiddenClassName,
            )}
          >
            {children}
          </div>
        </FloatingPortal>
      )}
    </div>
  );
};
