import { Circle } from '@/components/Circle/Circle';
import { formatNumber } from '@/utils';
import { useTranslation } from 'react-i18next';

type InvestmentMetricsProps = {
  metrics: {
    totalAmount: number;
    totalInfrastructureAmount: number;
    totalAmountDevelopment: number;
    totalAmountMaintenance: number;
    totalAmountSupport: number;
    infrastructureAmountPercentage: number;
    maintenanceAmountPercentage: number;
    developmentAmountPercentage: number;
    supportAmountPercentage: number;
  };
  devTimeChartColors: {
    infrastructureColor: string;
    maintenanceColor: string;
    developmentColor: string;
    supportColor: string;
  };
};

export const InvestmentMetrics = ({
  metrics: {
    totalAmount,
    totalInfrastructureAmount,
    totalAmountDevelopment,
    totalAmountMaintenance,
    totalAmountSupport,
    infrastructureAmountPercentage,
    maintenanceAmountPercentage,
    developmentAmountPercentage,
    supportAmountPercentage,
  },
  devTimeChartColors,
}: InvestmentMetricsProps) => {
  const { t } = useTranslation();
  return (
    <div className="mb-6 flex flex-wrap gap-4">
      <div className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center">
        <span className="font-extrabold">{formatNumber(totalAmount)}€</span>
        <span className="text-sm text-gray-500">{t('total_investment')}</span>
      </div>
      <div
        style={{
          color: devTimeChartColors.infrastructureColor,
        }}
        className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center"
      >
        <div className="flex items-baseline gap-1.5">
          <Circle color={devTimeChartColors.infrastructureColor} />
          <span className="font-extrabold">
            {formatNumber(totalInfrastructureAmount)}€ (
            {formatNumber(infrastructureAmountPercentage)}
            %)
          </span>
        </div>
        <span className="text-sm text-gray-500">{t('infrastructure')}</span>
      </div>
      <div
        style={{
          color: devTimeChartColors.maintenanceColor,
        }}
        className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center"
      >
        <div className="flex items-baseline gap-1.5">
          <Circle color={devTimeChartColors.maintenanceColor} />
          <span className="font-extrabold">
            {formatNumber(totalAmountMaintenance)}€ (
            {formatNumber(maintenanceAmountPercentage)}
            %)
          </span>
        </div>
        <span className="text-sm text-gray-500">{t('maintenance')}</span>
      </div>
      <div
        style={{
          color: devTimeChartColors.developmentColor,
        }}
        className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center"
      >
        <div className="flex items-baseline gap-1.5">
          <Circle color={devTimeChartColors.developmentColor} />
          <span className="font-extrabold">
            {formatNumber(totalAmountDevelopment)}€ (
            {formatNumber(developmentAmountPercentage)}
            %)
          </span>
        </div>
        <span className="text-sm text-gray-500">{t('development')}</span>
      </div>
      <div
        style={{
          color: devTimeChartColors.supportColor,
        }}
        className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center"
      >
        <div className="flex items-baseline gap-1.5">
          <Circle color={devTimeChartColors.supportColor} />
          <span className="font-extrabold">
            {formatNumber(totalAmountSupport)}€ (
            {formatNumber(supportAmountPercentage)}
            %)
          </span>
        </div>
        <span className="text-sm text-gray-500">{t('support')}</span>
      </div>
    </div>
  );
};
