import { SortDirection } from '@/components/Table/types';

export abstract class InfrastructureCostKey {
  static ALL = ['infrastructure_cost'];
  static LIST = () => [...InfrastructureCostKey.ALL, 'list'];
  static PAGE = (
    page: number | undefined,
    filter: string | undefined,
    sortBy: string | undefined,
    sortOrder: SortDirection | undefined,
  ) => [
    ...InfrastructureCostKey.LIST(),
    'page',
    page,
    filter,
    sortBy,
    sortOrder,
  ];
  static BY_ID = (id: number) => [...InfrastructureCostKey.ALL, 'by_id', id];
  static BY_ORGANIZATION = (organizationId: number) => [
    ...InfrastructureCostKey.ALL,
    'by_organization',
    organizationId,
  ];
  static PAGE_BY_ORGANIZATION = (
    organizationId: number,
    page: number | undefined,
    filter: string | undefined,
    sortBy: string | undefined,
    sortOrder: SortDirection | undefined,
  ) => [
    ...InfrastructureCostKey.BY_ORGANIZATION(organizationId),
    'page',
    page,
    filter,
    sortBy,
    sortOrder,
  ];
  static BY_COMPANY = (companyId: number) => [
    ...InfrastructureCostKey.ALL,
    'by_company',
    companyId,
  ];
  static PAGE_BY_COMPANY = (
    companyId: number,
    page: number | undefined,
    filter: string | undefined,
    sortBy: string | undefined,
    sortOrder: SortDirection | undefined,
  ) => [
    ...InfrastructureCostKey.BY_COMPANY(companyId),
    'page',
    page,
    filter,
    sortBy,
    sortOrder,
  ];
  static BY_AUTOMATION = (automationId?: number) => [
    ...InfrastructureCostKey.ALL,
    'by_automation',
    automationId,
  ];

  static PAGE_BY_AUTOMATION = (
    automationId: number,
    page: number | undefined,
    filter: string | undefined,
    sortBy: string | undefined,
    sortOrder: SortDirection | undefined,
  ) => [
    ...InfrastructureCostKey.BY_AUTOMATION(automationId),
    'page',
    page,
    filter,
    sortBy,
    sortOrder,
  ];
  static COST_HISTORY = (id: number) => [
    ...InfrastructureCostKey.ALL,
    'cost_history',
    id,
  ];

  static COST_HISTORY_PAGE = (
    id: number,
    page: number | undefined,
    filter: string | undefined,
    sortBy: string | undefined,
    sortOrder: SortDirection | undefined,
  ) => [
    ...InfrastructureCostKey.COST_HISTORY(id),
    page,
    filter,
    sortBy,
    sortOrder,
  ];
}
