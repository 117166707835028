import { ExceptionType, PaginationParams } from '@/shared/types';
import { DateRange } from 'react-day-picker';

export abstract class WorkflowKey {
  static ALL = ['workflows'];
  static LIST = [...WorkflowKey.ALL, 'list'];
  static LIST_PAGED = (pagination: PaginationParams) => [
    ...WorkflowKey.LIST,
    pagination,
  ];
  static BY_ID = (workflowId: number) => [
    ...WorkflowKey.ALL,
    'by-id',
    workflowId,
  ];
  static BY_BUSINESS_AREA = (businessAreaId?: number) => [
    ...WorkflowKey.ALL,
    'by-business-area',
    businessAreaId,
  ];
  static BY_BUSINESS_AREA_PAGED = (
    businesssAreaId: number,
    pagination: PaginationParams,
  ) => [...WorkflowKey.BY_BUSINESS_AREA(businesssAreaId), pagination];
  static BY_PROCESS = (processId: number) => [
    ...WorkflowKey.ALL,
    'by-process',
    processId,
  ];
  static BY_ORGANIZATION = (organizationId: number) => [
    ...WorkflowKey.ALL,
    'by-organization',
    organizationId,
  ];
  static BY_ORGANIZATION_PAGED = (
    organizationId: number,
    pagination: PaginationParams,
  ) => [...WorkflowKey.BY_ORGANIZATION(organizationId), pagination];
  static BY_COMPANY = (companyId: number) => [
    ...WorkflowKey.ALL,
    'by-company',
    companyId,
  ];
  static BY_COMPANY_PAGED = (
    companyId: number,
    pagination: PaginationParams,
  ) => [...WorkflowKey.BY_COMPANY(companyId), pagination];
  static TASKS_BY_PROCESS = (processId: number) => [
    ...WorkflowKey.ALL,
    'tasks',
    'process',
    processId,
  ];

  static BASE_INFO_BY_PROCESS = (processId?: number) => [
    ...WorkflowKey.ALL,
    'base-info',
    'process',
    processId,
  ];

  static AUTOMATIONS_PERFORMANCE = (
    workflowId: number,
    dateRange?: DateRange,
  ) => [...WorkflowKey.BY_ID(workflowId), 'automations-performance', dateRange];

  static LABOUR_AUTOMATED = (
    workflowId: number,
    dateRange?: DateRange,
    filter?: string,
  ) => [
    ...WorkflowKey.BY_ID(workflowId),
    'labour-automated',
    dateRange,
    filter,
  ];

  static TOTAL_RETURN = (
    workflowId: number,
    dateRange?: DateRange,
    filter?: string,
  ) => [...WorkflowKey.BY_ID(workflowId), 'total-return', dateRange, filter];

  static LAST_RUN = (
    automationId: number,
    filter?: string,
    result?: boolean,
    exceptionType?: ExceptionType,
  ) => [
    ...WorkflowKey.BY_ID(automationId),
    'last-run',
    filter,
    result,
    exceptionType,
  ];

  static OVERALL_RUNS = (
    automationId: number,
    dateRange?: DateRange,
    filter?: string,
    result?: boolean,
    exceptionType?: ExceptionType,
  ) => [
    ...WorkflowKey.BY_ID(automationId),
    'overall-runs',
    dateRange,
    filter,
    result,
    exceptionType,
  ];
}
