import { ReactElement } from 'react';
import { AvatarSize, UserAvatar } from '@/components';
import { TenantModel } from '@/modules/Tenants/domain';

export interface CurrentTenantAvatarProps {
  size: AvatarSize;
  isLoading?: boolean;
  className?: string;
  tenant?: TenantModel;
}

export const CurrentTenantAvatar = ({
  size = 'medium',
  isLoading,
  className,
  tenant,
}: CurrentTenantAvatarProps): ReactElement => {
  return (
    <UserAvatar
      className={className}
      size={size}
      name={tenant?.name}
      imageId={tenant?.imageFileId}
      isLoading={isLoading}
    />
  );
};
