import { AutomationPerformanceCardsContainer } from '@/components/PerformanceCardsContainer/components/AutomationPerformanceCardContainer/AutomationPerformanceCardContainer';
import { Environment, EnvironmentVariable } from '@/constants';
import { useGetAutomationPerformance } from '@/modules/Automations/queries';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { AutomationPerformanceCharts, ROIDateCard } from './components';

export const AutomationPerformanceTab = (): ReactElement => {
  const { automationId: automationIdParam } = useParams<{
    automationId: string;
  }>();
  const automationId = +automationIdParam!;

  const { data: automationPerformance } =
    useGetAutomationPerformance(automationId);

  return (
    <div>
      <AutomationPerformanceCardsContainer automationId={automationId} />
      <AutomationPerformanceCharts automationId={automationId} />
      {EnvironmentVariable.MODE !== Environment.Production && ( //TODO: Remove this after testing
        <ROIDateCard
          ROI={automationPerformance?.ROI}
          initialInvestment={automationPerformance?.investment.development}
          totalInvestment={automationPerformance?.totalInvestment}
        />
      )}
    </div>
  );
};
