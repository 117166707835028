import { ReactElement, useState } from 'react';
import {
  NoDataCell,
  TableHead,
  TableRowCell,
} from '@/components/Table/components';
import { TableColumn, TableVariant } from '@/components/Table/types';
import { ProcessWorkflowTask, WorkflowWithTasks } from '../../types';
import { t } from 'i18next';
import { TaskRow } from '../TaskRow';

interface WorkflowRowProps {
  workflow: WorkflowWithTasks;
  workflowsColumns: TableColumn<WorkflowWithTasks>[];
  taskColumns: TableColumn<ProcessWorkflowTask>[];
  variant: TableVariant;
  noOutline?: boolean;
  selected?: boolean;
  showDots?: boolean;
  depth?: number;
  isTree?: boolean;
  noChildDots?: boolean;
  onRowHoverStyling?: string;
  collapseClassname?: string;
}

export const WorkflowRow = ({
  workflow,
  workflowsColumns,
  taskColumns,
  depth = 0,
  isTree,
  variant,
  noOutline,
  selected,
  showDots,
  noChildDots,
  onRowHoverStyling,
  collapseClassname,
}: WorkflowRowProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TableRowCell
        row={workflow}
        cols={workflowsColumns}
        depth={depth}
        isTree={isTree}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        noOutline={noOutline}
        onRowClick={() => setIsOpen(!isOpen)}
        selected={selected}
        variant={variant}
        showDots={showDots}
        showBlueDot={false}
        noChildDots={noChildDots}
        onRowHoverStyling={onRowHoverStyling}
        onlyCollapse
        collapseClassname={collapseClassname}
      />
      {isOpen && (
        <tr>
          <td colSpan={workflowsColumns.length + 1}>
            <div className="my-2 ml-10 rounded-md bg-brightGray p-3">
              <table className="w-full">
                <thead className="bg-inherit">
                  <TableHead columns={taskColumns} variant="medium" isTree />
                </thead>
                <tbody>
                  {workflow?.rows.length === 0 ? (
                    <NoDataCell
                      colSpan={taskColumns.length}
                      variant={'medium'}
                      noResultsLabel={t('no_tasks_available')}
                    />
                  ) : (
                    workflow?.rows?.map((task) => (
                      <TaskRow
                        key={task.id}
                        task={task}
                        taskColumns={taskColumns}
                        variant={variant}
                        onRowHoverStyling={onRowHoverStyling}
                        noOutline={noOutline}
                      />
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
