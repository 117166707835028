import { CompanyKeys, useUpdateCompany } from '@/modules/Companies/queries';
import { useGetCompany } from '@/modules/Companies/queries/get-company';
import { CompanySchemaType } from '@/modules/Companies/types';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CompanyModal } from '../CompanyModal/CompanyModal';
import { AutomationKey } from '@/modules/Automations/queries/keys';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';

interface EditCompanyModal {
  isOpen: boolean;
  id: number;
  handleClose: () => void;
}

export const EditCompanyModal = ({
  isOpen,
  id,
  handleClose,
}: EditCompanyModal): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useUpdateCompany();
  const { data: company, isLoading: isLoadingGet } = useGetCompany(id);

  const handleConfirm = (data: CompanySchemaType) => {
    if (!company) {
      return;
    }

    const formData = new FormData();

    formData.append('id', (data.id ?? company.id).toString());
    formData.append('addressId', company.address.id.toString());
    formData.append('organizationId', data.organizationId.toString());
    formData.append('status', 'true');
    formData.append('line1', data.addressLineOne ?? company.address.line1);
    formData.append('line2', data.addressLineTwo ?? company.address.line2);
    formData.append('postalCode', data.postCode ?? company.address.postalCode);
    formData.append('country', data.country ?? company.address.country);
    formData.append('city', data.city ?? company.address.city);
    formData.append('name', data.companyName ?? company.name);
    formData.append('code', data.companyCode ?? company.code);
    formData.append('nif', data.nif);
    formData.append('jiraLink', data.jiraLink ?? company.jiraLink);
    data.file && formData.append('file', data.file);
    data.fileId && formData.append('fileId', data.fileId.toString());

    mutate(formData, {
      onError: () => {
        toast.error(t('error_update_company'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(OrganizationKeys.ALL);
        queryClient.invalidateQueries(CompanyKeys.ALL);
        queryClient.invalidateQueries(AutomationKey.ALL);
        handleClose();
        toast.success(t('success_update_company'));
      },
    });
  };

  return (
    <CompanyModal
      type="edit"
      handleOnSubmit={handleConfirm}
      handleClose={handleClose}
      title={t('edit_company')}
      isOpen={isOpen}
      isLoading={isLoading || isLoadingGet}
      organizationId={company?.organizationId}
      companyName={company?.name}
      companyCode={company?.code}
      nif={company?.nif}
      jiraLink={company?.jiraLink}
      addressLineOne={company?.address?.line1}
      addressLineTwo={company?.address?.line2}
      postCode={company?.address.postalCode}
      country={company?.address?.country}
      city={company?.address?.city}
      profileImageFileId={company?.profileImageFileId}
    />
  );
};
