import { TenantContext } from '@/components';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { SystemMessageModel } from '../domain/system-model';
import { systemRepo } from '@/constants/providers';
import { SystemKey } from './keys';

export const useGetNotifications = (environment?: string) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<SystemMessageModel[]>(
    [...SystemKey.ALL, currentSelectedTenant?.id],
    () => {
      if (!environment) return [];
      return systemRepo.getNotifications(environment);
    },
  );
};
