import {
  OverallRunSuccessRateHeader,
  PerformanceCardDetails,
} from '@/components';
import { getOverallRunsSucessRateColumns } from '@/modules/Automations/pages/AutomationsPage/utils';
import { useGetAutomationPerformance } from '@/modules/Automations/queries';
import { useAutomationOverallRuns } from '@/modules/Automations/queries/use-automation-performance';
import { ExceptionType, ResultFilter, Success } from '@/shared/types';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

type OverallRunsDetailsProps = {
  automationId: number;
  onClose: () => void;
};

export const OverallRunsDetails = ({
  automationId,
  onClose,
}: OverallRunsDetailsProps) => {
  const { t } = useTranslation();
  const [runsDateRange, setRunsDateRange] = useState<DateRange>();
  const [selectedResult, setSelectedResult] = useState<ResultFilter>();
  const [search, setSearch] = useState<string>();

  const { data: automationPerformance, isLoading } =
    useGetAutomationPerformance(automationId);

  const {
    data,
    isLoading: isLoadingOverallRuns,
    fetchNextPage: fetchOverallRunsNextPage,
    isFetching,
    isFetchingNextPage,
  } = useAutomationOverallRuns({
    automationId,
    dateRange: runsDateRange,
    filter: search,
    caseResult: selectedResult === Success.Success ? true : undefined,
    exceptionType:
      !!selectedResult &&
      [ExceptionType.Business, ExceptionType.Generic].includes(selectedResult)
        ? selectedResult
        : undefined,
  });

  const overallSuccessRate = useMemo(
    () => data?.pages.flatMap((page) => page) || [],
    [data?.pages],
  );

  const totalMetrics = useMemo(() => {
    const { businessErrors, genericErrors, successes } =
      automationPerformance?.overallSuccessRate ?? {
        businessErrors: 0,
        genericErrors: 0,
        successes: 0,
      };

    return {
      totalCases: successes + businessErrors + genericErrors,
      businessErrors,
      genericErrors,
      successes,
      totalRuns: automationPerformance?.totalExecutions ?? 0,
    };
  }, [automationPerformance]);

  return (
    <PerformanceCardDetails
      title={t('overall_success_rate')}
      subTitle={t('cases')}
      description={t('overall_run_success_rate_description')}
      columns={getOverallRunsSucessRateColumns()}
      onClose={onClose}
      data={{
        items: overallSuccessRate,
        totalPages: 1,
        totalRows: overallSuccessRate.length,
      }}
      metrics={
        !isLoading && (
          <OverallRunSuccessRateHeader
            key="overallRunSuccessMetric"
            metrics={totalMetrics}
          />
        )
      }
      kpiDateRange={runsDateRange}
      setKpiDateRange={setRunsDateRange}
      fetchNextPage={fetchOverallRunsNextPage}
      contextType="overallRuns"
      selectedResult={selectedResult}
      setSelectedResult={setSelectedResult}
      isLoading={isLoadingOverallRuns}
      setSearchInput={setSearch}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};
