import { DEFAULT_PAGE_SIZE, SortDirection } from '@/components/Table/types';
import { HttpClient } from '@/core/http';
import { ApiResponse, DataWithPagination } from '@/shared/types';
import {
  AmountModel,
  InfrastructureCostBaseInfoModel,
  InfrastructureCostModel,
} from '../domain/infrastructure-model';
import {
  infrastructureCostAmountsWithPaginationMapper,
  infrastructureCostBaseInfoMapper,
  infrastructureCostsWithPaginationMapper,
  mapInfrastructureCostJsonToInfrastructureCostModel,
} from '../mappers';
import {
  ApiResponseInfrastructureCost,
  ApiResponseInfrastructureCostAmounts,
  ApiResponseInfrastructureCosts,
  AttachAutomationInfrastructureCostModel,
  AttachCompanyInfrastructureCostModel,
  AttachOrganizationInfrastructureCostModel,
  CreateAmountDto,
  CreateInfrastructureCost,
  DeleteInfrastructureCostDocumentJson,
  InfrastructureCostBaseInfoJson,
  InfrastructureCostJson,
  PagedInfrastructureCostAmounts,
  PagedInfrastructureCosts,
  UpdateAmountDto,
} from '../types';
import { InfrastructureRepository } from './infrastructure.repository';

export class HttpInfrastructureRepository extends InfrastructureRepository {
  constructor(private readonly client: HttpClient) {
    super();
  }

  createInfrastructure = async (
    infrastructureCostJson: CreateInfrastructureCost,
  ): Promise<void> => {
    await this.client.post({
      body: infrastructureCostJson,
    });
  };

  async getInfrastructureCosts(
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<InfrastructureCostModel[]>> {
    const infrastructureCosts = await this.client.get<
      ApiResponseInfrastructureCosts<PagedInfrastructureCosts>
    >({
      url: '/me/infrastructure-costs',
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return infrastructureCostsWithPaginationMapper(infrastructureCosts);
  }

  async getInfrastructureCost(id: number): Promise<InfrastructureCostModel> {
    const costs = await this.client.get<
      ApiResponseInfrastructureCost<InfrastructureCostJson>
    >({
      url: `${id}`,
    });
    return mapInfrastructureCostJsonToInfrastructureCostModel(
      costs.data.infrastructureCost,
    );
  }

  updateInfrastructureCost = async (
    data: InfrastructureCostJson,
  ): Promise<InfrastructureCostModel> => {
    const updatedCost = await this.client.put<InfrastructureCostJson>(
      `${data.id}`,
      data,
    );
    return mapInfrastructureCostJsonToInfrastructureCostModel(updatedCost);
  };

  enableInfrastructureCost = async (
    id: number,
  ): Promise<ApiResponse<unknown>> => {
    return this.client.patch(`${id}/enable`);
  };

  disableInfrastructureCost = async (
    id: number,
  ): Promise<ApiResponse<unknown>> => {
    return this.client.patch(`${id}/disable`);
  };

  attachCompanyInfrastructureCost = async (
    data: AttachCompanyInfrastructureCostModel,
  ): Promise<void> => {
    return await this.client.post({
      body: data,
      url: `associate-company`,
    });
  };

  attachOrganizationInfrastructureCost = async (
    data: AttachOrganizationInfrastructureCostModel,
  ): Promise<void> => {
    return await this.client.post({
      body: data,
      url: `associate-organization`,
    });
  };

  attachAutomationInfrastructureCost = async (
    data: AttachAutomationInfrastructureCostModel,
  ): Promise<void> => {
    return await this.client.post({
      body: data,
      url: `associate-automation`,
    });
  };

  async getInfrastructureCostsByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<InfrastructureCostModel[]>> {
    const companies = await this.client.get<
      ApiResponseInfrastructureCosts<PagedInfrastructureCosts>
    >({
      url: `/organizations/${organizationId}/infrastructure-costs`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return infrastructureCostsWithPaginationMapper(companies);
  }

  async getInfrastructureCostsByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<InfrastructureCostModel[]>> {
    const companies = await this.client.get<
      ApiResponseInfrastructureCosts<PagedInfrastructureCosts>
    >({
      url: `/companies/${companyId}/infrastructure-costs`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return infrastructureCostsWithPaginationMapper(companies);
  }

  async getInfrastructureCostsByAutomation(
    automationId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<InfrastructureCostModel[]>> {
    const companies = await this.client.get<
      ApiResponseInfrastructureCosts<PagedInfrastructureCosts>
    >({
      url: `/automations/${automationId}/infrastructure-costs`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return infrastructureCostsWithPaginationMapper(companies);
  }

  async getAllInfrastructureCostsByAutomationId(
    automationId: number,
  ): Promise<InfrastructureCostBaseInfoModel[]> {
    const infrastructureCostsJson = await this.client.get<
      ApiResponseInfrastructureCosts<InfrastructureCostBaseInfoJson[]>
    >({
      url: `base-info/${automationId}`,
    });

    return infrastructureCostsJson.data.infrastructureCosts.map(
      infrastructureCostBaseInfoMapper,
    );
  }

  async getInfrastructureCostAmountHistory(
    costId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<AmountModel[]>> {
    const amounts = await this.client.get<
      ApiResponseInfrastructureCostAmounts<PagedInfrastructureCostAmounts>
    >({
      url: `${costId}/amount-history`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return infrastructureCostAmountsWithPaginationMapper(amounts);
  }

  createInfrastructureCostAmount = async (
    infrastructureCostAmountJson: CreateAmountDto,
  ): Promise<void> => {
    await this.client.post({
      body: infrastructureCostAmountJson,
      url: `amount`,
    });
  };

  updateInfrastructureCostAmount = async (
    data: UpdateAmountDto,
  ): Promise<void> => {
    await this.client.put(`amount`, data);
  };

  addDocument = async (data: FormData): Promise<unknown> => {
    return await this.client.post<unknown>({
      url: `${data.get('id')}/document`,
      body: data,
    });
  };

  deleteDocument = async (
    data: DeleteInfrastructureCostDocumentJson,
  ): Promise<unknown> => {
    return await this.client.delete<unknown>('document', data);
  };
}
