import { TableColumn, TableRow } from '@/components/Table/types';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

type RunFinishColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const runFinishColumnConfig = <T extends TableRow>({
  options,
}: RunFinishColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'runFinish',
  label: <Trans i18nKey="run_finish" />,
  render: (value) => (
    <div className="overflow-hidden text-ellipsis">
      {value && new Date(value).getFullYear() > 1
        ? new Date(value).toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })
        : t('on_going')}
    </div>
  ),
  acessorKey: 'runFinish',
  sortable: false,
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
