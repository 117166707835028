import {
  AutomationsTab,
  BusinessAreasTab,
  CompaniesTab,
  CustomPageContainer,
  InfrastructureCostsTab,
  OrganizationsIcon,
  ProcessesWithWorkflowsTab,
  TabItemProps,
  TablePaginationContextProvider,
  UsersTab,
} from '@/components';
import { LabelIcon } from '@/components/LabelIcon/LabelIcon';
import { PageDetailsActions } from '@/components/PageDetailsActions/PageDetailsActions';
import { TabContainer } from '@/components/TabContainer';
import { TabsWrapper } from '@/components/TabsWrapper';
import { queryClient } from '@/constants';
import { HttpError } from '@/core/http';
import { RoutePath } from '@/core/router/route-paths';
import { AutomationModel } from '@/modules/Automations/domain';
import { getAutomationColumnsNamedMapped } from '@/modules/Automations/pages/AutomationsPage/utils';
import { useGetAutomationsByOrganization } from '@/modules/Automations/queries';
import { BusinessAreaModel } from '@/modules/BusinessAreas/domain';
import { getBusinessAreaColumnsNamedMapped } from '@/modules/BusinessAreas/pages/BusinessAreasPage/utils';
import { useGetBusinessAreasByOrganization } from '@/modules/BusinessAreas/queries';
import { CompanyModel } from '@/modules/Companies/domain';
import { getCompaniesColumnsNamedMapped } from '@/modules/Companies/pages/CompaniesPage/utils';
import { useGetCompaniesByOrganization } from '@/modules/Companies/queries';
import { InfrastructureCostModel } from '@/modules/Infrastructure/domain';
import { getInfrastructureCostColumnsNamedMapped } from '@/modules/Infrastructure/pages/InfrastructureCostPage/utils';
import { useGetInfrastructureCostsByOrganization } from '@/modules/Infrastructure/queries';
import { getProcessesColumnsNamedMapped } from '@/modules/Processes';
import { ProcessModel } from '@/modules/Processes/domain';
import { useGetProcessesByOrganization } from '@/modules/Processes/queries';
import { UserModel } from '@/modules/Users/domain';
import { getUsersColumnsNamedMapped } from '@/modules/Users/pages/UsersPage/utils';
import { useGetUsersByOrganization } from '@/modules/Users/queries';
import { UserKey } from '@/modules/Users/queries/types';
import { HttpStatusCode } from 'axios';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OrganizationStatus } from '../..';
import { useDisableOrganization } from '../../queries/disabled-organization';
import { useDisassociateOrganizationUser } from '../../queries/disassociate-user';
import { useEnableOrganization } from '../../queries/enable-organization';
import { useGetOrganization } from '../../queries/get-organization';
import { DisassociateOrganizationUserModel } from '../../types/disassociate-organization-user-model';
import { OrganizationHeaderRow } from './components/OrganizationHeaderRow';
import { OrganizationPerformanceCards } from './components/OrganizationPerformanceCards';

export const OrganizationDetailPage = (): ReactElement => {
  const { organizationId: organizationIdRouteParam } = useParams<{
    organizationId: string;
  }>();
  const organizationId = +organizationIdRouteParam!;
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { mutate } = useDisassociateOrganizationUser();

  const {
    data: organization,
    isLoading: isLoadingOrganizations,
    isError,
    error,
  } = useGetOrganization(organizationId);

  useEffect(() => {
    if (isError && error) {
      if ((error as HttpError<unknown>)?.code === HttpStatusCode.NotFound) {
        navigate(RoutePath.notFound());
      } else {
        toast.error(t('error_get_organization_details'));
      }
    }
  }, [isError, error, navigate, t]);

  const [status, setStatus] = useState<boolean>(false);

  useEffect(() => {
    setStatus(organization?.status === OrganizationStatus.Active);
  }, [organization]);

  const { mutate: enableOrganization } = useEnableOrganization();
  const { mutate: disableOrganization } = useDisableOrganization();

  const handleStatus = useCallback(
    (checked: boolean) => {
      if (!organization?.id) {
        return toast.error(t('generic_errors'));
      }
      setStatus(checked);
      if (checked) {
        enableOrganization(organization?.id, {
          onError: () => {
            toast.error(t('error_enable_organization'));
          },
          onSuccess: () => {
            toast.success(t('success_enable_organization'));
          },
        });
      } else {
        disableOrganization(organization?.id, {
          onError: () => {
            toast.error(t('error_disable_organization'));
          },
          onSuccess: () => {
            toast.success(t('success_disable_organization'));
          },
        });
      }
    },
    [organization, enableOrganization, disableOrganization, t],
  );

  const tabs: Array<TabItemProps> = useMemo(
    () => [
      { label: t('users'), badgeNumber: organization?.usersCount },
      { label: t('companies'), badgeNumber: organization?.companiesCount },
      {
        label: t('business_areas'),
        badgeNumber: organization?.businessAreasCount,
      },
      {
        label: t('processes'),
        badgeNumber: organization?.processesCount,
      },
      { label: t('automations'), badgeNumber: organization?.automationsCount },
      {
        label: t('infrastructure_costs'),
        badgeNumber: organization?.infrastructureCostsCount,
      },
    ],
    [t, organization],
  );

  const disassociateOrganizationUser = useCallback(
    (userId: number) => {
      if (!userId) {
        return;
      }
      const convertedData: DisassociateOrganizationUserModel = {
        userId,
        organizationId,
      };

      mutate(convertedData, {
        onError: () => {
          toast.error(t('error_remove_user_from_organization'));
        },
        onSuccess: () => {
          toast.success(t('success_remove_user_from_organization'));
          queryClient.invalidateQueries(
            UserKey.BY_ORGANIZATION(organizationId),
          );
        },
      });
    },
    [mutate, t, organizationId],
  );

  const routeChange = useCallback(() => {
    navigate('/organizations');
  }, [navigate]);

  const tabList = useMemo(() => {
    if (!organizationId) {
      return [];
    }
    return [
      {
        tab: (
          <TabContainer<UserModel>
            parentId={organizationId}
            useGetData={useGetUsersByOrganization}
            getColumnsNamedMapped={getUsersColumnsNamedMapped}
          >
            <UsersTab
              organizationId={organization?.id}
              showRole
              unlinkData={{
                action: disassociateOrganizationUser,
                message: t('confirm_user_organization_disassociate'),
                canUnlink: true,
              }}
            />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<CompanyModel>
            parentId={organizationId}
            useGetData={useGetCompaniesByOrganization}
            getColumnsNamedMapped={getCompaniesColumnsNamedMapped}
          >
            <CompaniesTab
              canCreate
              disableFields={['role', 'organizationId']}
              organizationId={organization?.id}
            />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<BusinessAreaModel>
            parentId={organizationId}
            useGetData={useGetBusinessAreasByOrganization}
            getColumnsNamedMapped={getBusinessAreaColumnsNamedMapped}
          >
            <BusinessAreasTab
              canCreate
              disableFields={['organizationId']}
              organizationId={organization?.id}
            />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<ProcessModel>
            parentId={organizationId}
            useGetData={useGetProcessesByOrganization}
            getColumnsNamedMapped={getProcessesColumnsNamedMapped}
          >
            <ProcessesWithWorkflowsTab context="organization" />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<AutomationModel>
            parentId={organizationId}
            getColumnsNamedMapped={getAutomationColumnsNamedMapped}
            useGetData={useGetAutomationsByOrganization}
          >
            <AutomationsTab
              canCreate
              disableFields={['organizationId']}
              organizationId={organization?.id}
            />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<InfrastructureCostModel>
            parentId={organizationId}
            getColumnsNamedMapped={getInfrastructureCostColumnsNamedMapped}
            useGetData={useGetInfrastructureCostsByOrganization}
          >
            <InfrastructureCostsTab
              disableFields={['organizationId']}
              context="organizationDetails"
            />
          </TabContainer>
        ),
      },
    ];
  }, [organizationId, organization?.id, disassociateOrganizationUser, t]);

  const breadcrumbs = useMemo(() => {
    if (!organization) {
      return [];
    }
    return [
      {
        label: t('organizations'),
        href: '/organizations',
      },
      {
        label: organization.name,
      },
    ];
  }, [t, organization]);

  return (
    <CustomPageContainer>
      <PageDetailsActions onBack={routeChange} breadcrumbs={breadcrumbs}>
        <LabelIcon
          label={t('organization')}
          icon={<OrganizationsIcon className="size-5 text-blueNuit" />}
        />
      </PageDetailsActions>
      <OrganizationHeaderRow
        organization={organization}
        handleStatus={handleStatus}
        status={status}
        isLoading={isLoadingOrganizations}
      />
      <TablePaginationContextProvider>
        <OrganizationPerformanceCards organizationId={organizationId} />
        <TabsWrapper tabs={tabs} tabList={tabList} />
      </TablePaginationContextProvider>
    </CustomPageContainer>
  );
};
