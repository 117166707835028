import {
  PerformanceCard,
  PerformanceContent,
} from '@/components/PerformanceCard/PerformanceCard';
import { Tooltip } from '@/components/Tooltip';
import { BusinessAreaAutomationsPerformanceJson } from '@/modules';
import { AutomationPerformance } from '@/modules/Automations/domain';
import {
  calculateValueDifference,
  formatBigNumber,
  formatNumber,
} from '@/utils';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type TotalReturnCardProps = {
  automationPerformance?:
    | AutomationPerformance
    | BusinessAreaAutomationsPerformanceJson;
  isLoading: boolean;
  addAdditionalColumns?: boolean;
  excludeSearchInput?: boolean;
  details?: ReactNode;
  setIsDetailsOpen: (isOpen: boolean) => void;
};

export const TotalReturnCard = ({
  automationPerformance,
  isLoading,
  setIsDetailsOpen,
  details,
}: TotalReturnCardProps) => {
  const { t } = useTranslation();

  const {
    performance: performanceTotalReturn,
    timeDifference: timeDifferenceTotalReturn,
    efficiency: returnEfficiency,
  } = calculateValueDifference(
    automationPerformance?.totalInvestment || 0,
    automationPerformance?.totalReturn || 0,
    'coin',
  );

  const investmentContent: PerformanceContent[] = useMemo(
    () => [
      {
        title: `${formatNumber(automationPerformance?.totalReturn || 0)}€`,
      },
      {
        title: `${formatNumber(automationPerformance?.totalExecutions ? (automationPerformance?.totalReturn ?? 0) / automationPerformance?.totalExecutions : 0)}€`,
        subtitle: t('average_return_per_run'),
      },
      {
        title: `${formatNumber(automationPerformance?.totalInvestment || 0)}€`,
        subtitle: t('total_investment'),
      },
      {
        title: (
          <Tooltip
            showArrow
            content={`${formatNumber((timeDifferenceTotalReturn as number) ?? 0)}€`}
          >
            {`${formatBigNumber((timeDifferenceTotalReturn as number) ?? 0)}€`}
          </Tooltip>
        ),
        extraTitle: (
          <Tooltip
            showArrow
            content={`${formatNumber(returnEfficiency ?? 0)}%`}
          >
            {`${formatBigNumber(returnEfficiency ?? 0)}%`}
          </Tooltip>
        ),
        subtitle: t('financial_outcome'),
        performance: performanceTotalReturn,
      },
    ],
    [
      automationPerformance,
      performanceTotalReturn,
      returnEfficiency,
      t,
      timeDifferenceTotalReturn,
    ],
  );

  return (
    <>
      <PerformanceCard
        key={t('total_return')}
        title={t('total_return')}
        content={investmentContent}
        isLoading={isLoading}
        onExpand={() => setIsDetailsOpen(true)}
      />
      {details}
    </>
  );
};
