import { TenantContext } from '@/components';
import { workflowsRepo } from '@/constants/providers';
import { WorkflowBaseInfo } from '@/modules/Workflows/domain';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { WorkflowKey } from './keys';

type UseGetAllWorkflowsByBusinessAreaProps = {
  businessAreaId?: number;
};

type UseGetAllWorkflowsByBusinessAreaOptions = {
  enabled: boolean;
};

export const useGetAllWorkflowsByBusinessArea = (
  { businessAreaId }: UseGetAllWorkflowsByBusinessAreaProps,
  options?: UseGetAllWorkflowsByBusinessAreaOptions,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);
  return useQuery<WorkflowBaseInfo[]>(
    [
      ...WorkflowKey.BY_BUSINESS_AREA(businessAreaId),
      currentSelectedTenant?.id,
    ],
    () =>
      businessAreaId
        ? workflowsRepo.getWorkflowsByBusinessAreaId(businessAreaId)
        : [],
    {
      enabled: !!businessAreaId && (options?.enabled ?? true),
      cacheTime: 1000 * 60 * 5,
    },
  );
};
