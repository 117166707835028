import { organizationsRepo } from '@/constants/providers';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { OrganizationKeys } from './keys';

export const useGetOrganizationAutomationsPerformance = (
  organizationId: number,
  dateRange?: DateRange,
) => {
  return useQuery({
    queryKey: OrganizationKeys.AUTOMATIONS_PERFORMANCE(
      organizationId,
      dateRange,
    ),
    queryFn: () =>
      organizationsRepo.getOrganizationAutomationsPerformanceById(
        organizationId,
        dateRange,
      ),
    cacheTime: 1000 * 60 * 5,
  });
};

type UseOrganizationLabourTimeAutomatedProps = {
  organizationId: number;
  enabled: boolean;
  dateRange?: DateRange;
  filter?: string;
};

export const useOrganizationLabourTimeAutomated = ({
  organizationId,
  enabled,
  dateRange,
  filter,
}: UseOrganizationLabourTimeAutomatedProps) => {
  return useInfiniteQuery(
    [OrganizationKeys.LABOUR_AUTOMATED(organizationId, dateRange, filter)],
    ({ pageParam = 1 }) =>
      organizationsRepo.getOrganizationLabourTimeAutomatedById(
        organizationId,
        dateRange,
        pageParam,
        filter,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};

type UseOrganizationTotalReturnProps = {
  organizationId: number;
  enabled: boolean;
  dateRange?: DateRange;
  filter?: string;
};

export const useOrganizationTotalReturn = ({
  organizationId,
  enabled,
  dateRange,
  filter,
}: UseOrganizationTotalReturnProps) => {
  return useInfiniteQuery(
    [OrganizationKeys.TOTAL_RETURN(organizationId, dateRange, filter)],
    ({ pageParam = 1 }) =>
      organizationsRepo.getOrganizationTotalReturnById(
        organizationId,
        dateRange,
        pageParam,
        filter,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};
