import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';
import { trunc } from '..';

export const amountColumnConfig = <T extends TableRow>(
  options?: Omit<TableColumn<T>, 'id' | 'label'>,
): TableColumn<T> => ({
  id: 'amount',
  label: <Trans i18nKey="amount" />,
  render: (value) => (
    <div className="overflow-hidden text-ellipsis">{trunc(value)}€</div>
  ),
  acessorKey: 'amount',
  sortable: true,
  align: 'left',
  alignHeader: 'left',
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
