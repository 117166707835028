import { Property } from 'csstype';

type CircleProps = {
  color?: Property.BackgroundColor;
};

export const Circle = ({ color }: CircleProps) => {
  return (
    <span
      className="inline-block size-3 rounded-full"
      style={{ backgroundColor: color }}
    ></span>
  );
};
