import { Modal } from '@/components';
import { useTaskCreateSubmission } from '@/modules/Tasks/hooks/use-task-create-submit.hook';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateTaskForm } from '../TaskForm';

interface CreateTaskModalProps {
  isOpen: boolean;
  handleClose: () => void;
  disableFields?: string[];
  workflowId?: number;
}

export const CreateTaskModal = ({
  isOpen,
  handleClose,
  disableFields,
  workflowId,
}: CreateTaskModalProps): ReactElement => {
  const { t } = useTranslation();

  const { submit, isLoading } = useTaskCreateSubmission({
    onSuccess: handleClose,
  });

  return (
    <Modal
      isOpen={isOpen}
      title={t('create_task')}
      className="h-144 w-128"
      handleClose={handleClose}
    >
      <CreateTaskForm
        defaultValues={{
          workflowId,
        }}
        handleClose={handleClose}
        handleOnSubmit={submit}
        isLoading={isLoading}
        disableFields={disableFields}
      />
    </Modal>
  );
};
