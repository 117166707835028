import { TablePaginationContext } from '@/components/TableWithFilterWrapper';
import { TabProps } from '@/shared/types/tabs';
import {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { TableSort } from '../Table/types';

// eslint-disable-next-line react-refresh/only-export-components
export const TabContext = createContext<{
  isLoading: boolean;
  data: any;
  onPageChange: (pageNumber: number) => void;
  handleSearchChange: (value: string) => void;
  handleModalState: () => void;
  isOpen: boolean;
  page: number;
  parentId?: number;
  tableSort: TableSort[];
  onSort: (data: TableSort[]) => void;
}>({
  isLoading: false,
  data: [],
  onPageChange: () => {},
  handleSearchChange: () => {},
  handleModalState: () => {},
  isOpen: false,
  page: 0,
  tableSort: [],
  onSort: () => {},
});

export const TabContainer = <T,>({
  parentId,
  useGetData,
  children,
  getColumnsNamedMapped,
}: TabProps<T> & { children: ReactNode }): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    onSort,
    tableSort,
    searchTerm,
    page,
    onPageChange,
    handleSearchChange,
  } = useContext(TablePaginationContext);

  const { data, isLoading, refetch } = useGetData(
    parentId,
    page + 1,
    searchTerm,
    getColumnsNamedMapped &&
      getColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
    tableSort?.[0]?.direction ?? 'ascending',
  );

  const handleModalState = useCallback(() => {
    if (isOpen) {
      refetch(); //TODO: Remove refetch and try to invalidate queries instead
    }
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, [isOpen, refetch]);

  return (
    <TabContext.Provider
      value={{
        data,
        isLoading,
        onPageChange,
        handleSearchChange,
        handleModalState,
        isOpen,
        page,
        onSort,
        tableSort,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};
