import { ProcessModel } from '@/modules/Processes/domain';
import {
  automatedColumnConfig,
  businessAreaNameColumnConfig,
  codeColumnConfig,
  emptyColumnConfig,
  estimatedCostColumnConfig,
  nameColumnConfig,
  periodColumnConfig,
  processCompanyNameColumnConfig,
  runTimeColumnConfig,
  statusColumnConfig,
  usersCountColumnConfig,
  workloadColumnConfig,
} from '@/utils';
import { dividerColumnConfig } from '@/utils/tableColumns/divider-column-config';
import { twMerge } from 'tailwind-merge';
import { OverflowTooltip } from '..';
import { TableColumn, TableRow } from '../Table/types';
import { ProcessWithWorkflows } from './types';

type BaseColumnsConfig = {
  onClick?: (row?: TableRow) => void;
  context?: 'organization' | 'company' | 'businessArea';
};

type ProcessColumnsConfig = BaseColumnsConfig & {
  context?: 'organization' | 'company' | 'businessArea';
};

type WorkflowColumnsConfig = BaseColumnsConfig;

type TaskColumnsConfig = BaseColumnsConfig;

const commomColumnConfig = {
  sortable: false,
  className: 'max-w-[20vw] w-[20vw]',
};

export const getProcessesColumns = ({
  onClick,
  context = 'organization',
}: ProcessColumnsConfig = {}): Array<TableColumn<TableRow>> => {
  return [
    nameColumnConfig(
      {
        ...commomColumnConfig,
        render: (value, row) => (
          <div
            className="flex items-center gap-2.5 overflow-hidden"
            onClick={() => onClick?.(row)}
          >
            <OverflowTooltip text={value} textClassName="font-black" />
          </div>
        ),
      },
      'process',
    ),
    emptyColumnConfig('0', {
      className: twMerge(
        'max-w-[4vw] w-[4vw]',
        context === 'company' && 'max-w-[5vw] w-[5vw]',
        context === 'businessArea' && 'max-w-[6vw] w-[6vw]',
      ),
    }),
    context === 'organization'
      ? processCompanyNameColumnConfig(commomColumnConfig)
      : emptyColumnConfig('1', commomColumnConfig),
    ['organization', 'company'].includes(context)
      ? businessAreaNameColumnConfig(commomColumnConfig)
      : emptyColumnConfig('2', commomColumnConfig),
    codeColumnConfig(commomColumnConfig),
    statusColumnConfig(commomColumnConfig),
    runTimeColumnConfig(commomColumnConfig),
  ];
};

export const getWorkflowsColumns = ({
  onClick,
}: WorkflowColumnsConfig = {}): Array<TableColumn<TableRow>> => {
  return [
    nameColumnConfig(
      {
        ...commomColumnConfig,
        render: (value, row) => (
          <div
            className="flex items-center gap-2.5 overflow-hidden"
            onClick={() => onClick?.(row)}
          >
            <OverflowTooltip text={value} textClassName="font-black" />
          </div>
        ),
      },
      'workflow',
    ),
    emptyColumnConfig('0', commomColumnConfig),
    emptyColumnConfig('1', commomColumnConfig),
    codeColumnConfig(commomColumnConfig),
    statusColumnConfig(commomColumnConfig),
    runTimeColumnConfig(commomColumnConfig),
  ];
};

export const getTasksColumns = ({ onClick }: TaskColumnsConfig = {}): Array<
  TableColumn<TableRow>
> => {
  return [
    nameColumnConfig(
      {
        ...commomColumnConfig,
        render: (value, row) => (
          <div
            className="flex items-center gap-2.5 overflow-hidden"
            onClick={() => onClick?.(row)}
          >
            <OverflowTooltip text={value} textClassName="font-black" />
          </div>
        ),
      },
      'task',
    ),
    codeColumnConfig(commomColumnConfig),
    statusColumnConfig(commomColumnConfig),
    usersCountColumnConfig(commomColumnConfig),
    dividerColumnConfig({ key: 'divider-1' }),
    periodColumnConfig(commomColumnConfig),
    workloadColumnConfig(commomColumnConfig),
    estimatedCostColumnConfig(commomColumnConfig),
    dividerColumnConfig({ key: 'divider-2' }),
    automatedColumnConfig(commomColumnConfig),
  ];
};

const mapProcessModelIntoProcessWithWorkflows = (
  process: ProcessModel,
): ProcessWithWorkflows => ({
  id: process.id,
  name: process.name,
  code: process.code,
  status: process.status,
  runTime: process.runTime,
  organization: process.organization,
  company: process.company,
  businessAreaName: process.businessAreaName,
  canDelete: process.canDelete,
  rows:
    process.workflows?.map((workflow) => ({
      id: workflow.id,
      name: workflow.name,
      code: workflow.code,
      order: workflow.order,
      status: workflow.status,
      runTime: workflow.runTime,
      rows:
        workflow.tasks?.map((task) => ({
          id: task.id,
          name: task.name,
          code: task.code,
          status: task.status,
          usersCount: task.usersCount,
          periodicityId: task.periodicityId,
          workload: task.workload,
          estimatedCost: task.estimatedCost,
          automated: task.automated,
        })) ?? [],
    })) ?? [],
});

export const mapProcessesModelIntoProcessWithWorkflows = (
  processes: ProcessModel[],
) => processes?.map(mapProcessModelIntoProcessWithWorkflows) ?? [];
