import { TenantContext } from '@/components';
import { SortDirection } from '@/components/Table/types';
import { companiesRepo } from '@/constants/providers';
import { CompanyModel } from '@/modules/Companies/domain';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { CompanyKeys } from './keys';

export const useGetCompaniesByOrganization = (
  organizationId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<CompanyModel[]>,
    any,
    DataWithPagination<CompanyModel[]>
  >,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<DataWithPagination<CompanyModel[]>>(
    [
      ...CompanyKeys.BY_ORGANIZATION_PAGED(organizationId, {
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      }),
      currentSelectedTenant?.id,
    ],
    () =>
      companiesRepo.getCompaniesByOrganization(
        organizationId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );
};

export const useGetAllCompaniesByOrganization = (organizationId?: number) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<CompanyModel[]>(
    [...CompanyKeys.BY_ORGANIZATION(organizationId), currentSelectedTenant?.id],
    () => {
      if (!organizationId) return [];
      return companiesRepo.getAllCompaniesByOrganization(organizationId);
    },
    {
      enabled: !!organizationId,
      cacheTime: 1000 * 60 * 5,
    },
  );
};
