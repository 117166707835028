import {
  convertSecondsToTimeString,
  formatBigNumber,
  getTotalDurationBySeconds,
  trunc,
} from '@/utils';
import { ReactElement, useMemo } from 'react';
import { Circle } from '../Circle/Circle';
import { Container, WarningMessage } from '../Container';
import { ExpandIcon } from '../Icons';
import { PieChart, PieData } from '../PieChart';

enum ValueType {
  hours = 'h',
  percentage = '%',
  coin = '€',
}

interface PieChartCardProps {
  data: Array<PieData>;
  chartTitle: string;
  valueType: keyof typeof ValueType;
  subtitle?: string;
  tooltip?: string;
  convert?: boolean; //TODO: this prop is to convert count/percentage to Time in Total label on Process and Workflow details page. Rename it or find another way to do it
  onExpand?: () => void;
  warningMessage?: WarningMessage[];
}
export const PieChartCard = ({
  data,
  valueType,
  chartTitle,
  subtitle,
  convert,
  tooltip,
  onExpand,
  warningMessage = [],
}: PieChartCardProps): ReactElement => {
  const total = useMemo(
    () =>
      data.reduce((acc, obj) => {
        return acc + (obj.value ?? 0);
      }, 0),
    [data],
  );

  const tagsData = useMemo(() => {
    if (valueType === 'percentage') {
      return data.map((obj) => ({
        ...obj,
        value: obj.value ? Math.round((obj.value / Number(total)) * 100) : 0,
      }));
    }

    if (valueType === 'coin') {
      return data.map((obj) => ({
        ...obj,
        value: trunc(obj.value, 2),
      }));
    }

    if (valueType === 'hours') {
      return data.map((obj) => {
        const seconds = obj.value ?? 0;

        const { hours } = getTotalDurationBySeconds(seconds);
        return {
          ...obj,
          value: trunc(hours, 2),
        };
      });
    }

    return data;
  }, [data, total, valueType]);

  const formattedTotal = useMemo(() => {
    if (valueType === 'hours') {
      const { hours } = getTotalDurationBySeconds(total);
      return Math.trunc(hours).toString() ?? '0h';
    }

    if (valueType === 'coin') {
      return formatBigNumber(total);
    }

    if (convert) {
      return convertSecondsToTimeString(total) ?? '0h';
    }

    return formatBigNumber(Math.round(total));
  }, [total, valueType, convert]);

  const formatTagValue = (value: number) => {
    if (valueType === 'percentage') {
      return `${formatBigNumber(value)}%`;
    }
    if (valueType === 'coin') {
      return `${formatBigNumber(value)}€`;
    }
    if (valueType === 'hours') {
      return `${value}${ValueType[valueType]}`;
    }
    return formatBigNumber(value);
  };

  return (
    <Container
      title={chartTitle}
      className="flex-1 text-sm font-black"
      tooltip={tooltip}
      warningMessages={warningMessage}
      actions={
        onExpand && (
          <ExpandIcon
            className="cursor-pointer self-start"
            onClick={onExpand}
          />
        )
      }
    >
      <div className="flex items-stretch gap-5">
        <div className="flex items-center justify-center self-stretch">
          <PieChart
            data={data}
            mainLabel={formattedTotal}
            subtitle={subtitle}
          />
        </div>
        <div className="flex flex-col items-stretch justify-center gap-1">
          <ul>
            {tagsData.map(({ name, value, fill }) => (
              <li key={name} className="mb-1">
                <div className="flex min-w-52 items-center justify-between gap-2">
                  <div className="inline-flex items-center gap-2">
                    <Circle color={fill} />
                    <span className="font-medium">{name}</span>
                  </div>
                  <span className="font-black">{formatTagValue(value)}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Container>
  );
};
