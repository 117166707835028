import { Tooltip } from '@/components';
import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';
import { formatNumber } from '..';

type RunCostColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const runCostColumnConfig = <T extends TableRow>({
  options,
}: RunCostColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'runCost',
  label: <Trans i18nKey="run_cost" />,
  render: (value: number) => (
    <Tooltip showArrow content={`${formatNumber(value, 4)}€`}>
      {formatNumber(value)}€
    </Tooltip>
  ),
  acessorKey: 'runCost',
  sortable: false,
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
