import { Button, Dropdown, FieldInfo, Input, SwitchStatus } from '@/components';
import { Spinner } from '@/components/Spinner';
import { UserModel } from '@/modules/Users/domain';
import { useGetUserProfiles } from '@/modules/Users/queries/get-user-profiles';
import { UserRole, UserSchema, UserSchemaType } from '@/modules/Users/types';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactElement, useEffect, useMemo } from 'react';
import {
  Controller,
  SubmitHandler,
  useForm,
  UseFormReset,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

interface UserModalProps {
  handleOnSubmit: (
    e: UserSchemaType,
    reset?: UseFormReset<UserSchemaType>,
  ) => void;
  handleClose: () => void;
  isLoading: boolean;
  disableFields?: string[];
  showRole?: boolean;
  role?: UserRole;
  user?: UserModel;
  type: 'create' | 'edit';
}

export const UserModal = ({
  handleOnSubmit,
  handleClose,
  isLoading,
  disableFields,
  showRole = false,
  user,
  type,
}: UserModalProps): ReactElement => {
  const { t } = useTranslation();

  const { data: roles } = useGetUserProfiles();
  const roleDropdownOptions = useMemo(
    () => getDropdownArray(roles ?? []),
    [roles],
  );

  const {
    clearErrors,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    reset,
  } = useForm<UserSchemaType>({
    resolver: zodResolver(UserSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
    defaultValues: {
      status: true,
      access: false,
    },
  });

  useEffect(() => {
    clearErrors();
    if (!user) {
      return;
    }

    setValue('firstName', user.firstName || '');
    setValue('lastName', user.lastName || '');
    setValue('email', user.email || '');
    setValue('role', user.profileTypeId);
    setValue('status', user.status);
    setValue('access', user.access);
  }, [clearErrors, setValue, user, showRole]);

  const onSubmit: SubmitHandler<UserSchemaType> = (data) => {
    handleOnSubmit(data, reset);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className=" flex h-112 flex-col overflow-y-auto px-2">
        <FieldInfo
          title={t('first_name')}
          errorText={
            errors.firstName?.message ? t(errors.firstName.message) : undefined
          }
          hasError={!!errors.firstName?.message}
        >
          <Input
            isDisabled={isLoading}
            className="w-full bg-brightGray italic"
            placeHolder={t('first_name')}
            name="firstName"
            register={register}
          />
        </FieldInfo>
        <FieldInfo
          title={t('last_name')}
          errorText={
            errors.lastName?.message ? t(errors.lastName.message) : undefined
          }
          hasError={!!errors.lastName?.message}
        >
          <Input
            isDisabled={isLoading}
            className="w-full bg-brightGray italic"
            placeHolder={t('last_name')}
            name="lastName"
            register={register}
          />
        </FieldInfo>
        <FieldInfo
          title={t('email')}
          errorText={
            errors.email?.message ? t(errors.email.message) : undefined
          }
          hasError={!!errors.email?.message}
        >
          <Input
            isDisabled={disableFields?.includes('email')}
            className="w-full bg-brightGray italic"
            placeHolder={t('email')}
            name="email"
            register={register}
          />
        </FieldInfo>

        <FieldInfo
          title={t('status')}
          errorText={
            errors.status?.message ? t(errors.status.message) : undefined
          }
          hasError={!!errors.status?.message}
        >
          <Controller
            name="status"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <div className="flex gap-2">
                <SwitchStatus checked={value} onChange={onChange} />
                <span
                  className={twMerge(
                    'text-sm capitalize text-blueNuit',
                    !value && 'text-primaryBlue',
                  )}
                >
                  {t(value ? 'active' : 'inactive')}
                </span>
              </div>
            )}
          />
        </FieldInfo>

        <FieldInfo
          title={t('access')}
          errorText={
            errors.access?.message ? t(errors.access.message) : undefined
          }
          hasError={!!errors.access?.message}
        >
          <Controller
            name="access"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <div className="flex gap-2">
                <SwitchStatus checked={value} onChange={onChange} />
                <span
                  className={twMerge(
                    'text-sm capitalize text-blueNuit',
                    !value && 'text-primaryBlue',
                  )}
                >
                  {t(value ? 'active' : 'inactive')}
                </span>
              </div>
            )}
          />
        </FieldInfo>
        {showRole && (
          <FieldInfo
            title={t('role')}
            errorText={
              errors.role && errors.role?.message
                ? t(errors.role.message)
                : undefined
            }
            hasError={!!errors.role?.message}
          >
            <Controller
              name="role"
              control={control}
              rules={{ required: true }}
              render={({
                field: { onChange, value, name, ...otherFieldProps },
              }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                  placeholder={t('role')}
                  options={roleDropdownOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={value}
                  disabled={disableFields && disableFields.includes(name)}
                />
              )}
            />
          </FieldInfo>
        )}
      </div>

      <div className="mt-8 flex justify-between">
        <Button onClick={handleClose} variant="outline" className="h-9 w-24">
          {t('cancel')}
        </Button>
        <Button buttonType="submit" className="h-9 w-24" isDisabled={isLoading}>
          {t(type === 'create' ? 'create' : 'confirm')}
          {isLoading && <Spinner className="ml-4 h-5 w-5" />}
        </Button>
      </div>
    </form>
  );
};
