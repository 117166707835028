import { mapPagination } from '@/shared/mappers';
import { parseCronExpression } from '@/utils/parse-cron-expression';
import {
  AutomationModel,
  AutomationRequestDetailsModel,
  AutomationRequestModel,
  FileModel,
  RequestHistoryModel,
  RequestToolModel,
  RequestUserModel,
  SchedulingModel,
} from '../domain';
import {
  ApiResponseAutomationRequests,
  ApiResponseAutomations,
  AutomationJson,
  AutomationRequestDetailsJson,
  AutomationRequestJson,
  FileJson,
  PagedAutomationRequests,
  PagedAutomations,
  RequestHistoryJson,
  RequestToolJson,
  RequestUserJson,
} from '../types';

const mapFiles = (files?: Array<FileJson>): Array<FileModel> => {
  return files
    ? files.map((file) => ({
        id: file.id ?? '',
        name: file.name ?? '',
        url: file.url ?? '',
        automationFileId: file.automationFileId ?? '',
      }))
    : [];
};

export const mapScheduling = (
  scheduleCronExpression?: string,
  lastRun?: string,
): SchedulingModel => {
  const lastRunDate = lastRun ? new Date(lastRun) : undefined;
  if (!scheduleCronExpression) {
    return { lastRun: lastRunDate, scheduleCron: '' };
  }
  const expression = parseCronExpression(scheduleCronExpression);

  return {
    lastRun: lastRunDate,
    nextRun: expression.next().toDate(),
    scheduleCron: scheduleCronExpression,
  };
};

export const automationMapper = (
  automationJson: AutomationJson,
): AutomationModel => ({
  id: automationJson.id ?? 0,
  stage: automationJson?.stage ?? '',
  stageId: automationJson.stageId ?? 0,
  version: automationJson.version ?? '',
  developer: automationJson.developer ?? '',
  epicId: automationJson.epicId ?? '',
  name: automationJson.name ?? '',
  description: automationJson.description ?? '',
  scheduling: mapScheduling(
    automationJson.scheduleCronExpression,
    automationJson.lastRun,
  ),
  organizationId: automationJson.organizationId ?? 0,
  organization: automationJson.organization ?? '',
  companyId: automationJson.companyId ?? 0,
  company: automationJson.company ?? '',
  workflowId: automationJson.workflowId ?? 0,
  processId: automationJson.processId ?? 0,
  businessAreaId: automationJson.businessAreaId ?? 0,
  businessAreaName: automationJson.businessAreaName ?? '',
  status: automationJson.status ?? false,
  averageCases: automationJson.averageCases ?? 0,
  pdds: mapFiles(automationJson?.pdds),
  sdds: mapFiles(automationJson?.sdds),
  manuals: mapFiles(automationJson?.manuals),
  supportDocuments: mapFiles(automationJson?.supportDocuments),
  code: automationJson.code ?? '',
  priority: automationJson.priority ?? '',
  automationFileId: automationJson.automationFileId ?? 0,
  orchestratorProcessId: automationJson.orchestratorProcessId ?? '',
  runTime: automationJson.runTime ?? '',
  developerId: automationJson.developerId ?? 0,
  tasks: automationJson?.tasks ?? [],
  success: automationJson.success ?? 0,
  canDelete: automationJson?.canDelete ?? false,
  infrastructureCostsCount: automationJson?.infrastructureCostsCount ?? 0,
});

export const automationRequestMapper = (
  automationRequestJson: AutomationRequestJson,
): AutomationRequestModel => ({
  id: automationRequestJson.id ?? 0,
  name: automationRequestJson?.name ?? '',
  owner: automationRequestJson.owner ?? 0,
  description: automationRequestJson.description ?? '',
  users: automationRequestJson.users ?? [],
  duration: automationRequestJson.duration ?? 0,
  frequency: automationRequestJson.frequency ?? '',
  priority: automationRequestJson.priority ?? '',
  tools: automationRequestJson.tools ?? [],
  additionalInformation: automationRequestJson.additionalInformation ?? '',
  requestOwner: automationRequestJson.requestOwner ?? '',
  date: automationRequestJson.date ?? undefined,
  requestStage: mapRequestStage(automationRequestJson.requestStage ?? ''),
});

export const automationRequestDetailsMapper = (
  automationRequestJson: AutomationRequestDetailsJson,
): AutomationRequestDetailsModel => ({
  id: automationRequestJson.id ?? 0,
  name: automationRequestJson?.name ?? '',
  owner: automationRequestJson.owner ?? 0,
  requestOwner: automationRequestJson.requestOwner ?? '',
  requestStage: 'Submitted', //mapRequestStage(automationRequestJson.requestStage ?? ''),
  isCreator: true, //automationRequestJson.isCreator,
  submittedDate: automationRequestJson.submittedDate
    ? new Date(automationRequestJson.submittedDate)
    : undefined,
  inReviewDate: automationRequestJson.inReviewDate
    ? new Date(automationRequestJson.inReviewDate)
    : undefined,
  blockedDate: automationRequestJson.blockedDate
    ? new Date(automationRequestJson.blockedDate)
    : undefined,
  estimatedEffortTime: automationRequestJson.estimatedEffortTime ?? undefined,
  rejectedDate: automationRequestJson.rejectedDate
    ? new Date(automationRequestJson.rejectedDate)
    : undefined,
  rejectedInfo: automationRequestJson.rejectedInfo ?? '',
  analysedDate: automationRequestJson.analysedDate
    ? new Date(automationRequestJson.analysedDate)
    : undefined,
  approvedDate: automationRequestJson.approvedDate
    ? new Date(automationRequestJson.approvedDate)
    : undefined,
  declinedDate: automationRequestJson.declinedDate
    ? new Date(automationRequestJson.declinedDate)
    : undefined,
  canceledDate: automationRequestJson.canceledDate
    ? new Date(automationRequestJson.canceledDate)
    : undefined,
  blockedInfo: automationRequestJson.blockedInfo ?? '',
  hourlyRate: automationRequestJson.hourlyRate ?? undefined,
  businessAreaId: automationRequestJson?.businessAreaId ?? 0,
  companyId: automationRequestJson?.companyId ?? 0,
  organizationId: automationRequestJson?.organizationId ?? 0,
  timeSavedPerRun: automationRequestJson?.timeSavedPerRun ?? 0,
  returnPerRun: automationRequestJson?.returnPerRun ?? 0,
  runsToAchieveROI: automationRequestJson?.runsToAchieveROI ?? 0,
  monthsToAchieveROI: automationRequestJson?.monthsToAchieveROI ?? 0,
  details: mapRequestHistory(automationRequestJson.details) ?? [],
});

const mapRequestStage = (stage: string) => {
  const stageMapping: any = {
    Submitted: 'Submitted',
    InReview: 'In Review',
    Blocked: 'Blocked',
    RejectedAutomationTeam: 'Rejected',
    AnalysisConcluded: 'Analysed',
    ApprovedForAutomation: 'Approved',
    RejectedBusiness: 'Declined',
    Canceled: 'Canceled',
  };

  return stageMapping[stage] || 'Unknown';
};

export const automationsMapper = (
  automationsJson: Array<AutomationJson>,
): Array<AutomationModel> => automationsJson.map(automationMapper);

export const automationRequestsMapper = (
  automationsJson: Array<AutomationRequestJson>,
): Array<AutomationRequestModel> =>
  automationsJson.map(automationRequestMapper);

export const automationsWithPaginationMapper = (
  automationsJson: ApiResponseAutomations<PagedAutomations>,
) => {
  const { data } = automationsJson;
  return {
    items: automationsMapper(data.automations.items),
    ...mapPagination(data.automations),
  };
};

export const automationRequestsWithPaginationMapper = (
  automationsJson: ApiResponseAutomationRequests<PagedAutomationRequests>,
) => {
  const { data } = automationsJson;
  return {
    items: automationRequestsMapper(data.requests.items),
    ...mapPagination(data.requests),
  };
};

const mapUsers = (users?: Array<RequestUserJson>): Array<RequestUserModel> => {
  return users
    ? users.map((user) => ({
        id: user.id ?? '',
        name: user.name ?? '',
      }))
    : [];
};

const mapTools = (tools?: Array<RequestToolJson>): Array<RequestToolModel> => {
  return tools
    ? tools.map((tool) => ({
        id: tool.id ?? '',
        name: tool.name ?? '',
      }))
    : [];
};

const mapRequestHistory = (
  details?: Array<RequestHistoryJson>,
): Array<RequestHistoryModel> => {
  return details
    ? details.map((detail) => ({
        id: detail.id ?? 0,
        requestId: detail.requestId ?? 0,
        description: detail.description ?? '',
        duration: detail.duration ?? 0,
        frequency: detail.frequency ?? '',
        priority: detail.priority ?? '',
        users: mapUsers(detail.users),
        tools: mapTools(detail.tools),
      }))
    : [];
};
