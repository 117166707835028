import { BusinessAreasKey } from '@/modules/BusinessAreas/queries/keys';
import { CompanyKeys } from '@/modules/Companies/queries';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { ProcessKey } from '@/modules/Processes/queries';
import { useCreateProcess } from '@/modules/Processes/queries/create-process';
import { CreateProcessJson } from '@/modules/Processes/types';
import { ProcessSchemaType } from '@/modules/Processes/types/process-schema';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ProcessModal } from '../ProcessModal';

interface CreateProcessModalProps {
  isOpen: boolean;
  handleClose: () => void;
  disableFields?: string[];
}

export const CreateProcessModal = ({
  isOpen,
  handleClose,
  disableFields,
}: CreateProcessModalProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useCreateProcess();

  const handleConfirm = (data: ProcessSchemaType) => {
    const convertedData: CreateProcessJson = {
      name: data.processName,
      description: data.description,
      businessAreaId: data.businessAreaId,
      ownerId: data.processOwnerId,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_creating_process'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(ProcessKey.ALL);
        queryClient.invalidateQueries(OrganizationKeys.ALL);
        queryClient.invalidateQueries(CompanyKeys.ALL);
        queryClient.invalidateQueries(BusinessAreasKey.ALL);

        handleClose();
        toast.success(t('success_creating_process'));
      },
    });
  };

  return (
    <ProcessModal
      handleClose={handleClose}
      type="create"
      handleOnSubmit={handleConfirm}
      title={t('create_process')}
      isOpen={isOpen}
      isLoading={isLoading}
      disableFields={disableFields}
    />
  );
};
