import { Periodicity } from '@/modules/Tasks/types/periodicity';
import { useTranslation } from 'react-i18next';

type PeriodicityColumnProps = {
  periodicity: Periodicity;
};

export const PeriodicityColumn = ({ periodicity }: PeriodicityColumnProps) => {
  const { t } = useTranslation();
  return (
    <div className="overflow-hidden text-ellipsis">
      {t(`periodicity_types.${Periodicity[periodicity]}`)}
    </div>
  );
};
