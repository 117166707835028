import { TenantContext } from '@/components';
import { workflowsRepo } from '@/constants/providers';
import { WorkflowModel } from '@/modules/Workflows/domain';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { WorkflowKey } from './keys';

export const useGetWorkflowsTasksByProcessId = (processId: number) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<WorkflowModel[]>(
    [...WorkflowKey.TASKS_BY_PROCESS(processId), currentSelectedTenant?.id],
    () => workflowsRepo.getWorkflowsTasksByProcessId(processId),
    {
      enabled: !!processId,
      cacheTime: 1000 * 60 * 5, // 5 minutes
    },
  );
};
