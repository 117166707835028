import { useTailwindTheme } from '@/hooks';
import { ReactElement, useMemo } from 'react';
import { Pie, PieChart as RechartPieChart } from 'recharts';
import { renderPieChartInnerText } from '../';

export interface PieData {
  name: string;
  value: number;
  fill?: string;
  textColor?: string;
}

interface PieChartProps {
  data: PieData[];
  width?: number;
  height?: number;
  mainLabel?: string;
  subtitle?: string;
}

export const PieChart = ({
  data = [],
  width = 129,
  height = 129,
  mainLabel = '',
  subtitle = '',
}: PieChartProps): ReactElement => {
  const theme = useTailwindTheme();
  const chartData = useMemo(() => {
    if (!data.length || data.every((item) => item.value === 0)) {
      return [
        ...data,
        {
          name: 'empty',
          value: 1,
          fill: theme.colors.intercoastalGray,
        },
      ];
    }

    return data;
  }, [data, theme.colors.intercoastalGray]);
  return (
    <div>
      <RechartPieChart
        width={width}
        height={height}
        data={chartData}
        margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
      >
        <Pie
          data={chartData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius="100%"
          innerRadius="70%"
          paddingAngle={0}
          stroke="none"
        />
        {renderPieChartInnerText({
          mainLabel,
          subtitle,
          cx: '50%',
          cy: '50%',
        })}
      </RechartPieChart>
    </div>
  );
};
