import { businessAreasRepo } from '@/constants/providers';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { BusinessAreasKey } from './keys';

export const useBusinessAreaAutomationsPerformance = (
  businessAreaId: number,
  dateRange?: DateRange,
) =>
  useQuery({
    queryKey: BusinessAreasKey.AUTOMATIONS_PERFORMANCE(
      businessAreaId,
      dateRange,
    ),
    queryFn: () =>
      businessAreasRepo.getAutomationsPerformanceById(
        businessAreaId,
        dateRange,
      ),
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });

type UseBusinessAreaLabourTimeAutomatedProps = {
  businessAreaId: number;
  enabled?: boolean;
  dateRange?: DateRange;
  filter?: string;
};

export const useBusinessAreaLabourTimeAutomated = ({
  businessAreaId,
  enabled,
  dateRange,
  filter,
}: UseBusinessAreaLabourTimeAutomatedProps) => {
  return useInfiniteQuery(
    [BusinessAreasKey.LABOUR_AUTOMATED(businessAreaId, dateRange, filter)],
    ({ pageParam = 1 }) =>
      businessAreasRepo.getBusinessAreaLabourTimeAutomatedById(
        businessAreaId,
        dateRange,
        pageParam,
        filter,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};

type UseBusinessAreaTotalReturnProps = {
  businessAreaId: number;
  enabled?: boolean;
  dateRange?: DateRange;
  filter?: string;
};

export const useBusinessAreaTotalReturn = ({
  businessAreaId,
  enabled = true,
  dateRange,
  filter,
}: UseBusinessAreaTotalReturnProps) => {
  return useInfiniteQuery(
    [BusinessAreasKey.TOTAL_RETURN(businessAreaId, dateRange, filter)],
    ({ pageParam = 1 }) =>
      businessAreasRepo.getBusinessAreaTotalReturnById(
        businessAreaId,
        dateRange,
        pageParam,
        filter,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};
