import { mapPagination } from '@/shared/mappers';
import { TaskAutomationsPerformance, TaskModel } from '../domain';
import {
  ApiResponseTasks,
  PagedTasks,
  TaskAutomationsPerformanceJson,
  TaskJson,
} from '../types/task-json';

export const taskMapper = (taskJson: TaskJson): TaskModel => ({
  id: taskJson?.id ?? 0,
  name: taskJson?.name ?? '',
  description: taskJson?.description ?? '',
  code: taskJson?.code ?? '',
  businessAreaId: taskJson?.businessAreaId ?? 0,
  businessAreaName: taskJson?.businessAreaName ?? '',
  workflowName: taskJson?.workflowName ?? '',
  workflowId: taskJson?.workflowId ?? 0,
  company: taskJson?.company ?? '',
  companyId: taskJson?.companyId ?? 0,
  organization: taskJson?.organization ?? '',
  organizationId: taskJson?.organizationId ?? 0,
  status: taskJson?.status ?? false,
  usersCount: taskJson?.usersCount ?? 0,
  automationsCount: taskJson?.automationsCount ?? 0,
  periodicityId: taskJson?.periodicityId,
  workload: taskJson?.workload ?? 0,
  estimatedCost: taskJson?.estimatedCost ?? 0,
  automated: taskJson.automated ?? false,
  runTime: taskJson?.runTime ?? 0,
  averageCases: taskJson?.averageCases ?? 0,
  sucess: taskJson?.sucess ?? 0,
  supportDocuments:
    taskJson?.supportDocuments?.map((doc) => ({
      id: doc.id ?? '',
      name: doc.name ?? '',
      url: doc.url ?? '',
      taskFileId: doc.taskFileId ?? '',
    })) ?? [],
  order: taskJson?.order ?? 0,
  automationId: taskJson?.automationId,
  workloadTypeId: taskJson?.workloadTypeId ?? 0,
  workloadType: taskJson?.workloadType ?? '',
  predictedManualTime: 0,
  predictedAverageManualTime: 0,
  automationRunTime: 0,
  averageAutomationRunTime: 0,
  totalInvestment: 0,
  totalReturn: 0,
  averageCost: 0,
  averageManualCost: 0,
  workflow: taskJson?.workflow ?? '',
  processId: taskJson?.processId ?? 0,
  processName: '',
  profileType: '',
  canDelete: false,
  totalExecutions: 0,
});

export const tasksWithPaginationMapper = (
  organizationJson: ApiResponseTasks<PagedTasks>,
) => {
  const { data } = organizationJson;
  return {
    items: tasksMapper(data.tasks.items),
    ...mapPagination(data.tasks),
  };
};

export const tasksMapper = (tasksJson: Array<TaskJson>): Array<TaskModel> =>
  tasksJson.map(taskMapper);

export const taskAutomationsPerformanceMapper = (
  performance: TaskAutomationsPerformanceJson,
): TaskAutomationsPerformance => ({
  predictedManualTime: performance?.predictedManualTime ?? 0,
  automationRunTime: performance?.automationRunTime ?? 0,
  totalExecutions: performance?.totalExecutions ?? 0,
  totalReturn: performance?.totalReturn ?? 0,
  averageCost: performance?.averageCost ?? 0,
  totalInvestment: performance?.totalInvestment ?? 0,
});
