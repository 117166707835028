import { useGetAllBusinessAreasByCompany } from '@/modules/BusinessAreas/queries';
import { useGetAllCompaniesByOrganization } from '@/modules/Companies/queries';
import { useGetAllOrganizations } from '@/modules/Organizations/queries';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { useMemo, useState } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../Dropdown';
import { FieldInfo } from '../FieldInfo';

interface FilterBusinessAreaFormGroupProps<T extends FieldValues> {
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
  disableFields?: string[];
  errors: FieldErrors<T>;
  control: Control<T, T>;
}

export const FilterBusinessAreaFormGroup = <T extends FieldValues>({
  organizationId,
  companyId,
  businessAreaId,
  disableFields,
  errors,
  control,
}: FilterBusinessAreaFormGroupProps<T>) => {
  const { t } = useTranslation();

  const [organization, setOrganization] = useState(organizationId);
  const [company, setCompany] = useState(companyId);

  const { data: organizations } = useGetAllOrganizations();
  const { data: companies } = useGetAllCompaniesByOrganization(organization);
  const { data: businessAreas } = useGetAllBusinessAreasByCompany(company);

  const dropdownOrganizationsOptions = useMemo(
    () =>
      getDropdownArray(
        organizations?.filter(
          (organization) =>
            organization.status || organization.id === organizationId,
        ) ?? [],
      ),
    [organizationId, organizations],
  );

  const dropdownCompaniesOptions = useMemo(
    () =>
      getDropdownArray(
        companies?.filter(
          (company) => company.status || company.id === companyId,
        ) ?? [],
      ),
    [companies, companyId],
  );

  const dropdownBusinessAreasOptions = useMemo(
    () =>
      getDropdownArray(
        businessAreas?.filter(
          (businessArea) =>
            businessArea.status || businessArea.id === businessAreaId,
        ) ?? [],
      ),
    [businessAreaId, businessAreas],
  );

  return (
    <>
      <FieldInfo
        title={t('organization')}
        errorText={
          errors.organizationId?.message
            ? t(errors.organizationId.message as string)
            : undefined
        }
        hasError={!!errors.organizationId?.message}
        hidden={disableFields?.includes('organizationId')}
      >
        <Dropdown
          className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
          placeholder={t('organization')}
          options={dropdownOrganizationsOptions}
          onSelect={(val) => setOrganization(val.value)}
          value={organization}
          disabled={disableFields?.includes('organizationId')}
        />
      </FieldInfo>
      <FieldInfo
        title={t('company')}
        errorText={
          errors.companyId?.message
            ? t(errors.companyId.message as string)
            : undefined
        }
        hasError={!!errors.companyId?.message}
        hidden={disableFields?.includes('companyId')}
      >
        <Dropdown
          className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
          placeholder={t('company')}
          options={dropdownCompaniesOptions}
          onSelect={(val) => setCompany(val.value)}
          value={company}
          disabled={disableFields?.includes('companyId')}
        />
      </FieldInfo>
      <FieldInfo
        title={t('businessArea')}
        errorText={
          errors.businessAreaId?.message
            ? t(errors.businessAreaId.message as string)
            : undefined
        }
        hasError={!!errors.businessAreaId?.message}
        hidden={disableFields?.includes('businessAreaId')}
      >
        <Controller
          name={'businessAreaId' as Path<T>}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value, ...otherFieldProps } }) => (
            <Dropdown
              {...otherFieldProps.onBlur}
              className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
              placeholder={t('businessArea')}
              options={dropdownBusinessAreasOptions}
              onSelect={(val) => onChange(val.value)}
              value={value}
              disabled={disableFields?.includes('businessAreaId')}
            />
          )}
        />
      </FieldInfo>
    </>
  );
};
