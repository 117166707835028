import { CustomPageContainer, TabItemProps } from '@/components';
import { EnvironmentVariable } from '@/constants';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutomationRequestsTab } from './components/AutomationRequestsTab';
import { AutomationsTab } from './components/AutomationsTab';
import { ManageAutomationsTab } from './types';

export const ManageAutomationsPage = (): ReactElement => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(
    ManageAutomationsTab.Automation,
  );
  const tabs: Array<TabItemProps> = useMemo(() => {
    const tabs = [{ label: t('automations') }];
    if (EnvironmentVariable.DISABLE_AUTOMATION_REQUESTS) {
      return tabs;
    }

    tabs.push({ label: t('requests') });

    return tabs;
  }, [t]);

  const onTabChange = useCallback((tabIndex: ManageAutomationsTab) => {
    setSelectedTab(tabIndex);
  }, []);

  return (
    <CustomPageContainer>
      <CustomPageContainer.Header title={t('manage_automations')} />
      {selectedTab === ManageAutomationsTab.Automation && (
        <AutomationsTab
          activeTab={selectedTab}
          tabs={tabs}
          onTabChange={onTabChange}
        />
      )}
      {selectedTab === ManageAutomationsTab.Request && (
        <AutomationRequestsTab
          activeTab={selectedTab}
          tabs={tabs}
          onTabChange={onTabChange}
        />
      )}
    </CustomPageContainer>
  );
};
