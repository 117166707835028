import {
  forwardRef,
  MouseEvent,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { DropdownInput } from '..';
import { Button } from '../Button';

export interface MultiSelectDropdownOption<T> {
  label: string;
  value: T;
}

interface MultiSelectDropdownProps<T> {
  className?: string;
  placeholder?: string;
  buttonRef?: RefObject<HTMLButtonElement>;
  options: MultiSelectDropdownOption<T>[];
  value?: T[];
  onSelect?: (option: MultiSelectDropdownOption<T>, newValue: T[]) => void;
  allowCustomOption?: boolean;
}

const MultiSelectDropdown = forwardRef<
  HTMLDivElement,
  MultiSelectDropdownProps<unknown>
>(
  (
    {
      className,
      options,
      placeholder,
      buttonRef,
      value = [],
      onSelect,
      allowCustomOption = false,
    },
    ref,
  ): ReactElement => {
    const buttonWrapperRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [customInputValue, setCustomInputValue] = useState<string>('');
    const [customOptions, setCustomOptions] = useState<
      MultiSelectDropdownOption<unknown>[]
    >([]);

    const handleClickOutside: EventListener = (e) => {
      if (!buttonWrapperRef?.current?.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const selectedOptionLabels = useMemo(() => {
      const allOptions = [...options, ...customOptions];
      const selectedOptions = allOptions?.filter((option) =>
        value.includes(option.value),
      );
      return selectedOptions.length > 0
        ? selectedOptions.map((option) => option.label).join(', ')
        : '';
    }, [value, options, customOptions]);

    const handleOptionClick = useCallback(
      (option: MultiSelectDropdownOption<unknown>) => () => {
        const newValue = value.includes(option.value)
          ? value.filter((val) => val !== option.value)
          : [...value, option.value];

        onSelect?.(option, newValue);
        setIsOpen(false);
      },
      [onSelect, value],
    );

    const handleCustomOptionSubmit = useCallback(() => {
      if (customInputValue.trim() !== '') {
        const newOption: MultiSelectDropdownOption<string> = {
          label: customInputValue,
          value: customInputValue,
        };
        setCustomOptions((prev) => [...prev, newOption]);

        const newValue = [...value, customInputValue];
        onSelect?.(newOption, newValue);
        setCustomInputValue('');
        setIsOpen(false);
      }
    }, [customInputValue, onSelect, value]);

    const handleSelectDropdownButtonClick = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setIsOpen((prev) => !prev);
    };

    return (
      <div
        ref={buttonWrapperRef || ref}
        className={twMerge('relative', className)}
      >
        <DropdownInput
          placeholder={placeholder}
          isOpen={isOpen}
          buttonRef={buttonRef}
          inputValue={selectedOptionLabels || undefined}
          onButtonClick={handleSelectDropdownButtonClick}
        >
          <ul className="max-h-48">
            {options.map((opt: MultiSelectDropdownOption<unknown>) => (
              <li className="group flex" key={`${opt.value}`}>
                <Button
                  variant="text"
                  className={twMerge(
                    'flex w-full flex-grow text-ellipsis whitespace-nowrap bg-white p-2 text-start outline-none hover:bg-spartanBlue hover:text-white group-last:rounded-b-md',
                    value.includes(opt.value)
                      ? 'bg-spartanBlue text-white'
                      : 'text-primaryBlue',
                  )}
                  onClick={handleOptionClick(opt)}
                >
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                    {opt.label}
                  </span>
                </Button>
              </li>
            ))}
            {allowCustomOption && (
              <li className="group flex">
                <input
                  type="text"
                  className="w-full rounded-md border border-gray-300 p-2 text-md outline-none focus:border-blue-500 active:border-blue-500"
                  placeholder="Type to add custom option"
                  value={customInputValue}
                  onChange={(e) => setCustomInputValue(e.target.value)}
                />
                <Button
                  variant="text"
                  onClick={handleCustomOptionSubmit}
                  className="ml-2 rounded-md bg-blueNuit p-2 text-sm text-white"
                >
                  Add
                </Button>
              </li>
            )}
          </ul>
        </DropdownInput>
      </div>
    );
  },
);

MultiSelectDropdown.displayName = 'MultiSelectDropdown';

export { MultiSelectDropdown };
