import { RouterMap } from './core/components/RouterMap';
import { RouterProvider } from './core/contexts';
import { routerConfiguration } from './router-configuration';

export const App = () => {
  return (
    <RouterProvider>
      <RouterMap routes={routerConfiguration} />
    </RouterProvider>
  );
};
