import {
  Button,
  FilterSearchBar,
  TabItemProps,
  Table,
  TablePaginationContext,
} from '@/components';
import { TableSort } from '@/components/Table/types';
import { RoutePath } from '@/core/router';
import { useGetRequests } from '@/modules/Automations/queries';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  getRequestColumns,
  getRequestColumnsNamedMapped,
} from '../../AutomationsPage/utils';
import { CreateRequestAutomationModal } from '../../RequestAutomation/components/CreateRequestAutomation';
import { ManageAutomationsTab } from '../types';
import { ManageAutomationsHeader } from './ManageAutomationsHeader';

type AutomationRequestsTabProps = {
  activeTab: number;
  tabs: TabItemProps[];
  onTabChange: (index: number) => void;
};

export const AutomationRequestsTab = ({
  activeTab,
  tabs,
  onTabChange,
}: AutomationRequestsTabProps) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const [isRequestOpen, setIsRequestOpen] = useState(false);
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const navigate = useNavigate();

  const { page, onPageChange } = useContext(TablePaginationContext);

  const { data: requests, isFetching: isLoadingrequests } = useGetRequests(
    page + 1,
    search,
    getRequestColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
    tableSort?.[0]?.direction ?? 'ascending',
    {
      enabled: activeTab === ManageAutomationsTab.Request,
    },
  );

  tabs[ManageAutomationsTab.Request].badgeNumber = requests?.totalRows;

  return (
    <>
      <div>
        <ManageAutomationsHeader
          activeTab={activeTab}
          tabs={tabs}
          onTabChange={onTabChange}
        >
          <FilterSearchBar
            variant="secondary"
            onSearch={setSearch}
            otherActions={
              <Button
                onClick={() => setIsRequestOpen(true)}
                variant="primary"
                className="px-5 py-2.5"
              >
                {t('request_new')}
              </Button>
            }
            showTitle={false}
          />
        </ManageAutomationsHeader>
      </div>
      <div className="py-5">
        <Table
          columns={getRequestColumns({
            onClick: (row) =>
              row && navigate(RoutePath.requestDetail(row.id.toString())),
          })}
          rows={requests?.items ?? []}
          isLoading={isLoadingrequests}
          noResultsLabel={t('no_requests_available')}
          withPagination
          totalItems={requests?.totalRows}
          totalPages={requests?.totalPages}
          currentPage={page}
          onPageChange={onPageChange}
          onSort={setTableSort}
        />
      </div>
      {isRequestOpen && (
        <CreateRequestAutomationModal
          handleClose={() => setIsRequestOpen(false)}
          isOpen={isRequestOpen}
        />
      )}
    </>
  );
};
