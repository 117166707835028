import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { MultiSelectDropdown, MultiSelectDropdownOption } from '..';

interface MultiSelectDropdownFieldProps<
  T extends FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
> {
  name: TName;
  placeholder: string;
  control: Control<T, T>;
  dropdownOptions: MultiSelectDropdownOption<number>[];
  allowCustomOption?: boolean;
}

export const MultiSelectDropdownField = <T extends FieldValues>({
  name,
  control,
  dropdownOptions,
  placeholder,
  allowCustomOption = false,
}: MultiSelectDropdownFieldProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      defaultValue={[] as any}
      render={({ field: { onChange, value, ...otherFieldProps } }) => (
        <MultiSelectDropdown
          {...otherFieldProps}
          className="h-10 w-full rounded-md bg-brightGray capitalize italic"
          placeholder={placeholder}
          options={dropdownOptions}
          onSelect={(_option, newValue) => {
            onChange(newValue);
          }}
          value={value as number[]}
          allowCustomOption={allowCustomOption}
        />
      )}
    />
  );
};
