import {
  Button,
  CustomPageContainer,
  FilterSearchBar,
  Table,
  TablePaginationContext,
} from '@/components';
import { TableRow, TableSort } from '@/components/Table/types';
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGetInfrastructureCosts } from '../../queries';
import { CreateInfrastructureCostModal } from './components/CreateInfrastructureCostModal/CreateInfrastructureCostModal';
import {
  getInfrastructureCostColumns,
  getInfrastructureCostColumnsNamedMapped,
} from './utils';

export const InfrastructureCostPage = (): ReactElement => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const navigate = useNavigate();

  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    TablePaginationContext,
  );

  const {
    data: infrastructureCosts,
    isFetching: isLoadinginfrastructureCosts,
    isError,
  } = useGetInfrastructureCosts(
    page + 1,
    searchTerm,
    getInfrastructureCostColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
    tableSort?.[0]?.direction ?? 'ascending',
  );

  useEffect(() => {
    if (isError) {
      toast.error(t('error_getting_infrastructureCosts'));
    }
  }, [isError, t]);

  const routeChange = (row?: TableRow) => {
    const path = `${row?.id}`;
    navigate(path);
  };

  const onSort = (data: TableSort[]) => {
    setTableSort(data);
  };

  return (
    <CustomPageContainer>
      <CustomPageContainer.Header title={t('infrastructure_costs')} />
      <FilterSearchBar
        total={infrastructureCosts?.totalRows || 0}
        title={t('costs')}
        variant="secondary"
        onSearch={handleSearchChange}
        otherActions={
          <Button
            onClick={handleModalState}
            variant="primary"
            className="px-5 py-2.5"
          >
            {t('add')}
          </Button>
        }
      />
      <Table
        columns={getInfrastructureCostColumns({
          onClick: routeChange,
        })}
        rows={infrastructureCosts?.items ?? []}
        isLoading={isLoadinginfrastructureCosts}
        noResultsLabel={t('no_costs_available')}
        withPagination
        totalItems={infrastructureCosts?.totalRows}
        totalPages={infrastructureCosts?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
      {isOpen && (
        <CreateInfrastructureCostModal
          handleClose={handleModalState}
          isOpen={isOpen}
        />
      )}
    </CustomPageContainer>
  );
};
