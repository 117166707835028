import { InfrastructureCostsTab, Tabs } from '@/components';
import { TabContainer } from '@/components/TabContainer';
import { InfrastructureCostModel } from '@/modules/Infrastructure/domain';
import { getInfrastructureCostColumnsNamedMapped } from '@/modules/Infrastructure/pages/InfrastructureCostPage/utils';
import { useGetInfrastructureCostsByAutomation } from '@/modules/Infrastructure/queries';
import { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AssociatedTasksTab } from '../AssociatedTasksTab';
import { AutomationPerformanceTab } from '../AutomationPerformanceTab';
import { TaskPerformanceCards } from '../TaskPerformanceTab/components/TaskPerformanceCards';

interface AutomationPerformanceProps {
  processId?: number;
  automationId?: number;
  tasksCount?: number;
  infrastructureCostsCount?: number;
}

export const AutomationPerformance = ({
  processId,
  automationId,
  tasksCount,
  infrastructureCostsCount,
}: AutomationPerformanceProps): ReactElement => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const onTabChange = useCallback(
    (tab: number) => {
      setSelectedTab(tab);
    },
    [setSelectedTab],
  );

  return (
    <div className="flex flex-col gap-4">
      <h1>{t('automation_performance')}</h1>
      <Tabs
        activeTab={selectedTab}
        tabs={[
          { label: t('automation_performance') },
          { label: t('task_performance'), badgeNumber: tasksCount },
          { label: t('associated_tasks'), badgeNumber: tasksCount },
          {
            label: t('infrastructure_costs'),
            badgeNumber: infrastructureCostsCount,
          },
        ]}
        onTabChange={onTabChange}
      />
      {selectedTab === 0 && <AutomationPerformanceTab />}
      {selectedTab === 1 && <TaskPerformanceCards />}
      {selectedTab === 2 && automationId && (
        <AssociatedTasksTab
          processId={processId?.toString()}
          automationId={automationId}
        />
      )}
      {selectedTab === 3 && automationId && (
        <TabContainer<InfrastructureCostModel>
          parentId={parseInt(automationId.toString())}
          getColumnsNamedMapped={getInfrastructureCostColumnsNamedMapped}
          useGetData={useGetInfrastructureCostsByAutomation}
        >
          <InfrastructureCostsTab
            context="automationDetails"
            canAttach
            automationId={automationId}
          />
        </TabContainer>
      )}
    </div>
  );
};
