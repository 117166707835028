import { TableColumn, TableRow } from '@/components/Table/types';
import i18next from 'i18next';
import { Trans } from 'react-i18next';

type CriticallityColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const criticallityColumnConfig = <T extends TableRow>({
  options,
}: CriticallityColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'criticallity',
  label: <Trans i18nKey="criticallity" />,
  render: (value) => (
    <div className="overflow-hidden text-ellipsis">{i18next.t(value)}</div>
  ),
  acessorKey: 'priority',
  sortable: true,
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
