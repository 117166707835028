import { EditPencil, UserAvatar } from '@/components';
import { TenantModel } from '@/modules/Tenants/domain';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

interface TenantEntryProps {
  tenant: TenantModel;
  className?: string;
  isSelected?: boolean;
  onClick: (tenant: TenantModel) => void;
  onEditClick: (tenant: TenantModel) => void;
}

export const TenantEntry = ({
  tenant,
  isSelected,
  className,
  onClick,
  onEditClick,
}: TenantEntryProps) => {
  const { t } = useTranslation();

  const handleOnEdit = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onEditClick(tenant);
  };

  return (
    <div
      data-testid="tenant-entry-button"
      role="button"
      tabIndex={0}
      onClick={() => onClick(tenant)}
      onKeyDown={(event) => event.key === 'Enter' && onClick(tenant)}
      className={twMerge(
        'flex h-14 items-center justify-between gap-5 rounded-md border border-transparent bg-white px-2.5 py-1',
        isSelected && 'border-fennelBlue bg-fennelBlue bg-opacity-25',
        !!onClick &&
          'cursor-pointer hover:border hover:border-blueAngel hover:bg-fennelBlue hover:bg-opacity-25',
        className,
      )}
    >
      <div
        className={twMerge(
          `grid items-center gap-5`,
          isSelected ? 'grid-cols-4' : 'grid-cols-3',
          className,
        )}
      >
        <div className="col-span-2 flex items-center gap-2.5">
          <UserAvatar name={tenant.name} imageId={tenant.imageFileId} />
          <p className="text-sm font-black">{tenant.name}</p>
          <EditPencil
            data-testid="edit-tenant-entry-button"
            className="size-4 text-intercoastalGray"
            onClick={handleOnEdit}
          />
        </div>
        <p className="text-sm font-normal">{tenant.userRole}</p>
        {isSelected && (
          <p className={`text-right text-sm italic text-blueNuit`}>
            {t('tenant_selected')}
          </p>
        )}
      </div>
    </div>
  );
};
