type FunctionString = (id?: string) => string;

type RouteKeyPath =
  | 'home'
  | 'automations'
  | 'automationDetail'
  | 'requestDetail'
  | 'automationStages'
  | 'associatedTasks'
  | 'businessAreas'
  | 'businessAreaDetail'
  | 'companies'
  | 'companyDetail'
  | 'organizations'
  | 'organizationDetail'
  | 'processes'
  | 'processDetail'
  | 'users'
  | 'userDetail'
  | 'userProfile'
  | 'workflows'
  | 'workflowDetail'
  | 'tasks'
  | 'taskDetail'
  | 'infrastructureCosts'
  | 'infrastructureCostDetail'
  | 'notFound'
  | 'loadingPage'
  | 'tenantProfile';

export type RoutePath = {
  [key in RouteKeyPath]: FunctionString;
};

export const RoutePath: RoutePath = {
  home: () => '/',
  automations: () => '/automations',
  automationDetail: (id = ':automationId') => `/automations/${id}`,
  requestDetail: (id = ':requestId') => `/automations/requests/${id}`,
  automationStages: () => '/automations/stages',
  associatedTasks: (id = ':automationId') =>
    `/automations/${id}/associatedTasks`,
  businessAreas: () => '/business-areas',
  businessAreaDetail: (id = ':businessAreaId') => `/business-areas/${id}`,
  companies: () => '/companies',
  companyDetail: (id = ':companyId') => `/companies/${id}`,
  organizations: () => '/organizations',
  organizationDetail: (id = ':organizationId') => `/organizations/${id}`,
  processes: () => '/processes',
  processDetail: (id = ':processId') => `/processes/${id}`,
  users: () => '/users',
  userDetail: (id = ':userId') => `/users/${id}`,
  userProfile: () => `/profile`,
  workflows: () => '/workflows',
  workflowDetail: (id = ':workflowId') => `/workflows/${id}`,
  tasks: () => '/tasks',
  taskDetail: (id = ':taskId') => `/tasks/${id}`,
  infrastructureCosts: () => '/infrastructure-costs',
  infrastructureCostDetail: (id = ':infrastructureCostId') =>
    `/infrastructure-costs/${id}`,
  notFound: () => '/not-found',
  loadingPage: () => '/loading-page',
  tenantProfile: () => `/tenant/profile`,
};
