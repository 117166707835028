import { useDebounced } from '@/hooks';
import { ExceptionType, ResultFilter, Success } from '@/shared/types';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import {
  ChangeEvent,
  KeyboardEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { twJoin } from 'tailwind-merge';
import { Button } from '../Button';
import { DateRangeDropdown } from '../DateRangeDropdown';
import { DropdownArrow } from '../DropdownArrow/DropdownArrow';
import { CrossDelete, SearchIcon } from '../Icons';
import { Input } from '../Input';
import { Table } from '../Table';
import { TableColumn, TableRow } from '../Table/types';

export interface PerformanceCardDetailsProps<T extends TableRow> {
  title: string;
  subTitle: string;
  description: string;
  columns: Array<TableColumn<T>>;
  data?: {
    items: T[];
    totalRows: number;
    totalPages: number;
  };
  onClose?: () => void;
  metrics?: ReactNode;
  footerRenderer?: ReactNode;
  kpiDateRange?: DateRange;
  setKpiDateRange?: (dateRange?: DateRange) => void;
  contextType:
    | 'labourAutomated'
    | 'totalReturn'
    | 'lastRun'
    | 'overallRuns'
    | 'devTime';
  fetchNextPage?: () => Promise<unknown>;
  selectedBillable?: number;
  setSelectedBillable?: (billable?: number) => void;
  selectedType?: number;
  setSelectedType?: (type?: number) => void;
  selectedResult?: ResultFilter;
  setSelectedResult?: (type?: ResultFilter) => void;
  isLoading?: boolean;
  excludeSearchInput?: boolean;
  setSearchInput?: (searchInput: string) => void;
  isFetching: boolean;
  isFetchingNextPage: boolean;
}

//TODO: This needs to be refactor into different components instead of using the contextType. Split between data and filters.
export const PerformanceCardDetails = <T extends TableRow>({
  title,
  subTitle,
  description,
  columns,
  data,
  onClose,
  metrics,
  footerRenderer,
  kpiDateRange,
  setKpiDateRange,
  contextType,
  selectedBillable,
  setSelectedBillable,
  selectedType,
  setSelectedType,
  selectedResult,
  setSelectedResult,
  isLoading,
  excludeSearchInput,
  fetchNextPage: getNextPage,
  setSearchInput,
  isFetching,
  isFetchingNextPage,
}: PerformanceCardDetailsProps<T>): ReactElement => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const searchTermDebounced = useDebounced(search, 500) as string;
  const { ref: lastRowRef } = useInView({
    onChange: (inView) => {
      if (inView && !isFetching) {
        getNextPage?.();
      }
    },
  });

  const handleTypeSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleOnSearch = useCallback(() => {
    setSearchInput?.(search);
  }, [search, setSearchInput]);

  const handleClearSearch = useCallback(() => {
    setSearch('');
  }, [setSearch]);

  const handleKeyPressed = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') {
        return;
      }
      handleOnSearch();
    },
    [handleOnSearch],
  );

  useEffect(() => {
    setSearchInput?.(searchTermDebounced);
  }, [searchTermDebounced, setSearchInput]);

  const billable = [
    { id: 1, name: t('billable') },
    { id: 2, name: t('notBillable') },
  ];
  const type = [
    { id: 1, name: t('maintenance') },
    { id: 2, name: t('development') },
    { id: 3, name: t('support') },
  ];
  const result = [
    { id: Success.Success, name: t('success') },
    { id: ExceptionType.Generic, name: t('generic_error') },
    { id: ExceptionType.Business, name: t('business_error') },
  ];

  const dropdownBillableOptions = getDropdownArray(billable);
  const dropdownTypeOptions = getDropdownArray(type);
  const dropdownResultOptions = getDropdownArray<ResultFilter>(result);

  return (
    <div
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          onClose?.();
        }
      }}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/50"
    >
      <div className="relative w-[80%] max-w-6xl rounded-md bg-white p-5 shadow-lg">
        <div
          className="absolute right-4 top-4 cursor-pointer"
          onClick={onClose}
        >
          <CrossDelete className="h-4 w-4 text-rinseGray" />
        </div>
        <div className="mb-4 font-black">{title}</div>
        <p className="mb-6 text-sm text-gray-500">{description}</p>
        {metrics}
        <div className="flex w-full justify-between py-2.5">
          <div className="flex items-center gap-2 text-ml font-bold">
            <div className="flex h-full items-center self-end align-middle font-black">
              {subTitle}
            </div>
          </div>
          <div className="flex justify-end gap-4">
            <div className="relative flex items-center gap-3">
              {contextType === 'devTime' && (
                <DropdownArrow
                  placeholder={t('select_billable_state')}
                  selectedValue={selectedBillable}
                  onSelect={(newValue) => {
                    if (newValue === selectedBillable) {
                      setSelectedBillable?.(undefined);
                    } else {
                      setSelectedBillable?.(newValue);
                    }
                  }}
                  options={dropdownBillableOptions}
                />
              )}
              {contextType === 'devTime' && (
                <DropdownArrow
                  placeholder={t('select_type')}
                  selectedValue={selectedType}
                  onSelect={(newValue) => {
                    if (newValue === selectedType) {
                      setSelectedType?.(undefined);
                    } else {
                      setSelectedType?.(newValue);
                    }
                  }}
                  options={dropdownTypeOptions}
                />
              )}
              {(contextType === 'lastRun' || contextType === 'overallRuns') && (
                <DropdownArrow
                  placeholder={t('filter_by_result')}
                  selectedValue={selectedResult}
                  onSelect={(newValue) => {
                    if (newValue === selectedResult) {
                      setSelectedResult?.(undefined);
                    } else {
                      setSelectedResult?.(newValue);
                    }
                  }}
                  options={dropdownResultOptions}
                />
              )}
              {contextType !== 'lastRun' && (
                <DateRangeDropdown
                  range={kpiDateRange}
                  onChange={setKpiDateRange}
                  placement="bottom-end"
                  className="bg-brightGray"
                />
              )}
              {!excludeSearchInput && (
                <Input
                  onKeyUp={handleKeyPressed}
                  value={search}
                  type="text"
                  isDisabled={false}
                  name="key_words"
                  className={twJoin('h-10 w-36 rounded-md bg-brightGray p-2')}
                  placeHolder={t('key_words')}
                  onChange={handleTypeSearchChange}
                />
              )}
              {!!search && (
                <div
                  className="pointer-events-auto absolute right-2 flex h-auto cursor-pointer items-center justify-center pl-3 hover:cursor-pointer"
                  onClick={handleClearSearch}
                >
                  <CrossDelete className="text-[#191B23]" />
                </div>
              )}
            </div>
            <Button
              variant="text"
              onClick={handleOnSearch}
              className="flex cursor-pointer rounded-md bg-darkBlue p-2"
            >
              <SearchIcon className="text-white" />
            </Button>
          </div>
        </div>
        <div className="max-h-104 overflow-auto">
          <Table
            columns={columns}
            rows={data?.items ?? []}
            isLoading={isLoading}
            noResultsLabel={t('no_data_available')}
            totalItems={data?.totalRows}
            totalPages={data?.totalPages}
            footer={footerRenderer}
            lastRowRef={lastRowRef}
            isFetchingNextPage={isFetchingNextPage}
          />
        </div>
      </div>
    </div>
  );
};
