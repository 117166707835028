import { DateUtility } from '@/utils/date.utils';
import { Placement } from '@floating-ui/react';
import { useCallback, useState } from 'react';
import { DateRange, DayPicker, getDefaultClassNames } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { Button } from '../Button';
import { Dropdown, TimeTag } from './components';
import { CUSTOM_DAYS_SELECT, TagType } from './constants';
import { useDatesPlaceholder } from './hooks';

type DateTag = {
  type: TagType;
  value: number | undefined;
};

type DateRangeDropdownProps = {
  label?: string;
  range?: DateRange;
  className?: string;
  onChange?: (range?: DateRange) => void;
  isDisabled?: boolean;
  placement?: Placement;
  containerClassName?: string;
};

const getTagFromDate = (
  type: TagType,
  days?: number,
): DateRange | undefined => {
  const currentDate = DateUtility.today();

  switch (type) {
    case TagType.ALL_TIME:
      return;
    case TagType.YEAR_TO_DATE:
      return { from: DateUtility.firstDayOfYear(), to: currentDate };
    case TagType.LAST_DAYS:
    default:
      return {
        from: DateUtility.subtract(currentDate, {
          days: days || 7,
        }),
        to: currentDate,
      };
  }
};

export const DateRangeDropdown = ({
  label,
  range,
  className,
  onChange,
  isDisabled,
  containerClassName,
  placement,
}: DateRangeDropdownProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<DateTag>();
  const [appliedTag, setAppliedTag] = useState<DateTag>();
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>(
    range,
  );
  const datesPlaceholder = useDatesPlaceholder(
    selectedRange,
    selectedTag?.type,
  );
  const weeklyDays = t('date_picker.weekly_days', {
    returnObjects: true,
  }) as string[];

  const monthNames = t('date_picker.month_names', {
    returnObjects: true,
  }) as string[];

  const placeholder = t(datesPlaceholder.label, {
    ...datesPlaceholder.opt,
    interpolation: { escapeValue: false },
  });

  const handleApply = useCallback(() => {
    setIsOpen(false);
    onChange?.(selectedRange);
    setAppliedTag(selectedTag);
  }, [onChange, selectedRange, selectedTag]);

  const handleTagsSelect = useCallback(
    (type: TagType, days?: number) => {
      const newDateRange = getTagFromDate(type, days);
      const dateTag = { type, value: days };
      setSelectedTag(dateTag);
      setSelectedRange(newDateRange);
      setAppliedTag(dateTag);
      onChange?.(newDateRange);
      setIsOpen(false);
    },
    [onChange],
  );

  const handleDateChange = useCallback((dateRange: DateRange | undefined) => {
    setSelectedTag(undefined);
    setSelectedRange({
      from: dateRange?.from,
      to: dateRange?.to,
    });
  }, []);

  const onCancelClick = useCallback(() => {
    setSelectedRange(range);
    setSelectedTag(appliedTag);
    setIsOpen(false);
  }, [appliedTag, range]);

  return (
    <div
      className={twMerge(
        'flex items-center space-x-2 text-nowrap',
        containerClassName,
      )}
    >
      {!!label && (
        <div className="bg- border-white text-md font-semibold">{label}</div>
      )}
      <Dropdown
        isOpen={isOpen}
        placeholder={placeholder}
        hiddenClassName="border-t p-2 rounded-md z-[60]"
        buttonClassMame={twMerge(
          'border-white focus:border-white active:border-white rounded-md',
        )}
        containerClassName={className}
        placement={placement}
        setIsOpen={setIsOpen}
        disabled={isDisabled}
        onOutsideClick={onCancelClick}
      >
        <div className="z-60 flex flex-col gap-2 p-1">
          <div className="flex max-w-36 flex-row flex-wrap gap-1">
            {CUSTOM_DAYS_SELECT.map((dateSelection) => (
              <TimeTag
                onClick={handleTagsSelect}
                key={`custom-days-${dateSelection.type}-${dateSelection.value}`}
                type={dateSelection.type}
                value={dateSelection.value}
                selected={
                  dateSelection.type === selectedTag?.type &&
                  dateSelection.value === selectedTag.value
                }
              />
            ))}
          </div>
          <DayPicker
            classNames={{
              button_previous: 'size-5',
              button_next: 'size-5',
              chevron: 'size-5',
              caption_label: 'text-sm font-black self-center',
              day: 'size-5 text-xs font-black text-primaryBlue border-transparent border data-[today=true]:border-blueNuit data-[selected=true]:rounded-full',
              day_button: 'size-5 text-xs',
              month_caption: 'flex h-5',
              nav: `${getDefaultClassNames().nav} h-5`,
              selected: 'font-black',
              weeks: `${getDefaultClassNames().weeks} text-xs`,
              month_grid: `${getDefaultClassNames().month_grid} border-separate border-spacing-1`,
              today: 'text-primaryBlue rounded-sm',
            }}
            modifiersClassNames={{
              range_middle: 'bg-lightBlue rounded-full',
              range_start: 'bg-blueNuit rounded-full text-white',
              range_end: 'bg-blueNuit rounded-full text-white',
            }}
            defaultMonth={selectedRange?.to ?? new Date()}
            mode="range"
            selected={selectedRange}
            onSelect={handleDateChange}
            ISOWeek
            showOutsideDays
            formatters={{
              formatCaption(date) {
                return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
              },
              formatWeekdayName: (weekday) => {
                return weeklyDays[weekday.getDay()];
              },
            }}
          />
          <div className="mr-2 flex flex-1 justify-end gap-1">
            <Button
              className="flex-1 capitalize"
              onClick={onCancelClick}
              variant="outline"
            >
              {t('cancel')}
            </Button>
            <Button
              className="flex-1 capitalize"
              onClick={handleApply}
              variant="primary"
            >
              {t('apply')}
            </Button>
          </div>
        </div>
      </Dropdown>
    </div>
  );
};
