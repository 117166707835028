import { TenantContext } from '@/components';
import { SortDirection } from '@/components/Table/types';
import { infrastructuresRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import {
  InfrastructureCostBaseInfoModel,
  InfrastructureCostModel,
} from '../domain';
import { InfrastructureCostKey } from './types';

export const useGetInfrastructureCosts = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<InfrastructureCostModel[]>,
    any,
    DataWithPagination<InfrastructureCostModel[]>
  >,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<DataWithPagination<InfrastructureCostModel[]>>(
    [
      ...InfrastructureCostKey.PAGE(pageNumber, filter, sortBy, sortOrder),
      currentSelectedTenant?.id,
    ],
    () =>
      infrastructuresRepo.getInfrastructureCosts(
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );
};

export const useGetAllInfrastructureCostsByAutomationId = (
  automationId?: number,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<InfrastructureCostBaseInfoModel[]>({
    queryKey: [
      ...InfrastructureCostKey.BY_AUTOMATION(automationId),
      currentSelectedTenant?.id,
    ],
    queryFn: () =>
      automationId
        ? infrastructuresRepo.getAllInfrastructureCostsByAutomationId(
            automationId,
          )
        : [],
    enabled: !!automationId,
  });
};
