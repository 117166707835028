import { TabItemProps, Tabs } from '@/components';
import { PropsWithChildren } from 'react';

type ManageAutomationsHeaderProps = PropsWithChildren<{
  activeTab: number;
  tabs: TabItemProps[];
  onTabChange: (index: number) => void;
}>;

export const ManageAutomationsHeader = ({
  activeTab,
  tabs,
  onTabChange,
  children,
}: ManageAutomationsHeaderProps) => {
  return (
    <div className="flex justify-between">
      <Tabs
        activeTab={activeTab}
        tabs={tabs}
        onTabChange={onTabChange}
        variant="secondary"
      />
      <div>{children}</div>
    </div>
  );
};
