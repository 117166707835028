import {
  NoDataCell,
  TableHead,
  TableRowCell,
} from '@/components/Table/components';
import { TableColumn, TableVariant } from '@/components/Table/types';
import { ReactElement, useState } from 'react';
import { WorkflowRow } from '../WorkflowRow';
import {
  ProcessWithWorkflows,
  ProcessWorkflowTask,
  WorkflowWithTasks,
} from '../../types';
import { t } from 'i18next';

interface ProcessRowrops {
  process: ProcessWithWorkflows;
  processColumns: TableColumn<ProcessWithWorkflows>[];
  workflowsColumns: TableColumn<WorkflowWithTasks>[];
  taskColumns: TableColumn<ProcessWorkflowTask>[];
  variant?: TableVariant;
  noOutline?: boolean;
  selected?: boolean;
  showDots?: boolean;
  depth?: number;
  noChildDots?: boolean;
  onRowHoverStyling?: string;
  collapseClassname?: string;
}

export const ProcessRow = ({
  process,
  processColumns,
  workflowsColumns,
  taskColumns,
  depth = 0,
  variant = 'medium',
  noOutline,
  onRowHoverStyling,
  collapseClassname,
}: ProcessRowrops): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <TableRowCell
        row={process}
        cols={processColumns}
        depth={depth}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        noOutline={noOutline}
        onRowClick={() => setIsOpen(!isOpen)}
        variant={variant}
        onRowHoverStyling={onRowHoverStyling}
        isTree
        onlyCollapse
        collapseClassname={collapseClassname}
      />
      {isOpen && (
        <tr>
          <td colSpan={processColumns.length + 1}>
            <div className="ml-5 flex">
              <div className="relative left-5 h-auto border-2 text-brightGray"></div>
              <table className="mb-3 mt-5">
                <thead className="bg-inherit">
                  <TableHead
                    columns={workflowsColumns}
                    variant="medium"
                    isTree
                  />
                </thead>
                <tbody>
                  {process?.rows.length === 0 ? (
                    <NoDataCell
                      colSpan={workflowsColumns.length}
                      variant={'medium'}
                      noResultsLabel={t('no_workflows_available')}
                    />
                  ) : (
                    process?.rows?.map((workflow) => (
                      <WorkflowRow
                        key={workflow.id}
                        workflow={workflow}
                        workflowsColumns={workflowsColumns}
                        taskColumns={taskColumns}
                        variant={variant}
                        depth={depth + 1}
                        isTree
                        onRowHoverStyling={onRowHoverStyling}
                        noOutline={noOutline}
                        collapseClassname={collapseClassname}
                      />
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
