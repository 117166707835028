import { TableColumn, TableRow } from '@/components/Table/types';

export const emptyColumnConfig = <T extends TableRow>(
  key: string,
  options?: Omit<TableColumn<T>, 'id' | 'label'>,
): TableColumn<T> => ({
  id: key,
  label: '',
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
