import { Container } from '@/components';
import { DateRangeDropdown } from '@/components/DateRangeDropdown';
import { ReactElement, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { BusinessAreaLabourTimeAutomatedCard } from './BusinessAreaLabourTimeAutomatedCard';
import { BusinessAreaTotalReturnCard } from './BusinessAreaTotalReturnCard';

type BusinessAreaPerformanceCardsProps = {
  businessAreaId: number;
};

export const BusinessAreaPerformanceCards = ({
  businessAreaId,
}: BusinessAreaPerformanceCardsProps): ReactElement => {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<DateRange>();

  return (
    <div className="flex flex-wrap gap-2">
      <Container
        title={t('auto_and_invest_performance')}
        className="flex-1"
        actions={
          <DateRangeDropdown
            range={dateRange}
            onChange={setDateRange}
            placement="bottom-end"
          />
        }
      >
        <div className="flex gap-3">
          <BusinessAreaLabourTimeAutomatedCard
            businessAreaId={businessAreaId}
            automationPerformanceDateRange={dateRange}
          />
          <BusinessAreaTotalReturnCard
            businessAreaId={businessAreaId}
            automationPerformanceDateRange={dateRange}
          />
        </div>
      </Container>
    </div>
  );
};
