import {
  Button,
  Dropdown,
  FieldInfo,
  FilterBusinessAreaFormGroup,
  Input,
  Modal,
  TextArea,
} from '@/components';
import {
  AutomationSchema,
  AutomationSchemaType,
} from '@/modules/Automations/types/automation-schema';
import { useGetAllProcessesByBusinessArea } from '@/modules/Processes/queries';
import { useGetUsersBaseInfo } from '@/modules/Users/queries';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { zodResolver } from '@hookform/resolvers/zod';
import { ChangeEvent, ReactElement, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AUTOMATION_PRIORITIES } from '../../utils';

interface AutomationModalProps {
  handleOnSubmit: (e: AutomationSchemaType) => void;
  handleClose: () => void;
  title: string;
  type: 'create' | 'edit';
  isLoading: boolean;
  isOpen: boolean;
  automationName?: string;
  automationCode?: string;
  organizationId?: number;
  companyId?: number;
  businessAreaId?: number;
  processId?: number;
  description?: string;
  developerId?: number;
  stageId?: number;
  priority?: string;
  epicId?: string;
  orchestratorProcessId?: string;
  disableFields?: string[];
}

//TODO: Refactor Split component for create and edit contexts. Split modal and form components
export const AutomationModal = ({
  title,
  isOpen,
  type,
  isLoading,
  handleOnSubmit,
  handleClose,
  automationName,
  automationCode,
  organizationId,
  companyId,
  businessAreaId,
  processId,
  description,
  developerId,
  stageId,
  priority,
  orchestratorProcessId,
  disableFields,
  epicId,
}: AutomationModalProps): ReactElement => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm<AutomationSchemaType>({
    defaultValues: {
      automationName,
      automationCode,
      epicId: epicId,
      businessAreaId,
      processId,
      description,
      developerId,
      priority,
      stageId,
      orchestratorProcessId,
    },
    resolver: zodResolver(AutomationSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  const { data: users } = useGetUsersBaseInfo();

  const businessArea = watch('businessAreaId');

  const { data: processes } = useGetAllProcessesByBusinessArea(businessArea);

  const {
    dropdownProcessesOptions,
    dropdownUsersOptions,
    dropdownStagesOptions,
    dropdownAutomationPriorityOptions,
  } = useMemo(() => {
    const stages = [
      //TODO: Refactor into enum
      { id: 1, name: 'PddSubmission' },
      { id: 2, name: 'PddApproval' },
      { id: 3, name: 'Development' },
      { id: 4, name: 'TestApproval' },
      { id: 5, name: 'Rollout' },
      { id: 6, name: 'Productive' },
      { id: 7, name: 'Active' },
      { id: 8, name: 'Maintenance' },
      { id: 9, name: 'Inactive' },
    ];

    return {
      dropdownProcessesOptions: getDropdownArray(
        processes?.filter(
          (process) => process.status || process.id === processId,
        ) ?? [],
      ),
      dropdownUsersOptions: getDropdownArray(users ?? []),
      dropdownStagesOptions: getDropdownArray(stages),
      dropdownAutomationPriorityOptions: getDropdownArray(
        AUTOMATION_PRIORITIES.map((priority) => ({
          id: priority,
          name: t(priority),
        })),
      ),
    };
  }, [processes, users, processId, t]);

  const onSubmit: SubmitHandler<AutomationSchemaType> = (data) => {
    handleOnSubmit(data);
  };

  const handleFileChange =
    (propertyName: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0] || null;

      switch (propertyName) {
        case 'automationPdd':
        case 'automationSdd':
        case 'automationManualFile':
          setValue(propertyName, file);
          break;
        default:
          setValue('automationManualFile', file);
      }
    };

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      className="h-144 w-128"
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2">
          <FieldInfo
            title={t('automationName')}
            errorText={
              errors.automationName?.message
                ? t(errors.automationName.message)
                : undefined
            }
            hasError={!!errors.automationName?.message}
          >
            <Input
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('automationName')}
              name="automationName"
              register={register}
            />
          </FieldInfo>
          <FieldInfo
            title={t('orchestrator_process_id')}
            errorText={
              errors.automationName?.message
                ? t(errors.automationName.message)
                : undefined
            }
            hasError={!!errors.automationName?.message}
          >
            <Input
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('orchestrator_process_id')}
              name="orchestratorProcessId"
              register={register}
            />
          </FieldInfo>
          <FieldInfo
            title={t('jira_epic_id')}
            errorText={
              errors.automationName?.message
                ? t(errors.automationName.message)
                : undefined
            }
            hasError={!!errors.automationName?.message}
          >
            <Input
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('jira_epic_id')}
              name="epicId"
              register={register}
            />
          </FieldInfo>
          <FieldInfo
            title={t('automationCode')}
            errorText={
              errors.automationCode?.message
                ? t(errors.automationCode.message)
                : undefined
            }
            hasError={!!errors.automationCode?.message}
            hidden={true}
          >
            <Input
              isDisabled={true}
              className="w-full bg-brightGray italic"
              placeHolder={t('automationCode')}
              name="automationCode"
              register={register}
            />
          </FieldInfo>

          <FieldInfo
            title={t('priority')}
            errorText={
              errors.priority?.message ? t(errors.priority.message) : undefined
            }
            hasError={!!errors.priority?.message}
          >
            <Controller
              name="priority"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...otherFieldProps } }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                  placeholder={t('priority')}
                  options={dropdownAutomationPriorityOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={value}
                />
              )}
            />
          </FieldInfo>
          <FilterBusinessAreaFormGroup<AutomationSchemaType>
            disableFields={disableFields}
            errors={errors}
            control={control}
            organizationId={organizationId}
            companyId={companyId}
            businessAreaId={businessAreaId}
          />
          <FieldInfo
            title={t('process')}
            errorText={
              errors.processId?.message
                ? t(errors.processId.message)
                : undefined
            }
            hasError={!!errors.processId?.message}
          >
            <Controller
              name="processId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...otherFieldProps } }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                  placeholder={t('process')}
                  options={dropdownProcessesOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={value}
                />
              )}
            />
          </FieldInfo>
          <FieldInfo
            title={t('description')}
            errorText={
              errors.description?.message
                ? t(errors.description.message)
                : undefined
            }
            hasError={!!errors.description?.message}
          >
            <TextArea
              isDisabled={isLoading}
              className="h-24 w-full bg-brightGray italic"
              placeHolder={t('description')}
              name="description"
              register={register}
            />
          </FieldInfo>
          <FieldInfo
            title={t('developer')}
            errorText={
              errors.developerId?.message
                ? t(errors.developerId.message)
                : undefined
            }
            hasError={!!errors.developerId?.message}
          >
            <Controller
              name="developerId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...otherFieldProps } }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                  placeholder={t('developer')}
                  options={dropdownUsersOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={value}
                />
              )}
            />
          </FieldInfo>
          <FieldInfo
            title={t('stage')}
            errorText={
              errors.stageId?.message ? t(errors.stageId.message) : undefined
            }
            hasError={!!errors.stageId?.message}
          >
            <Controller
              name="stageId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...otherFieldProps } }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                  placeholder={t('stage')}
                  options={dropdownStagesOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={value}
                />
              )}
            />
          </FieldInfo>
          {type === 'create' && (
            <>
              <FieldInfo
                title={t('automationPdd')}
                hasError={!!errors.automationPdd?.message}
              >
                <div className="relative w-full bg-brightGray capitalize">
                  <Controller
                    name="automationPdd"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          id="file-upload-pdd"
                          type="file"
                          accept=".pdf, .doc, .docx"
                          className="absolute inset-0 z-50 w-full opacity-0"
                          onChange={handleFileChange('automationPdd')}
                          disabled={isLoading}
                        />
                        <label
                          htmlFor="file-upload-pdd"
                          className="flex h-10 items-center justify-center rounded-md border border-dashed border-gray-300 bg-white p-2"
                        >
                          <span className="cursor-pointer text-sm font-bold underline">
                            {field.value ? field.value.name : t('choose_file')}
                          </span>
                        </label>
                      </>
                    )}
                  />
                </div>
              </FieldInfo>
              <FieldInfo
                title={t('automationSdd')}
                hasError={!!errors.automationSdd?.message}
              >
                <div className="relative w-full bg-brightGray capitalize">
                  <Controller
                    name="automationSdd"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          id="file-upload-sdd"
                          type="file"
                          accept=".pdf, .doc, .docx"
                          className="absolute inset-0 z-50 w-full opacity-0"
                          onChange={handleFileChange('automationSdd')}
                          disabled={isLoading}
                        />
                        <label
                          htmlFor="file-upload-sdd"
                          className="flex h-10 items-center justify-center rounded-md border border-dashed border-gray-300 bg-white p-2"
                        >
                          <span className="cursor-pointer text-sm font-bold underline">
                            {field.value ? field.value.name : t('choose_file')}
                          </span>
                        </label>
                      </>
                    )}
                  />
                </div>
              </FieldInfo>
              <FieldInfo
                title={t('automation_manual_file')}
                hasError={!!errors.automationManualFile?.message}
              >
                <div className="relative w-full bg-brightGray capitalize">
                  <Controller
                    name="automationManualFile"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          id="file-upload-manual"
                          type="file"
                          accept=".pdf, .doc, .docx"
                          className="absolute inset-0 z-50 w-full opacity-0"
                          onChange={handleFileChange('automationManualFile')}
                          disabled={isLoading}
                        />
                        <label
                          htmlFor="file-upload-manual"
                          className="flex h-10 items-center justify-center rounded-md border border-dashed border-gray-300 bg-white p-2"
                        >
                          <span className="cursor-pointer text-sm font-bold underline">
                            {field.value ? field.value.name : t('choose_file')}
                          </span>
                        </label>
                      </>
                    )}
                  />
                </div>
              </FieldInfo>
            </>
          )}
        </div>
        <div className="mt-4 flex justify-between">
          <Button
            isDisabled={isLoading}
            onClick={handleClose}
            variant="outline"
            className="h-9 w-24"
          >
            {t('cancel')}
          </Button>
          <Button
            isDisabled={isLoading}
            buttonType="submit"
            className="h-9 w-24"
          >
            {type === 'create' ? t('create') : t('confirm')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
