import { PaginationParams } from '@/shared/types';
import { DateRange } from 'react-day-picker';

export abstract class TaskKey {
  static ALL = ['tasks'];
  static LIST = [...TaskKey.ALL, 'list'];
  static LIST_PAGED = (pagination: PaginationParams) => [
    ...TaskKey.LIST,
    pagination,
  ];
  static BY_ID = (taskId: number) => [...TaskKey.ALL, 'by-id', taskId];
  static BY_PROCESS = (processId: number) => [
    ...TaskKey.ALL,
    'by-process',
    processId,
  ];
  static BY_PROCESS_PAGED = (
    processId: number,
    pagination: PaginationParams,
  ) => [...TaskKey.BY_PROCESS(processId), pagination];
  static BY_ORGANIZATION = (organizationId: number) => [
    ...TaskKey.ALL,
    'by-organization',
    organizationId,
  ];
  static BY_ORGANIZATION_PAGED = (
    organizationId: number,
    pagination: PaginationParams,
  ) => [...TaskKey.BY_ORGANIZATION(organizationId), pagination];
  static BY_COMPANY = (companyId: number) => [
    ...TaskKey.ALL,
    'by-company',
    companyId,
  ];
  static BY_COMPANY_PAGED = (
    companyId: number,
    pagination: PaginationParams,
  ) => [...TaskKey.BY_COMPANY(companyId), pagination];
  static BY_BUSINESS_AREA = (businessAreaId: number) => [
    ...TaskKey.ALL,
    'by-business-area',
    businessAreaId,
  ];
  static BY_BUSINESS_AREA_WITHOUT_WORKFLOW = (businessAreaId: number) => [
    ...TaskKey.BY_BUSINESS_AREA(businessAreaId),
    'without-workflow',
  ];
  static BY_BUSINESS_AREA_PAGED = (
    businessAreaId: number,
    pagination: PaginationParams,
  ) => [...TaskKey.BY_BUSINESS_AREA(businessAreaId), pagination];

  static BY_USER = (userId: number) => [...TaskKey.ALL, 'by-user', userId];
  static BY_USER_PAGED = (userId: number, pagination: PaginationParams) => [
    ...TaskKey.BY_USER(userId),
    pagination,
  ];
  static AUTOMATIONS_PERFORMANCE = (taskId: number, dateRange?: DateRange) => [
    ...TaskKey.BY_ID(taskId),
    'automations-performance',
    dateRange,
  ];
  static LABOUR_AUTOMATED = (
    taskId: number,
    dateRange?: DateRange,
    filter?: string,
  ) => [...TaskKey.BY_ID(taskId), 'labour-automated', dateRange, filter];
  static TOTAL_RETURN = (
    taskId: number,
    dateRange?: DateRange,
    filter?: string,
  ) => [...TaskKey.BY_ID(taskId), 'total-return', dateRange, filter];
  static LAST_RUN = (automationId: number, dateRange?: DateRange) => [
    ...TaskKey.BY_ID(automationId),
    'last-run',
    dateRange,
  ];
  static OVERALL_RUNS = (automationId: number, dateRange?: DateRange) => [
    ...TaskKey.BY_ID(automationId),
    'overall-runs',
    dateRange,
  ];
}
