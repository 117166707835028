import { TenantContext } from '@/components';
import { automationsRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { AutomationKey } from './keys';

export const useGetAutomationPerformance = (automationId: number) => {
  const { currentSelectedTenant } = useContext(TenantContext);
  return useQuery(
    [
      ...AutomationKey.OVERALL_PEFORMANCE(automationId),
      currentSelectedTenant?.id,
    ],
    () => automationsRepo.getAutomationOverallPerformance(automationId),
    {
      enabled: !!automationId && !!currentSelectedTenant?.id,
      cacheTime: 1000 * 60 * 5, // 5 minutes
    },
  );
};
