import { ProtectedHttpClient } from '@/core/http';
import { HttpAutomationRepository } from '@/modules/Automations/repositories/http-automation.repository';
import { HttpBusinessAreaRepository } from '@/modules/BusinessAreas/repositories/http-businessArea.repository';
import { HttpCompanyRepository } from '@/modules/Companies/repositories/http-company.repository';
import { FileRepository } from '@/modules/Files/repositories/file.repository';
import { HttpFileRepository } from '@/modules/Files/repositories/http-file.repository';
import { HttpInfrastructureRepository } from '@/modules/Infrastructure/repositories/http-infrastructure.repository';
import { InfrastructureRepository } from '@/modules/Infrastructure/repositories/infrastructure.repository';
import { HttpOrganizationRepository } from '@/modules/Organizations/repositories/http-organization.repository';
import { HttpProcessRepository } from '@/modules/Processes/repositories/http-process.repository';
import { HttpSystemRepository } from '@/modules/System/repositories';
import { TaskRepository } from '@/modules/Tasks/repositories';
import { HttpTasksRepository } from '@/modules/Tasks/repositories/http-task.repository';
import { HttpTenantRepository } from '@/modules/Tenants/repositories';
import { HttpUserRepository } from '@/modules/Users/repositories/http-user.repository';
import { HttpWorkflowRepository } from '@/modules/Workflows/repositories/http-workflow.repository';

// HTTP Clients
const automationsClient = new ProtectedHttpClient('automations');
const businessAreasClient = new ProtectedHttpClient('business-areas');
const companiesClient = new ProtectedHttpClient('companies');
const organizationsClient = new ProtectedHttpClient('organizations');
const usersClient = new ProtectedHttpClient('users');
const workflowsClient = new ProtectedHttpClient('workflows');
const processesClient = new ProtectedHttpClient('processes');
const tasksClient = new ProtectedHttpClient('tasks');
const filesClient = new ProtectedHttpClient('files');
const infrastructureCostClient = new ProtectedHttpClient(
  'infrastructure-costs',
);
const tenantClient = new ProtectedHttpClient('tenants');
const systemClient = new ProtectedHttpClient('system');

// Create repositories
export const automationsRepo = new HttpAutomationRepository(automationsClient);
export const businessAreasRepo = new HttpBusinessAreaRepository(
  businessAreasClient,
);
export const companiesRepo = new HttpCompanyRepository(companiesClient);
export const organizationsRepo = new HttpOrganizationRepository(
  organizationsClient,
);
export const usersRepo = new HttpUserRepository(usersClient);
export const workflowsRepo = new HttpWorkflowRepository(workflowsClient);
export const processesRepo = new HttpProcessRepository(processesClient);
export const tasksRepo: TaskRepository = new HttpTasksRepository(tasksClient);
export const filesRepo: FileRepository = new HttpFileRepository(filesClient);
export const infrastructuresRepo: InfrastructureRepository =
  new HttpInfrastructureRepository(infrastructureCostClient);
export const tenantRepo = new HttpTenantRepository(tenantClient);
export const systemRepo = new HttpSystemRepository(systemClient);
