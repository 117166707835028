import { Button } from '@/components/Button';
import { SwitchIcon } from '@/components/Icons';
import { TenantContext } from '@/components/TenantProvider';
import { RoutePath } from '@/core/router/route-paths';
import { CurrentTenantAvatar } from '@/modules/Tenants/pages/TenantProfilePage/components/CurrentTenantAvatar';
import { useGetCurrentUser } from '@/modules/Users/queries';
import {
  MouseEvent,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
interface SideNavProfileAvatarProps {
  onChangeTenantModalClick: () => void;
}
export const SideNavProfileAvatar = ({
  onChangeTenantModalClick,
}: SideNavProfileAvatarProps): ReactElement => {
  const { t } = useTranslation();
  const { data: user } = useGetCurrentUser();
  const { currentSelectedTenant: tenant, hasMultipleTenants } =
    useContext(TenantContext);
  const { email } = useMemo(() => {
    let fullName = user?.name ?? '';
    if (!fullName) {
      fullName = user ? `${user.firstName} ${user.lastName}` : '';
    }
    return { fullName, email: user?.email ?? '', userId: user?.id };
  }, [user]);
  const handleButtonChangeTenantOnClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      onChangeTenantModalClick();
    },
    [onChangeTenantModalClick],
  );
  const { currentSelectedTenant } = useContext(TenantContext);

  return (
    <NavLink
      to={RoutePath.tenantProfile()}
      className="group relative z-99 flex h-16 w-full items-center justify-center rounded-r-md"
    >
      <div className="group relative z-10 flex h-25 w-full items-center justify-center rounded-r-md border-b-1 ">
        <div className="flex h-full items-center rounded-r-md group-hover:absolute group-hover:left-0 group-hover:top-0 group-hover:bg-primaryBlue group-hover:shadow-lg">
          <span className="flex w-20 items-center justify-center ">
            <CurrentTenantAvatar
              size="medium-large"
              isLoading={false}
              tenant={currentSelectedTenant}
            />
          </span>
          <span
            className={twJoin(
              'text-base hidden whitespace-nowrap rounded-md pr-3 font-bold group-hover:inline-block',
            )}
          >
            <div className="flex items-center justify-between">
              <div className="mr-4 flex flex-col">
                <span className="font-bold">
                  {tenant
                    ? `${tenant.userRole?.concat(',') ?? ''} ${tenant.name}`
                    : email}
                </span>
                <span className="text-sm text-gray-500">Tenant</span>
              </div>
              {hasMultipleTenants && (
                <Button
                  className="h-fit w-fit border-blueNuit bg-transparent px-4 font-black text-blueNuit"
                  variant="outline"
                  onClick={handleButtonChangeTenantOnClick}
                >
                  <SwitchIcon className="mr-2" />
                  {t('change')}
                </Button>
              )}
            </div>
          </span>
        </div>
      </div>
    </NavLink>
  );
};
