import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { SchedulingModel } from '@/modules/Automations/domain';
import { DateTimeFormatOptions } from '@/shared/types';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import cronstrue from 'cronstrue';
import 'cronstrue/locales/en';
import 'cronstrue/locales/pt_PT';
import { useMemo } from 'react';

interface SchedulingRowProps {
  scheduling?: SchedulingModel;
  averageCases?: number;
  isLoading?: boolean;
}

const dateFormatOptions: DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const SchedulingRow = ({
  scheduling,
  averageCases,
  isLoading,
}: SchedulingRowProps) => {
  const { i18n } = useTranslation();

  const locale = useMemo(() => {
    if (i18n.language === 'pt') {
      return 'pt_PT';
    }

    return 'en';
  }, [i18n.language]);

  return (
    <Container>
      <span className="flex flex-row gap-2.5">
        <Card title={t('schedule')} isLoading={isLoading}>
          {scheduling?.scheduleCron
            ? cronstrue.toString(scheduling.scheduleCron, {
                locale: locale,
                verbose: true,
                use24HourTimeFormat: true,
              })
            : ''}
        </Card>
        <Card title={t('next_run')} isLoading={isLoading}>
          {scheduling?.nextRun?.toLocaleString('en-GB', dateFormatOptions) ??
            ''}
        </Card>
        <Card title={t('last_run')} isLoading={isLoading}>
          {scheduling?.lastRun?.toLocaleString('en-GB', dateFormatOptions) ??
            ''}
        </Card>
        <Card title={t('average_cases_per_run')} isLoading={isLoading}>
          {Math.trunc(averageCases || 0)}
        </Card>
      </span>
    </Container>
  );
};
