import { LabourTimeAutomatedTotalsModel } from '@/shared/domain';
import { formatBigNumber, secondsToTimeDurationString } from '@/utils';
import { Trans } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type LabourTimeAutomatedFooterProps = {
  addAdditionalColumns?: boolean;
  totalMetrics?: LabourTimeAutomatedTotalsModel;
};

export const LabourTimeAutomatedFooter = ({
  addAdditionalColumns,
  totalMetrics,
}: LabourTimeAutomatedFooterProps) => {
  const timeEfficiency = totalMetrics?.timeEfficiency ?? 0;
  return (
    <tr className="text-sm font-medium">
      <td colSpan={addAdditionalColumns ? 3 : 2} className="px-4 pb-2 pt-4">
        <Trans
          i18nKey={'total_n_runs'}
          count={totalMetrics?.totalRows ?? 0}
          components={{
            b: <strong />,
          }}
        />
      </td>
      <td className="px-4" align="center">
        {secondsToTimeDurationString(totalMetrics?.labourTime ?? 0)}
      </td>
      <td className="px-4" align="center">
        {secondsToTimeDurationString(totalMetrics?.totalRunTimeSeconds ?? 0)}
      </td>
      <td
        className={twMerge(
          'overflow-hidden text-ellipsis font-bold text-green-500',
          timeEfficiency < 0 && 'text-red-500',
        )}
        align="center"
      >
        {formatBigNumber(timeEfficiency)}%
      </td>
    </tr>
  );
};
