import { ResultColumn } from '@/components/ResultColumn/ResultColumn';
import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';
type ResultColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const resultColumnConfig = <T extends TableRow>({
  options,
}: ResultColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'isSuccessful',
  label: <Trans i18nKey="result" />,
  render: (_, row) => <ResultColumn row={row} />,
  acessorKey: 'isSuccessful',
  sortable: false,
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
