import {
  Button,
  Dropdown,
  FieldInfo,
  FilterBusinessAreaFormGroup,
  Input,
  TextArea,
} from '@/components';
import { useTaskFormOptions } from '@/modules/Tasks/hooks/use-task-form-options.hook';
import {
  TaskFormHiddenField,
  TaskSchema,
  TaskSchemaType,
} from '@/modules/Tasks/types';
import { LabourWorkload } from '@/modules/Tasks/types/labourWorkLoad';
import { Periodicity } from '@/modules/Tasks/types/periodicity';
import { useGetAllWorkflowsByBusinessArea } from '@/modules/Workflows/queries';
import { useWorkflowsBaseInfoByProcessId } from '@/modules/Workflows/queries/use-workflows-base-info-by-process-id';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type EditTaskFormProps = {
  id: number;
  processId: number | undefined;
  handleOnSubmit: (e: TaskSchemaType) => void;
  handleClose: () => void;
  isLoading: boolean;
  disableFields?: TaskFormHiddenField[];
  defaultValues: {
    taskName?: string;
    organizationId?: number;
    companyId?: number;
    businessAreaId?: number;
    workflowId?: number;
    taskDescription?: string;
    taskPeriodicity?: Periodicity;
    labourWorkload?: number;
    averageCases?: number;
    labourWorkloadType?: LabourWorkload;
  };
};

export const EditTaskForm = ({
  isLoading,
  handleOnSubmit,
  handleClose,
  disableFields,
  defaultValues,
  processId,
}: EditTaskFormProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<TaskSchemaType>({
    defaultValues,
    resolver: zodResolver(TaskSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });
  const businessAreaId = watch('businessAreaId');

  const { data: processWorkflows } = useWorkflowsBaseInfoByProcessId({
    processId,
  });

  const { data: businessAreaWorkflows } = useGetAllWorkflowsByBusinessArea(
    {
      businessAreaId,
    },
    { enabled: !processId },
  );

  const workflows = useMemo(
    () => (processId ? processWorkflows : businessAreaWorkflows),
    [businessAreaWorkflows, processId, processWorkflows],
  );

  const { dropdownWorkflowsOptions } = useMemo(() => {
    return {
      dropdownWorkflowsOptions: getDropdownArray(
        workflows?.filter(
          (workflow) =>
            workflow.status || workflow.id === defaultValues?.workflowId,
        ) ?? [],
      ),
    };
  }, [workflows, defaultValues]);

  const { dropdownPeriodicityOptions, dropdownLabourWorkloadTypeOptions } =
    useTaskFormOptions();

  const onSubmit = (data: TaskSchemaType) => {
    handleOnSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2 pb-20">
        <FieldInfo
          title={t('task_name')}
          errorText={
            errors.taskName?.message ? t(errors.taskName.message) : undefined
          }
          hasError={!!errors.taskName?.message}
        >
          <Input
            isDisabled={isLoading}
            className="w-full bg-brightGray italic"
            placeHolder={t('task_name')}
            name="taskName"
            register={register}
          />
        </FieldInfo>
        <FilterBusinessAreaFormGroup<TaskSchemaType>
          organizationId={defaultValues.organizationId}
          companyId={defaultValues.companyId}
          businessAreaId={defaultValues.businessAreaId}
          disableFields={disableFields}
          errors={errors}
          control={control}
        />
        <FieldInfo
          title={t('workflow')}
          errorText={
            errors.workflowId?.message
              ? t(errors.workflowId.message)
              : undefined
          }
          hasError={!!errors.workflowId?.message}
          hidden={disableFields?.includes('workflowId')}
        >
          <Controller
            name="workflowId"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                disabled={disableFields?.includes('workflowId')}
                className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                placeholder={t('workflow')}
                options={dropdownWorkflowsOptions}
                onSelect={(val) => onChange(val.value)}
                value={value}
              />
            )}
          />
        </FieldInfo>
        <FieldInfo
          title={t('task_description')}
          errorText={
            errors.taskDescription?.message
              ? t(errors.taskDescription.message, { length: '9' })
              : undefined
          }
          hasError={!!errors.taskDescription?.message}
        >
          <TextArea
            isDisabled={isLoading}
            className="h-24 w-full  bg-brightGray italic"
            placeHolder={t('task_description')}
            register={register}
            name="taskDescription"
          />
        </FieldInfo>
        <FieldInfo
          title={t('task_periodicity')}
          errorText={
            errors.taskPeriodicity?.message
              ? t(errors.taskPeriodicity.message, { length: '9' })
              : undefined
          }
          hasError={!!errors.taskPeriodicity?.message}
        >
          <Controller
            name="taskPeriodicity"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                disabled={disableFields?.includes('taskPeriodicity')}
                className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                placeholder={t('task_periodicity')}
                options={dropdownPeriodicityOptions}
                onSelect={(val) => onChange(val.value)}
                value={value}
              />
            )}
          />
        </FieldInfo>
        <FieldInfo
          title={t('labour_workload')}
          errorText={
            errors.labourWorkload?.message
              ? t(errors.labourWorkload.message, { length: '9' })
              : undefined
          }
          hasError={!!errors.labourWorkload?.message}
        >
          <Input
            type="text"
            isDisabled={isLoading}
            className="w-full bg-brightGray italic"
            placeHolder={t('labour_workload_minutes')}
            register={register}
            name="labourWorkload"
            step="any"
          />
        </FieldInfo>
        <FieldInfo
          title={t('labour_workload_type')}
          errorText={
            errors.labourWorkloadType?.message
              ? t(errors.labourWorkloadType.message, { length: '9' })
              : undefined
          }
          hasError={!!errors.labourWorkloadType?.message}
        >
          <Controller
            name="labourWorkloadType"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Dropdown
                disabled={disableFields?.includes('labourWorkloadType')}
                className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                placeholder={t('labour_workload_type')}
                options={dropdownLabourWorkloadTypeOptions}
                onSelect={(val) => onChange(val.value)}
                value={value}
              />
            )}
          />
        </FieldInfo>
        {watch('labourWorkloadType') === LabourWorkload.PerCase && (
          <FieldInfo
            title={t('average_cases')}
            errorText={
              errors.averageCases?.message
                ? t(errors.averageCases.message)
                : undefined
            }
            hasError={!!errors.averageCases?.message}
          >
            <Input
              type="number"
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('average_cases')}
              register={register}
              name="averageCases"
            />
          </FieldInfo>
        )}
      </div>
      <div className="mt-4 flex justify-between">
        <Button
          isDisabled={isLoading}
          onClick={handleClose}
          variant="outline"
          className="h-9 w-24"
        >
          {t('cancel')}
        </Button>
        <Button isDisabled={isLoading} buttonType="submit" className="h-9 w-24">
          {t('confirm')}
        </Button>
      </div>
    </form>
  );
};
