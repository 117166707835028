import { ReactElement, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  ChevronLeft,
  CustomPageContainer,
  TenantIcon,
} from '@/components';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@/components/Spinner';
import { TenantContext } from '@/components/TenantProvider';
import { TenantInfo } from './components/TenantInfo';
import { ManageUsers } from '@/modules/Users/pages/UserProfilePage/components';
import { LabelIcon } from '@/components/LabelIcon/LabelIcon';
import { useGetCurrentUser } from '@/modules/Users/queries';

export const TenantProfilePage = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLoadingTenantInfo, currentSelectedTenant } =
    useContext(TenantContext);

  const { data: user, isLoading: isLoadingUser } = useGetCurrentUser();

  const routeChange = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const isLoadingInfo = useMemo(
    () => isLoadingUser || isLoadingTenantInfo,
    [isLoadingUser, isLoadingTenantInfo],
  );

  return (
    <CustomPageContainer className="w-4/5">
      {isLoadingInfo ? (
        <Spinner className="ml-4 h-5 w-5" />
      ) : (
        <>
          <div className="flex justify-between">
            <div>
              <Button
                className="h-6 w-14 justify-between border-blueNuit p-2 font-black text-blueNuit"
                variant="outline"
                onClick={routeChange}
              >
                <ChevronLeft />
                {t('back')}
              </Button>
              <p className="mt-3 text-xs">
                {t('tenant_page')} /{' '}
                <span className="font-black">
                  {currentSelectedTenant?.name}
                </span>
              </p>
            </div>
            <div>
              <LabelIcon
                label={t('tenant')}
                icon={<TenantIcon className="size-5 text-blueNuit" />}
              />
            </div>
          </div>

          <div className="flex w-full flex-col gap-10">
            <TenantInfo />
            {user?.accountAdmin && <ManageUsers />}
          </div>
        </>
      )}
    </CustomPageContainer>
  );
};
