import { PropsWithChildren } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { RouterContext } from './router.context';

type RouterProviderProps = PropsWithChildren;

export const RouterProvider = ({ children, ...props }: RouterProviderProps) => (
  <Router>
    <RouterContext.Provider value={{ ...props }}>
      {children}
    </RouterContext.Provider>
  </Router>
);
