import { PieChartCard } from '@/components';
import { SuccessRate } from '@/core/domain/global-performance-model';
import { useGetChartColorsAndLabel } from '@/hooks';
import { useGetAutomationPerformance } from '@/modules/Automations/queries';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LastSuccessRunRateDetails } from './LastSuccessRunRateDetails';

type LastSuccessRunRateCardProps = {
  automationId: number;
};

export const LastSuccessRunRateCard = ({
  automationId,
}: LastSuccessRunRateCardProps) => {
  const { t } = useTranslation();
  const { rateChart } = useGetChartColorsAndLabel();
  const { data: automationPerformance } =
    useGetAutomationPerformance(automationId);
  const [isLastSuccessRunRateDetailsOpen, setisLastSuccessRunRateDetailsOpen] =
    useState<boolean>();

  const lastRunSuccessRateData = useMemo(
    () =>
      Object.entries(rateChart).map(([key, value]) => ({
        ...value,
        value:
          automationPerformance?.lastRunSuccessRate?.[
            key as keyof SuccessRate
          ] ?? 0,
      })),
    [automationPerformance, rateChart],
  );

  return (
    <>
      <PieChartCard
        data={lastRunSuccessRateData}
        chartTitle={t('last_run_performance')}
        valueType="percentage"
        subtitle={t('cases')}
        tooltip={t('last_run_performance_tooltip_description')}
        onExpand={() => setisLastSuccessRunRateDetailsOpen(true)}
      />
      {isLastSuccessRunRateDetailsOpen && (
        <LastSuccessRunRateDetails
          automationId={automationId}
          onClose={() => {
            setisLastSuccessRunRateDetailsOpen(false);
          }}
        />
      )}
    </>
  );
};
