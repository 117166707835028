import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { DropdownArrow } from '@/components/DropdownArrow/DropdownArrow';
import { PageDetailsHeaderRow } from '@/components/PageDetailsHeaderRow/PageDetailsHeaderRow';
import { StatusCard } from '@/components/StatusCard/StatusCard';
import { Tag } from '@/components/Tag';
import { AutomationModel } from '@/modules/Automations/domain';
import { useUpdateStage } from '@/modules/Automations/queries';
import { AutomationKey } from '@/modules/Automations/queries/keys';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { useQueryClient } from '@tanstack/react-query';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { EditAutomationModal } from '../../../AutomationsPage/components/EditAutomationModal';
import { AutomationFilesRow } from '../FilesRow';
import { SchedulingRow } from '../SchedulingRow';
import { useSearchParams } from 'react-router-dom';

interface HeaderRowProps {
  automation?: AutomationModel;
  isLoading?: boolean;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent>,
    id: string,
  ) => void;
  status: boolean;
}

export const AutomationHeaderRow = ({
  automation,
  isLoading,
  handleStatus,
  status,
}: HeaderRowProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const isEditMode = searchParams.get('edit') === 'true';
  const [isOpen, setIsOpen] = useState(isEditMode);
  const [selectedStage, setSelectedStage] = useState(automation?.stageId || 0);

  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const { mutate } = useUpdateStage();

  useEffect(() => {
    if (automation?.stageId) {
      setSelectedStage(automation?.stageId);
    }
  }, [automation?.stageId]);

  useEffect(() => {
    setIsOpen(isEditMode);
  }, [isEditMode]);

  const stages = [
    { id: 1, name: 'PddSubmission' },
    { id: 2, name: 'PddApproval' },
    { id: 3, name: 'Development' },
    { id: 4, name: 'TestApproval' },
    { id: 5, name: 'Rollout' },
    { id: 6, name: 'Productive' },
    { id: 7, name: 'Active' },
    { id: 8, name: 'Maintenance' },
    { id: 9, name: 'Inactive' },
  ];

  const dropdownStagesOptions = getDropdownArray(stages);

  const handleChange = async (value: number) => {
    setSelectedStage(value);

    const convertedData = {
      id: automation?.id ?? 0,
      stage: value,
    };
    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_update_stage'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(AutomationKey.ALL);
        toast.success(t('success_update_stage'));
      },
    });
  };

  return (
    <div>
      <PageDetailsHeaderRow
        isLoading={!automation}
        title={automation?.name ?? ''}
        description={
          <>
            <span className="font-bold" data-testid="ba-comp-section">
              {`${automation?.businessAreaName}, ${automation?.company}, `}
            </span>
            <span className="font-normal">{automation?.organization}</span>
          </>
        }
        actions={
          <>
            <div className="flex flex-row items-center gap-5 rounded-md bg-white px-2.5 py-1">
              <span className="text-sm font-black">{t('stage')}</span>
              <DropdownArrow
                placeholder={t('stage')}
                selectedValue={selectedStage}
                onSelect={handleChange}
                options={dropdownStagesOptions}
              />
            </div>
            <StatusCard status={status} handleStatus={handleStatus} />
          </>
        }
        onEditClick={handleModalState}
        details={
          <>
            <Container>
              <Card title={t('description')} isLoading={isLoading}>
                <p className="whitespace-pre-wrap">{automation?.description}</p>
              </Card>
              <div className="flex gap-2.5">
                <Card title={t('stage')} isLoading={isLoading}>
                  <Tag className="bg-blue-500 text-white">
                    {t(`${automation?.stage}`)}
                  </Tag>
                </Card>
                <Card title={t('version')} isLoading={isLoading}>
                  {automation?.version}
                </Card>
                <Card title={t('code')} isLoading={isLoading}>
                  {automation?.code}
                </Card>
                <Card title={t('developer')} isLoading={isLoading}>
                  {automation?.developer}
                </Card>
              </div>
            </Container>
            <AutomationFilesRow automation={automation} isLoading={isLoading} />
          </>
        }
      >
        <SchedulingRow
          scheduling={automation?.scheduling}
          averageCases={automation?.averageCases}
          isLoading={isLoading}
        />
      </PageDetailsHeaderRow>
      {isOpen && automation?.id && (
        <EditAutomationModal
          isOpen={isOpen}
          id={automation.id}
          handleClose={handleModalState}
        />
      )}
    </div>
  );
};
