import { DEFAULT_PAGE_SIZE, SortDirection } from '@/components/Table/types';
import { HttpClient } from '@/core/http/http-client';
import {
  mapLabourTimeAutomatedList,
  mapLastRunSuccessRateList,
  mapOverallRunsSuccessRateList,
  mapTotalReturnList,
} from '@/shared/mappers/kpi-mapper';
import {
  ApiResponse,
  DataWithPagination,
  ExceptionType,
  LabourTimeAutomatedDetailsJson,
  LastRunSuccessRateDetailsJson,
  OverallRunsSuccessRateDetailsJson,
  TotalReturnDetailsJson,
} from '@/shared/types';
import { DateUtility } from '@/utils/date.utils';
import { DateRange } from 'react-day-picker';
import { WorkflowBaseInfo, WorkflowModel } from '../domain';
import {
  workflowAutomationsPerformanceMapper,
  workflowMapper,
  workflowsBaseInfoMapper,
  workflowsMapper,
  workflowsWithPaginationMapper,
} from '../mappers';
import {
  ApiResponseWorkflow,
  ApiResponseWorkflows,
  AssociateTaskToWorkflow,
  CreateWorkflowJson,
  DisassociateTaskFromWorkflow,
  OrderWorkflow,
  PagedWorkflows,
  WorkflowAutomationsPerformanceJson,
  WorkflowBaseInfoJson,
  WorkflowJson,
} from '../types';
import { WorkflowRepository } from './workflow.repository';

export class HttpWorkflowRepository extends WorkflowRepository {
  constructor(private readonly client: HttpClient) {
    super();
  }

  async getWorkflows(
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<WorkflowModel[]>> {
    const response = await this.client.get<
      ApiResponseWorkflows<PagedWorkflows>
    >({
      url: '/me/workflows',
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });
    return workflowsWithPaginationMapper(response);
  }

  createWorkflow = async (data: CreateWorkflowJson): Promise<void> => {
    await this.client.post<WorkflowJson>({ body: data });
  };

  getWorkflow = async (id: number): Promise<WorkflowModel> => {
    const workflow = await this.client.get<ApiResponseWorkflow<WorkflowJson>>({
      url: `${id}`,
    });

    return workflowMapper(workflow.data.workflow);
  };

  async getAutomationsPerformanceById(
    workflowId: number,
    dateRange?: DateRange,
  ) {
    const response = await this.client.get<
      ApiResponse<WorkflowAutomationsPerformanceJson>
    >({
      url: `${workflowId}/automations-performance`,
      query: {
        ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
      },
    });

    return workflowAutomationsPerformanceMapper(response.data);
  }

  enableWorkflow = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/enable`,
    });
  };

  disableWorkflow = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/disable`,
    });
  };

  orderWorkflow = async (
    data: OrderWorkflow,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.patch(`${data.id}`, data);
  };

  deleteWorkflow = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.delete(`${id}`);
  };

  updateWorkflow = async (data: CreateWorkflowJson): Promise<unknown> => {
    return await this.client.put<unknown>(`${data.id}`, data);
  };

  async getWorkflowsByBusinessAreaId(
    businessAreaId: number,
  ): Promise<WorkflowBaseInfo[]> {
    const workflows = await this.client.get<
      ApiResponseWorkflows<WorkflowBaseInfoJson[]>
    >({
      url: `/business-areas/${businessAreaId}/workflows/base-info`,
    });

    return workflowsBaseInfoMapper(workflows.data.workflows);
  }

  async getWorkflowsByBusinessArea(
    businessAreaId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<WorkflowModel[]>> {
    const workflows = await this.client.get<
      ApiResponseWorkflows<PagedWorkflows>
    >({
      url: `/business-areas/${businessAreaId}/workflows`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return workflowsWithPaginationMapper(workflows);
  }

  async getWorkflowsByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<WorkflowModel[]>> {
    const workflows = await this.client.get<
      ApiResponseWorkflows<PagedWorkflows>
    >({
      url: `/organizations/${organizationId}/workflows`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return workflowsWithPaginationMapper(workflows);
  }

  async getWorkflowsTasksByProcessId(
    processId: number,
  ): Promise<WorkflowModel[]> {
    const workflows = await this.client.get<
      ApiResponseWorkflows<WorkflowJson[]>
    >({
      url: `/processes/${processId}/workflows/tasks`,
    });

    return workflowsMapper(workflows.data.workflows);
  }

  async getWorkflowsBaseInfoByProcessId(
    processId: number,
  ): Promise<WorkflowBaseInfo[]> {
    const workflows = await this.client.get<
      ApiResponseWorkflows<WorkflowBaseInfoJson[]>
    >({
      url: `/processes/${processId}/workflows/base-info`,
    });

    return workflowsBaseInfoMapper(workflows.data.workflows);
  }

  async getWorkflowsByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<WorkflowModel[]>> {
    const workflows = await this.client.get<
      ApiResponseWorkflows<PagedWorkflows>
    >({
      url: `/companies/${companyId}/workflows`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return workflowsWithPaginationMapper(workflows);
  }

  associateTaskToWorkflow = async (
    data: AssociateTaskToWorkflow,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `associate-task`,
      body: data,
    });
  };

  disassociateTaskFromWorkflow = async (
    data: DisassociateTaskFromWorkflow,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `disassociate-task`,
      body: data,
    });
  };

  async getWorkflowLabourTimeAutomatedById(
    workflowId: number,
    dateRange?: DateRange,
    page?: number,
    filter?: string,
  ) {
    const response = await this.client.get<
      ApiResponse<LabourTimeAutomatedDetailsJson>
    >({
      url: `${workflowId}/labour-time-automated`,
      query: {
        page,
        filter,
        ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
      },
    });

    return mapLabourTimeAutomatedList(
      response.data.executions,
      response.data.totals,
    );
  }

  async getWorkflowTotalReturnById(
    workflowId: number,
    dateRange?: DateRange,
    page?: number,
    filter?: string,
  ) {
    const response = await this.client.get<ApiResponse<TotalReturnDetailsJson>>(
      {
        url: `${workflowId}/total-return`,
        query: {
          page,
          filter,
          ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
        },
      },
    );

    return mapTotalReturnList(response.data.executions, response.data.totals);
  }

  async getWorkflowLastRunById(
    workflowId: number,
    page?: number,
    filter?: string,
    result?: boolean,
    exceptionType?: ExceptionType,
  ) {
    const response = await this.client.get<
      ApiResponse<LastRunSuccessRateDetailsJson>
    >({
      url: `${workflowId}/last-run`,
      query: {
        page,
        filter,
        result,
        exceptionType,
      },
    });

    return mapLastRunSuccessRateList(response.data.executions);
  }

  async getWorkflowOverallRunsById(
    workflowId: number,
    dateRange?: DateRange,
    page?: number,
    filter?: string,
    result?: boolean,
    exceptionType?: ExceptionType,
  ) {
    const response = await this.client.get<
      ApiResponse<OverallRunsSuccessRateDetailsJson>
    >({
      url: `${workflowId}/overall-runs`,
      query: {
        page,
        filter,
        result,
        ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
        exceptionType,
      },
    });

    return mapOverallRunsSuccessRateList(response.data.executions);
  }
}
