import {
  performanceCardTextColors,
  PerformanceType,
} from '@/components/PerformanceCard/utils';
import { TableColumn, TableRow } from '@/components/Table/types';
import { dividerColumnConfig } from '@/utils/tableColumns/divider-column-config';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { PerformanceData } from '../../hooks';
import { OverflowTooltip } from '@/components';

const commonStyling = (isParent: boolean) =>
  twMerge('font-normal', isParent && 'font-black');

export const columns = (
  onClick: (row?: TableRow) => void,
): TableColumn<PerformanceData>[] => [
  {
    id: 'name',
    label: <Trans i18nKey="name" />,
    render: (value, row) => {
      return (
        <div
          className="flex cursor-pointer gap-1 overflow-hidden text-ellipsis"
          onClick={() => onClick?.(row)}
        >
          <OverflowTooltip text={value} textClassName="font-black" />
          {row.rows?.length && (
            <span className="font-normal">
              {`(${row.rows?.length} ${t('tasks')})`}
            </span>
          )}
        </div>
      );
    },
    acessorKey: 'name',
    sortable: true,
    align: 'left',
    alignHeader: 'left',
    className: 'min-w-[15vw] max-w-[15vw] w-[15vw] font-black',
  },
  {
    id: 'labourTimeAutomated',
    label: <Trans i18nKey="labourTimeAutomated" />,
    acessorKey: 'labourTimeAutomated',
    render: (value, row) => {
      return <span className={commonStyling(row.isParent)}>{value}</span>;
    },
  },
  {
    id: 'automationRunTime',
    label: <Trans i18nKey="automationRunTime" />,
    acessorKey: 'automationRunTime',
    render: (value, row) => {
      return <span className={commonStyling(row.isParent)}>{value}</span>;
    },
  },
  {
    id: 'timeEfficiency',
    label: <Trans i18nKey="timeEfficiency" />,
    acessorKey: 'timeEfficiency',
    render: (value, row) => {
      return (
        <span
          className={twMerge(
            commonStyling(row.isParent),
            performanceCardTextColors[
              row.timePerformance ?? PerformanceType.Neutral
            ],
          )}
        >
          {value}
        </span>
      );
    },
  },
  dividerColumnConfig({ key: 'divider-2' }),
  {
    id: 'labourCost',
    label: <Trans i18nKey="labourCost" />,
    acessorKey: 'labourCost',
    render: (value, row) => {
      return <span className={commonStyling(row.isParent)}>{value}</span>;
    },
  },
  {
    id: 'automationRunCost',
    label: <Trans i18nKey="automationRunCost" />,
    acessorKey: 'automationRunCost',
    render: (value, row) => {
      return <span className={commonStyling(row.isParent)}>{value}</span>;
    },
  },
  {
    id: 'finantialOutcome',
    label: <Trans i18nKey="finantialOutcome" />,
    acessorKey: 'finantialOutcome',
    render: (value, row) => {
      return (
        <span
          className={twMerge(
            commonStyling(row.isParent),
            performanceCardTextColors[
              row.financialPerformance ?? PerformanceType.Neutral
            ],
          )}
        >
          {value}
        </span>
      );
    },
  },
];
