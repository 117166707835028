import { ReactElement } from 'react';
import { DevTimeCard } from '../DevTimeCard/DevTimeCard';
import { LastSuccessRunRateCard } from '../LastSuccesRunRateCard/LastSuccessRunRateCard';
import { OverallRunsCard } from '../OverallRunsCard/OverallRunsCard';
import { InvestmentCard } from './InvestmentCard/InvestmentCard';

type AutomationPerformanceChartsProps = {
  automationId: number;
};

export const AutomationPerformanceCharts = ({
  automationId,
}: AutomationPerformanceChartsProps): ReactElement => {
  return (
    <div className="flex flex-col gap-2 py-2">
      <div className="flex flex-1 gap-2">
        <LastSuccessRunRateCard automationId={automationId} />
        <OverallRunsCard automationId={automationId} />
      </div>
      <div className="flex gap-2">
        <DevTimeCard automationId={automationId} />
        <InvestmentCard automationId={automationId} />
      </div>
    </div>
  );
};
