import { PerformanceCardDetails } from '@/components';
import { useGetChartColorsAndLabel } from '@/hooks';
import { getDevTimeColumns } from '@/modules/Automations/pages/AutomationsPage/utils';
import { useGetAutomationPerformance } from '@/modules/Automations/queries';
import { useAutomationInvestment } from '@/modules/Automations/queries/use-automation-performance';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { DevTimeMetrics } from '../AutomationPerformanceCharts/components/DevTimeMetrics';

type DevTimeDetailsProps = {
  automationId: number;
  onClose: () => void;
};

export const DevTimeDetails = ({
  automationId,
  onClose,
}: DevTimeDetailsProps) => {
  const { t } = useTranslation();
  const { devTimeChartColors } = useGetChartColorsAndLabel();
  const { data: automationPerformance } =
    useGetAutomationPerformance(automationId);

  const [selectedBillable, setSelectedBillable] = useState<number>();
  const [dateRange, setDateRange] = useState<DateRange>();
  const [selectedType, setSelectedType] = useState<number>();
  const [search, setSearch] = useState<string>();

  const {
    data,
    isLoading: isLoadingDevTimeInvestment,
    fetchNextPage: fetchDevTimeNextPage,
    isFetching,
    isFetchingNextPage,
  } = useAutomationInvestment({
    automationId,
    dateRange,
    filter: search,
    billable: selectedBillable,
    type: selectedType,
  });

  const devTimeInvestment = useMemo(
    () => data?.pages.flat() || [],
    [data?.pages],
  );

  const devTimeMetrics = useMemo(() => {
    const { maintenance, development, support } =
      automationPerformance?.devTime ?? {
        maintenance: 0,
        development: 0,
        support: 0,
      };

    const totalTime = maintenance + development + support;
    let devPercentage = 0;
    let maintenancePercentage = 0;
    let supportPercentage = 0;
    if (totalTime > 0) {
      devPercentage = (development / totalTime) * 100;
      maintenancePercentage = (maintenance / totalTime) * 100;
      supportPercentage = (support / totalTime) * 100;
    }

    return {
      totalTimeSpentSeconds: totalTime,
      totalTimeSpentSecondsMaintenance: maintenance,
      totalTimeSpentSecondsDevelopment: development,
      totalTimeSpentSecondsSupport: support,
      maintenanceTimePercentage: maintenancePercentage,
      developmentTimePercentage: devPercentage,
      supportTimePercentage: supportPercentage,
    };
  }, [automationPerformance]);

  return (
    <PerformanceCardDetails
      title={t('dev_time')}
      subTitle={t('dev_time_history')}
      description={t('dev_time_description')}
      columns={getDevTimeColumns()}
      onClose={onClose}
      data={{
        items: devTimeInvestment ?? [],
        totalPages: 1,
        totalRows: devTimeInvestment?.length ?? 0,
      }}
      fetchNextPage={fetchDevTimeNextPage}
      contextType="devTime"
      selectedBillable={selectedBillable}
      selectedType={selectedType}
      setSelectedBillable={setSelectedBillable}
      setSelectedType={setSelectedType}
      metrics={
        <DevTimeMetrics
          devTimeChartColors={devTimeChartColors}
          metrics={devTimeMetrics}
        />
      }
      kpiDateRange={dateRange}
      setKpiDateRange={setDateRange}
      isLoading={isLoadingDevTimeInvestment}
      setSearchInput={setSearch}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};
