import { DateUtility } from '@/utils/date.utils';
import { useMemo } from 'react';
import { DateRange } from 'react-day-picker';
import { TagType } from '../constants';

type DatePlaceholder = {
  label: string;
  opt?: { value?: number; startDate?: string; endDate?: string };
};

export const useDatesPlaceholder = (
  range?: DateRange,
  selectedTag?: TagType,
): DatePlaceholder => {
  return useMemo(() => {
    if (!range?.to) {
      return {
        label: 'all_time',
      };
    }

    const diff = DateUtility.differenceInDays(
      range.to || DateUtility.today(),
      range.from || DateUtility.subtract(DateUtility.today(), { days: 7 }),
    );

    if (diff === 0) {
      return {
        label: 'x_single_date',
        opt: {
          endDate: range.to.toLocaleDateString('en-de'),
        },
      };
    }

    if (DateUtility.isToday(range.to) && selectedTag) {
      if (selectedTag === TagType.YEAR_TO_DATE) {
        return {
          label: 'year_to_date',
        };
      }

      return {
        label: 'last_x_days',
        opt: {
          value: diff,
        },
      };
    }

    return {
      label: 'time_period_x_selected',
      opt: {
        startDate: range.from?.toLocaleDateString('en-de'),
        endDate: range.to.toLocaleDateString('en-de'),
      },
    };
  }, [range, selectedTag]);
};
