import {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEvent,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { useOutsideClick } from '@/hooks';
import { ChevronLeft } from '../Icons';

export interface SelectHierarchicalDropdownOption<T> {
  label: string;
  value: T;
  parentId: number | null;
}

interface HierarchicalDropdownProps<T> {
  className?: string;
  placeholder?: string;
  options: SelectHierarchicalDropdownOption<T>[];
  value?: T;
  onSelect?: (option: SelectHierarchicalDropdownOption<T>) => void;
  disabled?: boolean;
}
export function HierarchicalDropdown<T>({
  className,
  options,
  placeholder,
  value,
  onSelect,
  disabled,
}: HierarchicalDropdownProps<T>): ReactElement {
  const buttonWrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [internalValue, setInternalValue] = useState<T | undefined>(value);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleOutsideClick = useCallback(() => {
    setIsOpen(false);
    setSearchTerm('');
  }, []);

  useOutsideClick(buttonWrapperRef, handleOutsideClick, isOpen);

  useEffect(() => {
    if (!searchTerm) {
      setFilteredOptions(options);
    } else {
      const filterOptions = (opts: SelectHierarchicalDropdownOption<T>[]) =>
        opts.filter((opt) =>
          opt.label.toLowerCase().includes(searchTerm.toLowerCase()),
        );
      setFilteredOptions(filterOptions(options));
    }
  }, [searchTerm, options]);

  const handleSelectDropdownButtonClick = useCallback((event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen((prev) => !prev);
    setSearchTerm('');
  }, []);

  const handleOptionClick = useCallback(
    (option: SelectHierarchicalDropdownOption<T>) => (event: MouseEvent) => {
      setInternalValue(option.value);
      if (onSelect) onSelect(option);
      setIsOpen(false);
      setSearchTerm('');
      event.preventDefault();
    },
    [onSelect],
  );

  const buildTree = (parentId: number, level: number = 0): ReactElement[] => {
    return filteredOptions
      .filter((opt) => opt.parentId === parentId)
      .map((opt) => (
        <li
          key={opt.value as string}
          className="flex w-full flex-col"
          style={{ paddingLeft: `${level * 1.5}rem` }}
        >
          <label
            className="flex w-full cursor-pointer items-center space-x-2 py-2"
            onClick={handleOptionClick(opt)}
          >
            <input
              type="radio"
              name="hierarchical-dropdown"
              value={opt.value as string}
              checked={internalValue === opt.value}
              onChange={() => {}}
              className="form-radio text-blue-600 focus:ring focus:ring-blue-300"
            />
            <span
              className={`text-sm ${
                internalValue === opt.value ? 'font-semibold text-blue-600' : ''
              }`}
            >
              {opt.label}
            </span>
          </label>
          <ul className="w-full">
            {buildTree(opt.value as number, level + 1)}
          </ul>
        </li>
      ));
  };

  const selectedOptionLabel = options.find(
    (opt) => opt.value === internalValue,
  )?.label;

  return (
    <div ref={buttonWrapperRef} className={twMerge('relative z-2', className)}>
      <div className="relative">
        <input
          type="text"
          value={isOpen ? searchTerm : selectedOptionLabel || ''}
          placeholder={placeholder}
          onFocus={() => setIsOpen(true)}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={twMerge(
            'flex h-10 w-full rounded-md border p-2 text-md font-normal text-primaryBlue outline-none',
            disabled
              ? 'cursor-not-allowed bg-gray-100'
              : 'cursor-pointer bg-brightGray',
          )}
          readOnly={!isOpen}
        />
        <button
          onClick={handleSelectDropdownButtonClick}
          className="absolute right-2 top-2"
          disabled={disabled}
        >
          <ChevronLeft
            className={twMerge(
              'mt-2 size-3 -rotate-90 text-blueNuit transition-transform',
              isOpen && 'rotate-90',
            )}
          />
        </button>
      </div>
      {isOpen && (
        <ul className="flex max-h-48 flex-col overflow-y-auto rounded-md border bg-brightGray pl-2 shadow-md">
          {buildTree(0)}
        </ul>
      )}
    </div>
  );
}
