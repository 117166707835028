import { SystemMessageJson } from '../types';
import { SystemMessageModel } from '../domain/system-model';

export const mapSystemMessageJsonToSystemMessageModel = (
  systemMessageJson?: SystemMessageJson,
): SystemMessageModel => ({
  id: systemMessageJson?.id ?? 0,
  startDate: systemMessageJson?.startDate
    ? new Date(systemMessageJson?.startDate)
    : undefined,
  endDate: systemMessageJson?.endDate
    ? new Date(systemMessageJson?.endDate)
    : undefined,
  status: systemMessageJson?.status ?? false,
  environment: systemMessageJson?.environment ?? '',
  systemMessageTypeId: systemMessageJson?.systemMessageTypeId ?? 0,
  content: systemMessageJson?.content ?? '',
});

export const notificationsMapper = (
  systemMessageJson: Array<SystemMessageJson>,
): Array<SystemMessageModel> =>
  systemMessageJson.map(mapSystemMessageJsonToSystemMessageModel);
