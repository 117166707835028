import { LabourWorkload } from '../types/labourWorkLoad';
import { Periodicity } from '../types/periodicity';

type BuildTaskFormDataProps = {
  name: string;
  businessAreaId: number;
  description: string | undefined;
  periodicity: Periodicity;
  workload: number;
  workflowId: number | undefined;
  labourWorkloadType: LabourWorkload;
  averageCases: number | undefined;
};

export const buildTaskFormData = ({
  name,
  businessAreaId,
  description,
  periodicity,
  workload,
  workflowId,
  labourWorkloadType,
  averageCases,
}: BuildTaskFormDataProps) => {
  const taskFormData = new FormData();
  taskFormData.append('name', name);
  taskFormData.append('businessAreaId', businessAreaId.toString());
  description && taskFormData.append('description', description);
  taskFormData.append('periodicityId', periodicity.toString());
  taskFormData.append('workload', workload.toString().replace('.', ','));
  workflowId && taskFormData.append('workflowId', workflowId.toString());
  taskFormData.append('workloadTypeId', labourWorkloadType.toString());
  averageCases && taskFormData.append('averageCases', averageCases.toString());

  return taskFormData;
};
