import { LabourTimeAutomatedCard } from '@/components/PerformanceCardsContainer/components/LabourTimeAutomatedCard';
import { LabourTimeAutomatedDetails } from '@/components/PerformanceCardsContainer/components/LabourTimeAutomatedDetails';
import {
  useBusinessAreaAutomationsPerformance,
  useBusinessAreaLabourTimeAutomated,
} from '@/modules/BusinessAreas/queries/use-business-area-automations-perforrmance';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';

type BusinessAreaLabourTimeAutomatedCardProps = {
  businessAreaId: number;
  automationPerformanceDateRange?: DateRange;
};

export const BusinessAreaLabourTimeAutomatedCard = ({
  businessAreaId,
  automationPerformanceDateRange,
}: BusinessAreaLabourTimeAutomatedCardProps) => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const [searchInput, setSearchInput] = useState<string>();
  const [isDetailsOpen, setDetailsOpen] = useState<boolean>(false);

  const { data: automationPerformance, isLoading } =
    useBusinessAreaAutomationsPerformance(
      businessAreaId,
      automationPerformanceDateRange,
    );

  const {
    data,
    isLoading: isLoadingLabourAutomation,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useBusinessAreaLabourTimeAutomated({
    businessAreaId,
    dateRange,
    filter: searchInput,
    enabled: isDetailsOpen,
  });

  const labourAutomated = useMemo(
    () => data?.pages.flatMap((page) => page) || [],
    [data?.pages],
  );

  return (
    <LabourTimeAutomatedCard
      automationPerformance={automationPerformance}
      isLoading={isLoading}
      setIsDetailsOpen={setDetailsOpen}
      details={
        isDetailsOpen && (
          <LabourTimeAutomatedDetails
            isLoadingLabourAutomation={isLoadingLabourAutomation}
            labourAutomated={labourAutomated}
            onClose={() => setDetailsOpen(false)}
            addAdditionalColumns
            fetchNextPage={fetchNextPage}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setSearchInput={setSearchInput}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
          />
        )
      }
    />
  );
};
