import { TableColumn, TableRow } from '@/components/Table/types';
import {
  LabourTimeAutomatedModel,
  OverallRunsSuccessRateModel,
  TotalReturnModel,
  WorklogCostModel,
} from '@/shared/domain';
import {
  billableAmountColumnConfig,
  averageCasesColumnConfig,
  billableColumnConfig,
  businessAreaNameColumnConfig,
  caseFinishColumnConfig,
  caseStartColumnConfig,
  criticallityColumnConfig,
  dateColumnConfig,
  deleteRowColumnConfig,
  descriptionColumnConfig,
  idColumnConfig,
  kpiAutomationsNameColumnConfig,
  labourCostColumnConfig,
  labourTimeColumnConfig,
  requestNameColumnConfig,
  requestOwnerColumnConfig,
  requestStatusColumnConfig,
  resultColumnConfig,
  returnColumnConfig,
  runCostColumnConfig,
  runFinishColumnConfig,
  runStartColumnConfig,
  runTimeColumnConfig,
  stageColumnConfig,
  statusColumnConfig,
  successColumnConfig,
  taskNameColumnConfig,
  timeEfficiencyColumnConfig,
  timeSpentColumnConfig,
  totalCasesColumnConfig,
  totalErrorColumnConfig,
  typeColumnConfig,
} from '@/utils';
import { automationsNameColumnConfig } from '@/utils/tableColumns/automations-name-column-config';
import { dividerColumnConfig } from '@/utils/tableColumns/divider-column-config';
import { processCompanyNameColumnConfig } from '@/utils/tableColumns/process-company-name-column-config';
import { processOrganizationNameColumnConfig } from '@/utils/tableColumns/process-organization-name-column-config';
import { toggleStatusColumnConfig } from '@/utils/tableColumns/user-status-column-config';
import { AutomationModel, AutomationRequestModel } from '../../domain';

type AutomationsColumnsConfig = {
  onClick?: (row?: TableRow) => void;
  disableDeleteMessage?: string;
  onDeleteClick?: (row?: TableRow) => void;
  onStatusChange: (checked: boolean, row: TableRow) => void;
};

type AutomationsTabColumnsConfig = {
  onClick?: (row?: TableRow) => void;
  disableDeleteMessage?: string;
  onDeleteClick?: (row?: TableRow) => void;
};

type RequestsColumnsConfig = {
  onClick?: (row?: TableRow) => void;
};

export const getAutomationsColumns = ({
  onClick,
  disableDeleteMessage,
  onDeleteClick,
  onStatusChange,
}: AutomationsColumnsConfig): Array<TableColumn<AutomationModel>> => {
  return [
    automationsNameColumnConfig({ onClick }),
    processOrganizationNameColumnConfig(),
    processCompanyNameColumnConfig(),
    businessAreaNameColumnConfig(),
    stageColumnConfig(),
    toggleStatusColumnConfig({
      onChange: onStatusChange,
    }),
    idColumnConfig(),
    dividerColumnConfig(),
    runTimeColumnConfig(),
    averageCasesColumnConfig(),
    successColumnConfig(),
    deleteRowColumnConfig({
      disabledMessage: disableDeleteMessage,
      onDeleteClick: onDeleteClick,
    }),
  ];
};

export const getAutomationTabColumns = ({
  onClick,
  disableDeleteMessage,
  onDeleteClick,
}: AutomationsTabColumnsConfig): Array<TableColumn<AutomationModel>> => {
  return [
    automationsNameColumnConfig({ onClick }),
    businessAreaNameColumnConfig(),
    stageColumnConfig(),
    statusColumnConfig(),
    idColumnConfig(),
    dividerColumnConfig(),
    runTimeColumnConfig(),
    averageCasesColumnConfig(),
    successColumnConfig(),
    deleteRowColumnConfig({
      disabledMessage: disableDeleteMessage,
      onDeleteClick: onDeleteClick,
    }),
  ];
};

export const getRequestColumns = ({
  onClick,
}: RequestsColumnsConfig): Array<TableColumn<AutomationRequestModel>> => {
  return [
    requestNameColumnConfig({ onClick }),
    requestOwnerColumnConfig(),
    dateColumnConfig(),
    criticallityColumnConfig(),
    requestStatusColumnConfig(),
  ];
};

export const getLabourTimeAutomatedColumns = (
  includeAdditionalColumns: boolean = false,
): Array<TableColumn<LabourTimeAutomatedModel>> => {
  const baseColumns = [
    runStartColumnConfig(),
    runFinishColumnConfig(),
    labourTimeColumnConfig(),
    runTimeColumnConfig(),
    timeEfficiencyColumnConfig(),
  ];

  const additionalColumns = includeAdditionalColumns
    ? [kpiAutomationsNameColumnConfig({})]
    : [];

  return [...additionalColumns, ...baseColumns];
};

export const getTotalReturnColumns = (
  includeAdditionalColumns: boolean = false,
): Array<TableColumn<TotalReturnModel>> => {
  const baseColumns = [
    runStartColumnConfig(),
    runFinishColumnConfig(),
    totalCasesColumnConfig(),
    successColumnConfig(),
    totalErrorColumnConfig(),
    labourCostColumnConfig(),
    runCostColumnConfig(),
    returnColumnConfig(),
  ];

  const additionalColumns = includeAdditionalColumns
    ? [kpiAutomationsNameColumnConfig({})]
    : [];

  return [...additionalColumns, ...baseColumns];
};

export const getLastRunSucessRateColumns = (): Array<TableColumn<TableRow>> => {
  return [
    taskNameColumnConfig(),
    caseStartColumnConfig(),
    caseFinishColumnConfig(),
    runTimeColumnConfig(),
    resultColumnConfig(),
  ];
};

export const getOverallRunsSucessRateColumns = (): Array<
  TableColumn<OverallRunsSuccessRateModel>
> => {
  return [
    taskNameColumnConfig(),
    caseStartColumnConfig(),
    caseFinishColumnConfig(),
    runTimeColumnConfig(),
    resultColumnConfig(),
  ];
};

export const getDevTimeColumns = (): Array<TableColumn<WorklogCostModel>> => {
  return [
    descriptionColumnConfig(),
    dateColumnConfig(),
    typeColumnConfig(),
    billableColumnConfig(),
    timeSpentColumnConfig(),
  ];
};

export const getInvestmentColumns = (): Array<
  TableColumn<WorklogCostModel>
> => {
  return [
    descriptionColumnConfig(),
    dateColumnConfig(),
    typeColumnConfig(),
    billableColumnConfig(),
    billableAmountColumnConfig(),
  ];
};

export const getAutomationColumnsNamedMapped = (columnName: string) => {
  const organizationsColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'Name',
    businessAreaName: 'BusinessAreaName',
    stage: 'Stage',
    status: 'Status',
    runTime: 'RunTime',
    averageCases: 'AverageCases',
    sucess: 'Sucess',
    organizationName: 'Organization',
    company: 'Company',
  };

  return organizationsColumnsNamedMapped[columnName] ?? 'Name';
};

export const getRequestColumnsNamedMapped = (columnName: string) => {
  const requestColumnsNamedMapped: Record<string, string> = {
    id: 'Id',
    name: 'Name',
    requestStage: 'RequestStage',
    description: 'Description',
    duration: 'Duration',
    frequency: 'Frequency',
    priority: 'Priority',
    additionalInformation: 'AdditionalInformation',
  };

  return requestColumnsNamedMapped[columnName] ?? 'Name';
};

export const AUTOMATION_PRIORITIES = [
  'very_low',
  'low',
  'medium',
  'high',
  'very_high',
];
