import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { PageDetailsHeaderRow } from '@/components/PageDetailsHeaderRow/PageDetailsHeaderRow';
import { StatusCard } from '@/components/StatusCard/StatusCard';
import { WorkflowModel } from '@/modules/Workflows/domain';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditWorkflowModal } from '../../../WorkflowsPage/components/EditWorkflowModal';

interface HeaderRowProps {
  workflow?: WorkflowModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent>,
    id: string,
  ) => void;
  status: boolean;
}

export const WorkflowHeaderRow = ({
  workflow,
  handleStatus,
  status,
}: HeaderRowProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <div>
      <PageDetailsHeaderRow
        isLoading={!workflow}
        title={workflow?.name ?? ''}
        description={
          <div className="text-sm">
            <span className="font-bold" data-testid="ba-comp-section">
              {`${workflow?.businessAreaName}, ${workflow?.company}, `}
            </span>
            <span>{workflow?.organization}</span>
          </div>
        }
        actions={<StatusCard status={status} handleStatus={handleStatus} />}
        onEditClick={handleModalState}
        details={
          <Container className="flex-row">
            <Card title={t('workflow_description')}>
              {<p>{workflow?.description}</p>}
            </Card>
            <Card title={t('code')}>{<p>{workflow?.code}</p>}</Card>
          </Container>
        }
      />
      {isOpen && workflow?.id && (
        <EditWorkflowModal
          isOpen={isOpen}
          id={workflow?.id}
          handleClose={handleModalState}
        />
      )}
    </div>
  );
};
