import { Button, FieldInfo, MultiSelectDropdownField } from '@/components';
import { queryClient } from '@/constants';
import { AssociateWorkflowToProcess } from '@/modules/Processes';
import {
  ProcessKey,
  useAssociateWorkflowToProcess,
} from '@/modules/Processes/queries';
import {
  useGetAllWorkflowsByBusinessArea,
  WorkflowKey,
} from '@/modules/Workflows/queries';
import {
  AddWorkflowSchema,
  AddWorkflowSchemaType,
} from '@/modules/Workflows/types/workflow-associate-schema';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface AddWorkflowFormProps {
  businessAreaId: number;
  processId: number;
  handleClose: () => void;
}

export const AddWorkflowForm = ({
  businessAreaId,
  handleClose,
  processId,
}: AddWorkflowFormProps) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    control,
  } = useForm<AddWorkflowSchemaType>({
    resolver: zodResolver(AddWorkflowSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  const { data: workflowOptions } = useGetAllWorkflowsByBusinessArea({
    businessAreaId,
  });

  const { mutate, isLoading } = useAssociateWorkflowToProcess();

  const onSubmit: SubmitHandler<AddWorkflowSchemaType> = (data) => {
    trigger();
    if (!isValid) {
      return;
    }
    const convertedData: AssociateWorkflowToProcess = {
      processId,
      workflowIds: (data.workflows as string[]).map((workflow) =>
        Number(workflow),
      ),
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_associate_workflows_to_process'));
      },
      onSuccess: () => {
        toast.success(t('success_associate_workflows_to_process'));
        handleClose();
        queryClient.invalidateQueries(ProcessKey.ALL);
        queryClient.invalidateQueries(WorkflowKey.ALL);
      },
    });
  };

  const dropdownWorkflowsOptions = useMemo(() => {
    return getDropdownArray(workflowOptions ?? []);
  }, [workflowOptions]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2 pb-20">
        <FieldInfo
          title={t('existing_workflows')}
          hasError={!!errors.workflows?.message}
        >
          <MultiSelectDropdownField<AddWorkflowSchemaType>
            name="workflows"
            control={control}
            dropdownOptions={dropdownWorkflowsOptions}
            placeholder={t('select_workflow')}
          />
        </FieldInfo>
      </div>
      <div className="mt-4 flex justify-between">
        <Button
          isDisabled={isLoading}
          onClick={handleClose}
          variant="outline"
          className="h-9 w-24"
        >
          {t('cancel')}
        </Button>
        <Button isDisabled={isLoading} buttonType="submit" className="h-9 w-24">
          {t('confirm')}
        </Button>
      </div>
    </form>
  );
};
