import {
  PerformanceCard,
  PerformanceContent,
} from '@/components/PerformanceCard/PerformanceCard';
import { Tooltip } from '@/components/Tooltip';
import { BusinessAreaAutomationsPerformanceJson } from '@/modules';
import { AutomationPerformance } from '@/modules/Automations/domain';
import {
  calculateValueDifference,
  convertTimeSecondsToString,
  formatBigNumber,
  formatNumber,
} from '@/utils';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type LabourTimeAutomatedCardProps = {
  isLoading: boolean;
  setIsDetailsOpen: (isOpen: boolean) => void;
  automationPerformance?:
    | AutomationPerformance
    | BusinessAreaAutomationsPerformanceJson;
  excludeSearchInput?: boolean;
  details?: ReactNode;
};

export const LabourTimeAutomatedCard = ({
  automationPerformance,
  isLoading,
  details,
  setIsDetailsOpen,
}: LabourTimeAutomatedCardProps) => {
  const { t } = useTranslation();

  const {
    performance: performanceTotalTime,
    timeDifference: timeDifferenceTotalTime,
    efficiency: timeEfficiency,
  } = calculateValueDifference(
    Math.round(automationPerformance?.automationRunTime ?? 0),
    Math.round(automationPerformance?.predictedManualTime ?? 0),
  );

  const timeContent: PerformanceContent[] = useMemo(
    () => [
      {
        title: convertTimeSecondsToString(
          automationPerformance?.predictedManualTime || 0,
        ),
      },
      {
        title: convertTimeSecondsToString(
          automationPerformance?.automationRunTime || 0,
        ),
        subtitle: t('automation_run_time'),
      },
      {
        title: formatNumber(automationPerformance?.totalExecutions ?? 0, 0),
        subtitle: t('automation_runs'),
      },
      {
        title: (
          <Tooltip showArrow content={`${formatNumber(timeEfficiency ?? 0)}%`}>
            {formatBigNumber(timeEfficiency ?? 0)}%
          </Tooltip>
        ),
        extraTitle: `(${timeDifferenceTotalTime})`,
        subtitle: t('time_efficiency'),
        performance: performanceTotalTime,
      },
    ],
    [
      automationPerformance,
      performanceTotalTime,
      t,
      timeDifferenceTotalTime,
      timeEfficiency,
    ],
  );
  return (
    <>
      <PerformanceCard
        key={t('labour_time_automated')}
        title={t('labour_time_automated')}
        content={timeContent}
        isLoading={isLoading}
        onExpand={() => setIsDetailsOpen(true)}
      />
      {details}
    </>
  );
};
