export enum RecurrenceTypes {
  daily = 'Daily',
  daily_business = 'Daily (business days)',
  weekly = 'Weekly',
  bi_weekly = 'Bi-weekly',
  monthly = 'Monthly',
  quarterly = 'Quarterly',
  four_monthly = 'Four-monthly',
  semi_annually = 'Semi-annually',
  yearly = 'Yearly',
}
