import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type ReturnColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const returnColumnConfig = <T extends TableRow>({
  options,
}: ReturnColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'return',
  label: <Trans i18nKey="return" />,
  render: (value) => (
    <div
      className={twMerge(
        'overflow-hidden text-ellipsis font-bold text-green-500',
        value < 0 && 'text-red-500',
      )}
    >
      {value.toFixed(2)}€
    </div>
  ),
  acessorKey: 'return',
  sortable: false,
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
