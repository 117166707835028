import { Modal } from '@/components';
import { TenantModel } from '@/modules/Tenants/domain';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditTenantNameModal } from '../EditTenantNameModal';
import { Spinner } from '../Spinner';
import { TenantEntry } from '../TenantEntry';

interface ChangeTenantModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSave: (tenantId: number) => void;
  isLoading: boolean;
  selectedTenantId: number | undefined;
  tenants: TenantModel[];
}

export const ChangeTenantModal = ({
  isOpen,
  handleClose,
  handleSave,
  isLoading,
  selectedTenantId,
  tenants,
}: ChangeTenantModalProps) => {
  const { t } = useTranslation();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editTenantId, setEditTenantId] = useState<TenantModel | undefined>(
    undefined,
  );

  const handleModalState = useCallback((tenant?: TenantModel) => {
    if (tenant) {
      setEditTenantId(tenant);
    }
    setIsEditOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const handleTenantClick = useCallback(
    (tenantId: number) => {
      handleSave(tenantId);
      handleClose();
    },
    [handleSave, handleClose],
  );

  return (
    <>
      <Modal
        isOpen={isOpen && !isEditOpen}
        title={t(selectedTenantId ? 'change_tenant' : 'select_tenant')}
        className="h-fit w-128"
        handleClose={handleClose}
        allowClickOutsideToClose={!!selectedTenantId}
      >
        {isLoading ? (
          <Spinner className="h-11 w-11" />
        ) : (
          <div className="flex flex-col gap-2 pt-5">
            {tenants.map((tenant) => (
              <TenantEntry
                className="w-full cursor-pointer"
                key={tenant.id}
                tenant={tenant}
                isSelected={tenant?.id === selectedTenantId}
                onClick={() => handleTenantClick(tenant.id)}
                onEditClick={() => handleModalState(tenant)}
              />
            ))}
          </div>
        )}
      </Modal>
      <EditTenantNameModal
        isOpen={isEditOpen}
        handleClose={handleModalState}
        tenant={editTenantId}
      />
    </>
  );
};
