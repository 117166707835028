import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type SucessColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const successColumnConfig = <T extends TableRow>({
  options,
}: SucessColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'success',
  label: <Trans i18nKey="success_percentage" />,
  render: (value: number) => (
    <div className="overflow-hidden text-ellipsis">{value.toFixed(2)}%</div>
  ),
  acessorKey: 'success',
  sortable: false,
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
