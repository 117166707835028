import { processesRepo } from '@/constants/providers';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { ProcessKey } from './keys';
import { ExceptionType } from '@/shared/types';

export const useProcessAutomationsPerformance = (
  processId: number,
  dateRange?: DateRange,
) =>
  useQuery({
    queryKey: ProcessKey.AUTOMATIONS_PERFORMANCE(processId, dateRange),
    queryFn: () =>
      processesRepo.getAutomationsPerformanceById(processId, dateRange),
    cacheTime: 1000 * 60 * 5, // Cache for 5 minutes
  });

type UseProcessLabourTimeAutomatedProps = {
  processId: number;
  enabled?: boolean;
  dateRange?: DateRange;
  filter?: string;
};

export const useProcessLabourTimeAutomated = ({
  processId,
  enabled,
  dateRange,
  filter,
}: UseProcessLabourTimeAutomatedProps) => {
  return useInfiniteQuery(
    [ProcessKey.LABOUR_AUTOMATED(processId, dateRange, filter)],
    ({ pageParam = 1 }) =>
      processesRepo.getProcessLabourTimeAutomatedById(
        processId,
        dateRange,
        pageParam,
        filter,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};

type UseProcessTotalReturnProps = {
  processId: number;
  enabled?: boolean;
  dateRange?: DateRange;
  filter?: string;
};

export const useProcessTotalReturn = ({
  processId,
  enabled,
  dateRange,
  filter,
}: UseProcessTotalReturnProps) => {
  return useInfiniteQuery(
    [ProcessKey.TOTAL_RETURN(processId, dateRange, filter)],
    ({ pageParam = 1 }) =>
      processesRepo.getProcessTotalReturnById(
        processId,
        dateRange,
        pageParam,
        filter,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};

type UseProcessLastRunProps = {
  processId: number;
  enabled?: boolean;
  filter?: string;
  caseResult?: boolean;
  exceptionType?: ExceptionType;
};

export const useProcessLastRun = ({
  processId,
  enabled,
  filter,
  caseResult,
  exceptionType,
}: UseProcessLastRunProps) => {
  return useInfiniteQuery(
    [ProcessKey.LAST_RUN(processId, filter, caseResult, exceptionType)],
    ({ pageParam = 1 }) =>
      processesRepo.getProcessLastRunById(
        processId,
        pageParam,
        filter,
        caseResult,
        exceptionType,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};

type UseProcessOverallRunsProps = {
  processId: number;
  enabled: boolean;
  dateRange?: DateRange;
  filter?: string;
  caseResult?: boolean;
  exceptionType?: ExceptionType;
};

export const useProcessOverallRuns = ({
  processId,
  enabled,
  dateRange,
  filter,
  caseResult,
  exceptionType,
}: UseProcessOverallRunsProps) => {
  return useInfiniteQuery(
    [
      ProcessKey.OVERALL_RUNS(
        processId,
        dateRange,
        filter,
        caseResult,
        exceptionType,
      ),
    ],
    ({ pageParam = 1 }) =>
      processesRepo.getProcessOverallRunsById(
        processId,
        dateRange,
        pageParam,
        filter,
        caseResult,
        exceptionType,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};
