import { useGetChartColorsAndLabel } from '@/hooks';
import { t } from 'i18next';
import { ReactElement } from 'react';

interface LastRunSuccessRateHeaderProps {
  metrics: {
    totalCases: number;
    successfulCases: number;
    businessErrors: number;
    genericErrors: number;
    runStartDate?: Date;
    runEndDate?: Date;
  };
}

export function LastRunSuccessRateHeader({
  metrics,
}: LastRunSuccessRateHeaderProps): ReactElement {
  const { rateColors } = useGetChartColorsAndLabel();

  return (
    <div className="mb-6 flex flex-wrap gap-4">
      <div className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center">
        <span className="font-extrabold">{metrics?.totalCases}</span>
        <span className="text-sm text-gray-500">{t('total_cases')}</span>
      </div>

      <div
        style={{
          color: rateColors.success,
        }}
        className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center"
      >
        <span className="font-extrabold">
          {metrics?.successfulCases} (
          {parseFloat(
            (
              (metrics?.successfulCases / metrics?.totalCases) * 100 || 0
            ).toFixed(2),
          )}
          %)
        </span>
        <span className="text-sm text-gray-500">{t('successful_cases')}</span>
      </div>

      <div
        style={{
          color: rateColors.businessError,
        }}
        className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center"
      >
        <span className="font-extrabold">
          {metrics?.businessErrors} (
          {parseFloat(
            (
              (metrics?.businessErrors / metrics?.totalCases) * 100 || 0
            ).toFixed(2),
          )}
          %)
        </span>
        <span className="text-sm text-gray-500">{t('business_errors')}</span>
      </div>

      <div
        style={{
          color: rateColors.genericErrors,
        }}
        className="text-orange-600 flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center"
      >
        <span className="font-extrabold">
          {metrics?.genericErrors} (
          {parseFloat(
            ((metrics?.genericErrors / metrics?.totalCases) * 100 || 0).toFixed(
              2,
            ),
          )}
          %)
        </span>
        <span className="text-sm text-gray-500">{t('generic_errors')}</span>
      </div>

      <div className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center">
        <span className="font-extrabold">
          {metrics?.runStartDate
            ? new Date(metrics.runStartDate).toLocaleString()
            : ''}
        </span>
        <span className="text-sm text-gray-500">{t('run_start_date')}</span>
      </div>

      <div className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center">
        <span className="font-extrabold">
          {metrics?.runEndDate
            ? new Date(metrics.runEndDate).toLocaleString()
            : ''}
        </span>
        <span className="text-sm text-gray-500">{t('run_end_date')}</span>
      </div>
    </div>
  );
}
