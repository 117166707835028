import { Modal } from '@/components';
import { Spinner } from '@/components/Spinner';
import { useGetInfrastructureCost } from '@/modules/Infrastructure/queries';
import { useTranslation } from 'react-i18next';
import { EditInfrastructureCostForm } from './EditInfrastructureCostForm';

type EditInfrastructureCostModalProps = {
  id: number;
  isOpen: boolean;
  onClose: () => void;
};

export const EditInfrastructureCostModal = ({
  id,
  isOpen,
  onClose,
}: EditInfrastructureCostModalProps) => {
  const { t } = useTranslation();
  const { data: infrastructureCost, isLoading } = useGetInfrastructureCost(id);
  return (
    <Modal
      isOpen={isOpen}
      title={t('edit_infrastructure_cost')}
      className="h-fit w-128"
      handleClose={onClose}
    >
      <>
        {isLoading && (
          <div className="p-8">
            <Spinner />
          </div>
        )}
        {!isLoading && infrastructureCost && (
          <EditInfrastructureCostForm
            id={id}
            onClose={onClose}
            defaultValues={{
              name: infrastructureCost.name,
              amountRecurrence: infrastructureCost.amountRecurrence,
              costTypeId: infrastructureCost.costTypeId,
              costLicenseTypeId: infrastructureCost.costLicenseTypeId,
              description: infrastructureCost.description,
            }}
          />
        )}
      </>
    </Modal>
  );
};
