import { NotFound, TablePaginationContextProvider } from './components';
import { EnvironmentVariable } from './constants';
import { RoutePath } from './core/router';
import {
  BusinessAreaDetailPage,
  BusinessAreasPage,
  CompaniesPage,
  CompanyDetailPage,
  HomePage,
  OrganizationsPage,
  ProcessDetailPage,
  ProcessesPage,
  UserProfilePage,
  UsersPage,
  WorkflowsPage,
} from './modules';
import { LoadingPage } from './modules/Auth/pages';
import { AutomationDetailPage } from './modules/Automations/pages/AutomationDetailPage';
import { ManageAutomationsPage } from './modules/Automations/pages/ManageAutomationsPage';
import { RequestDetailPage } from './modules/Automations/pages/RequestDetailPage';
import {
  InfrastructureCostDetailPage,
  InfrastructureCostPage,
} from './modules/Infrastructure';
import { OrganizationDetailPage } from './modules/Organizations/pages/OrganizationDetailPage';
import { TaskDetailPage } from './modules/Tasks/pages/TaskDetailPage';
import { TaskPage } from './modules/Tasks/pages/TasksPage';
import { TenantProfilePage } from './modules/Tenants/pages/TenantProfilePage';
import { UserDetailPage } from './modules/Users/pages/UserDetailPage/UserDetailPage';
import { WorkflowDetailPage } from './modules/Workflows/pages/WorkflowDetailsPage';
import { RouteEntry } from './utils';

export const routerConfiguration: RouteEntry[] = [
  {
    path: RoutePath.home(),
    component: HomePage,
    requiresAuth: true,
  },
  {
    path: RoutePath.automations(),
    component: () =>
      TablePaginationContextProvider({ children: <ManageAutomationsPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.automationDetail(),
    component: AutomationDetailPage,
    requiresAuth: true,
  },
  ...(!EnvironmentVariable.DISABLE_AUTOMATION_REQUESTS
    ? [
        {
          path: RoutePath.requestDetail(),
          component: RequestDetailPage,
          requiresAuth: true,
        },
      ]
    : []),
  {
    path: RoutePath.businessAreas(),
    component: () =>
      TablePaginationContextProvider({ children: <BusinessAreasPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.businessAreaDetail(),
    component: BusinessAreaDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.companies(),
    component: () =>
      TablePaginationContextProvider({ children: <CompaniesPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.companyDetail(),
    component: CompanyDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.organizations(),
    component: () =>
      TablePaginationContextProvider({ children: <OrganizationsPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.organizationDetail(),
    component: OrganizationDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.processes(),
    component: () =>
      TablePaginationContextProvider({ children: <ProcessesPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.processDetail(),
    component: ProcessDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.tasks(),
    component: () => TablePaginationContextProvider({ children: <TaskPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.taskDetail(),
    component: TaskDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.users(),
    component: () =>
      TablePaginationContextProvider({ children: <UsersPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.userDetail(),
    component: UserDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.userProfile(),
    component: UserProfilePage,
    requiresAuth: true,
  },
  {
    path: RoutePath.workflows(),
    component: () =>
      TablePaginationContextProvider({ children: <WorkflowsPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.workflowDetail(),
    component: WorkflowDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.infrastructureCosts(),
    component: () =>
      TablePaginationContextProvider({ children: <InfrastructureCostPage /> }),
    requiresAuth: true,
  },
  {
    path: RoutePath.infrastructureCostDetail(),
    component: InfrastructureCostDetailPage,
    requiresAuth: true,
  },
  {
    path: RoutePath.tenantProfile(),
    component: TenantProfilePage,
    requiresAuth: true,
  },
  {
    path: RoutePath.notFound(),
    component: NotFound,
    requiresAuth: true,
  },
  {
    path: RoutePath.loadingPage(),
    component: LoadingPage,
    requiresAuth: false,
  },
];
