import { useTailwindTheme } from '@/hooks';
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useFloating,
  useHover,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react';
import { ReactElement, ReactNode, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
interface TooltipProps {
  children: ReactNode;
  containerClassName?: string;
  content: ReactNode;
  placement?: Placement;
  className?: string;
  showArrow?: boolean;
  enabled?: boolean;
}

export function Tooltip({
  children,
  content,
  placement = 'top',
  className,
  containerClassName,
  showArrow,
  enabled = true,
}: TooltipProps): ReactElement {
  const theme = useTailwindTheme();
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, context, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    open: isOpen && enabled,
    onOpenChange: setIsOpen,
    placement,
    middleware: [
      offset(8),
      flip(),
      shift({ padding: 8 }),
      showArrow && arrow({ element: arrowRef }),
    ],
  });

  const { isMounted, styles } = useTransitionStyles(context);

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className={containerClassName}
      >
        {children}
        {isOpen && (
          <FloatingPortal>
            <div
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
              className={'z-99'}
            >
              {isMounted && (
                <>
                  <div
                    style={styles}
                    className={twMerge(
                      'rounded-sm bg-spartanBlue px-2 py-1 text-sm font-normal text-white drop-shadow',
                      className,
                    )}
                  >
                    {content}
                  </div>
                  {showArrow && (
                    <FloatingArrow
                      ref={arrowRef}
                      context={context}
                      style={styles}
                      fill={theme.colors['spartanBlue']}
                    />
                  )}
                </>
              )}
            </div>
          </FloatingPortal>
        )}
      </div>
    </>
  );
}
