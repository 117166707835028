import {
  OverallRunSuccessRateHeader,
  PerformanceCardDetails,
  PieChartCard,
} from '@/components';
import { SuccessRate } from '@/core/domain/global-performance-model';
import { useGetChartColorsAndLabel } from '@/hooks';
import { getOverallRunsSucessRateColumns } from '@/modules/Automations/pages/AutomationsPage/utils';
import { useGetWorkflow } from '@/modules/Workflows/queries';
import { useWorkflowOverallRuns } from '@/modules/Workflows/queries/use-workflow-automations-performance';
import { ExceptionType, ResultFilter, Success } from '@/shared/types';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

type WorkflowOverallSuccessRateCardProps = {
  workflowId: number;
};

export const WorkflowOverallSuccessRateCard = ({
  workflowId,
}: WorkflowOverallSuccessRateCardProps) => {
  const { t } = useTranslation();
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [search, setSearch] = useState<string>();
  const [selectedResult, setSelectedResult] = useState<ResultFilter>();
  const { rateChart } = useGetChartColorsAndLabel();
  const [dateRange, setDateRange] = useState<DateRange>();

  const { data: workflow, isLoading } = useGetWorkflow(workflowId);

  const {
    data,
    isLoading: isLoadingOverallRuns,
    fetchNextPage: fetchOverallRunsNextPage,
    isFetching,
    isFetchingNextPage,
  } = useWorkflowOverallRuns({
    workflowId,
    dateRange,
    enabled: isDetailsOpen,
    filter: search,
    caseResult: selectedResult === Success.Success ? true : undefined,
    exceptionType:
      !!selectedResult &&
      [ExceptionType.Business, ExceptionType.Generic].includes(selectedResult)
        ? selectedResult
        : undefined,
  });

  const overallSuccessRate = useMemo(
    () => data?.pages.flatMap((page) => page) || [],
    [data?.pages],
  );

  const overallSuccessRateData = useMemo(
    () =>
      Object.entries(rateChart).map(([key, value]) => ({
        ...value,
        value: workflow?.overallSuccessRate[key as keyof SuccessRate] ?? 0,
      })),
    [workflow, rateChart],
  );

  const totalMetrics = useMemo(() => {
    const { businessErrors, genericErrors, successes } =
      workflow?.overallSuccessRate ?? {
        businessErrors: 0,
        genericErrors: 0,
        successes: 0,
      };

    return {
      totalRuns: workflow?.totalRuns ?? 0,
      totalCases: businessErrors + genericErrors + successes,
      businessErrors,
      genericErrors,
      successes,
    };
  }, [workflow]);

  return (
    <>
      <PieChartCard
        data={overallSuccessRateData}
        chartTitle={t('overall_success_rate')}
        valueType="percentage"
        subtitle={t('cases')}
        onExpand={() => setIsDetailsOpen(true)}
      />
      {isDetailsOpen && (
        <PerformanceCardDetails
          title={t('overall_success_rate')}
          subTitle={t('cases')}
          description={t('overall_run_success_rate_description')}
          columns={getOverallRunsSucessRateColumns()}
          onClose={() => setIsDetailsOpen(false)}
          data={{
            items: overallSuccessRate ?? [],
            totalPages: 1,
            totalRows: overallSuccessRate?.length ?? 0,
          }}
          metrics={
            !isLoading && (
              <OverallRunSuccessRateHeader
                key="overallRunSuccessMetric"
                metrics={totalMetrics}
              />
            )
          }
          kpiDateRange={dateRange}
          setKpiDateRange={setDateRange}
          fetchNextPage={fetchOverallRunsNextPage}
          contextType="overallRuns"
          selectedResult={selectedResult}
          setSelectedResult={setSelectedResult}
          isLoading={isLoadingOverallRuns}
          setSearchInput={setSearch}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
        />
      )}
    </>
  );
};
