import {
  BellIcon,
  LanguageDropdown,
  TenantContext,
  ToolIcon,
  WarningIcon,
} from '@/components';
import { ChangeTenantModal } from '@/components/ChangeTenantModal';
import { ChangePasswordModal } from '@/components/SideNav/components';
import { Environment, EnvironmentVariable } from '@/constants';
import { useGetNotifications } from '@/modules/System/queries';
import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { AvatarDropdown } from '../AvatarDropdown';

export const Header = (): ReactElement => {
  const { i18n } = useTranslation();
  const {
    currentSelectedTenant: tenant,
    onTenantIdChange,
    userTenants,
    isLoadingTenantInfo,
  } = useContext(TenantContext);

  const { data: notifications } = useGetNotifications(EnvironmentVariable.MODE);

  const [visibleNotifications, setVisibleNotifications] = useState<number[]>(
    [],
  );

  useMemo(() => {
    if (notifications) {
      setVisibleNotifications(notifications.map((message) => message.id));
    }
  }, [notifications]);

  const filteredMessages = notifications?.filter((message) =>
    visibleNotifications.includes(message.id),
  );

  const [isChangeTenantModalOpen, setIsChangeTenantModalOpen] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);

  const handleChangeTenantModalClose = useCallback(() => {
    setIsChangeTenantModalOpen(false);
  }, []);

  const handleChangeTenantModalOpen = useCallback(() => {
    setIsChangeTenantModalOpen(true);
  }, []);

  const handleCloseChangePasswordModal = useCallback(
    () => setOpenChangePasswordModal(false),
    [],
  );

  const handleOpenChangePasswordModal = useCallback(
    () => setOpenChangePasswordModal(true),
    [],
  );

  const isDevEnvironment = useMemo(
    () => EnvironmentVariable.MODE !== Environment.Production,
    [],
  );

  const handleClose = (id: number) => {
    setVisibleNotifications((prev) => prev.filter((notifId) => notifId !== id));
  };

  const getStyleByTypeId = (typeId: number) => {
    switch (typeId) {
      case 1:
        return 'bg-lightYellow text-orange';
      case 2:
        return 'bg-lightPurple text-fennelBlue';
      case 3:
        return 'bg-lightGreen text-green-600';
    }
  };

  const getIconByTypeId = (typeId: number) => {
    switch (typeId) {
      case 1:
        return <WarningIcon className="flex-shrink- size-4" />;
      case 2:
        return;
      case 3:
        return <BellIcon className="flex-shrink- size-4" />;
    }
  };

  return (
    <>
      <div className={`sticky top-0 z-10 flex w-full flex-col bg-white`}>
        {isDevEnvironment && (
          <div className="mb-2 flex w-full items-center gap-2.5 bg-lightPurple px-1 py-1 text-fennelBlue sm:px-12">
            <ToolIcon className="size-4 flex-shrink-0" />
            <p>
              <Trans
                i18n={i18n}
                className="text-sm"
                i18nKey={'testing_message'}
                components={{
                  bold: <span className="font-black" />,
                }}
              />
            </p>
          </div>
        )}
        {filteredMessages?.map((message) => (
          <div
            key={message.id}
            className={`flex w-full items-center gap-2.5 px-1 py-1 sm:px-12 ${getStyleByTypeId(
              message.systemMessageTypeId,
            )}`}
          >
            {getIconByTypeId(message.systemMessageTypeId)}
            <p className="flex-grow">
              <Trans
                i18nKey={'notification_content'}
                components={{
                  bold: <span className="font-black" />,
                }}
              >
                {message.content}
              </Trans>
            </p>
            <button
              onClick={() => handleClose(message.id)}
              className={twMerge(
                'text-xl font-bold text-gray-600  focus:outline-none',
                message.systemMessageTypeId === 1 && 'text-orange',
                message.systemMessageTypeId === 2 && 'text-fennelBlue',
                message.systemMessageTypeId === 3 && 'text-green-600',
              )}
              aria-label="Close notification"
            >
              &times;
            </button>
          </div>
        ))}
        <div className="flex items-center justify-end py-2 pr-1.5">
          <AvatarDropdown
            handleOpenChangeTenantModal={handleChangeTenantModalOpen}
            handleOpenChangePasswordModal={handleOpenChangePasswordModal}
          />
          <div className="h-full border-1 text-brightGray"></div>
          <LanguageDropdown />
        </div>
      </div>
      <ChangeTenantModal
        isOpen={isChangeTenantModalOpen}
        handleClose={handleChangeTenantModalClose}
        handleSave={onTenantIdChange}
        selectedTenantId={tenant?.id}
        tenants={userTenants}
        isLoading={isLoadingTenantInfo}
      />
      <ChangePasswordModal
        isOpen={openChangePasswordModal}
        handleClose={handleCloseChangePasswordModal}
      />
    </>
  );
};
