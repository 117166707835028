import { LabourTimeAutomatedCard } from '@/components/PerformanceCardsContainer/components/LabourTimeAutomatedCard';
import { LabourTimeAutomatedDetails } from '@/components/PerformanceCardsContainer/components/LabourTimeAutomatedDetails';
import {
  useWorkflowAutomationsPerformance,
  useWorkflowLabourTimeAutomated,
} from '@/modules/Workflows/queries/use-workflow-automations-performance';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';

type WorkflowLabourTimeAutomatedCardProps = {
  workflowId: number;
  automationPerformanceDateRange?: DateRange;
};

export const WorkflowLabourTimeAutomatedCard = ({
  workflowId,
  automationPerformanceDateRange,
}: WorkflowLabourTimeAutomatedCardProps) => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const [searchInput, setSearchInput] = useState<string>();
  const [isDetailsOpen, setDetailsOpen] = useState<boolean>();

  const { data: automationPerformance, isLoading } =
    useWorkflowAutomationsPerformance(
      workflowId,
      automationPerformanceDateRange,
    );

  const {
    data,
    isLoading: isLoadingLabourAutomation,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useWorkflowLabourTimeAutomated({
    workflowId,
    dateRange,
    filter: searchInput,
    enabled: isDetailsOpen,
  });

  const labourAutomated = useMemo(
    () => data?.pages.flatMap((page) => page) || [],
    [data?.pages],
  );

  return (
    <LabourTimeAutomatedCard
      automationPerformance={automationPerformance}
      isLoading={isLoading}
      setIsDetailsOpen={setDetailsOpen}
      details={
        isDetailsOpen && (
          <LabourTimeAutomatedDetails
            isLoadingLabourAutomation={isLoadingLabourAutomation}
            labourAutomated={labourAutomated}
            onClose={() => setDetailsOpen(false)}
            fetchNextPage={fetchNextPage}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setSearchInput={setSearchInput}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
          />
        )
      }
    />
  );
};
