import { PieChartCard } from '@/components';
import { TimeDistribution } from '@/core/domain/global-performance-model';
import { useGetChartColorsAndLabel } from '@/hooks';
import { useGetWorkflow } from '@/modules/Workflows/queries';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type WorkflowTimeDistributionCardProps = {
  workflowId: number;
};

export const WorkflowTimeDistributionCard = ({
  workflowId,
}: WorkflowTimeDistributionCardProps) => {
  const { t } = useTranslation();

  const { data: workflow } = useGetWorkflow(workflowId);

  const { workflowTimeDistributionChart } = useGetChartColorsAndLabel();

  const workflowTimeDistributionData = useMemo(
    () =>
      Object.entries(workflowTimeDistributionChart).map(([key, value]) => ({
        ...value,
        value:
          workflow?.workflowTimeDistribution[key as keyof TimeDistribution] ??
          0,
      })),
    [workflow, workflowTimeDistributionChart],
  );

  return (
    <PieChartCard
      data={workflowTimeDistributionData}
      chartTitle={t('workflow_time_distribution')}
      valueType="percentage"
      subtitle={t('total')}
      convert
    />
  );
};
