import {
  LastRunSuccessRateHeader,
  PerformanceCardDetails,
  PieChartCard,
} from '@/components';
import { SuccessRate } from '@/core/domain/global-performance-model';
import { useGetChartColorsAndLabel } from '@/hooks';
import { getLastRunSucessRateColumns } from '@/modules/Automations/pages/AutomationsPage/utils';
import { useGetProcess } from '@/modules/Processes/queries';
import { useProcessLastRun } from '@/modules/Processes/queries/use-process-automations-performance';
import { ExceptionType, ResultFilter, Success } from '@/shared/types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ProcesssLastRunSuccessRateCardProps = {
  processId: number;
};

export const ProcesssLastRunSuccessRateCard = ({
  processId,
}: ProcesssLastRunSuccessRateCardProps) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>();
  const [selectedResult, setSelectedResult] = useState<ResultFilter>();

  const { rateChart } = useGetChartColorsAndLabel();

  const { data: process, isLoading } = useGetProcess(processId);

  const lastRunSuccessRateData = useMemo(
    () =>
      Object.entries(rateChart).map(([key, value]) => ({
        ...value,
        value: process?.lastRunSuccessRate[key as keyof SuccessRate] ?? 0,
      })),
    [process, rateChart],
  );

  const {
    data,
    isLoading: isLoadingLastRun,
    fetchNextPage: fetchLastRunNextPage,
    isFetching,
    isFetchingNextPage,
  } = useProcessLastRun({
    processId,
    enabled: isDetailsOpen,
    filter: search,
    caseResult: selectedResult === Success.Success ? true : undefined,
    exceptionType:
      !!selectedResult &&
      [ExceptionType.Business, ExceptionType.Generic].includes(selectedResult)
        ? selectedResult
        : undefined,
  });

  const lastRunSuccessRate = useMemo(
    () => data?.pages.flatMap((page) => page) || [],
    [data?.pages],
  );

  const totalMetrics = useMemo(() => {
    const {
      businessErrors,
      genericErrors,
      successes: successfulCases,
    } = process?.lastRunSuccessRate ?? {
      businessErrors: 0,
      genericErrors: 0,
      successes: 0,
    };

    return {
      totalCases: successfulCases + businessErrors + genericErrors,
      businessErrors,
      genericErrors,
      successfulCases,
      runStartDate: process?.lastRunStartDate,
      runEndDate: process?.lastRunEndDate,
    };
  }, [process]);

  return (
    <>
      <PieChartCard
        data={lastRunSuccessRateData}
        chartTitle={t('last_run_success_rate')}
        valueType="percentage"
        subtitle={t('cases')}
        onExpand={() => setIsDetailsOpen(true)}
      />
      {isDetailsOpen && (
        <PerformanceCardDetails
          title={t('last_run_success_rate')}
          subTitle={t('cases')}
          description={t('last_run_success_rate_description')}
          columns={getLastRunSucessRateColumns()}
          onClose={() => setIsDetailsOpen(false)}
          data={{
            items: lastRunSuccessRate,
            totalPages: 1,
            totalRows: lastRunSuccessRate.length,
          }}
          metrics={
            !isLoading && (
              <LastRunSuccessRateHeader
                key="lastRunHeader"
                metrics={totalMetrics}
              />
            )
          }
          fetchNextPage={fetchLastRunNextPage}
          contextType="lastRun"
          selectedResult={selectedResult}
          setSelectedResult={setSelectedResult}
          isLoading={isLoadingLastRun}
          setSearchInput={setSearch}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
        />
      )}
    </>
  );
};
