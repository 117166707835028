import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import { CardSkeleton } from '../CardSkeleton';
import { Tooltip } from '../Tooltip';
import { CautionIcon } from '../Icons';
import { useTranslation } from 'react-i18next';

export interface ContainerProps {
  title?: ReactNode;
  className?: string;
  variant?: 'primary' | 'secondary';
  isLoading?: boolean;
  tooltip?: string;
  actions?: ReactNode;
  warningMessages?: WarningMessage[];
}

export interface WarningMessage {
  message: string;
  onClick?: () => void;
}

export const Container = ({
  children,
  className,
  title,
  variant = 'primary',
  isLoading,
  tooltip,
  actions,
  warningMessages = [],
}: PropsWithChildren<ContainerProps>): ReactElement => {
  const { t } = useTranslation();
  return (
    <div
      className={twJoin(
        twMerge('flex min-w-fit flex-col gap-3 rounded-md p-3', className),
        variant === 'primary' ? 'bg-brightGray' : 'bg-transparent',
      )}
    >
      {(!!title || !!actions) && (
        <div className="flex items-center justify-between">
          {title && (
            <Tooltip
              content={tooltip}
              containerClassName="w-fit"
              className="max-w-40"
              enabled={!!tooltip}
            >
              <div className="font-black">{title}</div>
            </Tooltip>
          )}
          <div>
            {warningMessages.length > 0 && (
              <div className=" flex flex-col gap-2">
                {warningMessages.map(({ message, onClick }, index) => (
                  <div
                    key={index}
                    className={twJoin(
                      'flex items-center justify-center whitespace-pre rounded-lg bg-lightPurple px-2 py-1 text-blueNuit',
                      onClick && ' cursor-pointer',
                    )}
                    onClick={onClick}
                  >
                    <div className="flex items-center font-black">
                      <CautionIcon className="mr-1" />
                      <span>{t('warning')}: </span>
                    </div>

                    <span className="font-normal">{message}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          {actions}
        </div>
      )}
      {isLoading ? <CardSkeleton /> : children}
    </div>
  );
};
