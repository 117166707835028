import { ReactElement } from 'react';
import { useRoutes } from 'react-router-dom';

import { NotFound } from '@/components';
import { RouteEntry, buildRouterConfiguration } from '@/utils';

interface AppRouterProps {
  routes: RouteEntry[];
  catchNotFound?: boolean;
}

export const RouterMap = ({
  routes,
  catchNotFound = true,
}: AppRouterProps): ReactElement | null => {
  const routesMapping = buildRouterConfiguration(routes);

  if (catchNotFound) {
    routesMapping.push({
      path: '*',
      element: <NotFound />,
    });
  }

  return useRoutes(routesMapping);
};
