import { DEFAULT_PAGE_SIZE, SortDirection } from '@/components/Table/types';
import { HttpClient } from '@/core/http/http-client';
import { DeleteProfileImageJson } from '@/modules/types';
import {
  ApiResponse,
  LabourTimeAutomatedDetailsJson,
  TotalReturnDetailsJson,
} from '@/shared/types';
import { DataWithPagination } from '@/shared/types/pagination';
import { DateRange } from 'react-day-picker';
import {
  OrganizationBaseInfo,
  OrganizationModel,
  OrganiztionAutomationsPeformance,
} from '../domain';
import {
  mapOrganizationJsonToOrganizationModel,
  organizationAutomationsPerformanceMapper,
  organizationBaseInfoMapper,
  organizationsMapper,
  organizationsWithPaginationMapper,
} from '../mappers';
import {
  ApiResponseOrganization,
  ApiResponseOrganizations,
  OrganizationAutomationPerformanceJson,
  OrganizationBaseInfoJson,
  OrganizationJson,
  PagedOrganizations,
} from '../types';
import { AttachUserOrganizationModel } from '../types/attach-user-entity-model';
import { DisassociateOrganizationUserModel } from '../types/disassociate-organization-user-model';
import { OrganizationRepository } from './organization.repository';
import {
  mapLabourTimeAutomatedList,
  mapTotalReturnList,
} from '@/shared/mappers/kpi-mapper';
import { DateUtility } from '@/utils/date.utils';

export class HttpOrganizationRepository extends OrganizationRepository {
  constructor(private readonly client: HttpClient) {
    super();
  }

  updateOrganization = async (data: FormData): Promise<OrganizationModel> => {
    const updatedOrganization = await this.client.put<OrganizationJson>(
      `${data.get('id')}`,
      data,
    );

    return mapOrganizationJsonToOrganizationModel(updatedOrganization);
  };

  createOrganization = async (data: FormData): Promise<OrganizationModel> => {
    const newOrganization = await this.client.post<OrganizationJson>({
      body: data,
    });

    return mapOrganizationJsonToOrganizationModel(newOrganization);
  };

  attachUserOrganization = async (
    data: AttachUserOrganizationModel,
  ): Promise<void> => {
    return await this.client.post({
      body: data,
      url: `associate-user`,
    });
  };

  uploadProfilePicture = async (data: FormData): Promise<unknown> => {
    return await this.client.post<unknown>({
      url: `${data.get('EntityId')}/profile-picture`,
      body: data,
    });
  };

  deleteProfilePicture = async (
    data: DeleteProfileImageJson,
  ): Promise<unknown> => {
    return await this.client.delete<unknown>(
      `${data.entityId}/profile-picture/${data.fileId}`,
    );
  };

  disassociateUser = async (
    data: DisassociateOrganizationUserModel,
  ): Promise<void> => {
    return await this.client.post({
      body: data,
      url: `disassociate-user`,
    });
  };

  async deleteOrganization(id: number): Promise<ApiResponse<unknown>> {
    return await this.client.delete(`${id}`);
  }

  async getAllOrganizations(
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<OrganizationModel[]>> {
    const organizationsJson = await this.client.get<
      ApiResponseOrganizations<PagedOrganizations>
    >({
      url: '/me/organizations',
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return organizationsWithPaginationMapper(organizationsJson);
  }

  async getAllOrganizationsWithoutPagination(): Promise<
    OrganizationBaseInfo[]
  > {
    const organizationsJson = await this.client.get<
      ApiResponseOrganizations<OrganizationBaseInfoJson[]>
    >({
      url: 'base-info',
    });

    return organizationsJson.data.organizations.map(organizationBaseInfoMapper);
  }

  async getOrganization(orgId: number): Promise<OrganizationModel> {
    const organizationJson = await this.client.get<
      ApiResponseOrganization<OrganizationJson>
    >({
      url: `${orgId}`,
    });

    return mapOrganizationJsonToOrganizationModel(
      organizationJson?.data.organization,
    );
  }

  async getOrganizationAutomationsPerformanceById(
    organizationId: number,
    dateRange?: DateRange,
  ): Promise<OrganiztionAutomationsPeformance> {
    const organizationAutomationPerformanceResponse = await this.client.get<
      ApiResponse<OrganizationAutomationPerformanceJson>
    >({
      url: `${organizationId}/automations-performance`,
      query: {
        ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
      },
    });

    return organizationAutomationsPerformanceMapper(
      organizationAutomationPerformanceResponse.data,
    );
  }

  enableOrganization = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/enable`,
    });
  };

  disableOrganization = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/disable`,
    });
  };

  async getOrganizationsByUser(
    userId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<OrganizationModel[]>> {
    const organizations = await this.client.get<
      ApiResponseOrganizations<PagedOrganizations>
    >({
      url: `/users/${userId}/organizations`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return organizationsWithPaginationMapper(organizations);
  }

  async getOrganizationsByInfrastructureCost(
    infrastructureCostId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<OrganizationModel[]>> {
    const organizations = await this.client.get<
      ApiResponseOrganizations<PagedOrganizations>
    >({
      url: `/infrastructure-costs/${infrastructureCostId}/organizations`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return organizationsWithPaginationMapper(organizations);
  }

  async getAllOrganizationsByInfrastructureCost(
    infrastructureCostId: number,
  ): Promise<OrganizationModel[]> {
    const organizations = await this.client.get<
      ApiResponseOrganizations<OrganizationJson[]>
    >({
      url: `/infrastructure-costs/${infrastructureCostId}/organizations/base-info`,
    });

    return organizationsMapper(organizations.data.organizations);
  }

  async getOrganizationLabourTimeAutomatedById(
    organizationId: number,
    dateRange?: DateRange,
    page?: number,
    filter?: string,
  ) {
    const response = await this.client.get<
      ApiResponse<LabourTimeAutomatedDetailsJson>
    >({
      url: `${organizationId}/labour-time-automated`,
      query: {
        page,
        filter,
        ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
      },
    });

    return mapLabourTimeAutomatedList(
      response.data.executions,
      response.data.totals,
    );
  }

  async getOrganizationTotalReturnById(
    organizationId: number,
    dateRange?: DateRange,
    page?: number,
    filter?: string,
  ) {
    const response = await this.client.get<ApiResponse<TotalReturnDetailsJson>>(
      {
        url: `${organizationId}/total-return`,
        query: {
          page,
          filter,
          ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
        },
      },
    );

    return mapTotalReturnList(response.data.executions, response.data.totals);
  }
}
