import { z } from 'zod';

export const TenantSchema = z.object({
  name: z.string().min(1, { message: 'error_field_required' }),
});

export type FormTenantSchemaType = z.infer<typeof TenantSchema>;

export interface TenantSchemaType extends FormTenantSchemaType {
  id?: number;
}
