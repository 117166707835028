import { TotalReturnCard } from '@/components/PerformanceCardsContainer/components/TotalReturnCard';
import { TotalReturnDetails } from '@/components/PerformanceCardsContainer/components/TotalReturnDetails';
import {
  useCompanyTotalReturn,
  useGetCompanyAutomationsPerformance,
} from '@/modules/Companies/queries/use-get-company-automations-performance';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';

type CompanyTotalReturnCardProps = {
  companyId: number;
  automationPerformanceDateRange?: DateRange;
};

export const CompanyTotalReturnCard = ({
  companyId,
  automationPerformanceDateRange,
}: CompanyTotalReturnCardProps) => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const [searchInput, setSearchInput] = useState<string>();
  const [isDetailsOpen, setDetailsOpen] = useState<boolean>(false);

  const { data: automationPerformance, isLoading } =
    useGetCompanyAutomationsPerformance(
      companyId,
      automationPerformanceDateRange,
    );

  const {
    data,
    isLoading: isLoadingTotalReturnAutomated,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useCompanyTotalReturn({
    companyId,
    enabled: isDetailsOpen,
    dateRange,
    filter: searchInput,
  });

  const totalReturnAutomated = useMemo(
    () => data?.pages.flat() || [],
    [data?.pages],
  );

  return (
    <TotalReturnCard
      automationPerformance={automationPerformance}
      isLoading={isLoading}
      setIsDetailsOpen={setDetailsOpen}
      details={
        isDetailsOpen && (
          <TotalReturnDetails
            isLoading={isLoadingTotalReturnAutomated}
            totalReturnAutomated={totalReturnAutomated}
            onClose={() => setDetailsOpen(false)}
            additionalTableColumns
            fetchNextPage={fetchNextPage}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setSearchInput={setSearchInput}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
          />
        )
      }
    />
  );
};
