import { PerformanceCardDetails } from '@/components/PerformanceCardDetails';
import { getTotalReturnColumns } from '@/modules/Automations/pages/AutomationsPage/utils';
import { TotalReturnModel, TotalReturnTotalsModel } from '@/shared/domain';
import { formatNumber } from '@/utils';
import { DateRange } from 'react-day-picker';
import { Trans, useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type TotalReturnDetailsFooter = {
  totalMetrics?: TotalReturnTotalsModel;
  additionalTableColumns?: boolean;
};
const TotalReturnDetailsFooter = ({
  totalMetrics,
  additionalTableColumns,
}: TotalReturnDetailsFooter) => {
  const totalReturn = totalMetrics?.return ?? 0;
  return (
    <tr className="border-t text-sm font-medium">
      <td colSpan={additionalTableColumns ? 3 : 2} className="px-4 pb-2 pt-4">
        <Trans
          i18nKey={'total_n_runs'}
          count={totalMetrics?.totalRows ?? 0}
          components={{
            b: <strong />,
          }}
        />
      </td>
      <td className="px-4 py-2" align="center">
        {formatNumber(totalMetrics?.totalCases ?? 0, 0)}
      </td>
      <td className="px-4 py-2" align="center">
        {formatNumber(totalMetrics?.successful ?? 0)}%
      </td>
      <td className="px-4 py-2" align="center">
        {formatNumber(totalMetrics?.totalError ?? 0, 0)}
      </td>
      <td className="px-4 py-2" align="center">
        {formatNumber(totalMetrics?.labourCost ?? 0)}€
      </td>
      <td className="px-4 py-2" align="center">
        {formatNumber(totalMetrics?.runCost ?? 0)}€
      </td>
      <td
        className={twMerge(
          'overflow-hidden text-ellipsis font-bold text-green-500',
          totalReturn < 0 && 'text-red-500',
        )}
        align="center"
      >
        {formatNumber(totalReturn)}€
      </td>
    </tr>
  );
};

type TotalReturnDetailsProps = {
  totalReturnAutomated?: TotalReturnModel[];
  isLoading?: boolean;
  additionalTableColumns?: boolean;
  onClose: () => void;
  fetchNextPage: () => Promise<unknown>;
  excludeSearchInput?: boolean;
  setSearchInput?: (value: string) => void;
  dateRange?: DateRange;
  setDateRange?: (dateRange?: DateRange) => void;
  isFetching: boolean;
  isFetchingNextPage: boolean;
};

export const TotalReturnDetails = ({
  totalReturnAutomated,
  additionalTableColumns,
  isLoading,
  fetchNextPage,
  onClose,
  excludeSearchInput,
  setSearchInput,
  dateRange,
  setDateRange,
  isFetching,
  isFetchingNextPage,
}: TotalReturnDetailsProps) => {
  const { t } = useTranslation();
  return (
    <PerformanceCardDetails
      title={t('total_return')}
      subTitle={t('automation_runs')}
      description={t('total_return_description')}
      columns={getTotalReturnColumns(additionalTableColumns)}
      onClose={onClose}
      kpiDateRange={dateRange}
      setKpiDateRange={setDateRange}
      isLoading={isLoading}
      data={{
        items: totalReturnAutomated ?? [],
        totalPages: 1,
        totalRows: totalReturnAutomated?.length ?? 0,
      }}
      footerRenderer={
        <TotalReturnDetailsFooter
          totalMetrics={totalReturnAutomated?.[0]?.totalMetrics}
          additionalTableColumns={additionalTableColumns}
        />
      }
      fetchNextPage={fetchNextPage}
      contextType="totalReturn"
      excludeSearchInput={excludeSearchInput}
      setSearchInput={setSearchInput}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};
