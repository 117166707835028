import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LabourWorkload } from '../types/labourWorkLoad';
import { Periodicity } from '../types/periodicity';

export const useTaskFormOptions = () => {
  const { t } = useTranslation();

  const dropdownPeriodicityOptions = useMemo(
    () =>
      getDropdownArray(
        Object.entries(Periodicity)
          .filter(([key]) => isNaN(parseInt(key)))
          .map(([key, value]) => ({
            id: value,
            name: t(`periodicity_types.${key}`),
          })),
      ),
    [t],
  );

  const dropdownLabourWorkloadTypeOptions = useMemo(
    () =>
      getDropdownArray(
        Object.entries(LabourWorkload)
          .filter(([key]) => isNaN(parseInt(key)))
          .map(([key, value]) => ({
            id: value,
            name: t(`labour_workload_types.${key}`),
          })),
      ),
    [t],
  );

  return {
    dropdownPeriodicityOptions,
    dropdownLabourWorkloadTypeOptions,
  };
};
