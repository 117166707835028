import { PerformanceCardDetails } from '@/components';
import { useGetChartColorsAndLabel } from '@/hooks';
import { getInvestmentColumns } from '@/modules/Automations/pages/AutomationsPage/utils';
import { useGetAutomationPerformance } from '@/modules/Automations/queries';
import { useAutomationInvestment } from '@/modules/Automations/queries/use-automation-performance';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { InvestmentMetrics } from '../components/InvestmentMetrics';

type InvestmentDetailsProps = {
  automationId: number;
  onClose: () => void;
};

export const InvestmentDetails = ({
  automationId,
  onClose,
}: InvestmentDetailsProps) => {
  const { t } = useTranslation();
  const { devTimeChartColors } = useGetChartColorsAndLabel();
  const [selectedBillable, setSelectedBillable] = useState<number>();
  const [dateRange, setDateRange] = useState<DateRange>();
  const [selectedType, setSelectedType] = useState<number>();
  const [searchInput, setSearchInput] = useState<string>();

  const { data: automationPerformance } =
    useGetAutomationPerformance(automationId);

  const investmentMetrics = useMemo(() => {
    const { development, infrastructure, maintenance, support } =
      automationPerformance?.investment ?? {
        development: 0,
        infrastructure: 0,
        maintenance: 0,
        support: 0,
      };

    const totalAmount = automationPerformance?.totalInvestment ?? 0;

    let devPercentage = 0;
    let maintenancePercentage = 0;
    let supportPercentage = 0;
    let infrastructurePercentage = 0;

    if (totalAmount > 0) {
      devPercentage = (development / totalAmount) * 100;
      maintenancePercentage = (maintenance / totalAmount) * 100;
      supportPercentage = (support / totalAmount) * 100;
      infrastructurePercentage = (infrastructure / totalAmount) * 100;
    }

    return {
      totalAmount,
      totalInfrastructureAmount: infrastructure,
      totalAmountDevelopment: development,
      totalAmountMaintenance: maintenance,
      totalAmountSupport: support,
      maintenanceAmountPercentage: maintenancePercentage,
      developmentAmountPercentage: devPercentage,
      supportAmountPercentage: supportPercentage,
      infrastructureAmountPercentage: infrastructurePercentage,
    };
  }, [automationPerformance]);

  const {
    data,
    isLoading: isLoadingDevTimeInvestment,
    fetchNextPage: fetchDevTimeNextPage,
    isFetching,
    isFetchingNextPage,
  } = useAutomationInvestment({
    automationId,
    dateRange,
    filter: searchInput,
    billable: selectedBillable,
    type: selectedType,
  });

  const devTimeInvestment = useMemo(
    () => data?.pages.flatMap((page) => page) || [],
    [data?.pages],
  );

  return (
    <PerformanceCardDetails
      title={t('investment')}
      subTitle={t('investment_history')}
      description={t('investment_description')}
      columns={getInvestmentColumns()}
      onClose={onClose}
      data={{
        items: devTimeInvestment,
        totalPages: 1,
        totalRows: devTimeInvestment.length,
      }}
      fetchNextPage={fetchDevTimeNextPage}
      contextType="devTime"
      selectedBillable={selectedBillable}
      selectedType={selectedType}
      setSelectedBillable={setSelectedBillable}
      setSelectedType={setSelectedType}
      metrics={
        <InvestmentMetrics
          metrics={investmentMetrics}
          devTimeChartColors={devTimeChartColors}
        />
      }
      kpiDateRange={dateRange}
      setKpiDateRange={setDateRange}
      isLoading={isLoadingDevTimeInvestment}
      setSearchInput={setSearchInput}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};
