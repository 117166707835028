import { TotalReturnCard } from '@/components/PerformanceCardsContainer/components/TotalReturnCard';
import { TotalReturnDetails } from '@/components/PerformanceCardsContainer/components/TotalReturnDetails';
import {
  useTaskAutomationsPerformance,
  useTaskTotalReturn,
} from '@/modules/Tasks/queries/use-get-task-automations-performance';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';

type TaskTotalReturnCardProps = {
  taskId: number;
  automationPerformanceDateRange?: DateRange;
};

export const TaskTotalReturnCard = ({
  taskId,
  automationPerformanceDateRange,
}: TaskTotalReturnCardProps) => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const [searchInput, setSearchInput] = useState<string>();
  const [isDetailsOpen, setDetailsOpen] = useState(false);

  const { data: automationPerformance, isLoading } =
    useTaskAutomationsPerformance({
      taskId,
      dateRange: automationPerformanceDateRange,
    });

  const {
    data: totalReturnAutomated,
    isLoading: isLoadingTotalReturnAutomated,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useTaskTotalReturn({
    taskId,
    enabled: isDetailsOpen,
    dateRange,
    filter: searchInput,
  });

  return (
    <TotalReturnCard
      automationPerformance={automationPerformance}
      isLoading={isLoading}
      setIsDetailsOpen={setDetailsOpen}
      details={
        isDetailsOpen && (
          <TotalReturnDetails
            isLoading={isLoadingTotalReturnAutomated}
            totalReturnAutomated={totalReturnAutomated}
            onClose={() => setDetailsOpen(false)}
            excludeSearchInput
            fetchNextPage={fetchNextPage}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setSearchInput={setSearchInput}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
          />
        )
      }
    />
  );
};
