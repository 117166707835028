import { Button, ConfirmModal, FilterSearchBar, Table } from '@/components';
import { useInvalidateBusinessAreaQueries } from '@/modules/BusinessAreas/hooks/use-invalidate-business-areas-queries';
import { AttachUserToBusinessModal } from '@/modules/BusinessAreas/pages/BusinessAreasPage/components/AttachUserToBusinessModal';
import { CreateBusinessAreaModal } from '@/modules/BusinessAreas/pages/BusinessAreasPage/components/CreateBusinessAreaModal';
import { useDisassociateBusinessAreaUser } from '@/modules/BusinessAreas/queries/disassociate-user';
import { DisassociateBusinessAreaUserModel } from '@/modules/BusinessAreas/types/disassociate-business-area-user-model';
import {
  automationsCountColumnConfig,
  codeColumnConfig,
  companyNameColumnConfig,
  getBusinessAreasChildRows,
  nameWithAvatarColumnConfig,
  processesCountColumnConfig,
  roleColumnConfig,
  statusColumnConfig,
  tasksCountColumnConfig,
  usersCountColumnConfig,
  workflowsColumnConfig,
} from '@/utils';
import { actionsEditRowColumnConfig } from '@/utils/tableColumns/actions-edit-row-column-config';
import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TabContext } from '../TabContainer';
import { TableColumn, TableRow } from '../Table/types';

interface BusinessAreasTabProps {
  canAttachUser?: boolean;
  canCreate?: boolean;
  userId?: number;
  disableFields?: string[];
  organizationId?: number;
  companyId?: number;
  context?: 'default' | 'userDetails';
}

export const BusinessAreasTab = ({
  canAttachUser,
  canCreate,
  userId,
  disableFields,
  organizationId,
  companyId,
  context,
}: BusinessAreasTabProps): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const invalidateBusinessAreaQueries = useInvalidateBusinessAreaQueries();

  const [businessAreaSelected, setBusinessAreaSelected] = useState<number>();
  const [isOpenUnlink, setIsOpenUnlink] = useState(false);

  const handleOpenRemoveBusinessModalState = useCallback((id: number) => {
    setIsOpenUnlink(true);
    setBusinessAreaSelected(id);
  }, []);

  const routeChange = (row?: TableRow) => {
    const path = `/business-areas/${row?.id}`;
    navigate(path);
  };

  const handleCloseRemoveBusinessAreaModalState = useCallback(() => {
    setIsOpenUnlink(false);
    setBusinessAreaSelected(undefined);
  }, []);

  const { mutate: disassociateBusinessAreaUser } =
    useDisassociateBusinessAreaUser();

  const {
    data,
    isLoading,
    isOpen,
    page,
    onPageChange,
    handleSearchChange,
    handleModalState,
    onSort,
  } = useContext(TabContext);

  const columns = useMemo(() => {
    const baseColumns = [
      nameWithAvatarColumnConfig({}),
      codeColumnConfig(),
      statusColumnConfig(),
      companyNameColumnConfig(),
      usersCountColumnConfig(),
      processesCountColumnConfig(),
      workflowsColumnConfig(),
      tasksCountColumnConfig(),
      automationsCountColumnConfig({
        options: {
          className: 'w-25',
        },
      }),
      actionsEditRowColumnConfig({
        options: { label: t('actions') } as TableColumn<TableRow>,
        canEdit: () => false,
        onUnlinkClick(row) {
          handleOpenRemoveBusinessModalState(row.id as number);
        },
        canUnlink: true,
      }),
    ];

    switch (context) {
      case 'userDetails':
        return [...baseColumns, roleColumnConfig()];
      case 'default':
      default:
        return [...baseColumns];
    }
  }, [context, handleOpenRemoveBusinessModalState, t]);

  const rows = useMemo(
    () => getBusinessAreasChildRows(data?.items ?? []),
    [data],
  );

  const handleDisassociateBusinessArea = useCallback(() => {
    if (!businessAreaSelected || !userId) {
      return;
    }

    const convertedData: DisassociateBusinessAreaUserModel = {
      userId: userId,
      businessAreaId: businessAreaSelected,
    };

    disassociateBusinessAreaUser(convertedData, {
      onError: () => {
        toast.error(t('error_remove_business_area_from_user'));
      },
      onSuccess: () => {
        toast.success(t('success_remove_business_area_from_user'));
        invalidateBusinessAreaQueries();
      },
    });

    handleCloseRemoveBusinessAreaModalState();
  }, [
    businessAreaSelected,
    disassociateBusinessAreaUser,
    handleCloseRemoveBusinessAreaModalState,
    invalidateBusinessAreaQueries,
    t,
    userId,
  ]);

  return (
    <>
      <div className="center flex flex-row">
        <FilterSearchBar
          total={data?.totalBusinessAreas || 0}
          title={t('business_areas')}
          onSearch={handleSearchChange}
          variant={'secondary'}
          otherActions={
            <>
              {canCreate && (
                <Button onClick={handleModalState} variant="primary">
                  {t('add')}
                </Button>
              )}
              {canAttachUser && userId && (
                <AttachUserToBusinessModal userId={userId} />
              )}
            </>
          }
        />
      </div>
      <CreateBusinessAreaModal
        handleClose={handleModalState}
        isOpen={isOpen}
        disableFields={disableFields}
        organizationId={organizationId}
        companyId={companyId}
      />
      <Table
        onRowClick={routeChange}
        columns={columns}
        rows={rows}
        isLoading={isLoading}
        noResultsLabel={t('no_business_areas_available')}
        withPagination
        totalItems={data?.totalRows}
        totalPages={data?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
        isTree
      />
      <ConfirmModal
        isOpen={isOpenUnlink}
        handleClose={handleCloseRemoveBusinessAreaModalState}
        title="Please confirm action"
        message={t('confirm_business_area_user_disassociate')}
        onConfirm={handleDisassociateBusinessArea}
      />
    </>
  );
};
