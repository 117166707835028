import { automationsRepo } from '@/constants/providers';
import { ExceptionType } from '@/shared/types';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { AutomationKey } from './keys';

export const useAutomationPerformance = (
  automationId: number,
  dateRange?: DateRange,
) =>
  useQuery({
    queryKey: AutomationKey.PERFORMANCE(automationId, dateRange),
    queryFn: () =>
      automationsRepo.getAutomationPerformanceById(automationId, dateRange),
  });

type UseAutomationLabourTimeAutomatedProps = {
  automationId: number;
  dateRange?: DateRange;
  filter?: string;
  enabled?: boolean;
};

export const useAutomationLabourTimeAutomated = ({
  automationId,
  dateRange,
  filter,
  enabled = true,
}: UseAutomationLabourTimeAutomatedProps) => {
  return useInfiniteQuery(
    [AutomationKey.LABOUR_AUTOMATED(automationId, dateRange, filter)],
    ({ pageParam = 1 }) =>
      automationsRepo.getAutomationLabourTimeAutomatedById(
        automationId,
        dateRange,
        pageParam,
        filter,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};

type UseAutomationTotalReturnProps = {
  automationId: number;
  enabled?: boolean;
  dateRange?: DateRange;
  filter?: string;
};

export const useAutomationTotalReturn = ({
  automationId,
  enabled = true,
  dateRange,
  filter,
}: UseAutomationTotalReturnProps) => {
  return useInfiniteQuery(
    [AutomationKey.TOTAL_RETURN(automationId, dateRange, filter)],
    ({ pageParam = 1 }) =>
      automationsRepo.getAutomationTotalReturnById(
        automationId,
        dateRange,
        pageParam,
        filter,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};

type UseAutomationLastRunProps = {
  automationId: number;
  enabled?: boolean;
  filter?: string;
  caseResult?: boolean;
  exceptionType?: ExceptionType;
};

export const useAutomationLastRun = ({
  automationId,
  filter,
  caseResult,
  enabled = true,
  exceptionType,
}: UseAutomationLastRunProps) => {
  return useInfiniteQuery(
    [AutomationKey.LAST_RUN(automationId, filter, caseResult, exceptionType)],
    ({ pageParam = 1 }) =>
      automationsRepo.getAutomationLastRunById(
        automationId,
        pageParam,
        filter,
        caseResult,
        exceptionType,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};

type UseAutomationOverallRunsProps = {
  automationId: number;
  dateRange?: DateRange;
  filter?: string;
  caseResult?: boolean;
  enabled?: boolean;
  exceptionType?: ExceptionType;
};

export const useAutomationOverallRuns = ({
  automationId,
  dateRange,
  filter,
  caseResult,
  exceptionType,
  enabled = true,
}: UseAutomationOverallRunsProps) => {
  return useInfiniteQuery(
    [
      AutomationKey.OVERALL_RUNS(
        automationId,
        dateRange,
        filter,
        caseResult,
        exceptionType,
      ),
    ],
    ({ pageParam = 1 }) =>
      automationsRepo.getAutomationOverallRunsById(
        automationId,
        dateRange,
        pageParam,
        filter,
        caseResult,
        exceptionType,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};

type UseAutomationInvestmentProps = {
  automationId: number;
  dateRange?: DateRange;
  filter?: string;
  billable?: number;
  type?: number;
  enabled?: boolean;
};

export const useAutomationInvestment = ({
  automationId,
  enabled = true,
  dateRange,
  filter,
  billable,
  type,
}: UseAutomationInvestmentProps) => {
  return useInfiniteQuery(
    [AutomationKey.INVESTMENT(automationId, dateRange, filter, billable, type)],
    ({ pageParam = 1 }) =>
      automationsRepo.getAutomationInvestmentById(
        automationId,
        dateRange,
        pageParam,
        filter,
        billable,
        type,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};
