import { PieChartCard } from '@/components';
import { SuccessRate } from '@/core/domain/global-performance-model';
import { useGetChartColorsAndLabel } from '@/hooks';
import { useGetAutomationPerformance } from '@/modules/Automations/queries';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAutomationPerformanceWarningMessages } from '../../hooks/use-automation-performance-warning-messages';
import { OverallRunsDetails } from './OverallRunsDetails';

type OveralOverallRunsCardProps = {
  automationId: number;
};

export const OverallRunsCard = ({
  automationId,
}: OveralOverallRunsCardProps) => {
  const { t } = useTranslation();

  const { data: automationPerformance, isLoading } =
    useGetAutomationPerformance(automationId);

  const [isOverallRunsDetailsOpen, setIsOverallRunsDetailsOpen] =
    useState<boolean>();

  const { rateChart } = useGetChartColorsAndLabel();

  const overallSuccessRateData = useMemo(
    () =>
      Object.entries(rateChart).map(([key, value]) => ({
        ...value,
        value:
          automationPerformance?.overallSuccessRate[key as keyof SuccessRate] ??
          0,
      })),
    [automationPerformance, rateChart],
  );

  const warningMessages = useAutomationPerformanceWarningMessages({
    automationId,
    automationPerformance,
    isLoading,
  });

  return (
    <>
      <PieChartCard
        data={overallSuccessRateData}
        chartTitle={t('overall_performance')}
        valueType="percentage"
        subtitle={t('cases')}
        tooltip={t('overall_performance_tooltip_description')}
        onExpand={() => setIsOverallRunsDetailsOpen(true)}
        warningMessage={warningMessages}
      />
      {isOverallRunsDetailsOpen && (
        <OverallRunsDetails
          automationId={automationId}
          onClose={() => setIsOverallRunsDetailsOpen(false)}
        />
      )}
    </>
  );
};
