import { ProcessKey, useGetProcess } from '@/modules/Processes/queries';
import { useUpdateProcess } from '@/modules/Processes/queries/update-process';
import { EditProcessJson } from '@/modules/Processes/types';
import { ProcessSchemaType } from '@/modules/Processes/types/process-schema';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ProcessModal } from '../ProcessModal/ProcessModal';

interface EditProcessModalProps {
  isOpen: boolean;
  id: number;
  handleClose: () => void;
}

export const EditProcessModal = ({
  isOpen,
  id,
  handleClose,
}: EditProcessModalProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useUpdateProcess();
  const { data: process, isLoading: isLoadingProcess } = useGetProcess(id);

  const handleConfirm = (data: ProcessSchemaType) => {
    if (!process) {
      return;
    }

    const convertedData: EditProcessJson = {
      id: process.id,
      name: data.processName,
      description: data.description,
      ownerId: data.processOwnerId,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_update_process'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(ProcessKey.ALL);
        handleClose();
        toast.success(t('success_update_process'));
      },
    });
  };

  return (
    !isLoadingProcess &&
    !!process && (
      <ProcessModal
        disableFields={['organizationId', 'companyId', 'businessAreaId']}
        handleClose={handleClose}
        type="edit"
        handleOnSubmit={handleConfirm}
        title={t('edit_process')}
        isOpen={isOpen}
        isLoading={isLoading}
        defaultValues={{
          processName: process.name,
          description: process.description,
          processOwnerId: process.owner?.id,
          businessAreaId: process.businessAreaId,
        }}
      />
    )
  );
};
