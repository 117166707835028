import { DEFAULT_PAGE_SIZE, SortDirection } from '@/components/Table/types';
import { HttpClient } from '@/core/http/http-client';
import {
  mapLabourTimeAutomatedList,
  mapLastRunSuccessRateList,
  mapOverallRunsSuccessRateList,
  mapTotalReturnList,
} from '@/shared/mappers/kpi-mapper';
import {
  ApiResponse,
  DataWithPagination,
  ExceptionType,
  LabourTimeAutomatedDetailsJson,
  LastRunSuccessRateDetailsJson,
  OverallRunsSuccessRateDetailsJson,
  TotalReturnDetailsJson,
} from '@/shared/types';
import { DateUtility } from '@/utils/date.utils';
import { DateRange } from 'react-day-picker';
import { ProcessModel } from '../domain';
import {
  mapProcessJsonToProcessModel,
  processAutomationPerformanceMapper,
  processesMapper,
  processesWithPaginationMapper,
} from '../mappers';
import {
  ApiResponseProcess,
  ApiResponseProcesses,
  AssociateWorkflowToProcess,
  CreateProcessJson,
  DisassociateWorkflowFromProcess,
  EditProcessJson,
  PagedProcesses,
  ProcessAutomationsPerformanceJson,
  ProcessJson,
} from '../types';
import { AttachUserProcessModel } from '../types/attach-user-entity-model';
import { ProcessRepository } from './process.repository';

export class HttpProcessRepository extends ProcessRepository {
  constructor(private readonly client: HttpClient) {
    super();
  }

  async getAllProcesses(
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<ProcessModel[]>> {
    const processesJson = await this.client.get<
      ApiResponseProcesses<PagedProcesses>
    >({
      url: '/me/processes',
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return processesWithPaginationMapper(processesJson);
  }

  createProcess = async (data: CreateProcessJson): Promise<ProcessModel> => {
    const newCompany = await this.client.post<ProcessJson>({ body: data });
    return mapProcessJsonToProcessModel(newCompany);
  };

  attachUserProcess = async (data: AttachUserProcessModel): Promise<void> => {
    return await this.client.post({
      body: data,
      url: `associate-user`,
    });
  };

  getProcess = async (id: number): Promise<ProcessModel> => {
    const process = await this.client.get<ApiResponseProcess<ProcessJson>>({
      url: `${id}`,
    });

    return mapProcessJsonToProcessModel(process.data.process);
  };

  enableProcess = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/enable`,
    });
  };

  disableProcess = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/disable`,
    });
  };

  updateProcess = async (
    data: EditProcessJson,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.put<ApiResponse<unknown>>(`${data.id}`, data);
  };

  async getProcessesByBusinessArea(
    businessAreaId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<ProcessModel[]>> {
    const processes = await this.client.get<
      ApiResponseProcesses<PagedProcesses>
    >({
      url: `/business-areas/${businessAreaId}/processes`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return processesWithPaginationMapper(processes);
  }

  async getAutomationsPerformanceById(
    processId: number,
    dateRange?: DateRange,
  ) {
    const response = await this.client.get<
      ApiResponse<ProcessAutomationsPerformanceJson>
    >({
      url: `${processId}/automations-performance`,
      query: {
        ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
      },
    });

    return processAutomationPerformanceMapper(response.data);
  }

  async getAllProcessesByBusinessArea(
    businessAreaId: number,
  ): Promise<ProcessModel[]> {
    const processes = await this.client.get<
      ApiResponseProcesses<ProcessJson[]>
    >({
      url: `/business-areas/${businessAreaId}/processes/base-info`,
    });

    return processesMapper(processes.data.processes);
  }

  async getProcessesByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<ProcessModel[]>> {
    const processes = await this.client.get<
      ApiResponseProcesses<PagedProcesses>
    >({
      url: `/companies/${companyId}/processes`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return processesWithPaginationMapper(processes);
  }

  async getProcessesByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<ProcessModel[]>> {
    const processes = await this.client.get<
      ApiResponseProcesses<PagedProcesses>
    >({
      url: `/organizations/${organizationId}/processes`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return processesWithPaginationMapper(processes);
  }

  async getProcessesByUser(
    userId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<ProcessModel[]>> {
    const processes = await this.client.get<
      ApiResponseProcesses<PagedProcesses>
    >({
      url: `/users/${userId}/processes`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return processesWithPaginationMapper(processes);
  }

  associateWorkflowToProcess = async (
    data: AssociateWorkflowToProcess,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `associate-workflow`,
      body: data,
    });
  };

  disassociateWorkflowFromProcess = async (
    data: DisassociateWorkflowFromProcess,
  ): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `disassociate-workflow`,
      body: data,
    });
  };

  async deleteProcess(id: number): Promise<ApiResponse<unknown>> {
    return await this.client.delete(`${id}`);
  }

  async getProcessLabourTimeAutomatedById(
    processId: number,
    dateRange?: DateRange,
    page?: number,
    filter?: string,
  ) {
    const response = await this.client.get<
      ApiResponse<LabourTimeAutomatedDetailsJson>
    >({
      url: `${processId}/labour-time-automated`,
      query: {
        page,
        filter,
        ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
      },
    });

    return mapLabourTimeAutomatedList(
      response.data.executions,
      response.data.totals,
    );
  }

  async getProcessTotalReturnById(
    processId: number,
    dateRange?: DateRange,
    page?: number,
    filter?: string,
  ) {
    const response = await this.client.get<ApiResponse<TotalReturnDetailsJson>>(
      {
        url: `${processId}/total-return`,
        query: {
          page,
          filter,
          ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
        },
      },
    );

    return mapTotalReturnList(response.data.executions, response.data.totals);
  }

  async getProcessLastRunById(
    processId: number,
    page?: number,
    filter?: string,
    result?: boolean,
    exceptionType?: ExceptionType,
  ) {
    const response = await this.client.get<
      ApiResponse<LastRunSuccessRateDetailsJson>
    >({
      url: `${processId}/last-run`,
      query: {
        page,
        filter,
        result,
        exceptionType,
      },
    });

    return mapLastRunSuccessRateList(response.data.executions);
  }

  async getProcessOverallRunsById(
    processId: number,
    dateRange?: DateRange,
    page?: number,
    filter?: string,
    result?: boolean,
    exceptionType?: ExceptionType,
  ) {
    const response = await this.client.get<
      ApiResponse<OverallRunsSuccessRateDetailsJson>
    >({
      url: `${processId}/overall-runs`,
      query: {
        page,
        filter,
        result,
        ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
        exceptionType,
      },
    });

    return mapOverallRunsSuccessRateList(response.data.executions);
  }
}
