export interface LabourTimeAutomatedDetailsJson {
  executions: Array<LabourTimeAutomatedJson>;
  totals: LabourTimeAutomatedTotalsJson;
}

export interface TotalReturnDetailsJson {
  executions: Array<TotalReturnJson>;
  totals: TotalReturnTotalsJson;
}

export interface LastRunSuccessRateDetailsJson {
  executions: Array<LastRunSuccessRateJson>;
}

export interface OverallRunsSuccessRateDetailsJson {
  executions: Array<OverallRunsSuccessRateJson>;
}

export interface WorklogCostDetailsJson {
  worklogs: Array<WorklogCostJson>;
}

export type LabourTimeAutomatedJson = {
  id?: number;
  automationName?: string;
  taskName?: string;
  runStart?: Date;
  runFinish?: Date;
  labourTime?: number;
  runTimeSeconds?: number;
  timeEfficiency?: number;
};

export type LabourTimeAutomatedTotalsJson = {
  labourTime?: number;
  totalRunTimeSeconds?: number;
  timeEfficiency?: number;
  totalRows?: number;
};

export type TotalReturnJson = {
  id?: number;
  automationName?: string;
  taskName?: string;
  runStart?: Date;
  runFinish?: Date;
  totalCases?: number;
  successful?: number;
  totalError?: number;
  labourCost?: number;
  runCost?: number;
  return?: number;
};

export type TotalReturnTotalsJson = {
  labourCost?: number;
  return?: number;
  runCost?: number;
  successful?: number;
  totalCases?: number;
  totalError?: number;
  totalRunTimeSeconds?: number;
  totalSuccessful?: number;
  totalRows?: number;
};

export enum ExceptionType {
  Generic = 1,
  Business = 2,
}

export enum Success {
  Success = 0,
}

export type ResultFilter = Success | ExceptionType;

export type LastRunSuccessRateJson = {
  id?: number;
  taskName?: string;
  caseStart?: Date;
  caseFinish?: Date;
  runTime?: number;
  isSuccessful?: boolean;
  totalCases?: number;
  successfulCases?: number;
  businessErrors?: number;
  genericErrors?: number;
  runStartDate?: Date;
  runEndDate?: Date;
  exceptionTypeId?: ExceptionType;
};

export type OverallRunsSuccessRateJson = {
  id?: number;
  taskName?: string;
  caseStart?: Date;
  caseFinish?: Date;
  runTime?: number;
  isSuccessful?: boolean;
  exceptionTypeId?: ExceptionType;
};

export type WorklogCostJson = {
  id?: number;
  timeSpent?: string;
  timeSpentSeconds?: number;
  type?: string;
  startDate?: Date;
  description?: string;
  amount?: number;
  billable?: boolean;
};

export type WorklogTotalsJson = {
  totalTimeMaintenanceInSeconds: number;
  totalTimeDevelopmentInSeconds: number;
  totalTimeSupportInSeconds: number;
  totalAmountMaintenance: number;
  totalAmountDevelopment: number;
  totalAmountSupport: number;
};
