import { UserAvatar } from '@/components';
import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { PageDetailsHeaderRow } from '@/components/PageDetailsHeaderRow/PageDetailsHeaderRow';
import { StatusCard } from '@/components/StatusCard/StatusCard';
import { queryClient } from '@/constants';
import { OrganizationModel } from '@/modules/Organizations/domain';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { useGetCurrentUser } from '@/modules/Users/queries';
import {
  useDeleteProfileOrganizationPicture,
  useUploadProfileOrganizationPicture,
} from '@/modules/Users/queries/profile-picture';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { EditOrganizationModal } from '../../../OrganizationsPage/components/EditOrganizationModal';

interface HeaderRowProps {
  organization?: OrganizationModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent>,
    id: string,
  ) => void;
  status: boolean;
  isLoading?: boolean;
}

export const OrganizationHeaderRow = ({
  organization,
  handleStatus,
  status,
}: HeaderRowProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const { mutate: uploadProfilePicture, isLoading: isUpdatingProfilePicture } =
    useUploadProfileOrganizationPicture();

  const { mutate: deleteProfileImage, isLoading: isDeletingProfilePicture } =
    useDeleteProfileOrganizationPicture();

  const { data: currentUser } = useGetCurrentUser();

  const handleSuccessRequest = useCallback(
    (toastKeyMsg: string) => {
      queryClient.invalidateQueries(OrganizationKeys.ALL);
      toast.success(t(toastKeyMsg));
    },
    [t],
  );

  const isLoadingProfileImage = useMemo(
    () => isUpdatingProfilePicture || isDeletingProfilePicture,
    [isDeletingProfilePicture, isUpdatingProfilePicture],
  );

  const onUploadClick = useCallback(
    (file: File) => {
      if (!currentUser?.accountAdmin) {
        return;
      }
      const formData = new FormData();
      formData.append('EntityId', (organization?.id ?? '').toString());
      formData.append('File', file);

      uploadProfilePicture(formData, {
        onSuccess: () => handleSuccessRequest('success_profile_image_updated'),
        onError: () => toast.error(t('error_updating_profile_image')),
      });
    },
    [
      currentUser?.accountAdmin,
      handleSuccessRequest,
      organization?.id,
      t,
      uploadProfilePicture,
    ],
  );

  const onDeleteClick = useCallback(() => {
    if (!currentUser?.accountAdmin || !organization?.profileImageFileId) {
      return;
    }

    deleteProfileImage(
      {
        entityId: organization.id,
        fileId: organization.profileImageFileId,
      },
      {
        onSuccess: () => handleSuccessRequest('success_profile_image_deleted'),
        onError: () => toast.error(t('error_deleting_profile_image')),
      },
    );
  }, [
    currentUser?.accountAdmin,
    deleteProfileImage,
    handleSuccessRequest,
    organization?.id,
    organization?.profileImageFileId,
    t,
  ]);

  return (
    <div>
      <PageDetailsHeaderRow
        isLoading={!organization}
        before={
          <UserAvatar
            size="medium-large"
            name={organization?.name || ''}
            allowEdit={currentUser?.accountAdmin}
            onUploadClick={onUploadClick}
            onDeleteClick={onDeleteClick}
            isLoading={isLoadingProfileImage}
            imageId={organization?.profileImageFileId}
          />
        }
        title={organization?.name ?? ''}
        actions={<StatusCard status={status} handleStatus={handleStatus} />}
        onEditClick={handleModalState}
        details={
          <Container>
            <Card title={t('description')} className="flex flex-col gap-2">
              <p
                className="whitespace-pre-wrap"
                data-testid="description-section"
              >
                {organization?.description}
              </p>
            </Card>
          </Container>
        }
      />
      {isOpen && organization && (
        <EditOrganizationModal
          isOpen={isOpen}
          organizationId={organization.id}
          handleClose={handleModalState}
        />
      )}
    </div>
  );
};
