import {
  Button,
  CustomPageContainer,
  FilterSearchBar,
  Table,
  TablePaginationContext,
} from '@/components';
import { TableRow, TableSort } from '@/components/Table/types';
import { useToggleUserStatus } from '@/hooks/use-toggle-user-status';
import {
  useGetTenantUsers,
  useUpdateUserAccountAdmin,
} from '@/modules/Users/queries';
import { UserKey } from '@/modules/Users/queries/types';
import { useQueryClient } from '@tanstack/react-query';
import {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AddAdminUserModal } from '../AddAdminUserModal';
import { getUsersColumns, getUsersColumnsNamedMapped } from './utils';

export const ManageUsers = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState(false);
  const [tableSort, setTableSort] = useState<TableSort[]>([]);

  const { searchTerm, page, onPageChange, handleSearchChange } = useContext(
    TablePaginationContext,
  );

  const { onChange: onStatusChange } = useToggleUserStatus();

  const allUsersOptions = useMemo(
    () => ({
      onError: () => {
        toast.error(t('error_getting_users'));
      },
    }),
    [t],
  );

  const { pageNumber, searchInput, sortBy, sortOrder } = useMemo(
    () => ({
      pageNumber: page + 1,
      searchInput: searchTerm,
      sortBy: getUsersColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
      sortOrder: tableSort?.[0]?.direction ?? 'ascending',
    }),
    [page, searchTerm, tableSort],
  );

  const { mutate: updateUserAccountAdmin, isLoading: isLoadingUserUpdate } =
    useUpdateUserAccountAdmin();

  const { data: users, isLoading: isLoadingUsers } = useGetTenantUsers(
    {
      pageNumber,
      filter: searchInput,
      sortBy,
      sortOrder,
      isAccountAdmin: true,
    },
    allUsersOptions,
  );

  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const handleOnDetailsClick = useCallback(
    (row: TableRow) => navigate(`/users/${row.id}`),
    [navigate],
  );

  const handleOnUnlinkClick = useCallback(
    (row: TableRow) =>
      updateUserAccountAdmin(
        {
          userIds: [Number(row.id)],
          accountAdmin: false,
        },
        {
          onError: () => {
            toast.error(
              t('error_updating_user_account_admin', {
                action: t('account_admin_remove_error_action'),
              }),
            );
          },
          onSuccess: () => {
            queryClient.invalidateQueries(UserKey.ADMIN_USERS);
            toast.success(
              t('success_updating_user_account_admin', {
                action: t('account_admin_remove_success_action'),
              }),
            );
          },
        },
      ),
    [queryClient, t, updateUserAccountAdmin],
  );

  const handleOnStatusChange = useCallback(
    (checked: boolean, row: TableRow) => {
      onStatusChange(checked, +row.id);
    },
    [onStatusChange],
  );

  const onSort = (data: TableSort[]) => {
    setTableSort(data);
  };

  return (
    <div className="flex w-full flex-col gap-5">
      <CustomPageContainer.Header title={t('manage_admin_users')} />
      <AddAdminUserModal handleClose={handleModalState} isOpen={isOpen} />
      <FilterSearchBar
        total={users?.totalRows || 0}
        title={t('admin_users')}
        onSearch={handleSearchChange}
        otherActions={
          <Button onClick={handleModalState} variant="primary">
            {t('new_admin_user')}
          </Button>
        }
      />
      <Table
        columns={getUsersColumns({
          onDetailsClick: handleOnDetailsClick,
          onUnlinkClick: handleOnUnlinkClick,
          onStatusChange: handleOnStatusChange,
          isLoadingUserUpdate,
        })}
        rows={users?.items ?? []}
        isLoading={isLoadingUsers}
        noResultsLabel={t('no_users_available')}
        withPagination
        totalItems={users?.totalRows}
        totalPages={users?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
    </div>
  );
};
