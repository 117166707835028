import { workflowsRepo } from '@/constants/providers';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { WorkflowKey } from './keys';
import { ExceptionType } from '@/shared/types';

export const useWorkflowAutomationsPerformance = (
  workflowId: number,
  dateRange?: DateRange,
) =>
  useQuery({
    queryKey: WorkflowKey.AUTOMATIONS_PERFORMANCE(workflowId, dateRange),
    queryFn: () =>
      workflowsRepo.getAutomationsPerformanceById(workflowId, dateRange),
    cacheTime: 1000 * 60 * 5,
  });

type UseWorkflowLabourTimeAutomatedProps = {
  workflowId: number;
  enabled?: boolean;
  dateRange?: DateRange;
  filter?: string;
};

export const useWorkflowLabourTimeAutomated = ({
  workflowId,
  enabled,
  dateRange,
  filter,
}: UseWorkflowLabourTimeAutomatedProps) => {
  return useInfiniteQuery(
    [WorkflowKey.LABOUR_AUTOMATED(workflowId, dateRange, filter)],
    ({ pageParam = 1 }) =>
      workflowsRepo.getWorkflowLabourTimeAutomatedById(
        workflowId,
        dateRange,
        pageParam,
        filter,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};

type UseWorkflowTotalReturnProps = {
  workflowId: number;
  enabled?: boolean;
  dateRange?: DateRange;
  filter?: string;
};

export const useWorkflowTotalReturn = ({
  workflowId,
  enabled,
  dateRange,
  filter,
}: UseWorkflowTotalReturnProps) => {
  return useInfiniteQuery(
    [WorkflowKey.TOTAL_RETURN(workflowId, dateRange, filter)],
    ({ pageParam = 1 }) =>
      workflowsRepo.getWorkflowTotalReturnById(
        workflowId,
        dateRange,
        pageParam,
        filter,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};

type UseWorkflowLastRunProps = {
  workflowId: number;
  enabled?: boolean;
  filter?: string;
  caseResult?: boolean;
  exceptionType?: ExceptionType;
};

export const useWorkflowLastRun = ({
  workflowId,
  enabled,
  filter,
  caseResult,
  exceptionType,
}: UseWorkflowLastRunProps) => {
  return useInfiniteQuery(
    [WorkflowKey.LAST_RUN(workflowId, filter, caseResult, exceptionType)],
    ({ pageParam = 1 }) =>
      workflowsRepo.getWorkflowLastRunById(
        workflowId,
        pageParam,
        filter,
        caseResult,
        exceptionType,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};

type UseWorkflowOverallRunsProps = {
  workflowId: number;
  enabled: boolean;
  dateRange?: DateRange;
  filter?: string;
  caseResult?: boolean;
  exceptionType?: ExceptionType;
};

export const useWorkflowOverallRuns = ({
  workflowId,
  enabled,
  dateRange,
  filter,
  caseResult,
  exceptionType,
}: UseWorkflowOverallRunsProps) => {
  return useInfiniteQuery(
    [
      WorkflowKey.OVERALL_RUNS(
        workflowId,
        dateRange,
        filter,
        caseResult,
        exceptionType,
      ),
    ],
    ({ pageParam = 1 }) =>
      workflowsRepo.getWorkflowOverallRunsById(
        workflowId,
        dateRange,
        pageParam,
        filter,
        caseResult,
        exceptionType,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};
