import { companiesRepo } from '@/constants/providers';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { CompanyKeys } from './keys';

export const useGetCompanyAutomationsPerformance = (
  companyId: number,
  dateRange?: DateRange,
) => {
  return useQuery({
    queryKey: CompanyKeys.AUTOMATIONS_PERFORMANCE(companyId, dateRange),
    queryFn: () =>
      companiesRepo.getCompanyAutomationsPerformanceById(companyId, dateRange),
    cacheTime: 1000 * 60 * 5,
  });
};

type UseCompanyLabourTimeAutomatedProps = {
  companyId: number;
  enabled: boolean;
  dateRange?: DateRange;
  filter?: string;
};

export const useCompanyLabourTimeAutomated = ({
  companyId,
  enabled,
  dateRange,
  filter,
}: UseCompanyLabourTimeAutomatedProps) => {
  return useInfiniteQuery(
    [CompanyKeys.LABOUR_AUTOMATED(companyId, dateRange, filter)],
    ({ pageParam = 1 }) =>
      companiesRepo.getCompanyLabourTimeAutomatedById(
        companyId,
        dateRange,
        pageParam,
        filter,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};

type UseCompanyTotalReturnProps = {
  companyId: number;
  enabled: boolean;
  dateRange?: DateRange;
  filter?: string;
};

export const useCompanyTotalReturn = ({
  companyId,
  enabled,
  dateRange,
  filter,
}: UseCompanyTotalReturnProps) => {
  return useInfiniteQuery(
    [CompanyKeys.TOTAL_RETURN(companyId, dateRange, filter)],
    ({ pageParam = 1 }) =>
      companiesRepo.getCompanyTotalReturnById(
        companyId,
        dateRange,
        pageParam,
        filter,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );
};
