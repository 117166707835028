import { HttpClient } from '@/core/http';
import { SystemMessageModel } from '../domain/system-model';
import { SystemRepository } from './system.repository';
import { ApiResponseNotifications, SystemMessageJson } from '../types';
import { notificationsMapper } from '../mappers';

export class HttpSystemRepository extends SystemRepository {
  constructor(private readonly client: HttpClient) {
    super();
  }

  async getNotifications(environment: string): Promise<SystemMessageModel[]> {
    const businessAreas = await this.client.get<
      ApiResponseNotifications<SystemMessageJson[]>
    >({
      url: `${environment}`,
    });

    return notificationsMapper(businessAreas.data.notifications);
  }
}
