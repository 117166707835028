import { LastRunSuccessRateHeader, PerformanceCardDetails } from '@/components';
import { getLastRunSucessRateColumns } from '@/modules/Automations/pages/AutomationsPage/utils';
import { useGetAutomationPerformance } from '@/modules/Automations/queries';
import { useAutomationLastRun } from '@/modules/Automations/queries/use-automation-performance';
import { ExceptionType, ResultFilter, Success } from '@/shared/types';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type LastSuccessRunRateDetailsProps = {
  automationId: number;
  onClose: () => void;
};

export const LastSuccessRunRateDetails = ({
  automationId,
  onClose,
}: LastSuccessRunRateDetailsProps) => {
  const [selectedResult, setSelectedResult] = useState<ResultFilter>();
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>();

  const { data: automationPerformance, isLoading } =
    useGetAutomationPerformance(automationId);

  const {
    data,
    isLoading: isLoadingLastRun,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useAutomationLastRun({
    automationId,
    filter: search,
    caseResult: selectedResult === Success.Success ? true : undefined,
    exceptionType:
      !!selectedResult &&
      [ExceptionType.Business, ExceptionType.Generic].includes(selectedResult)
        ? selectedResult
        : undefined,
  });

  const lastRunSuccessRate = useMemo(() => {
    return data?.pages.flatMap((page) => page) || [];
  }, [data?.pages]);

  const totalMetrics = useMemo(() => {
    const {
      successes: successfulCases,
      genericErrors,
      businessErrors,
    } = automationPerformance?.lastRunSuccessRate ?? {
      successes: 0,
      genericErrors: 0,
      businessErrors: 0,
    };

    const totalCases = successfulCases + genericErrors + businessErrors;

    return {
      totalCases,
      successfulCases,
      genericErrors,
      businessErrors,
      runStartDate: automationPerformance?.lastRunStartDate,
      runEndDate: automationPerformance?.lastRunEndDate,
    };
  }, [automationPerformance]);

  return (
    <PerformanceCardDetails
      title={t('last_run_success_rate')}
      subTitle={t('cases')}
      description={t('last_run_success_rate_description')}
      columns={getLastRunSucessRateColumns()}
      onClose={onClose}
      data={{
        items: lastRunSuccessRate ?? [],
        totalPages: 1,
        totalRows: lastRunSuccessRate?.length ?? 0,
      }}
      metrics={
        !isLoading && (
          <LastRunSuccessRateHeader
            key="lastRunHeader"
            metrics={totalMetrics}
          />
        )
      }
      fetchNextPage={fetchNextPage}
      contextType="lastRun"
      selectedResult={selectedResult}
      setSelectedResult={setSelectedResult}
      isLoading={isLoadingLastRun}
      setSearchInput={setSearch}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};
