import { TenantContext } from '@/components';
import { SortDirection } from '@/components/Table/types';
import { infrastructuresRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { InfrastructureCostModel } from '../domain';
import { InfrastructureCostKey } from './types';

export const useGetInfrastructureCostsByCompany = (
  companyId: number,
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<InfrastructureCostModel[]>,
    any,
    DataWithPagination<InfrastructureCostModel[]>
  >,
) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<DataWithPagination<InfrastructureCostModel[]>>(
    [
      ...InfrastructureCostKey.PAGE_BY_COMPANY(
        companyId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
      currentSelectedTenant?.id,
    ],
    () =>
      infrastructuresRepo.getInfrastructureCostsByCompany(
        companyId,
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    options,
  );
};
