import { TenantContext } from '@/components';
import { SortDirection } from '@/components/Table/types';
import { organizationsRepo } from '@/constants/providers';
import { QueryOptions } from '@/modules/types';
import { DataWithPagination } from '@/shared/types/pagination';
import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { OrganizationBaseInfo, OrganizationModel } from '../domain';
import { OrganizationKeys } from './keys';

export const useGetOrganizations = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: QueryOptions<DataWithPagination<OrganizationModel[]>>,
) => {
  const { t } = useTranslation();
  const { currentSelectedTenant } = useContext(TenantContext);
  const query = useQuery<DataWithPagination<OrganizationModel[]>>(
    [
      ...OrganizationKeys.PAGED({ pageNumber, filter, sortBy, sortOrder }),
      currentSelectedTenant?.id,
    ],
    () =>
      organizationsRepo.getAllOrganizations(
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    { ...options, cacheTime: 1000 * 60 * 5 },
  );

  useEffect(() => {
    if (query.isError) {
      toast.error(t('error_getting_organizations'));
    }
  }, [query, t]);

  return query;
};

export const useGetAllOrganizations = () => {
  const { currentSelectedTenant } = useContext(TenantContext);
  return useQuery<OrganizationBaseInfo[]>({
    queryKey: [OrganizationKeys.ALL, currentSelectedTenant?.id],
    queryFn: () => organizationsRepo.getAllOrganizationsWithoutPagination(),
    cacheTime: 1000 * 60 * 5,
  });
};
