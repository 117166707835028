import { PropsWithChildren, ReactElement, RefObject, MouseEvent } from 'react';
import { twMerge } from 'tailwind-merge';
import { Button } from '../Button';

interface DropdownInputProps {
  buttonRef?: RefObject<HTMLButtonElement>;
  inputValue?: string;
  placeholder?: string;
  isOpen?: boolean;
  onButtonClick?: (event: MouseEvent) => void;
  disabled?: boolean;
}

export const DropdownInput = ({
  children,
  buttonRef,
  inputValue,
  placeholder,
  isOpen,
  onButtonClick,
  disabled,
}: PropsWithChildren<DropdownInputProps>): ReactElement => (
  <>
    <Button
      buttonRef={buttonRef}
      variant="text"
      onClick={onButtonClick}
      className="flex h-10 w-full justify-start rounded-md p-2 outline-none"
      isDisabled={disabled}
    >
      <span
        className={twMerge(
          'max-w-[82%] overflow-hidden text-ellipsis whitespace-nowrap text-md font-normal',
          !inputValue && 'text-spartanBlue',
          inputValue && 'text-primaryBlue',
        )}
      >
        {inputValue ?? placeholder ?? 'Select an option'}
      </span>
    </Button>
    <div
      className={twMerge(
        'absolute left-0 right-0 z-50 divide-y divide-white overflow-y-auto rounded-md rounded-t-none border border-t-0 text-sm shadow',
        !isOpen && 'hidden',
      )}
    >
      {children}
    </div>
  </>
);
