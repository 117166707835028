import { OverflowTooltip } from '@/components';
import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

type DescriptionColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const descriptionColumnConfig = <T extends TableRow>({
  options,
}: DescriptionColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'description',
  label: <Trans i18nKey="description" />,
  render: (value) => <OverflowTooltip showArrow text={value} />,
  acessorKey: 'description',
  sortable: true,
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
