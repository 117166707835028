import {
  AutomationsTab,
  BusinessAreasTab,
  CompaniesIcon,
  CustomPageContainer,
  InfrastructureCostsTab,
  ProcessesWithWorkflowsTab,
  TabItemProps,
  TablePaginationContextProvider,
  UsersTab,
} from '@/components';
import { LabelIcon } from '@/components/LabelIcon/LabelIcon';
import { PageDetailsActions } from '@/components/PageDetailsActions/PageDetailsActions';
import { TabContainer } from '@/components/TabContainer';
import { TabsWrapper } from '@/components/TabsWrapper';
import { queryClient } from '@/constants';
import { HttpError } from '@/core/http';
import { RoutePath } from '@/core/router/route-paths';
import { AutomationModel } from '@/modules/Automations/domain';
import { getAutomationColumnsNamedMapped } from '@/modules/Automations/pages/AutomationsPage/utils';
import { useGetAutomationsByCompany } from '@/modules/Automations/queries';
import { BusinessAreaModel } from '@/modules/BusinessAreas/domain';
import { getBusinessAreaColumnsNamedMapped } from '@/modules/BusinessAreas/pages/BusinessAreasPage/utils';
import { useGetBusinessAreasByCompany } from '@/modules/BusinessAreas/queries';
import { InfrastructureCostModel } from '@/modules/Infrastructure/domain';
import { getInfrastructureCostColumnsNamedMapped } from '@/modules/Infrastructure/pages/InfrastructureCostPage/utils';
import { useGetInfrastructureCostsByCompany } from '@/modules/Infrastructure/queries';
import { getProcessesColumnsNamedMapped } from '@/modules/Processes';
import { ProcessModel } from '@/modules/Processes/domain';
import { useGetProcessesByCompany } from '@/modules/Processes/queries';
import { UserModel } from '@/modules/Users/domain';
import { getUsersColumnsNamedMapped } from '@/modules/Users/pages/UsersPage/utils';
import { useGetUsersByCompany } from '@/modules/Users/queries';
import { UserKey } from '@/modules/Users/queries/types';
import { HttpStatusCode } from 'axios';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDisableCompany, useEnableCompany } from '../../queries';
import { useDisassociateCompanyUser } from '../../queries/disassociate-user';
import { useGetCompany } from '../../queries/get-company';
import { DisassociateCompanyUserModel } from '../../types/disassociate-company-user-model';
import { CompanyHeaderRow } from './components/CompanyHeaderRow';
import { CompanyPerformanceCards } from './components/CompanyPerformanceCards';

export const CompanyDetailPage = (): ReactElement => {
  const { companyId: companyIdParam } = useParams<{ companyId: any }>();
  const companyId = +companyIdParam!;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate } = useDisassociateCompanyUser();

  const {
    data: company,
    isLoading: isLoadingCompany,
    isError,
    error,
  } = useGetCompany(companyId);

  useEffect(() => {
    if (isError && error) {
      if ((error as HttpError<unknown>)?.code === HttpStatusCode.NotFound) {
        navigate(RoutePath.notFound());
      } else {
        toast.error(t('error_get_company_details'));
      }
    }
  }, [isError, error, navigate, t]);

  const [status, setStatus] = useState<boolean>(false);

  useEffect(() => {
    setStatus(company?.status === true);
  }, [company]);

  const { mutate: enableCompany } = useEnableCompany();
  const { mutate: disableCompany } = useDisableCompany();

  const handleStatus = useCallback(
    (checked: boolean) => {
      if (!company?.id) {
        return toast.error(t('generic_errors'));
      }
      setStatus(checked);
      if (checked) {
        enableCompany(company?.id, {
          onError: () => {
            toast.error(t('error_enable_company'));
          },
          onSuccess: () => {
            toast.success(t('success_enable_company'));
          },
        });
      } else {
        disableCompany(company?.id, {
          onError: () => {
            toast.error(t('error_disable_company'));
          },
          onSuccess: () => {
            toast.success(t('success_disable_company'));
          },
        });
      }
    },
    [company, enableCompany, disableCompany, t],
  );

  const tabs: Array<TabItemProps> = useMemo(
    () => [
      { label: t('users'), badgeNumber: company?.usersCount },
      {
        label: t('business_areas'),
        badgeNumber: company?.businessAreasCount,
      },
      {
        label: t('processes'),
        badgeNumber: company?.processesCount,
      },
      {
        label: t('automations'),
        badgeNumber: company?.automationsCount,
      },
      {
        label: t('infrastructure_costs'),
        badgeNumber: company?.infrastructureCostsCount,
      },
    ],
    [t, company],
  );

  const disassociateCompanyUser = useCallback(
    (userId: number) => {
      if (!userId) return;

      const convertedData: DisassociateCompanyUserModel = {
        userId: userId,
        companyId,
      };

      mutate(convertedData, {
        onError: () => {
          toast.error(t('error_remove_user_from_company'));
        },
        onSuccess: () => {
          toast.success(t('success_remove_user_from_company'));
          queryClient.invalidateQueries(UserKey.BY_COMPANY(companyId));
        },
      });
    },
    [mutate, t, companyId],
  );

  const routeChange = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const tabList = useMemo(() => {
    if (!companyId) {
      return [];
    }
    return [
      {
        tab: (
          <TabContainer<UserModel>
            parentId={companyId}
            useGetData={useGetUsersByCompany}
            getColumnsNamedMapped={getUsersColumnsNamedMapped}
          >
            <UsersTab
              companyId={company?.id}
              showRole
              unlinkData={{
                action: disassociateCompanyUser,
                message: t('confirm_user_company_disassociate'),
                canUnlink: true,
              }}
            />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<BusinessAreaModel>
            parentId={companyId}
            useGetData={useGetBusinessAreasByCompany}
            getColumnsNamedMapped={getBusinessAreaColumnsNamedMapped}
          >
            <BusinessAreasTab
              canCreate
              disableFields={['organizationId', 'companyId']}
              organizationId={company?.organizationId}
              companyId={company?.id}
            />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<ProcessModel>
            parentId={companyId}
            useGetData={useGetProcessesByCompany}
            getColumnsNamedMapped={getProcessesColumnsNamedMapped}
          >
            <ProcessesWithWorkflowsTab context="company" />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<AutomationModel>
            parentId={companyId}
            getColumnsNamedMapped={getAutomationColumnsNamedMapped}
            useGetData={useGetAutomationsByCompany}
          >
            <AutomationsTab
              canCreate
              disableFields={['organizationId', 'companyId']}
              organizationId={company?.organizationId}
              companyId={company?.id}
            />
          </TabContainer>
        ),
      },
      {
        tab: (
          <TabContainer<InfrastructureCostModel>
            parentId={companyId}
            getColumnsNamedMapped={getInfrastructureCostColumnsNamedMapped}
            useGetData={useGetInfrastructureCostsByCompany}
          >
            <InfrastructureCostsTab disableFields={['organizationId']} />
          </TabContainer>
        ),
      },
    ];
  }, [
    companyId,
    company?.id,
    company?.organizationId,
    disassociateCompanyUser,
    t,
  ]);

  const breadcrumbs = useMemo(() => {
    if (!company) {
      return [];
    }
    return [
      {
        label: t('organizations'),
        href: RoutePath.organizations(),
      },
      {
        label: company.organizationName,
        href: RoutePath.organizationDetail(company.organizationId.toString()),
      },
      {
        label: company.name,
      },
    ];
  }, [company, t]);

  return (
    <CustomPageContainer>
      <PageDetailsActions onBack={routeChange} breadcrumbs={breadcrumbs}>
        <LabelIcon
          label={t('company')}
          icon={<CompaniesIcon className="size-5 text-blueNuit" />}
        />
      </PageDetailsActions>
      <CompanyHeaderRow
        company={company}
        handleStatus={handleStatus}
        status={status}
        isLoading={isLoadingCompany}
      />
      <TablePaginationContextProvider>
        <CompanyPerformanceCards companyId={companyId} />
        <TabsWrapper tabs={tabs} tabList={tabList} />
      </TablePaginationContextProvider>
    </CustomPageContainer>
  );
};
