import { TableColumn, TableRow } from '@/components/Table/types';
import { WorkloadTypes } from '@/constants';
import { Trans } from 'react-i18next';

export const workloadColumnConfig = <T extends TableRow>(
  options?: Omit<TableColumn<T>, 'id' | 'label'>,
  className = 'max-w-[20vw] w-[20vw]',
): TableColumn<T> => ({
  id: 'workload',
  label: <Trans i18nKey="workload" />,
  render: (value, row) => {
    const workloadTypeName =
      row.workloadTypeId === WorkloadTypes.PER_CASE ? ' / case' : '';
    return (
      <div className="overflow-hidden text-ellipsis">
        {value} m {workloadTypeName}
      </div>
    );
  },
  acessorKey: 'workload',
  sortable: true,
  className,
  ...options,
});
