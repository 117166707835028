import { TotalReturnCard } from '@/components/PerformanceCardsContainer/components/TotalReturnCard';
import { TotalReturnDetails } from '@/components/PerformanceCardsContainer/components/TotalReturnDetails';
import {
  useWorkflowAutomationsPerformance,
  useWorkflowTotalReturn,
} from '@/modules/Workflows/queries/use-workflow-automations-performance';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';

type WorkflowTotalReturnCardProps = {
  workflowId: number;
  automationPerformanceDateRange?: DateRange;
};

export const WorkflowTotalReturnCard = ({
  workflowId,
  automationPerformanceDateRange,
}: WorkflowTotalReturnCardProps) => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const [searchInput, setSearchInput] = useState<string>();
  const [isDetailsOpen, setDetailsOpen] = useState<boolean>(false);

  const { data: automationPerformance, isLoading } =
    useWorkflowAutomationsPerformance(
      workflowId,
      automationPerformanceDateRange,
    );

  const {
    data,
    isLoading: isLoadingTotalReturnAutomated,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useWorkflowTotalReturn({
    workflowId,
    enabled: isDetailsOpen,
    dateRange,
    filter: searchInput,
  });

  const totalReturnAutomated = useMemo(
    () => data?.pages.flatMap((page) => page) || [],
    [data?.pages],
  );

  return (
    <TotalReturnCard
      automationPerformance={automationPerformance}
      isLoading={isLoading}
      setIsDetailsOpen={setDetailsOpen}
      details={
        isDetailsOpen && (
          <TotalReturnDetails
            isLoading={isLoadingTotalReturnAutomated}
            totalReturnAutomated={totalReturnAutomated}
            onClose={() => setDetailsOpen(false)}
            fetchNextPage={fetchNextPage}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setSearchInput={setSearchInput}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
          />
        )
      }
    />
  );
};
