import { t } from 'i18next';
import {
  AggregatedInvestmentModel,
  LabourTimeAutomatedModel,
  LabourTimeAutomatedTotalsModel,
  LastRunSuccessRateModel,
  OverallRunsSuccessRateModel,
  TotalReturnModel,
  TotalReturnTotalsModel,
  WorklogCostModel,
} from '../domain';
import {
  LabourTimeAutomatedJson,
  LabourTimeAutomatedTotalsJson,
  LastRunSuccessRateJson,
  OverallRunsSuccessRateJson,
  TotalReturnJson,
  TotalReturnTotalsJson,
  WorklogCostJson,
  WorklogTotalsJson,
} from '../types';

export const labourTimeAutomatedMapper = (
  labourTimeAutomatedJson: LabourTimeAutomatedJson,
  labourTimeAutomatedJsonTotals: LabourTimeAutomatedTotalsJson,
): LabourTimeAutomatedModel => ({
  id: labourTimeAutomatedJson.id ?? 0,
  automationName: labourTimeAutomatedJson.automationName ?? '',
  taskName: labourTimeAutomatedJson.taskName ?? '',
  runStart: labourTimeAutomatedJson.runStart,
  runFinish: labourTimeAutomatedJson.runFinish,
  labourTime: labourTimeAutomatedJson.labourTime ?? 0,
  runTime: labourTimeAutomatedJson.runTimeSeconds ?? 0,
  timeEfficiency: labourTimeAutomatedJson.timeEfficiency ?? 0,
  totalMetrics: labourTimeAutomatedTotalsMapper(labourTimeAutomatedJsonTotals),
});

export const totalReturnMapper = (
  totalReturnJson: TotalReturnJson,
  totalReturnTotalsJson: TotalReturnTotalsJson,
): TotalReturnModel => ({
  id: totalReturnJson.id ?? 0,
  automationName: totalReturnJson.automationName ?? '',
  taskName: totalReturnJson.taskName ?? '',
  runStart: totalReturnJson.runStart,
  runFinish: totalReturnJson.runFinish,
  totalCases: totalReturnJson.totalCases ?? 0,
  success: totalReturnJson.successful ?? 0,
  totalError: totalReturnJson.totalError ?? 0,
  labourCost: totalReturnJson.labourCost ?? 0,
  runCost: totalReturnJson.runCost ?? 0,
  return: totalReturnJson.return ?? 0,
  totalMetrics: mapTotalReturnTotals(totalReturnTotalsJson),
});

export const lastRunSuccessRateMapper = (
  lastRunSuccessRateJson: LastRunSuccessRateJson,
): LastRunSuccessRateModel => ({
  id: lastRunSuccessRateJson.id ?? 0,
  name: lastRunSuccessRateJson.taskName ?? '',
  caseStart: lastRunSuccessRateJson.caseStart,
  caseFinish: lastRunSuccessRateJson.caseFinish,
  runTime: lastRunSuccessRateJson.runTime ?? 0,
  isSuccessful: lastRunSuccessRateJson.isSuccessful ?? false,
  totalCases: lastRunSuccessRateJson.totalCases ?? 0,
  successfulCases: lastRunSuccessRateJson.successfulCases ?? 0,
  businessErrors: lastRunSuccessRateJson.businessErrors ?? 0,
  genericErrors: lastRunSuccessRateJson.genericErrors ?? 0,
  runStartDate: lastRunSuccessRateJson.runStartDate,
  runEndDate: lastRunSuccessRateJson.runEndDate,
  exceptionTypeId: lastRunSuccessRateJson.exceptionTypeId,
});

export const overallRunsSuccessRateMapper = (
  overallRunsSuccessRateJson: OverallRunsSuccessRateJson,
): OverallRunsSuccessRateModel => ({
  id: overallRunsSuccessRateJson.id ?? 0,
  name: overallRunsSuccessRateJson.taskName ?? '',
  caseStart: overallRunsSuccessRateJson.caseStart,
  caseFinish: overallRunsSuccessRateJson.caseFinish,
  runTime: overallRunsSuccessRateJson.runTime ?? 0,
  isSuccessful: overallRunsSuccessRateJson.isSuccessful ?? false,
  exceptionTypeId: overallRunsSuccessRateJson.exceptionTypeId,
});

export const worklogCostMapper = (
  worklogCostJson: WorklogCostJson,
): WorklogCostModel => ({
  id: worklogCostJson.id ?? 0,
  timeSpent: worklogCostJson.timeSpent ?? '',
  timeSpentSeconds: worklogCostJson.timeSpentSeconds ?? 0,
  costType: worklogCostJson.type ? t(worklogCostJson.type.toLowerCase()) : '',
  date: worklogCostJson.startDate,
  description: worklogCostJson.description ?? '',
  amount: worklogCostJson.amount ?? 0,
  billable: worklogCostJson.billable ?? false,
});

export const aggregateWorklogCost = (
  worklogTotals: WorklogTotalsJson,
): AggregatedInvestmentModel => {
  const {
    totalTimeMaintenanceInSeconds,
    totalTimeDevelopmentInSeconds,
    totalTimeSupportInSeconds,
    totalAmountMaintenance,
    totalAmountDevelopment,
    totalAmountSupport,
  } = worklogTotals;
  const totalTimeSpentSeconds =
    totalTimeMaintenanceInSeconds +
    totalTimeDevelopmentInSeconds +
    totalTimeSupportInSeconds;

  const totalAmount =
    totalAmountMaintenance + totalAmountDevelopment + totalAmountSupport;

  const maintenanceTimePercentage =
    totalTimeSpentSeconds > 0
      ? parseFloat(
          (
            (totalTimeMaintenanceInSeconds / totalTimeSpentSeconds) *
            100
          ).toFixed(2),
        )
      : 0;

  const developmentTimePercentage =
    totalTimeSpentSeconds > 0
      ? parseFloat(
          (
            (totalTimeDevelopmentInSeconds / totalTimeSpentSeconds) *
            100
          ).toFixed(2),
        )
      : 0;

  const supportTimePercentage =
    totalTimeSpentSeconds > 0
      ? parseFloat(
          ((totalTimeSupportInSeconds / totalTimeSpentSeconds) * 100).toFixed(
            2,
          ),
        )
      : 0;

  const maintenanceAmountPercentage =
    totalAmount > 0
      ? parseFloat(((totalAmountMaintenance / totalAmount) * 100).toFixed(2))
      : 0;

  const developmentAmountPercentage =
    totalAmount > 0
      ? parseFloat(((totalAmountDevelopment / totalAmount) * 100).toFixed(2))
      : 0;

  const supportAmountPercentage =
    totalAmount > 0
      ? parseFloat(((totalAmountSupport / totalAmount) * 100).toFixed(2))
      : 0;

  return {
    totalTimeSpentSeconds,
    totalTimeSpentSecondsMaintenance: totalTimeMaintenanceInSeconds,
    totalTimeSpentSecondsDevelopment: totalTimeDevelopmentInSeconds,
    totalTimeSpentSecondsSupport: totalTimeSupportInSeconds,
    totalAmount,
    totalAmountMaintenance,
    totalAmountDevelopment,
    totalAmountSupport,
    maintenanceTimePercentage,
    developmentTimePercentage,
    supportTimePercentage,
    maintenanceAmountPercentage,
    developmentAmountPercentage,
    supportAmountPercentage,
  };
};

export const mapLabourTimeAutomatedList = (
  labourTimeAutomatedJsonList: Array<LabourTimeAutomatedJson>,
  labourTimeAutomatedJsonTotals: LabourTimeAutomatedTotalsJson,
): Array<LabourTimeAutomatedModel> =>
  labourTimeAutomatedJsonList.map((elem) =>
    labourTimeAutomatedMapper(elem, labourTimeAutomatedJsonTotals),
  );

export const mapTotalReturnList = (
  totalReturnJsonList: Array<TotalReturnJson>,
  totalReturnTotalsJson: TotalReturnTotalsJson,
): Array<TotalReturnModel> =>
  totalReturnJsonList.map((elem) =>
    totalReturnMapper(elem, totalReturnTotalsJson),
  );

export const mapTotalReturnTotals = (
  totalReturnTotalsJson: TotalReturnTotalsJson,
): TotalReturnTotalsModel => ({
  labourCost: totalReturnTotalsJson?.labourCost ?? 0,
  return: totalReturnTotalsJson?.return ?? 0,
  runCost: totalReturnTotalsJson?.runCost ?? 0,
  successful: totalReturnTotalsJson?.successful ?? 0,
  totalCases: totalReturnTotalsJson?.totalCases ?? 0,
  totalError: totalReturnTotalsJson?.totalError ?? 0,
  totalRunTimeSeconds: totalReturnTotalsJson?.totalRunTimeSeconds ?? 0,
  totalSuccessful: totalReturnTotalsJson?.totalSuccessful ?? 0,
  totalRows: totalReturnTotalsJson?.totalRows ?? 0,
});

export const labourTimeAutomatedTotalsMapper = (
  labourTimeAutomatedJsonTotals: LabourTimeAutomatedTotalsJson,
): LabourTimeAutomatedTotalsModel => ({
  totalRows: labourTimeAutomatedJsonTotals?.totalRows ?? 0,
  labourTime: labourTimeAutomatedJsonTotals?.labourTime ?? 0,
  totalRunTimeSeconds: labourTimeAutomatedJsonTotals?.totalRunTimeSeconds ?? 0,
  timeEfficiency: labourTimeAutomatedJsonTotals?.timeEfficiency ?? 0,
});

export const mapLastRunSuccessRateList = (
  lastRunSuccessRateJsonList: Array<LastRunSuccessRateJson>,
): Array<LastRunSuccessRateModel> =>
  lastRunSuccessRateJsonList.map(lastRunSuccessRateMapper);

export const mapOverallRunsSuccessRateList = (
  overallRunsSuccessRateJsonList: Array<OverallRunsSuccessRateJson>,
): Array<OverallRunsSuccessRateModel> =>
  overallRunsSuccessRateJsonList.map(overallRunsSuccessRateMapper);

export const mapWorklogCostList = (
  worklogs: Array<WorklogCostJson>,
): Array<WorklogCostModel> => {
  return worklogs.map((worklogCostJson) => worklogCostMapper(worklogCostJson));
};
