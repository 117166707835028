import { useGetChartColorsAndLabel } from '@/hooks';
import { ExceptionType } from '@/shared/types';
import { useTranslation } from 'react-i18next';
import { TableRow } from '../Table/types';

type ResultColumnProps = { row: TableRow };

export const ResultColumn = ({ row }: ResultColumnProps) => {
  const { t } = useTranslation();
  const { rateColors } = useGetChartColorsAndLabel();
  return (
    <div className="overflow-hidden text-ellipsis">
      {row.isSuccessful ? (
        <span className="font-extrabold text-green-500">{t('success')}</span>
      ) : row.exceptionTypeId === ExceptionType.Business ? (
        <span
          className="font-extrabold"
          style={{ color: rateColors.businessError }}
        >
          {t('business_error')}
        </span>
      ) : (
        <span
          className="font-extrabold text-red-500"
          style={{ color: rateColors.genericErrors }}
        >
          {t('generic_error')}
        </span>
      )}
    </div>
  );
};
