import { z } from 'zod';

export const ProcessSchema = z.object({
  businessAreaId: z.coerce
    .number({ message: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  description: z.string().optional(),
  processName: z
    .string({ message: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
  processOwnerId: z.coerce
    .number({ message: 'error_field_required' })
    .min(1, { message: 'error_field_required' }),
});

export type FormProcessSchemaType = z.infer<typeof ProcessSchema>;

export interface ProcessSchemaType extends FormProcessSchemaType {
  id?: number;
}
