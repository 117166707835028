import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { ReactElement, ReactNode, Ref, memo, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  LoadingRow,
  NoDataCell,
  TableHead,
  TablePagination,
  TableRow,
} from './components';
import { useTable } from './hooks';
import './styles.css';
import {
  TableColumn,
  TableRow as TableRowInterface,
  TableSort,
  TableVariant,
} from './types';

interface TableProps<R extends TableRowInterface> {
  className?: string;
  columns: Array<TableColumn<R>>;
  rows: Array<R>;
  variant?: TableVariant;
  noOutline?: boolean;
  selectedRowId?: string | number;
  defaultSort?: TableSort;
  withPagination?: boolean;
  sorting?: TableSort[];
  currentPage?: number;
  totalItems?: number;
  totalPages?: number;
  loadingRowsCount?: number;
  isLoading?: boolean;
  noResultsLabel?: string;
  multipleSorts?: boolean;
  draggable?: boolean;
  onPageChange?: (page: number) => void;
  onSort?: (sort: TableSort[]) => void;
  onRowClick?: (row: TableRowInterface) => void;
  actionCallbackDisassociate?: (id: number) => void;
  actionCallbackEdit?: (id: number) => void;
  renderUnlink?: (id?: number, disabled?: boolean) => ReactNode;
  renderEdit?: (id?: number, disabled?: boolean) => ReactNode;
  highlightRows?: number[];
  checkPropertyTodisable?: string;
  checkValueToDisable?: number;
  isTree?: boolean;
  showDots?: boolean;
  noChildDots?: boolean;
  onRowHoverStyling?: string;
  footer?: ReactNode;
  lastRowRef?: Ref<HTMLTableRowElement>;
  isFetchingNextPage?: boolean;
}

const TableComponent = <R extends TableRowInterface>({
  className,
  columns,
  rows,
  variant = 'medium',
  noOutline,
  sorting,
  defaultSort,
  selectedRowId,
  currentPage = 1,
  totalItems = 0,
  totalPages = 0,
  withPagination,
  loadingRowsCount = 5,
  isLoading,
  noResultsLabel,
  draggable,
  onPageChange,
  onSort,
  onRowClick,
  actionCallbackDisassociate,
  actionCallbackEdit,
  renderUnlink,
  renderEdit,
  highlightRows = [],
  checkValueToDisable,
  checkPropertyTodisable,
  isTree,
  showDots,
  noChildDots,
  onRowHoverStyling,
  footer,
  lastRowRef,
  isFetchingNextPage,
}: TableProps<R>): ReactElement => {
  const {
    pageIndex,
    sortedData,
    handleSort,
    sorts,
    handleNextPage,
    handlePageChange,
    handlePrevPage,
    nextDisabled,
    prevDisabled,
  } = useTable(
    rows,
    columns,
    currentPage,
    totalPages,
    onPageChange,
    withPagination,
    sorting,
    defaultSort,
    onSort,
  );

  const checkDisableRow = useCallback(
    (row: { [x: string]: number }) =>
      !!checkPropertyTodisable &&
      !!checkValueToDisable &&
      !!row[checkPropertyTodisable] &&
      row[checkPropertyTodisable] !== checkValueToDisable,
    [checkPropertyTodisable, checkValueToDisable],
  );

  return (
    <>
      <div className={twMerge(`w-full bg-white`, className)}>
        <table
          className="w-full border-separate bg-inherit"
          cellPadding={0}
          cellSpacing={0}
        >
          <thead
            className="sticky bg-white"
            style={{
              insetBlockStart: 0,
            }}
          >
            <TableHead
              columns={columns}
              variant={variant}
              onSort={handleSort}
              sorting={sorts}
              isTree={isTree}
            />
          </thead>
          <tbody>
            {isLoading &&
              Array.from({ length: loadingRowsCount ?? 0 }).map(
                (_, rowIndex) => (
                  <LoadingRow
                    key={`loading-${rowIndex}`}
                    rowIndex={rowIndex}
                    columnsCount={columns.length}
                  />
                ),
              )}
            {!isLoading &&
              (sortedData.length === 0 ? (
                <NoDataCell
                  colSpan={columns.length}
                  variant={variant}
                  noResultsLabel={noResultsLabel}
                />
              ) : (
                <SortableContext
                  items={rows}
                  strategy={verticalListSortingStrategy}
                  disabled={!draggable}
                >
                  {sortedData.map((row, index) => (
                    <TableRow
                      checkDisableRow={checkDisableRow}
                      highlightRows={highlightRows}
                      draggable={draggable}
                      key={`${row.id}-${index}`}
                      row={row}
                      columns={columns}
                      variant={variant}
                      noOutline={noOutline}
                      onRowClick={onRowClick}
                      selected={row.id === selectedRowId}
                      actionCallbackDisassociate={actionCallbackDisassociate}
                      actionCallbackEdit={actionCallbackEdit}
                      renderUnlink={renderUnlink}
                      renderEdit={renderEdit}
                      highlightRow={
                        !checkDisableRow(row) &&
                        highlightRows.includes(row.id as number)
                      }
                      disableRow={checkDisableRow(row) as boolean}
                      isTree={isTree}
                      showDots={showDots}
                      noChildDots={noChildDots}
                      onRowHoverStyling={onRowHoverStyling}
                    />
                  ))}
                </SortableContext>
              ))}
            {isFetchingNextPage &&
              Array.from({ length: 3 }).map((_, rowIndex) => (
                <LoadingRow
                  key={`loading-${rowIndex}`}
                  rowIndex={rowIndex}
                  columnsCount={columns.length}
                />
              ))}
            <tr ref={lastRowRef} />
          </tbody>
          {!!footer && (
            <tfoot
              className="sticky border border-red-900 bg-white"
              style={{
                insetBlockEnd: 0,
              }}
            >
              {footer}
            </tfoot>
          )}
        </table>
      </div>

      {withPagination && totalItems > 0 && (
        <TablePagination
          pageIndex={pageIndex}
          nextDisabled={nextDisabled}
          prevDisabled={prevDisabled}
          totalPages={totalPages}
          totalItems={totalItems}
          onPageChange={handlePageChange}
          onPrevPage={handlePrevPage}
          onNextPage={handleNextPage}
        />
      )}
    </>
  );
};

export const Table = memo(TableComponent) as typeof TableComponent;
