import { Container } from '@/components';
import { ReactElement, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { DateRangeDropdown } from '../../../DateRangeDropdown';
import { AutomationLabourTimeAutomatedCard } from './AutomationLabourTimeCard/AutomationLabourTimeAutomatedCard';
import { AutomationTotalReturnCard } from './AutomationTotalReturnCard/AutomationTotalReturnCard';

type AutomationPerformanceCardsContainerProps = {
  automationId: number;
};

export const AutomationPerformanceCardsContainer = ({
  automationId,
}: AutomationPerformanceCardsContainerProps): ReactElement => {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<DateRange>();

  return (
    <div className="flex flex-wrap gap-2">
      <Container
        title={t('auto_and_invest_performance')}
        className="flex-1"
        actions={
          <DateRangeDropdown
            range={dateRange}
            onChange={setDateRange}
            placement="bottom-end"
          />
        }
      >
        <div className="flex gap-3">
          <AutomationLabourTimeAutomatedCard
            automationId={automationId}
            automationPerformanceDateRange={dateRange}
          />
          <AutomationTotalReturnCard
            automationId={automationId}
            automationPerformanceDateRange={dateRange}
          />
        </div>
      </Container>
    </div>
  );
};
