import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';
import { secondsToTimeDurationString } from '..';

type LabourTimeColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const labourTimeColumnConfig = <T extends TableRow>({
  options,
}: LabourTimeColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'labourTime',
  label: <Trans i18nKey="labour_time" />,
  render: (value) => (
    <div className="overflow-hidden text-ellipsis">
      {secondsToTimeDurationString(value)}
    </div>
  ),
  acessorKey: 'labourTime',
  sortable: false,
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
