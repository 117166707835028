import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';

export const automatedColumnConfig = <T extends TableRow>(
  options?: Omit<TableColumn<T>, 'id' | 'label'>,
): TableColumn<T> => ({
  id: 'automated',
  acessorKey: 'automated',
  label: <Trans i18nKey="automated" />,
  render: (value) => <Trans i18nKey={value ? 'yes' : 'no'} />,
  sortable: true,
  ...options,
});
