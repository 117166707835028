import { PrivateRoute } from '@/core/components/PrivateRoute';
import { ReactElement } from 'react';
import { RouteObject } from 'react-router-dom';

export interface RouteEntry {
  path: string;
  component?: () => ReactElement | null;
  exact?: boolean;
  routes?: RouteEntry[];
  requiresAuth?: boolean;
  showSideNav?: boolean;
}

const routesConfigurationMap =
  () =>
  (route: RouteEntry): RouteObject => {
    const {
      path,
      component: Component,
      exact = true,
      requiresAuth,
      routes,
      showSideNav = true,
    } = route;

    const resolvedPath = [path.startsWith('/') ? path.substring(1) : path];

    if (!exact) resolvedPath.push('*');

    const reactRouter: RouteObject = {
      path: resolvedPath.join('/'),
    };

    if (Component && !routes) {
      reactRouter.element = requiresAuth ? (
        <PrivateRoute showSideNav={showSideNav}>
          <Component />
        </PrivateRoute>
      ) : (
        <Component />
      );
    }

    if (routes) {
      reactRouter.children = routes.map(routesConfigurationMap());
    }

    return reactRouter;
  };

export const buildRouterConfiguration = (config: RouteEntry[]) =>
  config.map(routesConfigurationMap());
