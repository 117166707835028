import { PerformanceCardDetails } from '@/components/PerformanceCardDetails';
import { getLabourTimeAutomatedColumns } from '@/modules/Automations/pages/AutomationsPage/utils';
import { LabourTimeAutomatedModel } from '@/shared/domain';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { LabourTimeAutomatedFooter } from './AutomationPerformanceCardContainer/LabourTimeAutomatedFooter';

type LabourTimeAutomatedDetailsProps = {
  labourAutomated?: LabourTimeAutomatedModel[];
  isLoadingLabourAutomation: boolean;
  onClose: () => void;
  fetchNextPage: () => Promise<unknown>;
  addAdditionalColumns?: boolean;
  excludeSearchInput?: boolean;
  setSearchInput: (value: string, includeAll?: boolean) => void;
  dateRange?: DateRange;
  setDateRange?: (dateRange?: DateRange) => void;
  isFetching: boolean;
  isFetchingNextPage: boolean;
};

export const LabourTimeAutomatedDetails = ({
  labourAutomated,
  isLoadingLabourAutomation,
  fetchNextPage,
  onClose,
  addAdditionalColumns,
  excludeSearchInput,
  setSearchInput,
  dateRange,
  setDateRange,
  isFetching,
  isFetchingNextPage,
}: LabourTimeAutomatedDetailsProps) => {
  const { t } = useTranslation();

  return (
    <PerformanceCardDetails
      title={t('labour_time_automated')}
      subTitle={t('automation_runs')}
      description={t('labour_time_automation_description')}
      columns={getLabourTimeAutomatedColumns(addAdditionalColumns)}
      onClose={onClose}
      kpiDateRange={dateRange}
      setKpiDateRange={setDateRange}
      isLoading={isLoadingLabourAutomation}
      data={{
        items: labourAutomated ?? [],
        totalPages: 1,
        totalRows: labourAutomated?.length ?? 0,
      }}
      footerRenderer={
        <LabourTimeAutomatedFooter
          addAdditionalColumns={addAdditionalColumns}
          totalMetrics={labourAutomated?.[0]?.totalMetrics}
        />
      }
      fetchNextPage={fetchNextPage}
      contextType="labourAutomated"
      excludeSearchInput={excludeSearchInput}
      setSearchInput={setSearchInput}
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
};
