import {
  Button,
  Dropdown,
  FieldInfo,
  FilterBusinessAreaFormGroup,
  Input,
  TextArea,
} from '@/components';
import { useTaskFormOptions } from '@/modules/Tasks/hooks/use-task-form-options.hook';
import { TaskSchema, TaskSchemaType } from '@/modules/Tasks/types';
import { LabourWorkload } from '@/modules/Tasks/types/labourWorkLoad';
import { useGetAllWorkflowsByBusinessArea } from '@/modules/Workflows/queries';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { zodResolver } from '@hookform/resolvers/zod';
import { ChangeEvent, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface TaskFormProps {
  handleOnSubmit: (e: TaskSchemaType) => void;
  handleClose: () => void;
  isLoading: boolean;
  defaultValues: {
    businessAreaId?: number;
    workflowId?: number;
  };
  disableFields?: string[];
}

export const CreateTaskForm = ({
  isLoading,
  handleOnSubmit,
  handleClose,
  disableFields,
  defaultValues,
}: TaskFormProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm<TaskSchemaType>({
    defaultValues,
    resolver: zodResolver(TaskSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  const businessAreaId = watch('businessAreaId');

  const { data: workflows } = useGetAllWorkflowsByBusinessArea({
    businessAreaId,
  });

  const dropdownWorkflowsOptions = useMemo(
    () =>
      getDropdownArray(workflows?.filter((workflow) => workflow.status) ?? []),
    [workflows],
  );

  const { dropdownLabourWorkloadTypeOptions, dropdownPeriodicityOptions } =
    useTaskFormOptions();

  const onSubmit = (data: TaskSchemaType) => {
    handleOnSubmit(data);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    setValue('documentation', file);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2 pb-20">
        <FieldInfo
          title={t('task_name')}
          errorText={
            errors.taskName?.message ? t(errors.taskName.message) : undefined
          }
          hasError={!!errors.taskName?.message}
        >
          <Input
            isDisabled={isLoading}
            className="w-full bg-brightGray italic"
            placeHolder={t('task_name')}
            name="taskName"
            register={register}
          />
        </FieldInfo>
        <FilterBusinessAreaFormGroup<TaskSchemaType>
          disableFields={disableFields}
          errors={errors}
          control={control}
        />
        <FieldInfo
          title={t('workflow')}
          errorText={
            errors.workflowId?.message
              ? t(errors.workflowId.message)
              : undefined
          }
          hasError={!!errors.workflowId?.message}
          hidden={disableFields?.includes('workflowId')}
        >
          <Controller
            name="workflowId"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ...otherFieldProps } }) => (
              <Dropdown
                disabled={disableFields?.includes('workflowId')}
                {...otherFieldProps.onBlur}
                className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                placeholder={t('workflow')}
                options={dropdownWorkflowsOptions}
                onSelect={(val) => onChange(val.value)}
                value={value}
              />
            )}
          />
        </FieldInfo>
        <FieldInfo
          title={t('task_description')}
          errorText={
            errors.taskDescription?.message
              ? t(errors.taskDescription.message, { length: '9' })
              : undefined
          }
          hasError={!!errors.taskDescription?.message}
        >
          <TextArea
            isDisabled={isLoading}
            className="h-24 w-full  bg-brightGray italic"
            placeHolder={t('task_description')}
            register={register}
            name="taskDescription"
          />
        </FieldInfo>
        <FieldInfo
          title={t('task_periodicity')}
          errorText={
            errors.taskPeriodicity?.message
              ? t(errors.taskPeriodicity.message, { length: '9' })
              : undefined
          }
          hasError={!!errors.taskPeriodicity?.message}
        >
          <Controller
            name="taskPeriodicity"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ...otherFieldProps } }) => (
              <Dropdown
                disabled={disableFields?.includes('taskPeriodicity')}
                {...otherFieldProps.onBlur}
                className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                placeholder={t('task_periodicity')}
                options={dropdownPeriodicityOptions}
                onSelect={(val) => onChange(val.value)}
                value={value}
              />
            )}
          />
        </FieldInfo>
        <FieldInfo
          title={t('labour_workload')}
          errorText={
            errors.labourWorkload?.message
              ? t(errors.labourWorkload.message, { length: '9' })
              : undefined
          }
          hasError={!!errors.labourWorkload?.message}
        >
          <Input
            type="text"
            isDisabled={isLoading}
            className="w-full bg-brightGray italic"
            placeHolder={t('labour_workload_minutes')}
            register={register}
            name="labourWorkload"
            step="any"
          />
        </FieldInfo>
        <FieldInfo
          title={t('labour_workload_type')}
          errorText={
            errors.labourWorkloadType?.message
              ? t(errors.labourWorkloadType.message, { length: '9' })
              : undefined
          }
          hasError={!!errors.labourWorkloadType?.message}
        >
          <Controller
            name="labourWorkloadType"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ...otherFieldProps } }) => (
              <Dropdown
                disabled={disableFields?.includes('labourWorkloadType')}
                {...otherFieldProps.onBlur}
                className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                placeholder={t('labour_workload_type')}
                options={dropdownLabourWorkloadTypeOptions}
                onSelect={(val) => onChange(val.value)}
                value={value}
              />
            )}
          />
        </FieldInfo>
        {watch('labourWorkloadType') === LabourWorkload.PerCase && (
          <FieldInfo
            title={t('average_cases')}
            errorText={
              errors.averageCases?.message
                ? t(errors.averageCases.message)
                : undefined
            }
            hasError={!!errors.averageCases?.message}
          >
            <Input
              type="number"
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('average_cases')}
              register={register}
              name="averageCases"
            />
          </FieldInfo>
        )}
        <FieldInfo
          title={t('documentation')}
          hasError={!!errors.documentation?.message}
        >
          <div className="relative w-full bg-brightGray capitalize">
            <Controller
              name="documentation"
              control={control}
              render={({ field }) => (
                <>
                  <input
                    id="file-upload"
                    type="file"
                    accept=".pdf, .doc, .docx"
                    className="absolute inset-0 z-50 w-full opacity-0"
                    onChange={handleFileChange}
                    disabled={isLoading}
                  />
                  <label
                    htmlFor="file-upload"
                    className="flex h-10 items-center justify-center rounded-md border border-dashed border-gray-300 bg-white p-2"
                  >
                    <span className="cursor-pointer text-sm font-bold underline">
                      {field.value ? field.value.name : t('choose_file')}
                    </span>
                  </label>
                </>
              )}
            />
          </div>
        </FieldInfo>
      </div>
      <div className="mt-4 flex justify-between">
        <Button
          isDisabled={isLoading}
          onClick={handleClose}
          variant="outline"
          className="h-9 w-24"
        >
          {t('cancel')}
        </Button>
        <Button isDisabled={isLoading} buttonType="submit" className="h-9 w-24">
          {t('create')}
        </Button>
      </div>
    </form>
  );
};
