import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';
import { formatBigNumber } from '..';

type TimeEfficiencyColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const timeEfficiencyColumnConfig = <T extends TableRow>({
  options,
}: TimeEfficiencyColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'timeEfficiency',
  label: <Trans i18nKey="time_efficiency" />,
  render: (value) => (
    <div
      className={`overflow-hidden text-ellipsis font-bold ${value >= 0 ? 'text-green-500' : 'text-red-500'}`}
    >
      {formatBigNumber(value)}%
    </div>
  ),
  acessorKey: 'timeEfficiency',
  sortable: false,
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
