import {
  Button,
  Container,
  CustomPageContainer,
  FileUploadIcon,
  Input,
  TenantContext,
  TrashGreyIcon,
  TrashIcon,
} from '@/components';
import { ChangeTenantModal } from '@/components/ChangeTenantModal';
import InputWithError from '@/components/InputWithError/InputWithError';
import InputWithTitle from '@/components/InputWithTitle/InputWithTitle';
import { ChangePasswordModal } from '@/components/SideNav/components';
import { Spinner } from '@/components/Spinner';
import { queryClient } from '@/constants/query-client';
import { useUpdateTenantName } from '@/modules/Tenants/queries';
import { TenantsKeys } from '@/modules/Tenants/queries/types';
import { TenantSchema, TenantSchemaType } from '@/modules/Tenants/types';
import {
  useDeleteProfileTenantPicture,
  useUploadProfileTenantPicture,
} from '@/modules/Users/queries/profile-picture';
import { UserKey } from '@/modules/Users/queries/types';
import { FILE_TYPES_ACCEPTED } from '@/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CurrentTenantAvatar } from '../CurrentTenantAvatar';

export const TenantInfo = (): ReactElement => {
  const { t } = useTranslation();
  const {
    currentSelectedTenant,
    onTenantIdChange,
    userTenants,
    isLoadingTenantInfo,
  } = useContext(TenantContext);

  const { mutate: uploadProfilePicture, isLoading: isUpdatingProfilePicture } =
    useUploadProfileTenantPicture();

  const { mutate: deleteProfileImage, isLoading: isDeletingProfilePicture } =
    useDeleteProfileTenantPicture();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [defaultValues, setDefaultValues] = useState({
    name: '',
  });

  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm<TenantSchemaType>({
    resolver: zodResolver(TenantSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  const handleResetFormValues = useCallback(() => {
    setValue('name', currentSelectedTenant?.name || '');
  }, [setValue, currentSelectedTenant]);

  useEffect(() => {
    if (!currentSelectedTenant) {
      return;
    }

    setDefaultValues({
      name: currentSelectedTenant.name || '',
    });

    handleResetFormValues();
  }, [handleResetFormValues, currentSelectedTenant]);

  const [name] = watch(['name']);

  const handleSuccessRequest = useCallback(
    (toastKeyMsg: string) => {
      queryClient.invalidateQueries(UserKey.CURRENT_USER);
      toast.success(t(toastKeyMsg));
    },
    [t],
  );

  const { mutate } = useUpdateTenantName();

  const handleSubmitChanges = (data: TenantSchemaType) => {
    const convertedData = {
      id: currentSelectedTenant?.id,
      name: data.name,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_update_tenant'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([TenantsKeys.TENANT]);
        queryClient.invalidateQueries([TenantsKeys.TENANTS_LIST]);
        toast.success(t('success_update_tenant'));
      },
    });
  };

  const handleCloseChangePasswordModal = useCallback(
    () => setOpenChangePasswordModal(false),
    [],
  );

  const handleButtonUploadPictureClick = useCallback(
    () => fileInputRef.current?.click(),
    [],
  );

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];

      const formData = new FormData();
      formData.append('EntityId', (currentSelectedTenant?.id ?? '').toString());
      formData.append('File', file);

      uploadProfilePicture(formData, {
        onSuccess: () => handleSuccessRequest('success_profile_image_updated'),
        onError: () => toast.error(t('error_updating_profile_image')),
      });
    }
  };

  const handleDeletePictureClick = useCallback(() => {
    if (!currentSelectedTenant || !currentSelectedTenant.imageFileId) {
      return;
    }

    deleteProfileImage(
      {
        entityId: currentSelectedTenant.id,
        fileId: currentSelectedTenant.imageFileId,
      },
      {
        onSuccess: () => handleSuccessRequest('success_profile_image_deleted'),
        onError: () => toast.error(t('error_deleting_profile_image')),
      },
    );
  }, [deleteProfileImage, handleSuccessRequest, t, currentSelectedTenant]);

  const isLoadingProfileImage = useMemo(
    () => isUpdatingProfilePicture || isDeletingProfilePicture,
    [isDeletingProfilePicture, isUpdatingProfilePicture],
  );

  const isDisabled = useMemo(() => {
    return isLoadingTenantInfo;
  }, [isLoadingTenantInfo]);

  const hasImage = useMemo(
    () => !!currentSelectedTenant?.imageFileId,
    [currentSelectedTenant?.imageFileId],
  );

  const [isChangeTenantModalOpen, setIsChangeTenantModalOpen] = useState(false);
  const handleChangeTenantModalOpen = useCallback(() => {
    setIsChangeTenantModalOpen(true);
  }, []);
  const handleChangeTenantModalClose = useCallback(() => {
    setIsChangeTenantModalOpen(false);
  }, []);

  return (
    <div className="flex w-full flex-col gap-5">
      <CustomPageContainer.Header title={''} />
      <Container className="flex-row justify-between">
        <div className="flex flex-1 justify-between">
          <div className="flex items-center gap-5">
            <CurrentTenantAvatar
              size="large"
              isLoading={isLoadingProfileImage}
              tenant={currentSelectedTenant}
            />
            <div>
              <h2>{currentSelectedTenant?.name}</h2>
              <span>Tenant</span>
            </div>
          </div>
          <div className="content-center">
            <Button className="h-9 w-28" onClick={handleChangeTenantModalOpen}>
              {t('change_tenant')}
            </Button>
          </div>
        </div>
      </Container>
      <Container>
        <form onSubmit={handleSubmit(handleSubmitChanges)}>
          <div className="mt-2 w-96">
            <InputWithTitle title={t('profile_image')}>
              <div className="flex">
                <Button
                  className="mr-2 h-6 justify-between gap-x-2.5 border-blueNuit p-3 font-black text-blueNuit"
                  variant="outline"
                  onClick={handleButtonUploadPictureClick}
                >
                  <FileUploadIcon />
                  {t('upload')}
                  <input
                    ref={fileInputRef}
                    type="file"
                    onChange={handleFileChange}
                    className="display-none"
                    accept={FILE_TYPES_ACCEPTED}
                  />
                </Button>
                <Button
                  className={`h-6 justify-between gap-x-2.5 ${hasImage ? 'border-black' : 'border-grey'} p-3 font-black`}
                  variant="outline"
                  onClick={handleDeletePictureClick}
                  isDisabled={!hasImage}
                >
                  {!hasImage ? <TrashGreyIcon /> : <TrashIcon />}
                  {t('remove')}
                </Button>
              </div>
            </InputWithTitle>
            <InputWithTitle title={t('name')}>
              <InputWithError
                errorText={
                  errors.name?.message ? t(errors.name.message) : undefined
                }
                hasError={!!errors.name?.message}
              >
                <Input
                  isDisabled={isDisabled}
                  className="w-full bg-white italic"
                  placeHolder={t('first_name')}
                  name="name"
                  register={register}
                />
              </InputWithError>
            </InputWithTitle>
          </div>
          <div className="mt-8 flex gap-5">
            <Button
              buttonType="submit"
              className="h-9 w-24"
              isDisabled={name === defaultValues.name || !isValid}
            >
              {t('save_changes')}
              {isDisabled && <Spinner className="ml-4 h-5 w-5" />}
            </Button>
          </div>
        </form>
      </Container>
      <ChangePasswordModal
        isOpen={openChangePasswordModal}
        handleClose={handleCloseChangePasswordModal}
      />
      <ChangeTenantModal
        isOpen={isChangeTenantModalOpen}
        handleSave={onTenantIdChange}
        handleClose={handleChangeTenantModalClose}
        isLoading={isLoadingTenantInfo}
        selectedTenantId={currentSelectedTenant?.id}
        tenants={userTenants}
      />
    </div>
  );
};
