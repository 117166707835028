import { TenantContext } from '@/components';
import { infrastructuresRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { InfrastructureCostModel } from '../domain';
import { InfrastructureCostKey } from './types';

export const useGetInfrastructureCost = (id: number) => {
  const { currentSelectedTenant } = useContext(TenantContext);

  return useQuery<InfrastructureCostModel>(
    [...InfrastructureCostKey.BY_ID(id), currentSelectedTenant?.id],
    () => infrastructuresRepo.getInfrastructureCost(id),
    {
      enabled: !!id,
    },
  );
};
