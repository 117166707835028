import { mapPagination } from '@/shared/mappers';
import {
  AmountModel,
  InfrastructureCostBaseInfoModel,
  InfrastructureCostModel,
} from '../domain/infrastructure-model';
import {
  AmountJson,
  ApiResponseInfrastructureCostAmounts,
  ApiResponseInfrastructureCosts,
  InfrastructureCostBaseInfoJson,
  InfrastructureCostJson,
  PagedInfrastructureCostAmounts,
  PagedInfrastructureCosts,
} from '../types';

export const mapInfrastructureCostJsonToInfrastructureCostModel = (
  infrastructureCostJson: InfrastructureCostJson,
): InfrastructureCostModel => ({
  id: infrastructureCostJson.id ?? 0,
  company: infrastructureCostJson.company ?? '',
  organization: infrastructureCostJson.organization ?? '',
  costTypeId: infrastructureCostJson.costTypeId ?? 0,
  costLicenseTypeId: infrastructureCostJson.costLicenseTypeId,
  costType: infrastructureCostJson.costType ?? '',
  name: infrastructureCostJson.name ?? '',
  startDate: infrastructureCostJson.startDate
    ? new Date(infrastructureCostJson.startDate)
    : undefined,
  endDate: infrastructureCostJson.endDate
    ? new Date(infrastructureCostJson.endDate)
    : undefined,
  createdAt: infrastructureCostJson.createdAt
    ? new Date(infrastructureCostJson.createdAt)
    : undefined,
  updatedAt: infrastructureCostJson.updatedAt
    ? new Date(infrastructureCostJson.updatedAt)
    : undefined,
  amount: infrastructureCostJson.amount ?? 0,
  amountRecurrence: infrastructureCostJson.amountRecurrence ?? '',
  description: infrastructureCostJson.description ?? '',
  status: infrastructureCostJson.status ?? false,
  organizationsCount: infrastructureCostJson.organizationsCount ?? 0,
  companiesCount: infrastructureCostJson.companiesCount ?? 0,
  automationsCount: infrastructureCostJson.automationsCount ?? 0,
  amountHistoryCount: infrastructureCostJson.amountHistoryCount ?? 0,
  costDocuments:
    infrastructureCostJson?.costDocuments?.map((doc) => ({
      id: doc.id ?? '',
      name: doc.name ?? '',
      url: doc.url ?? '',
      infrastructureCostFileId: doc.infrastructureCostFileId ?? '',
    })) ?? [],
});

export const infrastructureCostsWithPaginationMapper = (
  infrastructureCostJson: ApiResponseInfrastructureCosts<PagedInfrastructureCosts>,
) => {
  const { data } = infrastructureCostJson;
  return {
    items: infrastructureCostsMapper(data.infrastructureCosts.items),
    ...mapPagination(data.infrastructureCosts),
  };
};

export const infrastructureCostsMapper = (
  infrastructureCostJson: Array<InfrastructureCostJson>,
): Array<InfrastructureCostModel> =>
  infrastructureCostJson.map(
    mapInfrastructureCostJsonToInfrastructureCostModel,
  );

export const infrastructureCostBaseInfoMapper = (
  organizationBaseInfoJson: InfrastructureCostBaseInfoJson,
): InfrastructureCostBaseInfoModel => ({
  id: organizationBaseInfoJson.id ?? 0,
  name: organizationBaseInfoJson.name ?? '',
});

export const infrastructureCostAmountsWithPaginationMapper = (
  infrastructureCostJson: ApiResponseInfrastructureCostAmounts<PagedInfrastructureCostAmounts>,
) => {
  const { data } = infrastructureCostJson;
  return {
    items: infrastructureCostsMapper(data.amountHistory.items),
    ...mapPagination(data.amountHistory),
  };
};

export const infrastructureCostAmountsMapper = (
  amountJson: Array<AmountJson>,
): Array<AmountModel> => amountJson.map(mapAmountJsonToAmountModel);

export const mapAmountJsonToAmountModel = (
  amountJson: AmountJson,
): AmountModel => ({
  id: amountJson.id ?? 0,
  amount: amountJson.amount ?? 0,
  startDate: amountJson.startDate ? new Date(amountJson.startDate) : undefined,
  endDate: amountJson.endDate ? new Date(amountJson.endDate) : undefined,
  updatedAt: amountJson.updatedAt ? new Date(amountJson.updatedAt) : undefined,
  createdAt: amountJson.createdAt ? new Date(amountJson.createdAt) : undefined,
});
