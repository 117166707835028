import { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { SortProps, TableColumn, TableRow, TableVariant } from '../../types';
import { TableHeadCell } from '../TableHeadCell';
import { TableHeadEmptyCell } from '../TableHeadEmptyCell';

interface TableHeadProps<R extends TableRow> extends SortProps {
  variant: TableVariant;
  columns: Array<TableColumn<R>>;
  isTree?: boolean;
}

export const TableHead = <R extends TableRow>({
  columns,
  isTree,
  ...otherProps
}: TableHeadProps<R>): ReactElement => {
  return (
    <tr className="bg-inherit">
      <TableHeadCell
        cell={columns[0]}
        {...otherProps}
        className={twMerge(isTree && 'pl-11')}
      />
      {columns.slice(1).map((headCol) => (
        <TableHeadCell key={headCol.id} cell={headCol} {...otherProps} />
      ))}
      <TableHeadEmptyCell {...otherProps} />
    </tr>
  );
};
