import { AutomationOverallPerformanceModel } from '@/modules/Automations/domain';
import { useGetAutomation } from '@/modules/Automations/queries';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type UseAutomationPerformanceWarningMessagesProps = {
  automationId: number;
  automationPerformance?: AutomationOverallPerformanceModel;
  isLoading?: boolean;
};

export const useAutomationPerformanceWarningMessages = ({
  automationId,
  automationPerformance,
  isLoading,
}: UseAutomationPerformanceWarningMessagesProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: automation, isLoading: isLoadingAutomation } =
    useGetAutomation(automationId);
  return useMemo(() => {
    const messages: Array<{ message: string; onClick: () => void }> = [];

    if (
      isLoading ||
      isLoadingAutomation ||
      !automationPerformance ||
      !automation
    ) {
      return messages;
    }

    if (!automationPerformance.hasJiraLink) {
      messages.push({
        message: t('warning_jira_not_configured'),
        onClick: () => navigate(`/companies/${automation.companyId}?edit=true`),
      });
    }

    if (!automationPerformance.hasEpicId) {
      messages.push({
        message: t('warning_epic_id_not_configured'),
        onClick: () => navigate(`/automations/${automationId}?edit=true`),
      });
    }

    return messages;
  }, [
    automation,
    automationId,
    automationPerformance,
    isLoading,
    isLoadingAutomation,
    navigate,
    t,
  ]);
};
