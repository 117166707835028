import { tenantRepo } from '@/constants/providers';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TenantModel } from '../domain';
import { TenantsKeys } from './types';
import { UpdateTenantNameJson } from '../types';

export const useGetTenant = (tenantId?: number) => {
  return useQuery<TenantModel | undefined>(
    [TenantsKeys.TENANT, tenantId],
    () => {
      if (!tenantId) {
        return;
      }
      return tenantRepo.getTenantById(tenantId);
    },
    {
      enabled: !!tenantId,
    },
  );
};

export const useGetUserTenants = () => {
  return useQuery<TenantModel[]>([TenantsKeys.TENANTS_LIST], () =>
    tenantRepo.getTenants(),
  );
};

export const useUpdateTenantName = () =>
  useMutation<number, unknown, UpdateTenantNameJson>(
    tenantRepo.updateTenantName,
  );
