import { Button, Dropdown, FieldInfo, Input, TextArea } from '@/components';
import { CostType } from '@/modules/Infrastructure/enums/cost-type.enum';
import { useInfrastructureCostFormOptions } from '@/modules/Infrastructure/hooks/use- infrastructure-cost-form-options.hook';
import { useUpdateInfrastructureCost } from '@/modules/Infrastructure/queries';
import { InfrastructureCostKey } from '@/modules/Infrastructure/queries/types';
import {
  EditInfrastructureCostSchema,
  EditInfrastructureCostSchemaType,
  InfrastructureCostJson,
} from '@/modules/Infrastructure/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface EditInfrastructureCostFormProps {
  id: number;
  defaultValues: {
    name: string;
    costTypeId: number;
    costLicenseTypeId?: number;
    amountRecurrence: string;
    description: string;
  };
  onClose: () => void;
}

export const EditInfrastructureCostForm = ({
  id,
  defaultValues,
  onClose,
}: EditInfrastructureCostFormProps): ReactElement => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useUpdateInfrastructureCost();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<EditInfrastructureCostSchemaType>({
    defaultValues,
    resolver: zodResolver(EditInfrastructureCostSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
  });

  const costTypeIdValue = watch('costTypeId');

  const {
    dropdownCostLicenseTypeOptions,
    dropdownCostTypeOptions,
    dropdownRecurrenceOptions,
  } = useInfrastructureCostFormOptions();

  const handleConfirm = (data: EditInfrastructureCostSchemaType) => {
    const convertedData: InfrastructureCostJson = {
      id,
      costTypeId: data.costTypeId,
      costLicenseTypeId:
        data.costTypeId == CostType.AutomationSoftware
          ? data.costLicenseTypeId
          : undefined,
      name: data.name,
      amountRecurrence: data.amountRecurrence,
      description: data.description,
      status: true,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_updating_infrastructure_cost'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(InfrastructureCostKey.ALL);
        onClose();
        toast.success(t('success_updating_infrastructure_cost'));
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(handleConfirm)}>
      <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2">
        <FieldInfo
          title={t('cost_name')}
          errorText={errors.name?.message ? t(errors.name.message) : undefined}
          hasError={!!errors.name?.message}
        >
          <Input
            isDisabled={isLoading}
            className="w-full bg-brightGray italic"
            placeHolder={t('cost_name')}
            name="name"
            register={register}
          />
        </FieldInfo>
        <FieldInfo
          title={t('cost_type')}
          errorText={
            errors.costTypeId?.message
              ? t(errors.costTypeId.message)
              : undefined
          }
          hasError={!!errors.costTypeId?.message}
        >
          <Controller
            name="costTypeId"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ...otherFieldProps } }) => (
              <Dropdown
                {...otherFieldProps.onBlur}
                className="h-10 w-full rounded-md bg-brightGray capitalize italic"
                placeholder={t('select_cost_type')}
                options={dropdownCostTypeOptions}
                onSelect={(val) => onChange(val.value)}
                value={value}
              />
            )}
          />
        </FieldInfo>
        {costTypeIdValue === CostType.AutomationSoftware && (
          <FieldInfo
            title={t('cost_license_type')}
            errorText={
              errors.costLicenseTypeId?.message
                ? t(errors.costLicenseTypeId.message)
                : undefined
            }
            hasError={!!errors.costLicenseTypeId?.message}
          >
            <Controller
              name="costLicenseTypeId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...otherFieldProps } }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md bg-brightGray capitalize italic"
                  placeholder={t('select_cost_license_type')}
                  options={dropdownCostLicenseTypeOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={value}
                />
              )}
            />
          </FieldInfo>
        )}
        <FieldInfo
          title={t('recurrence')}
          errorText={
            errors.amountRecurrence?.message
              ? t(errors.amountRecurrence.message)
              : undefined
          }
          hasError={!!errors.amountRecurrence?.message}
        >
          <Controller
            name="amountRecurrence"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ...otherFieldProps } }) => (
              <Dropdown
                {...otherFieldProps.onBlur}
                className="h-10 w-full rounded-md bg-brightGray capitalize italic"
                placeholder={t('select_recurrence')}
                options={dropdownRecurrenceOptions}
                onSelect={(val) => onChange(val.value)}
                value={value}
              />
            )}
          />
        </FieldInfo>
        <FieldInfo
          title={t('description')}
          errorText={
            errors.description?.message
              ? t(errors.description.message)
              : undefined
          }
          hasError={!!errors.description?.message}
        >
          <TextArea
            isDisabled={isLoading}
            className="h-24 w-full bg-brightGray"
            placeHolder={t('optional')}
            name="description"
            register={register}
          />
        </FieldInfo>
      </div>

      <div className="mt-4 flex justify-between">
        <Button
          isDisabled={isLoading}
          onClick={onClose}
          variant="outline"
          className="h-9 w-24"
        >
          {t('cancel')}
        </Button>
        <Button isDisabled={isLoading} buttonType="submit" className="h-9 w-24">
          {t('confirm')}
        </Button>
      </div>
    </form>
  );
};
