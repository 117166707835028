import { Button, FilterSearchBar, Table } from '@/components';
import { AttachUserToProcessModal } from '@/modules/Processes/pages/ProcessesPage/components/AttachUserToProcessModal';
import { CreateProcessModal } from '@/modules/Processes/pages/ProcessesPage/components/CreateProcessModal';
import { getProcessesColumns } from '@/modules/Processes/pages/ProcessesPage/utils';
import { roleColumnConfig } from '@/utils';
import { ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TabContext } from '../TabContainer';
import { TableRow } from '../Table/types';

interface ProcessesTabProps {
  canCreate?: boolean;
  canAttachUser?: boolean;
  userId?: number;
  disableFields?: string[];
  context?: 'default' | 'userDetails';
}

export const ProcessesTab = ({
  canCreate = true,
  canAttachUser,
  userId,
  disableFields,
  context,
}: ProcessesTabProps): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: processes,
    isLoading: isLoadingProcesses,
    isOpen,
    page,
    onPageChange,
    handleSearchChange,
    handleModalState,
    onSort,
  } = useContext(TabContext);

  const routeChange = (row?: TableRow) => {
    const path = `/processes/${row?.id}`;
    navigate(path);
  };

  const columns = useMemo(() => {
    const baseColumns = getProcessesColumns();

    switch (context) {
      case 'userDetails':
        return [...baseColumns, roleColumnConfig()];
      case 'default':
      default:
        return [...baseColumns];
    }
  }, [context]);

  return (
    <>
      <FilterSearchBar
        total={processes?.totalRows || 0}
        title={t('processes')}
        onSearch={handleSearchChange}
        variant={'secondary'}
        otherActions={
          <>
            {canCreate && (
              <Button onClick={handleModalState} variant="primary">
                {t('add')}
              </Button>
            )}
            {canAttachUser && userId && (
              <AttachUserToProcessModal userId={userId} />
            )}
          </>
        }
      />
      <Table
        onRowClick={routeChange}
        columns={columns}
        rows={processes?.items ?? []}
        isLoading={isLoadingProcesses}
        noResultsLabel={t('no_processes_available')}
        withPagination
        totalItems={processes?.totalRows}
        totalPages={processes?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
        onSort={onSort}
      />
      {isOpen && (
        <CreateProcessModal
          isOpen={isOpen}
          handleClose={handleModalState}
          disableFields={disableFields}
        />
      )}
    </>
  );
};
