import { tasksRepo } from '@/constants/providers';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { TaskKey } from './keys';

type UseTaskAutomationsPerformanceProps = {
  taskId: number;
  dateRange?: DateRange;
};

export const useTaskAutomationsPerformance = ({
  taskId,
  dateRange,
}: UseTaskAutomationsPerformanceProps) =>
  useQuery({
    queryKey: TaskKey.AUTOMATIONS_PERFORMANCE(taskId, dateRange),
    queryFn: () => tasksRepo.getAutomationsPerformanceById(taskId, dateRange),
  });

type UseTaskLabourTimeAutomatedProps = {
  taskId: number;
  enabled?: boolean;
  dateRange?: DateRange;
  filter?: string;
};

export const useTaskLabourTimeAutomated = ({
  taskId,
  enabled,
  dateRange,
  filter,
}: UseTaskLabourTimeAutomatedProps) => {
  const result = useInfiniteQuery(
    [TaskKey.LABOUR_AUTOMATED(taskId, dateRange, filter)],
    ({ pageParam = 1 }) =>
      tasksRepo.getTaskLabourTimeAutomatedById(
        taskId,
        dateRange,
        pageParam,
        filter,
      ),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );

  const data = result.data?.pages.flatMap((page) => page) || [];

  return {
    ...result,
    data,
  };
};

type UseTaskTotalReturnProps = {
  taskId: number;
  enabled?: boolean;
  dateRange?: DateRange;
  filter?: string;
};

export const useTaskTotalReturn = ({
  taskId,
  enabled,
  dateRange,
  filter,
}: UseTaskTotalReturnProps) => {
  const result = useInfiniteQuery(
    [TaskKey.TOTAL_RETURN(taskId, dateRange, filter)],
    ({ pageParam = 1 }) =>
      tasksRepo.getTaskTotalReturnById(taskId, dateRange, pageParam, filter),
    {
      enabled,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length < 10 ? undefined : allPages.length + 1;
      },
    },
  );

  const data = result.data?.pages.flatMap((page) => page) || [];

  return {
    ...result,
    data,
  };
};

export const useTaskLastRun = (workflowId: number) =>
  useQuery({
    queryKey: TaskKey.LAST_RUN(workflowId),
    queryFn: () => tasksRepo.getTaskLastRunById(workflowId),
  });

export const useTaskOverallRuns = (workflowId: number) =>
  useQuery({
    queryKey: TaskKey.OVERALL_RUNS(workflowId),
    queryFn: () => tasksRepo.getTaskOverallRunsById(workflowId),
  });
