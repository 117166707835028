import { ExceptionType, PaginationParams } from '@/shared/types';
import { DateRange } from 'react-day-picker';

export abstract class AutomationKey {
  static ALL = ['automations'];
  static LIST = [...AutomationKey.ALL, 'list'];
  static LIST_PAGED = (pagination: PaginationParams) => [
    ...AutomationKey.LIST,
    pagination,
  ];
  static REQUESTSLIST_PAGED = (pagination: PaginationParams) => [
    ...AutomationKey.ALL,
    pagination,
  ];
  static OVERALL_PEFORMANCE = (automationId: number) => [
    ...AutomationKey.BY_ID(automationId),
    'overall-performance',
  ];
  static PERFORMANCE = (automationId: number, dateRange?: DateRange) => [
    ...AutomationKey.BY_ID(automationId),
    'performance',
    dateRange,
  ];
  static BY_ID = (automationId: number) => [
    ...AutomationKey.ALL,
    'by-id',
    automationId,
  ];
  static REQUEST_BY_ID = (requestId: number) => [
    ...AutomationKey.ALL,
    'request-by-id',
    requestId,
  ];
  static TASK_PERFORMANCE = (automationId: number) => [
    ...AutomationKey.ALL,
    'task-performance',
    automationId,
  ];
  static BY_BUSINESS_AREA = (businessArea: number) => [
    ...AutomationKey.ALL,
    'by-business-area',
    businessArea,
  ];
  static BY_BUSINESS_AREA_PAGED = (
    businessArea: number,
    pagination: PaginationParams,
  ) => [...AutomationKey.BY_BUSINESS_AREA(businessArea), pagination];
  static BY_COMPANY = (companyId: number) => [
    ...AutomationKey.ALL,
    'by-company',
    companyId,
  ];
  static BY_COMPANY_PAGED = (
    companyId: number,
    pagination: PaginationParams,
  ) => [...AutomationKey.BY_COMPANY(companyId), pagination];
  static BY_ORGANIZATION = (organizationId: number) => [
    ...AutomationKey.ALL,
    'by-organization',
    organizationId,
  ];
  static BY_ORGANIZATION_PAGED = (
    organizationId: number,
    pagination: PaginationParams,
  ) => [...AutomationKey.BY_ORGANIZATION(organizationId), pagination];
  static BY_TASK = (taskId: number) => [
    ...AutomationKey.ALL,
    'by-task',
    taskId,
  ];
  static BY_TASK_PAGED = (taskId: number, pagination: PaginationParams) => [
    ...AutomationKey.BY_TASK(taskId),
    pagination,
  ];
  static BY_INFRASTRUCTURE_COST = (infrastructureCostId: number) => [
    ...AutomationKey.ALL,
    'by-infrastructure-cost',
    infrastructureCostId,
  ];
  static BY_INFRASTRUCTURE_COST_PAGED = (
    infrastructureCostId: number,
    pagination: PaginationParams,
  ) => [
    ...AutomationKey.BY_INFRASTRUCTURE_COST(infrastructureCostId),
    pagination,
  ];
  static LABOUR_AUTOMATED = (
    automationId: number,
    dateRange?: DateRange,
    filter?: string,
  ) => [
    ...AutomationKey.BY_ID(automationId),
    'labour-automated',
    dateRange,
    filter,
  ];
  static TOTAL_RETURN = (
    automationId: number,
    dateRange?: DateRange,
    filter?: string,
  ) => [
    ...AutomationKey.BY_ID(automationId),
    'total-return',
    dateRange,
    filter,
  ];
  static LAST_RUN = (
    automationId: number,
    filter?: string,
    result?: boolean,
    exceptionType?: ExceptionType,
  ) => [
    ...AutomationKey.BY_ID(automationId),
    'last-run',
    filter,
    result,
    exceptionType,
  ];
  static OVERALL_RUNS = (
    automationId: number,
    dateRange?: DateRange,
    filter?: string,
    result?: boolean,
    exceptionType?: ExceptionType,
  ) => [
    ...AutomationKey.BY_ID(automationId),
    'overall-runs',
    dateRange,
    filter,
    result,
    exceptionType,
  ];
  static INVESTMENT = (
    automationId: number,
    dateRange?: DateRange,
    filter?: string,
    billable?: number,
    type?: number,
  ) => [
    ...AutomationKey.BY_ID(automationId),
    'investment',
    dateRange,
    filter,
    billable,
    type,
  ];
}

export abstract class AutomationStageKey {
  static ALL = ['stages'];
}
