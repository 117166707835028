import { ReactElement, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { CardSkeleton } from '../CardSkeleton';
import { performanceCardTextColors, PerformanceType } from './utils';
import { ExpandIcon } from '../Icons';

export interface PerformanceContent {
  title: string | number | ReactNode;
  extraTitle?: string | number | ReactNode;
  subtitle?: string | number;
  performance?: PerformanceType;
}

export interface PerformanceCardProps {
  title: string;
  content: PerformanceContent[];
  isLoading?: boolean;
  onExpand?: () => void;
}

export const PerformanceCard = ({
  title,
  content,
  isLoading,
  onExpand,
}: PerformanceCardProps): ReactElement => {
  return !isLoading ? (
    <div className="flex flex-1 flex-col gap-1.25 rounded-md border border-transparent bg-white p-2">
      <div className="flex items-center justify-between">
        <div></div>
        <ExpandIcon className="cursor-pointer" onClick={onExpand} />
      </div>
      <h6 className="whitespace-nowrap text-left font-black ">{title}</h6>
      <div
        data-testid="content-list"
        className="flex h-full w-full items-center justify-around"
      >
        {content.map((contentLine, index) => {
          const component = contentLine.subtitle ? (
            <div className="ml-5 flex w-full flex-col">
              <div
                data-testid="content-component"
                className={twMerge(
                  'text-base flex items-center gap-1 font-black',
                  contentLine.performance &&
                    performanceCardTextColors[contentLine.performance],
                )}
              >
                {contentLine.title}{' '}
                {contentLine.extraTitle && (
                  <span className="text-sm">{contentLine.extraTitle}</span>
                )}
              </div>
              <p className="text-sm font-medium text-spartanBlue">
                {contentLine.subtitle}
              </p>
            </div>
          ) : (
            <h1 className="ml-2 w-full">{contentLine.title}</h1>
          );

          return (
            <div className="flex h-full w-full" key={index}>
              {component}
              {index < content.length - 1 && (
                <div className="h-full border-1 text-brightGray"></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <CardSkeleton />
  );
};
