import { useGetChartColorsAndLabel } from '@/hooks';
import { t } from 'i18next';
import { ReactElement } from 'react';
import { Circle } from '../Circle/Circle';

interface OverallRunSuccessRateHeaderProps {
  metrics: {
    totalCases: number;
    totalRuns: number;
    businessErrors: number;
    genericErrors: number;
    successes: number;
  };
}

export function OverallRunSuccessRateHeader({
  metrics,
}: OverallRunSuccessRateHeaderProps): ReactElement {
  const { rateColors } = useGetChartColorsAndLabel();

  return (
    <div className="mb-6 flex flex-wrap gap-4">
      <div className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center">
        <span className="font-extrabold">{metrics.totalCases ?? 0}</span>
        <span className="text-sm text-gray-500">{t('total_cases')}</span>
      </div>
      <div className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center">
        <span className="font-extrabold">{metrics.totalRuns ?? 0}</span>
        <span className="text-sm text-gray-500">{t('total_runs')}</span>
      </div>
      <div
        style={{
          color: rateColors.success,
        }}
        className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center"
      >
        <div className="flex items-baseline gap-1.5">
          <Circle color={rateColors.success} />
          <span className="font-extrabold">
            {metrics.successes ?? 0} (
            {metrics.totalCases
              ? ((metrics.successes / metrics.totalCases) * 100).toFixed(2)
              : '0.00'}
            %)
          </span>
        </div>
        <span className="text-sm text-gray-500">{t('successful_cases')}</span>
      </div>
      <div
        style={{
          color: rateColors.businessError,
        }}
        className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center"
      >
        <div className="flex items-baseline gap-1.5">
          <Circle color={rateColors.businessError} />
          <span className="font-extrabold">
            {metrics.businessErrors ?? 0} (
            {metrics.totalCases
              ? ((metrics.businessErrors / metrics.totalCases) * 100).toFixed(2)
              : '0.00'}
            %)
          </span>
        </div>
        <span className="text-sm text-gray-500">{t('business_errors')}</span>
      </div>
      <div
        style={{
          color: rateColors.genericErrors,
        }}
        className="text-orange-600 flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center"
      >
        <div className="flex items-baseline gap-1.5">
          <Circle color={rateColors.genericErrors} />
          <span className="font-extrabold">
            {metrics.genericErrors ?? 0} (
            {metrics.totalCases
              ? ((metrics.genericErrors / metrics.totalCases) * 100).toFixed(2)
              : '0.00'}
            %)
          </span>
        </div>
        <span className="text-sm text-gray-500">{t('generic_errors')}</span>
      </div>
    </div>
  );
}
