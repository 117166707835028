import { captureException } from '@sentry/react';
import { SigninSilentArgs, User } from 'oidc-client-ts';
import { PropsWithChildren, useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

type RetrySilentSignInProps = {
  signIn: (args?: SigninSilentArgs) => Promise<User | null>;
  retryCount?: number;
};

const retrySilentSignIn = ({
  signIn,
  retryCount = 1,
}: RetrySilentSignInProps): Promise<boolean> => {
  return new Promise<boolean>((res) => {
    setTimeout(
      async () => {
        if (await signIn()) {
          res(true);
          return;
        }

        if (retryCount > 2) {
          res(false);
          return;
        }

        res(retrySilentSignIn({ signIn, retryCount: ++retryCount }));
      },
      Math.pow(retryCount, 2) * 1000,
    );
  });
};

type AuthSilentSessionRenewHandler = PropsWithChildren;

export const AuthSilentSessionRenewHandler = ({
  children,
}: AuthSilentSessionRenewHandler) => {
  const auth = useAuth();

  useEffect(() => {
    return auth.events.addSilentRenewError(async (error) => {
      if (!error) {
        return;
      }

      const result = await retrySilentSignIn({
        signIn: auth.signinSilent,
      });

      if (!result) {
        captureException(error);
      }
    });
  }, [auth]);
  return children;
};
