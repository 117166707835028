import { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { SortProps, TableVariant } from '../../types';
import { variantStyles } from './styles';

interface TableHeadEmptyCellProps extends SortProps {
  variant: TableVariant;
  className?: string;
}

export const TableHeadEmptyCell = ({
  variant,
  className,
}: TableHeadEmptyCellProps): ReactElement => {
  return (
    <th
      scope="col"
      className={twMerge(
        'sticky top-0 z-2 w-auto border-b-2.5 border-transparent bg-inherit text-xs font-black leading-xs',
        variantStyles[variant],
        className,
      )}
    ></th>
  );
};
