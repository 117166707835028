import { Dots } from '@/components/Dots';
import { HandlerIcon } from '@/components/Icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { get } from 'lodash';
import { ReactElement, ReactNode, useMemo, useState } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import { TableColumn, TableRow, TableVariant } from '../../types';
import { alignStyles } from './styles';
interface TableRowCellProps<R extends TableRow> {
  row: R;
  cols: Array<TableColumn<R>>;
  variant?: TableVariant;
  noOutline?: boolean;
  selected?: boolean;
  draggable?: boolean;
  onRowClick?: (row: R) => void;
  hasAction?: boolean;
  highlightRow?: boolean;
  disableRow?: boolean;
  depth?: number;
  isTree?: boolean;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  onUnlink?: (id: number) => void;
  onEdit?: (id: number) => void;
  renderUnlink?: (id?: number, disabled?: boolean) => ReactNode;
  renderEdit?: (id?: number, disabled?: boolean) => ReactNode;
  showDots?: boolean;
  showBlueDot?: boolean;
  onlyCollapse?: boolean;
  noChildDots?: boolean;
  onRowHoverStyling?: string;
  collapseClassname?: string;
}

export const TableRowCell = <R extends TableRow>({
  row,
  cols,
  variant,
  noOutline,
  selected,
  onRowClick,
  draggable,
  highlightRow = false,
  disableRow = false,
  depth = 0,
  isTree,
  isOpen,
  setIsOpen,
  onUnlink,
  onEdit,
  renderEdit,
  renderUnlink,
  showDots,
  showBlueDot = true,
  onlyCollapse,
  collapseClassname,
  noChildDots,
  onRowHoverStyling,
}: TableRowCellProps<R>): ReactElement => {
  const handleOnRowClick = () => onRowClick?.(row);

  const [hover, setHover] = useState(false);

  const { setNodeRef, attributes, listeners, transform, transition } =
    useSortable({ id: row.id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    backgroundColor: 'bg-lightPurple',
  };

  const firstColumn = cols[0];

  const tableDataStyling = useMemo(
    () =>
      twMerge(
        'group/cell m-0 border-collapse border-spacing-0 border-b-4 border-transparent p-0',
      ),
    [],
  );

  const commonStyling = useMemo(() => {
    return twMerge(
      `text-sm text-primaryBlue flex h-11.25 w-full items-center overflow-hidden bg-white`,
      !noOutline &&
        `border-b-1 border-t-1 border-rinseGray group-last/cell:border-r group-last/cell:border-t group-last/cell:rounded-r-md`,
      selected && `border-b-1 border-t-1 border-shinyBlue`,
      !firstColumn.cellSeparator && 'px-2.5',
      variant === 'small' && 'h-8.75',
      !onRowClick && 'cursor-default',
      highlightRow && 'bg-lightPurple',
      disableRow && 'cursor-not-allowed opacity-50',
      hover && !!row.rows?.length && onRowHoverStyling,
    );
  }, [
    disableRow,
    firstColumn.cellSeparator,
    highlightRow,
    hover,
    noOutline,
    onRowHoverStyling,
    onRowClick,
    row.rows?.length,
    selected,
    variant,
  ]);

  const value = get(row, firstColumn.acessorKey ?? firstColumn.id);

  const hasActionsColumn = !!renderUnlink || !!renderEdit;
  return (
    <tr
      onClick={() => setIsOpen?.(!isOpen)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <td
        key={`${firstColumn.id}-item-${row.id ?? ''}`}
        className={twMerge(tableDataStyling, firstColumn.className)}
        align={firstColumn.align ?? 'center'}
      >
        <div className="flex items-center gap-3">
          {(isTree || showDots) && (
            <div className="relative flex h-full flex-row items-center gap-5 pl-2">
              {Array.from(
                { length: depth > 0 && noChildDots ? 1 : depth + 1 },
                (_, index) => (
                  <Dots
                    key={index}
                    showCollapse={index === depth && !!row.rows?.length}
                    isCollapsed={!isOpen}
                    showBlueDot={showBlueDot}
                    onlyCollapse={onlyCollapse}
                    collapseClassName={collapseClassname}
                  />
                ),
              )}
            </div>
          )}
          <div
            key={`${firstColumn.id}-row-${row.id ?? ''}`}
            ref={setNodeRef}
            style={style}
            className={twMerge(
              'rounded-l-md ',
              !noOutline && 'border-l-1',
              commonStyling,
            )}
            onClick={handleOnRowClick}
            tabIndex={0}
            role="button"
          >
            {firstColumn.cellSeparator && (
              <span className="h-[60%] border-l-1 border-spartanBlue" />
            )}
            <div
              className={twJoin(
                'inline-flex w-[inherit] whitespace-nowrap',
                firstColumn.align
                  ? alignStyles[firstColumn.align]
                  : 'justify-center',
              )}
            >
              {firstColumn.render ? firstColumn.render(value, row) : value}
            </div>
            {draggable && cols.length === 1 && (
              <span
                className="flex cursor-pointer"
                {...attributes}
                {...listeners}
              >
                <HandlerIcon /> {/* TODO: Improve styles for the handler */}
              </span>
            )}
          </div>
        </div>
      </td>
      {cols.slice(1).map((column, index) => {
        const value = get(row, column.acessorKey ?? column.id);
        return (
          <td
            key={`${column.id}-item-${row.id ?? ''}`}
            className={twMerge(tableDataStyling, column.className)}
            align={column.align ?? 'center'}
          >
            <div
              key={`${column.id}-row-${row.id ?? ''}`}
              ref={setNodeRef}
              style={style}
              className={commonStyling}
              onClick={handleOnRowClick}
              tabIndex={0}
              role="button"
            >
              {column.cellSeparator && (
                <span className="h-[60%] border-l-1 border-spartanBlue" />
              )}
              <div
                className={twJoin(
                  'inline-flex w-[inherit] whitespace-nowrap',
                  column.align ? alignStyles[column.align] : 'justify-center',
                )}
              >
                {column.render ? column.render(value, row) : value}
              </div>
              {draggable && cols.length === index + 1 && (
                <span
                  className="flex cursor-pointer"
                  {...attributes}
                  {...listeners}
                >
                  <HandlerIcon /> {/* TODO: Improve styles for the handler */}
                </span>
              )}
            </div>
          </td>
        );
      })}
      {/* TODO: Refactor into a normal column that renders a specific component with actions for each table context. The actions column should not be defined explicity here on the TableRowCell component*/}
      {hasActionsColumn && (
        <td className={tableDataStyling}>
          <div className={twMerge('rounded-r-md', commonStyling)}>
            {renderUnlink && (
              <span
                onClick={() => onUnlink && onUnlink(Number(row?.id))}
                className="mr-2"
              >
                {renderUnlink(Number(row?.id), disableRow)}
              </span>
            )}
            {renderEdit && (
              <span onClick={() => onEdit && onEdit(Number(row?.id))}>
                {renderEdit(Number(row?.id), disableRow)}
              </span>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};
