import { mapUserJsonToUserModel } from '@/modules/Users/mappers';
import { workflowsMapper } from '@/modules/Workflows/mappers';
import { mapPagination } from '@/shared/mappers';
import { ProcessAutomationsPeformance, ProcessModel } from '../domain';
import {
  ApiResponseProcesses,
  PagedProcesses,
  ProcessAutomationsPerformanceJson,
  ProcessJson,
} from '../types';

export const mapProcessJsonToProcessModel = (
  processJson?: ProcessJson,
): ProcessModel => ({
  id: processJson?.id ?? 0,
  name: processJson?.name ?? '',
  description: processJson?.description ?? '',
  frequency: processJson?.frequency ?? '',
  weeklyHours: processJson?.weeklyHours ?? 0,
  status: processJson?.status ?? true,
  code: processJson?.code ?? '',
  businessAreaName: processJson?.businessAreaName ?? '',
  organization: processJson?.organization ?? '',
  company: processJson?.company ?? '',
  runTime: processJson?.runTime ?? 0,
  workflowsCount: processJson?.workflowsCount ?? 0,
  tasksCount: processJson?.tasksCount ?? 0,
  workflows: workflowsMapper(processJson?.workflows || []),
  organizationId: processJson?.organizationId ?? 0,
  companyId: processJson?.companyId ?? 0,
  businessAreaId: processJson?.businessAreaId ?? 0,
  predictedManualTime: processJson?.predictedManualTime ?? 0,
  predictedAverageManualTime: processJson?.predictedAverageManualTime ?? 0,
  automationRunTime: processJson?.automationRunTime ?? 0,
  averageAutomationRunTime: processJson?.averageAutomationRunTime ?? 0,
  totalInvestment: processJson?.totalInvestment ?? 0,
  totalReturn: processJson?.totalReturn ?? 0,
  averageCost: processJson?.averageCost ?? 0,
  averageManualCost: processJson?.averageManualCost ?? 0,
  owner: processJson?.owner && mapUserJsonToUserModel(processJson?.owner),
  lastRunSuccessRate: {
    successes: processJson?.executionMetrics?.lastRunTotalSuccessful ?? 0,
    businessErrors:
      processJson?.executionMetrics?.lastRunTotalBusinessExceptions ?? 0,
    genericErrors:
      processJson?.executionMetrics?.lastRunTotalSystemExceptions ?? 0,
  },
  lastRunStartDate: processJson?.executionMetrics?.lastRunStartDate,
  lastRunEndDate: processJson?.executionMetrics?.lastRunEndDate,
  overallSuccessRate: {
    successes: processJson?.executionMetrics?.totalSuccessfulCases ?? 0,
    businessErrors:
      processJson?.executionMetrics?.totalBusinessExceptionsCases ?? 0,
    genericErrors:
      processJson?.executionMetrics?.totalSystemExceptionsCases ?? 0,
  },
  processTimeDistribution: {
    manualTime: processJson?.manualTime ?? 0,
    automatedTime: processJson?.automatedTime ?? 0,
  },
  estimatedCost: processJson?.estimatedCost ?? 0,
  profileType: processJson?.profileType ?? '',
  canDelete: processJson?.canDelete ?? false,
  totalRuns: processJson?.executionMetrics?.totalRuns ?? 0,
});

export const processesWithPaginationMapper = (
  businessAreaJson: ApiResponseProcesses<PagedProcesses>,
) => {
  const { data } = businessAreaJson;
  return {
    items: processesMapper(data.processes.items),
    ...mapPagination(data.processes),
  };
};

export const processesMapper = (
  processesJson: Array<ProcessJson>,
): Array<ProcessModel> => processesJson.map(mapProcessJsonToProcessModel);

export const processAutomationPerformanceMapper = (
  perforrmance: ProcessAutomationsPerformanceJson,
): ProcessAutomationsPeformance => ({
  automationRunTime: perforrmance?.automationRunTime ?? 0,
  totalInvestment: perforrmance?.totalInvestment ?? 0,
  totalReturn: perforrmance?.totalReturn ?? 0,
  averageCost: perforrmance?.averageCost ?? 0,
  predictedManualTime: perforrmance?.predictedManualTime ?? 0,
  totalExecutions: perforrmance?.totalExecutions ?? 0,
});
