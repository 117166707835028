import { PieChartCard } from '@/components';
import { Dev } from '@/core/domain/global-performance-model';
import { useGetChartColorsAndLabel } from '@/hooks';
import { useGetAutomationPerformance } from '@/modules/Automations/queries';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAutomationPerformanceWarningMessages } from '../../../hooks/use-automation-performance-warning-messages';
import { InvestmentDetails } from './InvestmentDetails';

type InvestmentCardProps = {
  automationId: number;
};

export const InvestmentCard = ({ automationId }: InvestmentCardProps) => {
  const { t } = useTranslation();
  const [isInvestmentDetailsOpen, setIsInvestmentDetailsOpen] =
    useState<boolean>();

  const { rateDevInvestChart } = useGetChartColorsAndLabel();

  const { data: automationPerformance, isLoading } =
    useGetAutomationPerformance(automationId);

  const warningMessages = useAutomationPerformanceWarningMessages({
    automationId,
    automationPerformance,
    isLoading,
  });

  const investmentData = useMemo(
    () =>
      Object.entries(rateDevInvestChart).map(([key, value]) => ({
        ...value,
        value: automationPerformance?.investment[key as keyof Dev] ?? 0,
      })),
    [automationPerformance?.investment, rateDevInvestChart],
  );

  return (
    <>
      <PieChartCard
        data={investmentData}
        chartTitle={t('investment')}
        valueType="coin"
        subtitle={t('total_eur')}
        tooltip={t('investment_tooltip_description')}
        onExpand={() => setIsInvestmentDetailsOpen(true)}
        warningMessage={warningMessages}
      />
      {isInvestmentDetailsOpen && (
        <InvestmentDetails
          automationId={automationId}
          onClose={() => setIsInvestmentDetailsOpen(false)}
        />
      )}
    </>
  );
};
