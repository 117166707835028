import { Container, Modal, TabItemProps, Tabs } from '@/components';
import { ProcessModel } from '@/modules/Processes/domain';
import { WorkflowSchemaType } from '@/modules/Workflows';
import { CreateWorkflowModal } from '@/modules/Workflows/pages/WorkflowsPage/components/CreateWorkflowModal';
import { t } from 'i18next';
import { useCallback, useMemo, useState } from 'react';
import { AddWorkflowForm } from '../AddWorkflowForm';

interface ProcessWorkflowModalProps {
  process?: ProcessModel;
  isOpen: boolean;
  handleClose: () => void;
  disableFields?: (keyof WorkflowSchemaType)[];
}

export const ProcessWorkflowModal = ({
  process,
  isOpen,
  handleClose,
}: ProcessWorkflowModalProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs: Array<TabItemProps> = useMemo(
    () => [
      { label: t('create_new_workflow') },
      {
        label: t('associate_workflow'),
      },
    ],
    [],
  );

  const onTabChange = useCallback(
    (tab: number) => {
      setSelectedTab(tab);
    },
    [setSelectedTab],
  );

  const tabList = useMemo(() => {
    if (!process) {
      return [];
    }
    return [
      {
        index: 0,
        tab: (
          <CreateWorkflowModal
            handleClose={handleClose}
            isOpen={isOpen}
            processId={process.id}
            organizationId={process?.organizationId}
            companyId={process?.companyId}
            businessAreaId={process?.businessAreaId}
            disableFields={[
              'processId',
              'organizationId',
              'companyId',
              'businessAreaId',
            ]}
          />
        ),
      },
      {
        index: 1,
        tab: (
          <AddWorkflowForm
            businessAreaId={process?.businessAreaId}
            processId={process.id}
            handleClose={handleClose}
          />
        ),
      },
    ];
  }, [handleClose, process, isOpen]);

  const elementToRender = tabList.find((el) => el.index === selectedTab);

  return (
    <Modal
      isOpen={isOpen}
      title={t('add_workflow')}
      className="h-fit w-128"
      handleClose={handleClose}
    >
      <div className="mt-6 flex flex-col gap-1">
        <Container className="mb-2">
          <Tabs activeTab={selectedTab} tabs={tabs} onTabChange={onTabChange} />
        </Container>
        {process && (
          <div className="flex flex-col gap-2">{elementToRender?.tab}</div>
        )}
      </div>
    </Modal>
  );
};
