import { DEFAULT_PAGE_SIZE, SortDirection } from '@/components/Table/types';
import { HttpClient } from '@/core/http/http-client';
import {
  mapLabourTimeAutomatedList,
  mapLastRunSuccessRateList,
  mapOverallRunsSuccessRateList,
  mapTotalReturnList,
  mapWorklogCostList,
} from '@/shared/mappers/kpi-mapper';
import {
  ApiResponse,
  DataWithPagination,
  ExceptionType,
  LabourTimeAutomatedDetailsJson,
  LastRunSuccessRateDetailsJson,
  OverallRunsSuccessRateDetailsJson,
  TotalReturnDetailsJson,
  WorklogCostDetailsJson,
} from '@/shared/types';
import { isEqual } from 'date-fns';
import { DateRange } from 'react-day-picker';
import {
  AutomationModel,
  AutomationOverallPerformanceModel,
  AutomationRequestDetailsModel,
  AutomationRequestModel,
} from '../domain';
import { AutomationTaskPerformance } from '../domain/associated-tasks-model';
import { StageModel } from '../domain/stage-model';
import {
  automationMapper,
  automationOverallPerformanceMapper,
  automationPerformanceMapper,
  automationRequestDetailsMapper,
  automationRequestsWithPaginationMapper,
  automationsWithPaginationMapper,
  mapAutomationTaskPerformances,
  stagesMapper,
} from '../mappers';
import {
  ApiResponseAutomationRequests,
  ApiResponseAutomations,
  AutomationDetailJson,
  AutomationPerformanceApiResponse,
  AutomationPerformanceJson,
  AutomationRequestDetailJson,
  DeleteAutomationDocumentJson,
  PagedAutomationRequests,
  PagedAutomations,
  UpdateAutomationJson,
  UpdateRequestStageJson,
  UpdateStageJson,
} from '../types';
import { AutomationTaskPerformanceJson } from '../types/associated-tasks-json';
import { StageJson } from '../types/stage-json';
import { AutomationRepository } from './automation.repository';
import { DateUtility } from '@/utils/date.utils';

export class HttpAutomationRepository extends AutomationRepository {
  constructor(private readonly client: HttpClient) {
    super();
  }

  async getAllAutomations(
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<AutomationModel[]>> {
    const response = await this.client.get<
      ApiResponseAutomations<PagedAutomations>
    >({
      url: '/me/automations',
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });
    return automationsWithPaginationMapper(response);
  }

  async getAutomationRequests(
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<AutomationRequestModel[]>> {
    const response = await this.client.get<
      ApiResponseAutomationRequests<PagedAutomationRequests>
    >({
      url: 'requests',
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });
    return automationRequestsWithPaginationMapper(response);
  }

  async getAutomation(id: number): Promise<AutomationModel> {
    const response = await this.client.get<ApiResponse<AutomationDetailJson>>({
      url: `${id}`,
    });
    return automationMapper(response.data.automation);
  }

  async getRequest(id: number): Promise<AutomationRequestDetailsModel> {
    const response = await this.client.get<
      ApiResponse<AutomationRequestDetailJson>
    >({
      url: `requests/${id}`,
    });
    return automationRequestDetailsMapper(response.data.request);
  }

  async getAutomationOverallPerformance(
    automationId: number,
    dateRange?: DateRange,
    page?: number,
    filter?: string,
  ): Promise<AutomationOverallPerformanceModel> {
    const automationPerformanceJson =
      await this.client.get<AutomationPerformanceApiResponse>({
        url: `${automationId}/overall-performance`,
        query: {
          page,
          filter,
          ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
        },
      });

    return automationOverallPerformanceMapper(
      automationPerformanceJson.data.automationPerformance,
    );
  }

  async getAutomationPerformanceById(
    automationId: number,
    dateRange?: DateRange,
  ) {
    const response = await this.client.get<
      ApiResponse<AutomationPerformanceJson>
    >({
      url: `${automationId}/performance`,
      query: {
        fromDate:
          !!dateRange?.from &&
          dateRange?.to &&
          !isEqual(dateRange?.from, dateRange?.to)
            ? dateRange?.from?.toISOString()
            : undefined,
        toDate: dateRange?.to?.toISOString(),
      },
    });

    return automationPerformanceMapper(response.data);
  }

  async getAutomationStages(): Promise<Array<StageModel>> {
    const stagesJson = await this.client.get<Array<StageJson>>({
      url: 'stages',
    });
    return stagesMapper(stagesJson);
  }

  async getTasksPerformanceByAutomationId(
    automationId: number,
  ): Promise<Array<AutomationTaskPerformance>> {
    const associatedTasksJson = await this.client.get<
      ApiResponse<{ tasks: Array<AutomationTaskPerformanceJson> }>
    >({
      url: `${automationId}/tasks/performance`,
    });
    return mapAutomationTaskPerformances(associatedTasksJson.data.tasks);
  }

  createAutomation = async (data: FormData): Promise<number> => {
    return await this.client.post<number>({ body: data });
  };

  async getAutomationsByBusinessArea(
    businessAreaId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<AutomationModel[]>> {
    const automations = await this.client.get<
      ApiResponseAutomations<PagedAutomations>
    >({
      url: `/business-areas/${businessAreaId}/automations`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return automationsWithPaginationMapper(automations);
  }

  async getAutomationsByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<AutomationModel[]>> {
    const automations = await this.client.get<
      ApiResponseAutomations<PagedAutomations>
    >({
      url: `/companies/${companyId}/automations`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return automationsWithPaginationMapper(automations);
  }

  async getAutomationsByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<AutomationModel[]>> {
    const automations = await this.client.get<
      ApiResponseAutomations<PagedAutomations>
    >({
      url: `/organizations/${organizationId}/automations`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return automationsWithPaginationMapper(automations);
  }

  async getAutomationsByTask(
    taskId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<AutomationModel[]>> {
    const automations = await this.client.get<
      ApiResponseAutomations<PagedAutomations>
    >({
      url: `/tasks/${taskId}/automations`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return automationsWithPaginationMapper(automations);
  }

  async getAutomationsByInfrastructureCost(
    infrastructureCostId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<AutomationModel[]>> {
    const automations = await this.client.get<
      ApiResponseAutomations<PagedAutomations>
    >({
      url: `/infrastructure-costs/${infrastructureCostId}/automations`,
      query: {
        pageNumber,
        rowsPerPage: DEFAULT_PAGE_SIZE,
        filter,
        sortBy,
        sortOrder,
      },
    });

    return automationsWithPaginationMapper(automations);
  }

  updateAutomation = async (data: UpdateAutomationJson): Promise<number> => {
    return await this.client.put<number>(`${data.id}`, data);
  };

  updateRequest = async (data: FormData): Promise<number> => {
    return await this.client.put<number>(
      `requests/${data.get('requestId')}`,
      data,
    );
  };

  updateStage = async (data: UpdateStageJson): Promise<unknown> => {
    return await this.client.put<unknown>(`${data.id}/stage`, data);
  };

  updateRequestStage = async (
    data: UpdateRequestStageJson,
  ): Promise<unknown> => {
    return await this.client.put<unknown>(`requests/${data.id}/stage`, data);
  };

  addDocument = async (data: FormData): Promise<unknown> => {
    return await this.client.post<unknown>({
      url: `${data.get('id')}/document`,
      body: data,
    });
  };

  deleteDocument = async (
    data: DeleteAutomationDocumentJson,
  ): Promise<unknown> => {
    return await this.client.delete<unknown>('document', data);
  };

  enableAutomation = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/enable`,
    });
  };

  disableAutomation = async (id: number): Promise<ApiResponse<unknown>> => {
    return await this.client.post({
      url: `${id}/disable`,
    });
  };

  async deleteAutomation(id: number): Promise<ApiResponse<unknown>> {
    return await this.client.delete(`${id}`);
  }

  createAutomationRequest = async (data: FormData): Promise<number> => {
    return await this.client.post<number>({
      url: 'request',
      body: data,
    });
  };

  async getAutomationLabourTimeAutomatedById(
    automationId: number,
    dateRange?: DateRange,
    page?: number,
    filter?: string,
  ) {
    //TODO: Refactor this to not return the same total metrics data for every element
    const response = await this.client.get<
      ApiResponse<LabourTimeAutomatedDetailsJson>
    >({
      url: `${automationId}/labour-time-automated`,
      query: {
        page,
        filter,
        ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
      },
    });

    return mapLabourTimeAutomatedList(
      response.data.executions,
      response.data.totals,
    );
  }

  async getAutomationTotalReturnById(
    automationId: number,
    dateRange?: DateRange,
    page?: number,
    filter?: string,
  ) {
    //TODO: Refactor this to not return the same total metrics data for every element
    const response = await this.client.get<ApiResponse<TotalReturnDetailsJson>>(
      {
        url: `${automationId}/total-return`,
        query: {
          page,
          filter,
          ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
        },
      },
    );

    return mapTotalReturnList(response.data.executions, response.data.totals);
  }

  async getAutomationLastRunById(
    automationId: number,
    page?: number,
    filter?: string,
    result?: boolean,
    exceptionType?: ExceptionType,
  ) {
    const response = await this.client.get<
      ApiResponse<LastRunSuccessRateDetailsJson>
    >({
      url: `${automationId}/last-run`,
      query: {
        page,
        filter,
        result,
        exceptionType,
      },
    });

    return mapLastRunSuccessRateList(response.data.executions);
  }

  async getAutomationOverallRunsById(
    automationId: number,
    dateRange?: DateRange,
    page?: number,
    filter?: string,
    result?: boolean,
    exceptionType?: ExceptionType,
  ) {
    const response = await this.client.get<
      ApiResponse<OverallRunsSuccessRateDetailsJson>
    >({
      url: `${automationId}/overall-runs`,
      query: {
        page,
        filter,
        result,
        ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
        exceptionType,
      },
    });

    return mapOverallRunsSuccessRateList(response.data.executions);
  }

  async getAutomationInvestmentById(
    automationId: number,
    dateRange?: DateRange,
    page?: number,
    filter?: string,
    billable?: number,
    type?: number,
  ) {
    const response = await this.client.get<ApiResponse<WorklogCostDetailsJson>>(
      {
        url: `${automationId}/investment`,
        query: {
          page,
          filter,
          billable,
          type,
          ...DateUtility.extractDateStringQueryParamsFromRange(dateRange),
        },
      },
    );

    return mapWorklogCostList(response.data.worklogs);
  }
}
