import { SortDirection } from '@/components/Table/types';
import { ApiResponse, DataWithPagination } from '@/shared/types';
import {
  AmountModel,
  InfrastructureCostBaseInfoModel,
  InfrastructureCostModel,
} from '../domain/infrastructure-model';
import {
  AttachAutomationInfrastructureCostModel,
  AttachCompanyInfrastructureCostModel,
  AttachOrganizationInfrastructureCostModel,
  CreateAmountDto,
  CreateInfrastructureCost,
  DeleteInfrastructureCostDocumentJson,
  InfrastructureCostJson,
  UpdateAmountDto,
} from '../types';

export abstract class InfrastructureRepository {
  abstract createInfrastructure(
    infrastructureCostJson: CreateInfrastructureCost,
  ): Promise<void>;
  abstract getInfrastructureCosts(
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<InfrastructureCostModel[]>>;
  abstract getInfrastructureCost(id: number): Promise<InfrastructureCostModel>;
  abstract updateInfrastructureCost(
    data: InfrastructureCostJson,
  ): Promise<InfrastructureCostModel>;
  abstract enableInfrastructureCost(id: number): Promise<ApiResponse<unknown>>;
  abstract disableInfrastructureCost(id: number): Promise<ApiResponse<unknown>>;
  abstract attachOrganizationInfrastructureCost(
    data: AttachOrganizationInfrastructureCostModel,
  ): Promise<void>;
  abstract attachCompanyInfrastructureCost(
    data: AttachCompanyInfrastructureCostModel,
  ): Promise<void>;
  abstract getInfrastructureCostsByOrganization(
    organizationId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<InfrastructureCostModel[]>>;
  abstract getInfrastructureCostsByCompany(
    companyId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<InfrastructureCostModel[]>>;
  abstract getInfrastructureCostsByAutomation(
    automationId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<InfrastructureCostModel[]>>;
  abstract getAllInfrastructureCostsByAutomationId(
    automationId: number,
  ): Promise<InfrastructureCostBaseInfoModel[]>;
  abstract attachAutomationInfrastructureCost(
    data: AttachAutomationInfrastructureCostModel,
  ): Promise<void>;
  abstract getInfrastructureCostAmountHistory(
    costId: number,
    pageNumber?: number,
    filter?: string,
    sortBy?: string,
    sortOrder?: SortDirection,
  ): Promise<DataWithPagination<AmountModel[]>>;
  abstract createInfrastructureCostAmount(
    infrastructureCostAmountJson: CreateAmountDto,
  ): Promise<void>;
  abstract updateInfrastructureCostAmount(data: UpdateAmountDto): Promise<void>;
  abstract addDocument(data: FormData): Promise<unknown>;
  abstract deleteDocument(
    data: DeleteInfrastructureCostDocumentJson,
  ): Promise<unknown>;
}
