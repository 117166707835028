import {
  useAutomationPerformance,
  useAutomationTotalReturn,
} from '@/modules/Automations/queries/use-automation-performance';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { TotalReturnCard } from '../../TotalReturnCard';
import { TotalReturnDetails } from '../../TotalReturnDetails';

type AutomationTotalReturnCardProps = {
  automationId: number;
  automationPerformanceDateRange?: DateRange;
};

export const AutomationTotalReturnCard = ({
  automationId,
  automationPerformanceDateRange,
}: AutomationTotalReturnCardProps) => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const [isDetailsOpen, setDetailsOpen] = useState<boolean>(false);

  const { data: automationPerformance, isLoading } = useAutomationPerformance(
    automationId,
    automationPerformanceDateRange,
  );

  const {
    data,
    isLoading: isLoadingTotalReturnAutomated,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useAutomationTotalReturn({
    automationId,
    enabled: isDetailsOpen,
    dateRange,
  });

  const totalReturnAutomated = useMemo(
    () => data?.pages.flatMap((page) => page) || [],
    [data?.pages],
  );

  return (
    <TotalReturnCard
      automationPerformance={automationPerformance}
      isLoading={isLoading}
      setIsDetailsOpen={setDetailsOpen}
      details={
        isDetailsOpen && (
          <TotalReturnDetails
            isLoading={isLoadingTotalReturnAutomated}
            totalReturnAutomated={totalReturnAutomated}
            onClose={() => setDetailsOpen(false)}
            excludeSearchInput
            fetchNextPage={fetchNextPage}
            dateRange={dateRange}
            setDateRange={setDateRange}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
          />
        )
      }
    />
  );
};
