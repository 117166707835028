import { TenantModel } from '../domain';
import { TenantRepository } from './tenant.repository';
import { HttpClient } from '@/core/http/http-client';
import {
  ApiResponseTenant,
  ApiResponseTenants,
  TenantJson,
  UpdateTenantNameJson,
} from '../types';
import { mapTenantJsonToTenantModel, tenantsMapper } from '../mappers';
import { DeleteProfileImageJson } from '@/modules/types';

export class HttpTenantRepository extends TenantRepository {
  constructor(private readonly client: HttpClient) {
    super();
  }

  async getTenants(): Promise<TenantModel[]> {
    const tenants = await this.client.get<ApiResponseTenants<TenantJson[]>>({
      url: `me/by-ids`,
    });

    return tenantsMapper(tenants.data.tenants);
  }

  async getTenantById(tenantId: number): Promise<TenantModel> {
    const tenant = await this.client.get<ApiResponseTenant<TenantJson>>({
      url: `${tenantId}`,
    });

    return mapTenantJsonToTenantModel(tenant.data.tenant);
  }

  updateTenantName = async (data: UpdateTenantNameJson): Promise<number> => {
    return await this.client.patch<number>(`${data.id}/name`, data);
  };

  uploadProfilePicture = async (data: FormData): Promise<unknown> => {
    return await this.client.post<unknown>({
      url: `${data.get('UserId')}/profile-picture`,
      body: data,
    });
  };

  deleteProfilePicture = async (
    data: DeleteProfileImageJson,
  ): Promise<unknown> => {
    return await this.client.delete<unknown>(
      `${data.entityId}/profile-picture/${data.fileId}`,
    );
  };
}
