import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { PageDetailsHeaderRow } from '@/components/PageDetailsHeaderRow/PageDetailsHeaderRow';
import { StatusCard } from '@/components/StatusCard/StatusCard';
import { InfrastructureCostModel } from '@/modules/Infrastructure/domain';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditInfrastructureCostModal } from '../../../InfrastructureCostPage/components/EditInfrastructureCostModal';
import { InfrastructureCostFileCard } from '../InfrastructureCostFileCard';

interface HeaderRowProps {
  infrastructureCost?: InfrastructureCostModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent>,
    id: string,
  ) => void;
  status: boolean;
  isLoading: boolean;
}

export const InfrastructureCostHeaderRow = ({
  infrastructureCost,
  handleStatus,
  status,
  isLoading,
}: HeaderRowProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <div>
      <PageDetailsHeaderRow
        isLoading={!infrastructureCost}
        title={infrastructureCost?.name ?? ''}
        description={infrastructureCost?.costType}
        actions={<StatusCard status={status} handleStatus={handleStatus} />}
        onEditClick={handleModalState}
        details={
          <Container>
            <Card title={t('cost_description')}>
              {infrastructureCost?.description}
            </Card>
            <InfrastructureCostFileCard
              title={t('cost_docs')}
              files={infrastructureCost?.costDocuments}
              isLoading={isLoading}
              id={infrastructureCost?.id}
            ></InfrastructureCostFileCard>
          </Container>
        }
      >
        <Container className="flex-row flex-wrap">
          <Card title={t('recurrence')}>
            {t(infrastructureCost?.amountRecurrence ?? '')}
          </Card>
          <Card title={t('amount')}>
            {<p>{infrastructureCost?.amount.toFixed(2)} €</p>}
          </Card>
        </Container>
      </PageDetailsHeaderRow>
      {isOpen && !!infrastructureCost?.id && (
        <EditInfrastructureCostModal
          id={infrastructureCost.id}
          isOpen={isOpen}
          onClose={handleModalState}
        />
      )}
    </div>
  );
};
