import { queryClient } from '@/constants/query-client';
import { AuthSilentSessionRenewHandler } from '@/modules/Auth/components/AuthSilentSessionRenewHandler';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { WebStorageStateStore } from 'oidc-client-ts';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { App } from './App';
import { EnvironmentVariable } from './constants';
import './index.css';
import keycloakConfig from './keycloak';
import './translations/i18n';

if (EnvironmentVariable.MODE !== 'development') {
  Sentry.init({
    dsn: EnvironmentVariable.SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    environment: EnvironmentVariable.MODE,
    ignoreErrors: ['Non-Error promise rejection captured'],
  });
}

const oidcConfig: AuthProviderProps = {
  authority: `${keycloakConfig.authServerUrl}/realms/${keycloakConfig.realm}`,
  client_id: keycloakConfig.clientId,
  automaticSilentRenew: true,
  redirect_uri: window.location.origin,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <AuthSilentSessionRenewHandler>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
        <ToastContainer />
      </AuthSilentSessionRenewHandler>
    </AuthProvider>
  </React.StrictMode>,
);
