import { t } from 'i18next';
import { getTotalDurationBySeconds } from '.';
import { round } from './math.util';

export const convertTimeMinutesToHours = (milliseconds: number) => {
  const totalSeconds = milliseconds / 1000;
  const totalMinutes = totalSeconds / 60;
  const totalHours = Math.floor(totalMinutes / 60);

  if (totalMinutes < 1) {
    const seconds = Math.round(totalSeconds);
    return { hours: 0, minutes: 0, seconds };
  }

  if (totalHours === 0) {
    return {
      minutes: Math.trunc(totalMinutes),
      seconds: Math.round(totalSeconds % 60),
    };
  }

  const minutesLeft = Math.round(totalMinutes % 60);
  return {
    hours: totalHours,
    minutes: minutesLeft,
  };
};

export const convertSecondsToTimeMinutesToHours = (seconds: number) => {
  const milliseconds = seconds * 1000;
  return convertTimeMinutesToHours(milliseconds);
};

export const convertTimeToString = (time: {
  minutes: number;
  hours?: number;
  seconds?: number;
}) => {
  const hour =
    time.hours !== 0 && time.hours !== undefined ? `${time.hours}h` : '';
  const minute = time.minutes > 0 ? `${time.minutes}m` : '';
  const seconds = time.seconds && time.seconds > 0 ? `${time.seconds}s` : '';

  return [hour, minute, seconds].join(' ').trim();
};

export const convertSecondsToTimeString = (seconds: number) => {
  if (seconds < 1) {
    return '<0s';
  }
  const time = convertSecondsToTimeMinutesToHours(seconds);
  return convertTimeToString(time);
};

export const convertTimeToHoursToString = (time: number) => {
  const timeInHours = convertTimeMinutesToHours(time);

  return convertTimeToString(timeInHours);
};

export const convertMinutesToHours = (minutes: number) => {
  return Math.round(minutes / 60);
};

export const secondsToTimeDurationString = (seconds: number) => {
  const {
    days,
    hours,
    minutes,
    seconds: durationSeconds,
  } = getTotalDurationBySeconds(seconds);
  const totalDays = round(days);
  const totalHours = round(hours);
  const totalMinutes = round(minutes, 0);
  const totalSeconds = round(durationSeconds, 0);

  if (Math.floor(hours / 100) > 0) {
    return `${totalDays} d`;
  }

  if (Math.floor(minutes / 100) > 0) {
    return `${totalHours} h`;
  }

  if (Math.floor(minutes) > 0) {
    return `${totalMinutes} m`;
  }

  return `${totalSeconds} s`;
};

const secondsInMinute = 60;
const secondsInHour = 60 * secondsInMinute;
const secondsInDay = 24 * secondsInHour;
const secondsInMonth = 30 * secondsInDay; // Approximation (30 days in a month)
const secondsInYear = 365 * secondsInDay; // Approximation (365 days in a year)

export const secondsToTimePartials = (
  seconds: number,
): {
  years?: number;
  months?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
} => {
  let years = 0;
  let months = 0;
  let days = 0;
  const nrOfYears = Math.floor(seconds / secondsInYear);
  const nrOfMonths = Math.floor(seconds / secondsInMonth);

  if (nrOfYears > 0 || nrOfMonths > 11) {
    /**
     * Since the metric used to calculate the number of months is 30 days, we may have
     * a case where nrOfMonths = 12 but the nrOfYears = 0, but if 12 months = 1 year
     * we need to force the year to be 1
     */
    years = nrOfMonths === 12 ? 1 : Math.floor(seconds / secondsInYear);
    seconds -= years * secondsInYear;

    months = Math.floor(seconds / secondsInMonth);

    return {
      years,
      months,
    };
  }

  if (nrOfMonths > 0 && nrOfMonths < 12) {
    months = Math.floor(seconds / secondsInMonth);
    seconds -= months * secondsInMonth;

    days = Math.floor(seconds / secondsInDay);

    return {
      months,
      days,
    };
  }

  const nrOfDays = Math.floor(seconds / secondsInDay);

  if (nrOfDays > 0 && nrOfDays < 30) {
    days = Math.floor(seconds / secondsInDay);
    seconds -= days * secondsInDay;

    const hours = Math.floor(seconds / secondsInHour);

    return {
      days,
      hours,
    };
  }

  const nrOfHours = Math.floor(seconds / secondsInHour);

  if (nrOfHours > 0 && nrOfHours < 24) {
    const hours = Math.floor(seconds / secondsInHour);
    seconds -= hours * secondsInHour;

    const minutes = Math.floor(seconds / secondsInMinute);

    return {
      hours,
      minutes,
    };
  }

  const nrOfMinutes = Math.floor(seconds / secondsInMinute);

  if (nrOfMinutes > 0 && nrOfMinutes < 60) {
    const minutes = Math.floor(seconds / secondsInMinute);
    seconds -= minutes * secondsInMinute;

    return {
      minutes,
      seconds,
    };
  }

  return {
    seconds,
  };
};

export const convertTimeSecondsToString = (nrOfSeconds: number) => {
  if (!nrOfSeconds) {
    return `<0${t('second-simplified')}`;
  }

  const { years, months, days, hours, minutes, seconds } =
    secondsToTimePartials(nrOfSeconds);

  if (years) {
    const monthStr =
      (months || 0) > 0 ? `${months}${t('month-simplified')}` : '';
    return `${years}${t('year-simplified')} ${monthStr}`;
  }

  if (months) {
    const daysStr = (days || 0) > 0 ? `${days}${t('day-simplified')}` : '';
    return `${months}${t('month-simplified')} ${daysStr}`;
  }

  if (days) {
    const hourStr = (hours || 0) > 0 ? `${hours}${t('hour-simplified')}` : '';
    return `${days}${t('day-simplified')} ${hourStr}`;
  }

  if (hours) {
    const minuteStr =
      (minutes || 0) > 0 ? `${minutes}${t('minute-simplified')}` : '';
    return `${hours}${t('hour-simplified')} ${minuteStr}`;
  }

  if (minutes) {
    const secondStr =
      (seconds || 0) > 0 ? `${seconds}${t('second-simplified')}` : '';
    return `${minutes}${t('minute-simplified')} ${secondStr}`;
  }

  return `${seconds}${t('second-simplified')}`;
};
