import {
  Button,
  Dropdown,
  FieldInfo,
  FilterBusinessAreaFormGroup,
  Input,
  Modal,
  TextArea,
} from '@/components';
import {
  ProcessSchema,
  ProcessSchemaType,
} from '@/modules/Processes/types/process-schema';
import { useGetUsersBaseInfo } from '@/modules/Users/queries';
import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactElement, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ProcessModalProps {
  handleOnSubmit: (e: ProcessSchemaType) => void;
  handleClose: () => void;
  title: string;
  type: 'create' | 'edit';
  isLoading: boolean;
  isOpen: boolean;
  defaultValues?: {
    processName?: string;
    description?: string;
    businessAreaId?: number;
    processOwnerId?: number;
  };
  disableFields?: string[];
}

export const ProcessModal = ({
  title,
  isOpen,
  type,
  isLoading,
  handleOnSubmit,
  handleClose,
  disableFields,
  defaultValues,
}: ProcessModalProps): ReactElement => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    control,
  } = useForm<ProcessSchemaType>({
    defaultValues,
    resolver: zodResolver(ProcessSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  const onSubmit: SubmitHandler<ProcessSchemaType> = (data) => {
    trigger();
    if (!isValid) {
      return;
    }
    handleOnSubmit(data);
  };

  const { data: users } = useGetUsersBaseInfo();

  const dropdownUsersOptions = useMemo(
    () =>
      getDropdownArray(
        users?.filter(
          (user) => user.status || user.id === defaultValues?.processOwnerId,
        ) ?? [],
      ),
    [defaultValues?.processOwnerId, users],
  );

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      className="h-144 w-128"
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2">
          <FieldInfo
            title={t('process_name')}
            errorText={
              errors.processName?.message
                ? t(errors.processName.message)
                : undefined
            }
            hasError={!!errors.processName?.message}
          >
            <Input
              isDisabled={isLoading}
              className="w-full bg-brightGray italic"
              placeHolder={t('process_name')}
              name="processName"
              register={register}
            />
          </FieldInfo>
          <FilterBusinessAreaFormGroup<ProcessSchemaType>
            disableFields={disableFields}
            errors={errors}
            control={control}
          />
          <FieldInfo
            title={t('process_owner')}
            errorText={
              errors.processOwnerId?.message
                ? t(errors.processOwnerId.message)
                : undefined
            }
            hasError={!!errors.processOwnerId?.message}
          >
            <Controller
              name="processOwnerId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, ...otherFieldProps } }) => (
                <Dropdown
                  {...otherFieldProps.onBlur}
                  className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                  placeholder={t('process_owner')}
                  options={dropdownUsersOptions}
                  onSelect={(val) => onChange(val.value)}
                  value={value}
                />
              )}
            />
          </FieldInfo>
          <FieldInfo
            title={t('description')}
            errorText={
              errors.description?.message
                ? t(errors.description.message)
                : undefined
            }
            hasError={!!errors.description?.message}
          >
            <TextArea
              isDisabled={isLoading}
              className="h-24 w-full bg-brightGray"
              placeHolder={t('description')}
              name="description"
              register={register}
            />
          </FieldInfo>
        </div>
        <div className="mt-4 flex justify-between">
          <Button
            isDisabled={isLoading}
            onClick={handleClose}
            variant="outline"
            className="h-9 w-24"
          >
            {t('cancel')}
          </Button>
          <Button
            isDisabled={isLoading}
            buttonType="submit"
            className="h-9 w-24"
          >
            {type === 'create' ? t('create') : t('confirm')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
