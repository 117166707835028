import { useDebounced } from '@/hooks';
import {
  KeyboardEvent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { twJoin, twMerge } from 'tailwind-merge';
import { Button } from '../Button';
import { CrossDelete, SearchIcon } from '../Icons';
import { Input } from '../Input';

interface FilterSearchBarProps {
  title?: string;
  total?: number;
  isLoading?: boolean;
  onSearch: (value: string) => void;
  variant?: 'primary' | 'secondary';
  otherActions?: ReactNode;
  showTitle?: boolean;
}

export const FilterSearchBar = ({
  total = 0,
  title = '',
  isLoading,
  onSearch,
  children,
  variant = 'primary',
  otherActions,
  showTitle = true,
}: PropsWithChildren<FilterSearchBarProps>): ReactElement => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const searchTermDebounced = useDebounced(search, 500) as string;

  const handleKeyPressed = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') {
        return;
      }

      onSearch(search);
    },
    [onSearch, search],
  );

  useEffect(() => {
    onSearch(searchTermDebounced);
  }, [searchTermDebounced, onSearch]);

  return (
    <div className="flex w-full justify-between py-2.5">
      {showTitle && (
        <div className="flex items-center gap-2 text-ml font-bold text-blueNuit">
          <div
            data-testid="total-section"
            className={twMerge(
              'flex h-8.75 min-h-8.75 min-w-8.75 items-center justify-center rounded-ml bg-rinseGray px-1.25',
              variant === 'secondary' && 'bg-brightGray',
            )}
          >
            {total}
          </div>
          <div className="flex h-full items-center self-end align-middle font-black">
            {title}
          </div>
        </div>
      )}
      <div className="flex justify-end gap-4">
        <div className="relative flex items-center gap-3">
          {children}
          <Input
            onKeyUp={handleKeyPressed}
            value={search}
            type="text"
            isDisabled={isLoading}
            name="key_words"
            className={twJoin(
              'h-10 w-36 rounded-md p-2',
              variant === 'primary' ? 'bg-white' : 'bg-brightGray',
            )}
            placeHolder={t('key_words')}
            onChange={(event) => setSearch(event.target.value)}
          />
          {!!search && (
            <div
              className="pointer-events-auto absolute right-2  flex h-auto cursor-pointer items-center justify-center pl-3 hover:cursor-pointer"
              onClick={() => setSearch('')}
            >
              <CrossDelete className="text-[#191B23]" />
            </div>
          )}
        </div>
        <Button
          variant="text"
          onClick={() => onSearch(search)}
          className="flex cursor-pointer rounded-md bg-darkBlue p-2"
        >
          <SearchIcon className="text-white" />
        </Button>
        {otherActions}
      </div>
    </div>
  );
};
