import { Button, FieldInfo, Input, Modal } from '@/components';
import { queryClient } from '@/constants';
import { TenantSchema, TenantSchemaType } from '@/modules';
import { TenantModel } from '@/modules/Tenants/domain';
import { useUpdateTenantName } from '@/modules/Tenants/queries';
import { TenantsKeys } from '@/modules/Tenants/queries/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactElement, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface EditTenantNameModalProps {
  handleClose: () => void;
  isOpen: boolean;
  tenant?: TenantModel;
}
export const EditTenantNameModal = ({
  handleClose,
  isOpen,
  tenant,
}: EditTenantNameModalProps): ReactElement => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<TenantSchemaType>({
    resolver: zodResolver(TenantSchema),
    defaultValues: {
      name: tenant?.name,
    },
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
  });

  useEffect(() => {
    setValue('name', tenant?.name || '');
  }, [tenant?.name, setValue]);

  const { mutate } = useUpdateTenantName();

  const onSubmit: SubmitHandler<TenantSchemaType> = (data) => {
    const convertedData = {
      id: tenant?.id,
      name: data.name,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_update_tenant'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([TenantsKeys.TENANT]);
        queryClient.invalidateQueries([TenantsKeys.TENANTS_LIST]);
        handleClose();
        toast.success(t('success_update_tenant'));
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('change_tenant_name')}
      className="w-128"
      handleClose={handleClose}
    >
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-2 flex flex-col overflow-y-auto p-2">
            <FieldInfo
              title={t('tenant_name')}
              errorText={
                errors.name?.message ? t(errors.name.message) : undefined
              }
              hasError={!!errors.name?.message}
            >
              <Input
                className="w-full bg-brightGray italic"
                placeHolder={t('tenant_name')}
                name="name"
                register={register}
              />
            </FieldInfo>
          </div>
          <div className="mt-4 flex justify-between">
            <Button
              onClick={handleClose}
              variant="outline"
              className="h-9 w-24"
            >
              {t('cancel')}
            </Button>
            <Button buttonType="submit" className="h-9 w-24">
              {t('confirm')}
            </Button>
          </div>
        </form>
      </>
    </Modal>
  );
};
