import { Button, FilterSearchBar, Table } from '@/components';
import { CreateTaskModal } from '@/modules/Tasks/pages/TasksPage/components/CreateTaskModal';
import {
  automatedColumnConfig,
  businessAreaNameColumnConfig,
  codeColumnConfig,
  estimatedCostColumnConfig,
  nameWithAvatarColumnConfig,
  periodColumnConfig,
  roleColumnConfig,
  statusColumnConfig,
  usersCountColumnConfig,
  workloadColumnConfig,
} from '@/utils';
import { ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TabContext } from '../TabContainer';
import { TableRow } from '../Table/types';

interface TasksTabProps {
  canCreate?: boolean;
  disableFields?: string[];
  context?: 'default' | 'userDetails';
}

export const TasksTab = ({
  canCreate,
  disableFields,
  context,
}: TasksTabProps): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: tasks,
    isLoading: isLoadingTasks,
    isOpen,
    page,
    onPageChange,
    handleSearchChange,
    handleModalState,
    onSort,
  } = useContext(TabContext);

  const routeChange = (row?: TableRow) => {
    const path = `/tasks/${row?.id}`;
    navigate(path);
  };

  const columns = useMemo(() => {
    const baseColumns = [
      nameWithAvatarColumnConfig({}),
      codeColumnConfig(),
      businessAreaNameColumnConfig(),
      statusColumnConfig(),
      usersCountColumnConfig(),
      periodColumnConfig(),
      workloadColumnConfig(),
      estimatedCostColumnConfig(),
      automatedColumnConfig(),
    ];

    switch (context) {
      case 'userDetails':
        return [...baseColumns, roleColumnConfig()];
      case 'default':
      default:
        return [...baseColumns];
    }
  }, [context]);

  return (
    <>
      <div className="center flex flex-row">
        <FilterSearchBar
          total={tasks?.totalRows || 0}
          title={t('tasks')}
          onSearch={handleSearchChange}
          variant={'secondary'}
          otherActions={
            <>
              {canCreate && (
                <div className="flex items-center">
                  <Button onClick={handleModalState} variant="primary">
                    {t('add')}
                  </Button>
                </div>
              )}
            </>
          }
        />
      </div>
      <CreateTaskModal
        isOpen={isOpen}
        handleClose={handleModalState}
        disableFields={disableFields}
      />
      <Table
        onRowClick={routeChange}
        columns={columns}
        rows={tasks?.items ?? []}
        isLoading={isLoadingTasks}
        noResultsLabel={t('no_tasks_available')}
        withPagination
        totalItems={tasks?.totalRows}
        totalPages={tasks?.totalPages}
        currentPage={page}
        onSort={onSort}
        onPageChange={onPageChange}
      />
    </>
  );
};
