import {
  Button,
  FilterSearchBar,
  TabItemProps,
  Table,
  TablePaginationContext,
} from '@/components';
import { TableSort } from '@/components/Table/types';
import { RoutePath } from '@/core/router';
import { useToggleAutomationStatus } from '@/modules/Automations/hooks/use-toggle-automation-status';
import { useGetAutomations } from '@/modules/Automations/queries';
import { useDeleteAutomation } from '@/modules/Automations/queries/delete-automation';
import { AutomationKey } from '@/modules/Automations/queries/keys';
import { BusinessAreasKey } from '@/modules/BusinessAreas/queries/keys';
import { CompanyKeys } from '@/modules/Companies/queries';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { ProcessKey } from '@/modules/Processes/queries';
import { TaskKey } from '@/modules/Tasks/queries';
import { WorkflowKey } from '@/modules/Workflows/queries';
import { useQueryClient } from '@tanstack/react-query';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateAutomationModal } from '../../AutomationsPage/components/CreateAutomationModal';
import {
  getAutomationColumnsNamedMapped,
  getAutomationsColumns,
} from '../../AutomationsPage/utils';
import { ManageAutomationsTab } from '../types';
import { ManageAutomationsHeader } from './ManageAutomationsHeader';

type AutomationsTabProps = {
  activeTab: number;
  tabs: TabItemProps[];
  onTabChange: (index: number) => void;
};

export const AutomationsTab = ({
  activeTab,
  tabs,
  onTabChange,
}: AutomationsTabProps) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const [isAutomationOpen, setIsAutomationOpen] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { onChange: onAutomationStatusChange } = useToggleAutomationStatus();

  const { mutate: deleteAutomation, isLoading: isLoadingDelete } =
    useDeleteAutomation();

  const handleDelete = (automationId: number) => {
    deleteAutomation(automationId, {
      onError: () => {
        toast.error(t('error_deleting_automation'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(AutomationKey.ALL);
        queryClient.invalidateQueries(TaskKey.ALL);
        queryClient.invalidateQueries(WorkflowKey.ALL);
        queryClient.invalidateQueries(ProcessKey.ALL);
        queryClient.invalidateQueries(BusinessAreasKey.ALL);
        queryClient.invalidateQueries(CompanyKeys.ALL);
        queryClient.invalidateQueries(OrganizationKeys.ALL);
        toast.success(t('success_deleting_automation'));
      },
    });
  };

  const { page, onPageChange } = useContext(TablePaginationContext);
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const { data: automations, isFetching: isLoadingAutomations } =
    useGetAutomations(
      page + 1,
      search,
      getAutomationColumnsNamedMapped(tableSort?.[0]?.columnId ?? 'name'),
      tableSort?.[0]?.direction ?? 'ascending',
      {
        enabled: activeTab === ManageAutomationsTab.Automation,
      },
    );

  tabs[ManageAutomationsTab.Automation].badgeNumber = automations?.totalRows;

  return (
    <>
      <div>
        <ManageAutomationsHeader
          activeTab={activeTab}
          tabs={tabs}
          onTabChange={onTabChange}
        >
          <FilterSearchBar
            variant="secondary"
            onSearch={setSearch}
            otherActions={
              <Button
                onClick={() => setIsAutomationOpen(true)}
                variant="primary"
                className="px-5 py-2.5"
              >
                {t('add')}
              </Button>
            }
            showTitle={false}
          />
        </ManageAutomationsHeader>
        <div className="py-5">
          <Table
            columns={getAutomationsColumns({
              onClick: (row) =>
                row?.id &&
                navigate(RoutePath.automationDetail(row.id.toString())),
              disableDeleteMessage: t('delete_automation'),
              onDeleteClick(row) {
                handleDelete(row?.id as number);
              },
              onStatusChange: (checked, row) =>
                row?.id && onAutomationStatusChange(checked, +row.id),
            })}
            rows={automations?.items ?? []}
            isLoading={isLoadingAutomations || isLoadingDelete}
            noResultsLabel={t('no_automations_available')}
            withPagination
            totalItems={automations?.totalRows}
            totalPages={automations?.totalPages}
            currentPage={page}
            onPageChange={onPageChange}
            onSort={setTableSort}
          />
        </div>
      </div>
      {isAutomationOpen && (
        <CreateAutomationModal
          handleClose={() => setIsAutomationOpen(false)}
          isOpen={isAutomationOpen}
        />
      )}
    </>
  );
};
