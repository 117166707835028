import { SelectDropdownOption } from '@/components';
import { SelectHierarchicalDropdownOption } from '@/components/HierarchicalDropdown';

type Option<T> = {
  name: string;
  parentId?: number;
  id: T;
};

export const getDropdownArray = <T>(
  data: Option<T>[],
): Array<SelectDropdownOption<T>> => {
  return data.map((val) => ({
    label: val.name,
    value: val.id,
  }));
};

export const getHierarchicalDropdownArray = <T>(
  data: Option<T>[],
): Array<SelectHierarchicalDropdownOption<T>> => {
  return data.map((val) => ({
    label: val.name,
    value: val.id,
    parentId: val.parentId ?? 0,
  }));
};
