import { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { ArrowCollapse, BlueDot, GreyDot } from '../Icons';

type DotsProps = {
  onClick?: () => void;
  showCollapse?: boolean;
  isCollapsed?: boolean;
  showBlueDot?: boolean;
  onlyCollapse?: boolean;
  collapseClassName?: string;
};

export const Dots = ({
  showCollapse,
  onClick,
  isCollapsed,
  showBlueDot,
  onlyCollapse,
  collapseClassName,
}: DotsProps): ReactElement => {
  return (
    <div className="flex h-full flex-col items-center gap-0.2">
      {!onlyCollapse && (
        <>
          <GreyDot />
          <GreyDot />
          {showBlueDot ? <BlueDot /> : <GreyDot />}
          <GreyDot />
          <GreyDot />
        </>
      )}
      {showCollapse && (
        <ArrowCollapse
          className={twMerge(
            'absolute size-5 translate-y-[calc(50%+0.1rem)] cursor-pointer transition-all',
            isCollapsed && 'rotate-180',
            collapseClassName,
          )}
          onClick={onClick}
        />
      )}
    </div>
  );
};
