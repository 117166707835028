import { TableColumn, TableRow } from '@/components/Table/types';
import { Trans } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

type RequestStatusColumnConfig<T extends TableRow> = {
  options?: Omit<TableColumn<T>, 'id' | 'label'>;
};

export const requestStatusColumnConfig = <T extends TableRow>({
  options,
}: RequestStatusColumnConfig<T> = {}): TableColumn<T> => ({
  id: 'requestStage',
  label: <Trans i18nKey="status" />,
  render: (value) => {
    const statusClass = twMerge(
      'overflow-hidden text-ellipsis px-2 py-1 rounded text-sm font-bold',
      value === 'Submitted' && 'bg-yellow-100 text-yellow-500',
      (value === 'Rejected' || value === 'Declined' || value === 'Canceled') &&
        'bg-red-100 text-red-500',
      (value === 'Analysed' || value === 'Approved') &&
        'bg-green-100 text-green-500',
      value === 'In Review' && 'bg-blue-100 text-blue-500',
      value === 'Blocked' && 'text-black-500 bg-gray-100',
    );

    return <div className={statusClass}>{value}</div>;
  },
  acessorKey: 'requestStage',
  sortable: true,
  className: 'max-w-[20vw] w-[20vw]',
  ...options,
});
