import { Button, FilterSearchBar, Table } from '@/components';
import { AttachEntityToInfrastructureCostModal } from '@/modules/Infrastructure/pages/InfrastructureCostPage/components/AttachEntityToInfrastructureCostModal';
import { AttachUserToOrganizationModal } from '@/modules/Organizations/pages/OrganizationsPage/components/AttachUserToOrganizationModal';
import {
  companiesCountColumnConfig,
  nameColumnConfig,
  roleColumnConfig,
  statusColumnConfig,
  usersCountColumnConfig,
} from '@/utils';
import { ReactElement, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TabContext } from '../TabContainer';
import { TableRow } from '../Table/types';

interface OrganizationsTabProps {
  canAttachUser?: boolean;
  canCreate?: boolean;
  canAttachCost?: boolean;
  userId?: number;
  costId?: number;
  context?: 'default' | 'userDetails';
}

export const OrganizationsTab = ({
  canAttachUser,
  canCreate,
  canAttachCost,
  costId,
  userId,
  context,
}: OrganizationsTabProps): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: organizations,
    isLoading: isLoadingOrganizations,
    page,
    onPageChange,
    handleSearchChange,
  } = useContext(TabContext);

  const routeChange = (row?: TableRow) => {
    const path = `/organizations/${row?.id}`;
    navigate(path);
  };

  const columns = useMemo(() => {
    const baseColumns = [
      statusColumnConfig(),
      usersCountColumnConfig(),
      companiesCountColumnConfig(),
    ];

    switch (context) {
      case 'userDetails':
        return [nameColumnConfig(), ...baseColumns, roleColumnConfig()];
      case 'default':
      default:
        return [nameColumnConfig({}), ...baseColumns];
    }
  }, [context]);

  return (
    <>
      <FilterSearchBar
        total={organizations?.totalRows || 0}
        title={t('organizations')}
        onSearch={handleSearchChange}
        variant={'secondary'}
        otherActions={
          <>
            {canCreate && (
              <Button /*onClick={} TODO: add action*/ variant="primary">
                {t('add')}
              </Button>
            )}
            {canAttachUser && userId && (
              <AttachUserToOrganizationModal userId={userId} />
            )}
            {canAttachCost && costId && (
              <AttachEntityToInfrastructureCostModal
                costId={costId}
                context="organization"
              />
            )}
          </>
        }
      />
      <Table
        onRowClick={routeChange}
        columns={columns}
        rows={organizations?.items ?? []}
        isLoading={isLoadingOrganizations}
        noResultsLabel={t('no_companies_available')}
        withPagination
        totalItems={organizations?.totalRows}
        totalPages={organizations?.totalPages}
        currentPage={page}
        onPageChange={onPageChange}
      />
    </>
  );
};
