import { PeriodicityColumn } from '@/components/PeriodicityColumn/PeriodicityColumn';
import { TableColumn, TableRow } from '@/components/Table/types';
import { Periodicity } from '@/modules/Tasks/types/periodicity';
import { Trans } from 'react-i18next';
export const periodColumnConfig = <T extends TableRow>(
  options?: Omit<TableColumn<T>, 'id' | 'label'>,
  className = 'max-w-[20vw] w-[20vw]',
): TableColumn<T> => ({
  id: 'period',
  label: <Trans i18nKey="period" />,
  render: (value: Periodicity) => <PeriodicityColumn periodicity={value} />,
  acessorKey: 'periodicityId',
  sortable: true,
  className,
  ...options,
});
