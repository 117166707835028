import { Container, Modal, TabItemProps, Tabs } from '@/components';
import { useTaskCreateSubmission } from '@/modules/Tasks/hooks/use-task-create-submit.hook';
import { CreateTaskForm } from '@/modules/Tasks/pages/TasksPage/components/TaskForm';
import { AddTaskForm } from '@/modules/Workflows/pages/WorkflowDetailsPage/components/AddTaskForm';
import { t } from 'i18next';
import { useCallback, useMemo, useState } from 'react';

interface WorkflowTaskModalProps {
  id: number;
  businessAreaId: number;
  isOpen: boolean;
  handleClose: () => void;
}

export const WorkflowTaskModal = ({
  id,
  businessAreaId,
  isOpen,
  handleClose,
}: WorkflowTaskModalProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs: Array<TabItemProps> = useMemo(
    () => [
      { label: t('create_new_task') },
      {
        label: t('associate_task'),
      },
    ],
    [],
  );

  const onTabChange = useCallback(
    (tab: number) => {
      setSelectedTab(tab);
    },
    [setSelectedTab],
  );

  const { submit, isLoading } = useTaskCreateSubmission({
    onSuccess: handleClose,
  });

  const tabList = useMemo(() => {
    return [
      <CreateTaskForm
        key={0}
        handleClose={handleClose}
        handleOnSubmit={submit}
        isLoading={isLoading}
        disableFields={[
          'organizationId',
          'companyId',
          'businessAreaId',
          'workflowId',
        ]}
        defaultValues={{
          businessAreaId: businessAreaId,
          workflowId: id,
        }}
      />,
      <AddTaskForm
        key={1}
        businessAreaId={businessAreaId}
        workflowId={id}
        handleClose={handleClose}
      />,
    ];
  }, [handleClose, submit, isLoading, businessAreaId, id]);

  return (
    <Modal
      isOpen={isOpen}
      title={t('add_task')}
      className="h-fit w-128"
      handleClose={handleClose}
    >
      <div className="mt-6 flex flex-col gap-1">
        <Container className="mb-2">
          <Tabs activeTab={selectedTab} tabs={tabs} onTabChange={onTabChange} />
        </Container>
        <div className="flex flex-col gap-2">{tabList[selectedTab]}</div>
      </div>
    </Modal>
  );
};
