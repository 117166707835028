import { FilterSearchBar } from '@/components';
import { ReactElement, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { TabContext } from '../TabContainer';
import {
  LoadingRow,
  NoDataCell,
  TableHead,
  TablePagination,
} from '../Table/components';
import { useTablePagination } from '../Table/hooks';
import { DEFAULT_PAGE_SIZE, TableRow } from '../Table/types';
import { ProcessRow } from './components/ProcessRow';
import {
  getProcessesColumns,
  getTasksColumns,
  getWorkflowsColumns,
  mapProcessesModelIntoProcessWithWorkflows,
} from './utils';

interface ProcessesWithWorkflowsTabProps {
  context?: 'organization' | 'company' | 'businessArea';
}

export const ProcessesWithWorkflowsTab = ({
  context,
}: ProcessesWithWorkflowsTabProps): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    data: processes,
    isLoading: isLoadingProcesses,
    page,
    onPageChange,
    handleSearchChange,
  } = useContext(TabContext);

  const onProcessRowClick = useCallback(
    (row?: TableRow) => {
      const path = `/processes/${row?.id}`;
      navigate(path);
    },
    [navigate],
  );

  const onWorkflowRowClick = useCallback(
    (row?: TableRow) => {
      const path = `/workflows/${row?.id}`;
      navigate(path);
    },
    [navigate],
  );

  const onTaskRowClick = useCallback(
    (row?: TableRow) => {
      const path = `/tasks/${row?.id}`;
      navigate(path);
    },
    [navigate],
  );

  const {
    pageIndex,
    nextDisabled,
    prevDisabled,
    handleNextPage,
    handlePrevPage,
    handlePageChange,
  } = useTablePagination(page, processes?.totalPages, onPageChange);

  const mappedProcesses = useMemo(
    () => mapProcessesModelIntoProcessWithWorkflows(processes?.items),
    [processes],
  );

  const processColumns = getProcessesColumns({
    context,
    onClick: onProcessRowClick,
  });
  const workflowColumns = getWorkflowsColumns({
    onClick: onWorkflowRowClick,
  });
  const taskColumns = getTasksColumns({
    onClick: onTaskRowClick,
  });

  return (
    <>
      <FilterSearchBar
        total={processes?.totalRows || 0}
        title={t('processes')}
        onSearch={handleSearchChange}
        variant={'secondary'}
      />
      <div className="flex h-auto">
        <div
          className={twMerge(
            'h-full border-2 text-brightGray',
            (isLoadingProcesses || mappedProcesses.length === 0) && 'hidden',
          )}
        ></div>
        <table>
          <thead className="bg-inherit">
            <TableHead columns={processColumns} variant="medium" isTree />
          </thead>
          <tbody>
            {isLoadingProcesses &&
              Array.from({ length: DEFAULT_PAGE_SIZE }).map((_, rowIndex) => (
                <LoadingRow
                  key={`loading-${rowIndex}`}
                  rowIndex={rowIndex}
                  columnsCount={processColumns.length}
                />
              ))}
            {!isLoadingProcesses && mappedProcesses.length === 0 ? (
              <NoDataCell
                colSpan={processColumns.length}
                variant={'medium'}
                noResultsLabel={t('no_processes_available')}
              />
            ) : (
              mappedProcesses?.map((rowProcess) => (
                <ProcessRow
                  key={rowProcess.id}
                  process={rowProcess}
                  processColumns={processColumns}
                  workflowsColumns={workflowColumns}
                  taskColumns={taskColumns}
                  onRowHoverStyling="transition-colors bg-lightPurple"
                  collapseClassname="translate-y-0 right-0 top-[-9px]"
                />
              ))
            )}
          </tbody>
        </table>
      </div>
      <TablePagination
        pageIndex={pageIndex}
        nextDisabled={nextDisabled}
        prevDisabled={prevDisabled}
        totalPages={processes?.totalPages}
        totalItems={processes?.totalRows}
        onPageChange={handlePageChange}
        onPrevPage={handlePrevPage}
        onNextPage={handleNextPage}
      />
    </>
  );
};
