import { Circle } from '@/components/Circle/Circle';
import { formatNumber, getTotalDurationBySeconds, trunc } from '@/utils';
import { useTranslation } from 'react-i18next';

type DevTimeMetricsProps = {
  metrics: {
    totalTimeSpentSeconds: number;
    totalTimeSpentSecondsMaintenance: number;
    totalTimeSpentSecondsDevelopment: number;
    totalTimeSpentSecondsSupport: number;
    maintenanceTimePercentage: number;
    developmentTimePercentage: number;
    supportTimePercentage: number;
  };
  devTimeChartColors: {
    maintenanceColor: string;
    developmentColor: string;
    supportColor: string;
  };
};

export const DevTimeMetrics = ({
  metrics: {
    totalTimeSpentSeconds,
    totalTimeSpentSecondsMaintenance,
    totalTimeSpentSecondsDevelopment,
    totalTimeSpentSecondsSupport,
    maintenanceTimePercentage,
    developmentTimePercentage,
    supportTimePercentage,
  },
  devTimeChartColors,
}: DevTimeMetricsProps) => {
  const { t } = useTranslation();
  return (
    <div className="mb-6 flex flex-wrap gap-4">
      <div className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center">
        <span className="font-extrabold">
          {trunc(getTotalDurationBySeconds(totalTimeSpentSeconds).hours, 2)}h
        </span>
        <span className="text-sm text-gray-500">{t('total_time')}</span>
      </div>
      <div
        style={{
          color: devTimeChartColors.maintenanceColor,
        }}
        className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center"
      >
        <div className="flex items-baseline gap-1.5">
          <Circle color={devTimeChartColors.maintenanceColor} />
          <span className="font-extrabold">
            {trunc(
              getTotalDurationBySeconds(totalTimeSpentSecondsMaintenance).hours,
              2,
            )}
            h ({formatNumber(maintenanceTimePercentage)}
            %)
          </span>
        </div>
        <span className="text-sm text-gray-500">{t('maintenance_time')}</span>
      </div>
      <div
        style={{
          color: devTimeChartColors.developmentColor,
        }}
        className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center"
      >
        <div className="flex items-baseline gap-1.5">
          <Circle color={devTimeChartColors.developmentColor} />
          <span className="font-extrabold">
            {trunc(
              getTotalDurationBySeconds(totalTimeSpentSecondsDevelopment).hours,
              2,
            )}
            h ({formatNumber(developmentTimePercentage)}
            %)
          </span>
        </div>
        <span className="text-sm text-gray-500">{t('development_time')}</span>
      </div>
      <div
        style={{
          color: devTimeChartColors.supportColor,
        }}
        className="flex h-20 flex-1 flex-col items-start justify-center border-l-2 pl-3 text-center"
      >
        <div className="flex items-baseline gap-1.5">
          <Circle color={devTimeChartColors.supportColor} />
          <span className="font-extrabold">
            {trunc(
              getTotalDurationBySeconds(totalTimeSpentSecondsSupport).hours,
              2,
            )}
            h ({formatNumber(supportTimePercentage)}%)
          </span>
        </div>
        <span className="text-sm text-gray-500">{t('support_time')}</span>
      </div>
    </div>
  );
};
