import { SortDirection } from '@/components/Table/types';
import { automationsRepo } from '@/constants/providers';
import { DataWithPagination } from '@/shared/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AutomationRequestModel } from '../domain';
import { AutomationKey } from './keys';

export const useGetRequests = (
  pageNumber?: number,
  filter?: string,
  sortBy?: string,
  sortOrder?: SortDirection,
  options?: UseQueryOptions<
    DataWithPagination<AutomationRequestModel[]>,
    any,
    DataWithPagination<AutomationRequestModel[]>
  >,
) =>
  useQuery<DataWithPagination<AutomationRequestModel[]>>(
    AutomationKey.REQUESTSLIST_PAGED({ pageNumber, filter, sortBy, sortOrder }),
    () =>
      automationsRepo.getAutomationRequests(
        pageNumber,
        filter,
        sortBy,
        sortOrder,
      ),
    { ...options, cacheTime: 1000 * 60 * 5 },
  );
