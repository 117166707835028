import { PieChartCard } from '@/components';
import { Dev } from '@/core/domain/global-performance-model';
import { useGetChartColorsAndLabel } from '@/hooks';
import { useGetAutomationPerformance } from '@/modules/Automations/queries';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAutomationPerformanceWarningMessages } from '../../hooks/use-automation-performance-warning-messages';
import { DevTimeDetails } from './DevTimeDetails';

export type DevTimeCardProps = {
  automationId: number;
};

export const DevTimeCard = ({ automationId }: DevTimeCardProps) => {
  const { t } = useTranslation();
  const [isDevTimeDetailsOpen, setIsDevTimeDetailsOpen] = useState<boolean>();

  const { rateDevInvestChart } = useGetChartColorsAndLabel();

  const { data: automationPerformance, isLoading } =
    useGetAutomationPerformance(automationId);

  const warningMessages = useAutomationPerformanceWarningMessages({
    automationId,
    automationPerformance,
    isLoading,
  });

  const devTimeData = useMemo(
    () =>
      Object.entries(rateDevInvestChart)
        .filter(([key]) => key !== 'infrastructure')
        .map(([key, value]) => ({
          ...value,
          value: automationPerformance?.devTime[key as keyof Dev] ?? 0,
        })),
    [automationPerformance?.devTime, rateDevInvestChart],
  );

  return (
    <>
      <PieChartCard
        data={devTimeData}
        chartTitle={t('team_effort')}
        valueType="hours"
        subtitle={t('total_hours')}
        tooltip={t('team_effort_tooltip_description')}
        onExpand={() => setIsDevTimeDetailsOpen(true)}
        warningMessage={warningMessages}
      />
      {isDevTimeDetailsOpen && (
        <DevTimeDetails
          automationId={automationId}
          onClose={() => setIsDevTimeDetailsOpen(false)}
        />
      )}
    </>
  );
};
