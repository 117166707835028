import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { TableSort } from '../Table/types';

/**
 * * Notice: This should be used as a generic local pagination context to handle table pagination.
 * * Setup the Provider \<TableWithFilter>...\</TableWithFilter> on the lowest parent component possible for this context when using it.
 *
 *  ! Do not modify this to a specific context implementation. Use local state or create a new Context for specific context if needed !
 */
// eslint-disable-next-line react-refresh/only-export-components
export const TablePaginationContext = createContext<{
  searchTerm: string;
  page: number;
  tableSort: TableSort[];
  onSort: (data: TableSort[]) => void;
  onPageChange: (pageNumber: number) => void;
  handleSearchChange: (value: string, includeAll?: boolean) => void;
  reset: () => void;
}>({
  searchTerm: '',
  page: 0,
  tableSort: [],
  onPageChange: () => {},
  handleSearchChange: () => {},
  onSort: () => {},
  reset: () => {},
});

export const TablePaginationContextProvider = ({
  children,
}: {
  children?: ReactNode | ReactElement;
}): ReactElement => {
  const [searchTerm, setSearchTerm] = useState('');

  const [page, setPage] = useState(0);
  const [tableSort, setTableSort] = useState<TableSort[]>([]);
  const onPageChange = useCallback((pageNumber: number) => {
    setPage(pageNumber);
  }, []);

  const handleSearchChange = useCallback(
    (value: string, includeAll: boolean = true) => {
      setSearchTerm(includeAll ? `all=${value}` : value);
      setPage(0);
    },
    [],
  );

  const reset = useCallback(() => {
    setSearchTerm('');
    setPage(0);
    setTableSort([]);
  }, []);

  return (
    <TablePaginationContext.Provider
      value={{
        tableSort,
        onSort: setTableSort,
        searchTerm,
        page,
        onPageChange,
        handleSearchChange,
        reset,
      }}
    >
      {children}
    </TablePaginationContext.Provider>
  );
};
