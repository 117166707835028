import { PieChartCard } from '@/components';
import { TimeDistribution } from '@/core/domain/global-performance-model';
import { useGetChartColorsAndLabel } from '@/hooks';
import { useGetProcess } from '@/modules/Processes/queries';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type ProcessTimeDistributionCardProps = {
  processId: number;
};

export const ProcessTimeDistributionCard = ({
  processId,
}: ProcessTimeDistributionCardProps) => {
  const { t } = useTranslation();

  const { data: process } = useGetProcess(processId);

  const { processTimeDistributionChart } = useGetChartColorsAndLabel();

  const processTimeDistributionData = useMemo(
    () =>
      Object.entries(processTimeDistributionChart).map(([key, value]) => ({
        ...value,
        value:
          process?.processTimeDistribution[key as keyof TimeDistribution] ?? 0,
      })),
    [process, processTimeDistributionChart],
  );

  return (
    <>
      <PieChartCard
        data={processTimeDistributionData}
        chartTitle={t('process_time_distribution')}
        valueType="percentage"
        subtitle={t('total')}
        convert={true}
      />
    </>
  );
};
