import { workflowsRepo } from '@/constants/providers';
import { useQuery } from '@tanstack/react-query';
import { WorkflowKey } from './keys';

type UseWorkflowsBaseInfoByProcessIdProps = {
  processId?: number;
};

export const useWorkflowsBaseInfoByProcessId = ({
  processId,
}: UseWorkflowsBaseInfoByProcessIdProps) => {
  return useQuery({
    queryKey: WorkflowKey.BASE_INFO_BY_PROCESS(processId),
    queryFn: () =>
      processId
        ? workflowsRepo.getWorkflowsBaseInfoByProcessId(processId)
        : undefined,
    enabled: !!processId,
    cacheTime: 1000 * 60 * 5,
  });
};
