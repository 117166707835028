import { Container } from '@/components';
import { DateRangeDropdown } from '@/components/DateRangeDropdown';
import { ReactElement, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { CompanyLabourTimeAutomatedCard } from './CompanyLabourTimeAutomatedCard';
import { CompanyTotalReturnCard } from './CompanyTotalReturnCard';

interface CompanyPerformanceCardsProps {
  companyId: number;
}

export const CompanyPerformanceCards = ({
  companyId,
}: CompanyPerformanceCardsProps): ReactElement => {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<DateRange>();

  return (
    <div className="flex flex-wrap gap-2">
      <Container
        title={t('auto_and_invest_performance')}
        className="flex-1"
        actions={
          <DateRangeDropdown
            range={dateRange}
            onChange={setDateRange}
            placement="bottom-end"
          />
        }
      >
        <div className="flex gap-3">
          <CompanyLabourTimeAutomatedCard
            companyId={companyId}
            automationPerformanceDateRange={dateRange}
          />
          <CompanyTotalReturnCard
            companyId={companyId}
            automationPerformanceDateRange={dateRange}
          />
        </div>
      </Container>
    </div>
  );
};
