export const EnvironmentVariable = {
  MODE: import.meta.env.MODE.toLowerCase(),
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  DISABLE_AUTOMATION_REQUESTS:
    import.meta.env.VITE_DISABLE_AUTOMATION_REQUESTS === 'true',
  API_URL: import.meta.env.VITE_API_URL,
  AUTH_SERVER_URL: import.meta.env.VITE_APP_AUTH_URL,
  AUTH_REALM: import.meta.env.VITE_APP_AUTH_REALM,
  AUTH_CLIENT_ID: import.meta.env.VITE_APP_AUTH_CLIENT_ID,
};
