import { TotalReturnCard } from '@/components/PerformanceCardsContainer/components/TotalReturnCard';
import { TotalReturnDetails } from '@/components/PerformanceCardsContainer/components/TotalReturnDetails';
import {
  useBusinessAreaAutomationsPerformance,
  useBusinessAreaTotalReturn,
} from '@/modules/BusinessAreas/queries/use-business-area-automations-perforrmance';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';

type BusinessAreaTotalReturnCardProps = {
  businessAreaId: number;
  automationPerformanceDateRange?: DateRange;
};

export const BusinessAreaTotalReturnCard = ({
  businessAreaId,
  automationPerformanceDateRange,
}: BusinessAreaTotalReturnCardProps) => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const [searchInput, setSearchInput] = useState<string>();
  const [isDetailsOpen, setDetailsOpen] = useState<boolean>(false);

  const { data: automationPerformance, isLoading } =
    useBusinessAreaAutomationsPerformance(
      businessAreaId,
      automationPerformanceDateRange,
    );

  const {
    data,
    isLoading: isLoadingTotalReturnAutomated,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useBusinessAreaTotalReturn({
    businessAreaId,
    enabled: isDetailsOpen,
    dateRange,
    filter: searchInput,
  });

  const totalReturnAutomated = useMemo(
    () => data?.pages.flatMap((page) => page) || [],
    [data?.pages],
  );

  return (
    <TotalReturnCard
      automationPerformance={automationPerformance}
      isLoading={isLoading}
      setIsDetailsOpen={setDetailsOpen}
      details={
        isDetailsOpen && (
          <TotalReturnDetails
            isLoading={isLoadingTotalReturnAutomated}
            totalReturnAutomated={totalReturnAutomated}
            onClose={() => setDetailsOpen(false)}
            additionalTableColumns
            fetchNextPage={fetchNextPage}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setSearchInput={setSearchInput}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
          />
        )
      }
    />
  );
};
