import { LabourTimeAutomatedCard } from '@/components/PerformanceCardsContainer/components/LabourTimeAutomatedCard';
import { LabourTimeAutomatedDetails } from '@/components/PerformanceCardsContainer/components/LabourTimeAutomatedDetails';
import {
  useTaskAutomationsPerformance,
  useTaskLabourTimeAutomated,
} from '@/modules/Tasks/queries/use-get-task-automations-performance';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';

type TaskLabourTimeAutomatedCardProps = {
  taskId: number;
  automationPerformanceDateRange?: DateRange;
};

export const TaskLabourTimeAutomatedCard = ({
  taskId,
  automationPerformanceDateRange,
}: TaskLabourTimeAutomatedCardProps) => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const [searchInput, setSearchInput] = useState<string>();
  const [isDetailsOpen, setDetailsOpen] = useState(false);

  const { data: automationPerformance, isLoading } =
    useTaskAutomationsPerformance({
      taskId,
      dateRange: automationPerformanceDateRange,
    });

  const {
    data: labourAutomated,
    isLoading: isLoadingLabourAutomation,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useTaskLabourTimeAutomated({
    taskId,
    dateRange,
    filter: searchInput,
    enabled: isDetailsOpen,
  });

  return (
    <LabourTimeAutomatedCard
      automationPerformance={automationPerformance}
      isLoading={isLoading}
      setIsDetailsOpen={setDetailsOpen}
      details={
        isDetailsOpen && (
          <LabourTimeAutomatedDetails
            isLoadingLabourAutomation={isLoadingLabourAutomation}
            labourAutomated={labourAutomated}
            onClose={() => setDetailsOpen(false)}
            excludeSearchInput
            fetchNextPage={fetchNextPage}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setSearchInput={setSearchInput}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
          />
        )
      }
    />
  );
};
