import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../tailwind.config';

export const useGetChartColorsAndLabel = () => {
  const { t } = useTranslation();

  const americanGreen = config.theme?.extend?.colors?.americanGreen as string;
  const salmonOrange = config.theme?.extend?.colors?.salmonOrange as string;
  const redBellPepper = config.theme?.extend?.colors?.redBellPepper as string;
  const blueNuit = config.theme?.extend?.colors?.blueNuit as string;
  const fennelBlue = config.theme?.extend?.colors?.fennelBlue as string;
  const lightBlue = config.theme.extend.colors.lightBlue as string;
  const primaryBlue = config.theme?.extend?.colors?.primaryBlue as string;

  const workflowTimeDistributionChart = useMemo(
    () => ({
      manualTime: {
        name: t('manual_labour'),
        fill: fennelBlue,
      },
      automatedTime: {
        name: t('automated'),
        fill: blueNuit,
      },
    }),
    [blueNuit, fennelBlue, t],
  );

  const processTimeDistributionChart = useMemo(
    () => ({
      manualTime: {
        name: t('manual_labour'),
        fill: fennelBlue,
      },
      automatedTime: {
        name: t('automated'),
        fill: blueNuit,
      },
    }),
    [blueNuit, fennelBlue, t],
  );

  const rateChart = useMemo(
    () => ({
      successes: { name: t('success'), fill: americanGreen },
      businessErrors: {
        name: t('business_error'),
        fill: salmonOrange,
      },
      genericErrors: { name: t('generic_error'), fill: redBellPepper },
    }),
    [americanGreen, redBellPepper, salmonOrange, t],
  );

  const rateDevInvestChart = useMemo(
    () => ({
      infrastructure: {
        name: t('infrastructure'),
        fill: primaryBlue,
      },
      maintenance: { name: t('maintenance'), fill: blueNuit },
      support: { name: t('support'), fill: lightBlue },
      development: { name: t('development'), fill: fennelBlue },
    }),
    [t, primaryBlue, blueNuit, lightBlue, fennelBlue],
  );

  const rateColors = useMemo(() => {
    return {
      success: rateChart.successes.fill,
      businessError: rateChart.businessErrors.fill,
      genericErrors: rateChart.genericErrors.fill,
    };
  }, [rateChart]);

  const devTimeChartColors = useMemo(
    () => ({
      maintenanceColor: rateDevInvestChart.maintenance.fill,
      developmentColor: rateDevInvestChart.development.fill,
      supportColor: rateDevInvestChart.support.fill,
      infrastructureColor: rateDevInvestChart.infrastructure.fill,
    }),
    [rateDevInvestChart],
  );

  return {
    rateChart,
    processTimeDistributionChart,
    workflowTimeDistributionChart,
    rateDevInvestChart,
    rateColors,
    devTimeChartColors,
  };
};
