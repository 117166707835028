import {
  Button,
  Dropdown,
  FieldInfo,
  HierarchicalDropdown,
  Input,
  Modal,
  MultiSelectDropdownField,
  SelectDropdownOption,
  TextArea,
} from '@/components';
import {
  AutomationRequestSchema,
  AutomationRequestSchemaType,
} from '@/modules/Automations/types/automation-schema';
import { useGetUsersBaseInfo } from '@/modules/Users/queries';
import {
  getDropdownArray,
  getHierarchicalDropdownArray,
} from '@/utils/get-dropdown-array.util';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactElement, useState, useMemo, ChangeEvent } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  PriorityTypes,
  RecurrenceTypes,
  ToolTypes,
} from '@/modules/Automations/types';
import { twMerge } from 'tailwind-merge';
import { useGetAllBusinessAreasByCompany } from '@/modules/BusinessAreas/queries';
import { useGetAllCompaniesByOrganization } from '@/modules/Companies/queries';
import { useGetAllOrganizations } from '@/modules/Organizations/queries';

interface RequestAutomationModalProps {
  handleOnSubmit: (e: AutomationRequestSchemaType) => void;
  handleClose: () => void;
  title: string;
  isLoading: boolean;
  isOpen: boolean;
  defaultValues?: Partial<AutomationRequestSchemaType>;
  dropdownToolOptions?: SelectDropdownOption<number>[];
}

export const RequestAutomationModal = ({
  title,
  isOpen,
  isLoading,
  handleOnSubmit,
  handleClose,
  defaultValues,
  dropdownToolOptions,
}: RequestAutomationModalProps): ReactElement => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    watch,
    setValue,
  } = useForm<AutomationRequestSchemaType>({
    resolver: zodResolver(AutomationRequestSchema),
    mode: 'onBlur',
    criteriaMode: 'all',
    progressive: true,
    defaultValues,
  });

  const { data: users } = useGetUsersBaseInfo();
  const { data: organizations } = useGetAllOrganizations();

  const organization = watch('organizationId');
  const company = watch('companyId');

  const { data: companies } = useGetAllCompaniesByOrganization(organization);
  const { data: businessAreas } = useGetAllBusinessAreasByCompany(company);

  const {
    dropdownOrganizationsOptions,
    dropdownCompaniesOptions,
    dropdownBusinessAreasOptions,
    dropdownUsersOptions,
    dropdownPrioritiesOptions,
    dropdownFrequenciesOptions,
  } = useMemo(() => {
    return {
      dropdownOrganizationsOptions: getDropdownArray(
        organizations?.filter((organization) => organization.status) ?? [],
      ),
      dropdownCompaniesOptions: getDropdownArray(
        companies?.filter((company) => company.status) ?? [],
      ),
      dropdownBusinessAreasOptions: getHierarchicalDropdownArray(
        businessAreas ?? [],
      ),
      dropdownUsersOptions: getDropdownArray(users ?? []),
      dropdownPrioritiesOptions: getDropdownArray(
        Object.keys(PriorityTypes).map((priorityTypes) => ({
          name: t(priorityTypes),
          id: priorityTypes,
        })),
      ),
      dropdownFrequenciesOptions: getDropdownArray(
        Object.keys(RecurrenceTypes).map((recurrenceTypes) => ({
          name: t(recurrenceTypes),
          id: recurrenceTypes,
        })),
      ),
    };
  }, [organizations, companies, businessAreas, users, t]);

  const finalDropdownToolOptions = useMemo(() => {
    if (dropdownToolOptions) {
      return dropdownToolOptions;
    }
    return getDropdownArray(
      Object.keys(ToolTypes).map((toolType, index) => ({
        name: t(toolType),
        id: index + 1,
      })),
    );
  }, [dropdownToolOptions, t]);

  const onSubmit: SubmitHandler<AutomationRequestSchemaType> = (data) => {
    handleOnSubmit(data);
  };

  const renderStepper = () => (
    <div className="my-4">
      <div className="flex justify-center ">
        <div className="relative top-[10px] h-0.2 w-24 bg-blueNuit"></div>
        <div className="flex flex-col items-center">
          <span
            data-testid="badgeNumber-section"
            className={twMerge(
              'flex size-5 items-center justify-center rounded-full bg-blueNuit text-sm font-black text-white transition-colors',
            )}
          >
            1
          </span>
          <p className="mt-1 text-xs font-bold text-blueNuit">
            {t('process_details')}
          </p>
        </div>
        <div
          className={twMerge(
            'relative top-[10px] h-0.2 w-24 bg-gray-300',
            step === 2 && 'bg-blueNuit',
          )}
        ></div>
        <div className="flex flex-col items-center">
          <span
            data-testid="badgeNumber-section"
            className={twMerge(
              'flex size-5 items-center justify-center rounded-full bg-rinseGray text-sm font-black text-white transition-colors',
              step === 2 && 'bg-blueNuit',
            )}
          >
            2
          </span>
          <p
            className={twMerge(
              'mt-1 text-xs font-bold transition-colors',
              step === 2 ? 'text-blueNuit' : 'text-gray-400',
            )}
          >
            {t('effort_details')}
          </p>
        </div>
        <div className="relative top-[10px] h-0.2 w-24 bg-gray-300"></div>
      </div>
    </div>
  );

  const handleFileChange = () => (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;

    setValue('additionalFiles', file);
  };

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      className="h-164 w-128"
      handleClose={handleClose}
    >
      <>
        {renderStepper()}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-2 flex h-112 flex-col overflow-y-auto p-2">
            {step === 1 ? (
              <>
                <FieldInfo
                  title={t('organization')}
                  errorText={
                    errors.organizationId?.message
                      ? t(errors.organizationId.message as string)
                      : undefined
                  }
                  hasError={!!errors.organizationId?.message}
                >
                  <Controller
                    name={'organizationId'}
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value, ...otherFieldProps },
                    }) => (
                      <Dropdown
                        {...otherFieldProps.onBlur}
                        className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                        placeholder={t('organization')}
                        options={dropdownOrganizationsOptions}
                        onSelect={(val) => onChange(val.value)}
                        value={value}
                      />
                    )}
                  />
                </FieldInfo>
                <FieldInfo
                  title={t('company')}
                  errorText={
                    errors.companyId?.message
                      ? t(errors.companyId.message as string)
                      : undefined
                  }
                  hasError={!!errors.companyId?.message}
                >
                  <Controller
                    name={'companyId'}
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value, ...otherFieldProps },
                    }) => (
                      <Dropdown
                        {...otherFieldProps.onBlur}
                        className="h-10 w-full rounded-md  bg-brightGray capitalize italic"
                        placeholder={t('company')}
                        options={dropdownCompaniesOptions}
                        onSelect={(val) => onChange(val.value)}
                        value={value}
                      />
                    )}
                  />
                </FieldInfo>
                <FieldInfo
                  title={t('businessArea')}
                  errorText={
                    errors.owner?.message ? t(errors.owner.message) : undefined
                  }
                  hasError={!!errors.owner?.message}
                >
                  <Controller
                    name="businessAreaId"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value, ...otherFieldProps },
                    }) => (
                      <HierarchicalDropdown
                        {...otherFieldProps}
                        className="h-10 w-full rounded-md bg-brightGray capitalize italic"
                        placeholder={t('businessArea')}
                        options={dropdownBusinessAreasOptions}
                        onSelect={(val) => onChange(val.value)}
                        value={value}
                      />
                    )}
                  />
                </FieldInfo>
                <FieldInfo
                  title={t('process_name')}
                  errorText={
                    errors.name?.message ? t(errors.name.message) : undefined
                  }
                  hasError={!!errors.name?.message}
                >
                  <Input
                    key="name"
                    isDisabled={isLoading}
                    className="w-full bg-brightGray italic"
                    placeHolder={t('process_name')}
                    name="name"
                    register={register}
                  />
                </FieldInfo>
                <FieldInfo
                  title={t('process_owner')}
                  errorText={
                    errors.owner?.message ? t(errors.owner.message) : undefined
                  }
                  hasError={!!errors.owner?.message}
                >
                  <Controller
                    key="owner"
                    name="owner"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value, ...otherFieldProps },
                    }) => (
                      <Dropdown
                        {...otherFieldProps}
                        className="h-10 w-full rounded-md bg-brightGray capitalize italic"
                        placeholder={t('select_user')}
                        options={dropdownUsersOptions}
                        onSelect={(val) => onChange(val.value)}
                        value={value}
                      />
                    )}
                  />
                </FieldInfo>
                <FieldInfo
                  title={t('business_stakeholders')}
                  hasError={!!errors.users?.message}
                >
                  <MultiSelectDropdownField<AutomationRequestSchemaType>
                    name="users"
                    control={control}
                    placeholder={t('select_users')}
                    dropdownOptions={dropdownUsersOptions}
                  />
                </FieldInfo>
                <FieldInfo
                  title={t('description')}
                  errorText={
                    errors.description?.message
                      ? t(errors.description.message)
                      : undefined
                  }
                  hasError={!!errors.description?.message}
                >
                  <TextArea
                    key="description"
                    isDisabled={isLoading}
                    className="h-24 w-full bg-brightGray italic"
                    placeHolder={t('request_description')}
                    name="description"
                    register={register}
                  />
                </FieldInfo>
              </>
            ) : (
              <>
                <FieldInfo
                  title={t('process_duration')}
                  errorText={
                    errors.duration?.message
                      ? t(errors.duration.message)
                      : undefined
                  }
                  hasError={!!errors.duration?.message}
                >
                  <Input
                    key="duration"
                    isDisabled={isLoading}
                    className="w-full bg-brightGray italic"
                    placeHolder={t('request_duration')}
                    name="duration"
                    register={register}
                  />
                </FieldInfo>
                <FieldInfo
                  title={t('execution_recurrence')}
                  errorText={
                    errors.frequency?.message
                      ? t(errors.frequency.message)
                      : undefined
                  }
                  hasError={!!errors.frequency?.message}
                >
                  <Controller
                    key="frequency"
                    name="frequency"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value, ...otherFieldProps },
                    }) => (
                      <Dropdown
                        key="frequency"
                        {...otherFieldProps}
                        className="h-10 w-full rounded-md bg-brightGray capitalize italic"
                        placeholder={t('select_periodicity')}
                        options={dropdownFrequenciesOptions}
                        onSelect={(val) => onChange(val.value)}
                        value={value}
                      />
                    )}
                  />
                </FieldInfo>
                <FieldInfo
                  title={t('process_critical')}
                  errorText={
                    errors.priority?.message
                      ? t(errors.priority.message)
                      : undefined
                  }
                  hasError={!!errors.priority?.message}
                >
                  <Controller
                    key="priority"
                    name="priority"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value, ...otherFieldProps },
                    }) => (
                      <Dropdown
                        key="priority"
                        {...otherFieldProps}
                        className="h-10 w-full rounded-md bg-brightGray capitalize italic"
                        placeholder={t('critical_rating')}
                        options={dropdownPrioritiesOptions}
                        onSelect={(val) => onChange(val.value)}
                        value={value}
                      />
                    )}
                  />
                </FieldInfo>
                <FieldInfo
                  title={t('tools_and_applications')}
                  hasError={!!errors.tools?.message}
                >
                  <MultiSelectDropdownField<AutomationRequestSchemaType>
                    key="tools"
                    name="tools"
                    control={control}
                    placeholder={t('select_tools_and_applications')}
                    dropdownOptions={finalDropdownToolOptions}
                    allowCustomOption={true}
                  />
                </FieldInfo>
                <FieldInfo
                  title={t('additional_files')}
                  hasError={!!errors.additionalFiles?.message}
                >
                  <div className="relative w-full bg-brightGray capitalize">
                    <Controller
                      name="additionalFiles"
                      control={control}
                      render={({ field }) => (
                        <>
                          <input
                            id="file-upload-sdd"
                            type="file"
                            accept=".pdf, .doc, .docx"
                            className="absolute inset-0 z-50 w-full opacity-0"
                            onChange={handleFileChange()}
                            disabled={isLoading}
                          />
                          <label
                            htmlFor="file-upload-sdd"
                            className="flex h-10 items-center justify-center rounded-md border border-dashed border-gray-300 bg-white p-2"
                          >
                            <span className="cursor-pointer text-sm font-bold underline">
                              {field.value ? field.value.name : t('load_file')}
                            </span>
                          </label>
                        </>
                      )}
                    />
                  </div>
                </FieldInfo>
              </>
            )}
          </div>
          <div className="mt-4 flex justify-between">
            <Button
              isDisabled={isLoading}
              onClick={step === 1 ? handleClose : () => setStep(1)}
              variant="outline"
              className="h-9 w-24"
            >
              {step === 1 ? t('cancel') : t('back')}
            </Button>
            {step === 1 ? (
              <Button
                isDisabled={isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  setStep(2);
                }}
                className="h-9 w-24"
              >
                {t('next')}
              </Button>
            ) : (
              <Button
                isDisabled={isLoading || !isDirty}
                buttonType="submit"
                className="h-9 w-24"
              >
                {t('submit')}
              </Button>
            )}
          </div>
        </form>
      </>
    </Modal>
  );
};
