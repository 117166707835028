import { BusinessAreasKey } from '@/modules/BusinessAreas/queries/keys';
import { CompanyKeys } from '@/modules/Companies/queries';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { ProcessKey } from '@/modules/Processes/queries';
import { UserKey } from '@/modules/Users/queries/types';
import { WorkflowKey } from '@/modules/Workflows/queries';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TaskKey, useCreateTask } from '../queries';
import { TaskSchemaType } from '../types';
import { buildTaskFormData } from '../utils/build-task-form-data';

type UseTaskCreateSubmissionProps = {
  onSuccess: () => void;
};

export const useTaskCreateSubmission = ({
  onSuccess,
}: UseTaskCreateSubmissionProps) => {
  const { mutate, isLoading } = useCreateTask();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const submit = useCallback(
    (data: TaskSchemaType) => {
      const taskFormData = buildTaskFormData({
        businessAreaId: data.businessAreaId,
        description: data.taskDescription,
        name: data.taskName,
        periodicity: data.taskPeriodicity,
        workload: data.labourWorkload,
        labourWorkloadType: data.labourWorkloadType,
        workflowId: data.workflowId,
        averageCases: data.averageCases,
      });
      taskFormData.append('taskStageId', '1');
      taskFormData.append('status', 'true');
      data.documentation &&
        taskFormData.append('documentation', data.documentation);

      mutate(taskFormData, {
        onError: () => {
          toast.error(t('error_creating_task'));
        },
        onSuccess: () => {
          queryClient.invalidateQueries(TaskKey.ALL);
          queryClient.invalidateQueries(WorkflowKey.ALL);
          queryClient.invalidateQueries(UserKey.ALL);
          queryClient.invalidateQueries(OrganizationKeys.ALL);
          queryClient.invalidateQueries(CompanyKeys.ALL);
          queryClient.invalidateQueries(BusinessAreasKey.ALL);
          queryClient.invalidateQueries(ProcessKey.ALL);

          onSuccess();
          toast.success(t('success_creating_task'));
        },
      });
    },
    [mutate, onSuccess, queryClient, t],
  );

  return { submit, isLoading };
};
