import { LabourTimeAutomatedCard } from '@/components/PerformanceCardsContainer/components/LabourTimeAutomatedCard';
import { LabourTimeAutomatedDetails } from '@/components/PerformanceCardsContainer/components/LabourTimeAutomatedDetails';
import {
  useCompanyLabourTimeAutomated,
  useGetCompanyAutomationsPerformance,
} from '@/modules/Companies/queries/use-get-company-automations-performance';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';

type CompanyLabourTimeAutomatedCardProps = {
  companyId: number;
  automationPerformanceDateRange?: DateRange;
};

export const CompanyLabourTimeAutomatedCard = ({
  companyId,
  automationPerformanceDateRange,
}: CompanyLabourTimeAutomatedCardProps) => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const [searchInput, setSearchInput] = useState<string>();
  const [isDetailsOpen, setDetailsOpen] = useState<boolean>(false);

  const { data: automationPerformance, isLoading } =
    useGetCompanyAutomationsPerformance(
      companyId,
      automationPerformanceDateRange,
    );

  const {
    data,
    isLoading: isLoadingLabourAutomation,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useCompanyLabourTimeAutomated({
    companyId,
    dateRange,
    filter: searchInput,
    enabled: isDetailsOpen,
  });

  const labourAutomated = useMemo(
    () => data?.pages.flatMap((page) => page) || [],
    [data?.pages],
  );

  return (
    <LabourTimeAutomatedCard
      automationPerformance={automationPerformance}
      isLoading={isLoading}
      setIsDetailsOpen={setDetailsOpen}
      details={
        isDetailsOpen && (
          <LabourTimeAutomatedDetails
            isLoadingLabourAutomation={isLoadingLabourAutomation}
            labourAutomated={labourAutomated}
            onClose={() => setDetailsOpen(false)}
            addAdditionalColumns
            fetchNextPage={fetchNextPage}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setSearchInput={setSearchInput}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
          />
        )
      }
    />
  );
};
