import { ReactElement } from 'react';
import { TableRowCell } from '@/components/Table/components';
import { TableColumn, TableVariant } from '@/components/Table/types';
import { ProcessWorkflowTask } from '../../types';

interface TaskRowProps {
  task: ProcessWorkflowTask;
  taskColumns: TableColumn<ProcessWorkflowTask>[];
  variant: TableVariant;
  noOutline?: boolean;
  onRowHoverStyling?: string;
}

export const TaskRow = ({
  task,
  taskColumns,
  variant,
  noOutline,
  onRowHoverStyling,
}: TaskRowProps): ReactElement => {
  return (
    <>
      <TableRowCell
        row={task}
        cols={taskColumns}
        noOutline={noOutline}
        variant={variant}
        onRowHoverStyling={onRowHoverStyling}
      />
    </>
  );
};
