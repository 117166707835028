import { TabItemProps, TablePaginationContext, Tabs } from '@/components';
import { ReactNode, useCallback, useContext, useState } from 'react';

type OrganizationTabsProps = {
  tabs: Array<TabItemProps>;
  tabList: Array<{ tab: ReactNode }>;
};

export const TabsWrapper = ({ tabs, tabList }: OrganizationTabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { reset } = useContext(TablePaginationContext);

  const onTabChange = useCallback(
    (tabIndex: number) => {
      reset();
      setSelectedTab(tabIndex);
    },
    [reset],
  );

  const elementToRender = tabList[selectedTab];

  return (
    <>
      <Tabs activeTab={selectedTab} tabs={tabs} onTabChange={onTabChange} />
      {elementToRender?.tab}
    </>
  );
};
