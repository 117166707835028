import { useGetAutomation } from '@/modules/Automations/queries';
import { AutomationKey } from '@/modules/Automations/queries/keys';
import { useUpdateAutomation } from '@/modules/Automations/queries/update-automation';
import { AutomationSchemaType } from '@/modules/Automations/types/automation-schema';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AutomationModal } from '../AutomationModal';
import { UpdateAutomationJson } from '@/modules/Automations/types';

interface EditAutomationModal {
  isOpen: boolean;
  id: number;
  handleClose: () => void;
}

export const EditAutomationModal = ({
  isOpen,
  id,
  handleClose,
}: EditAutomationModal) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useUpdateAutomation();
  const { data: automation, isLoading: isLoadingGet } = useGetAutomation(id);

  const handleConfirm = (data: AutomationSchemaType) => {
    if (!automation) {
      return;
    }

    const convertedData: UpdateAutomationJson = {
      id: id,
      name: data.automationName,
      description: data.description,
      developerId: data.developerId,
      stageId: data.stageId,
      priority: data.priority,
      epicId: data.epicId,
      orchestratorProcessId: data.orchestratorProcessId,
      processId: data.processId,
      businessAreaId: data.businessAreaId,
    };

    mutate(convertedData, {
      onError: () => {
        toast.error(t('error_update_automation'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(AutomationKey.ALL);
        handleClose();
        toast.success(t('success_update_automation'));
      },
    });
  };

  return (
    !isLoadingGet &&
    !!automation && (
      <AutomationModal
        type="edit"
        handleOnSubmit={handleConfirm}
        handleClose={handleClose}
        title={t('edit_automation')}
        isOpen={isOpen}
        isLoading={isLoading}
        automationName={automation.name}
        automationCode={automation.code}
        description={automation.description}
        developerId={automation.developerId}
        stageId={automation.stageId}
        priority={automation.priority}
        organizationId={automation.organizationId}
        companyId={automation.companyId}
        businessAreaId={automation.businessAreaId}
        processId={automation.processId}
        epicId={automation.epicId}
        orchestratorProcessId={automation.orchestratorProcessId}
      />
    )
  );
};
