import { TenantContext } from '@/components';
import { RoutePath } from '@/core/router';
import { ReactElement, useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { useUpdateLastAccess } from '@/modules/Users/queries';

const SESSION_UPDATED_KEY = 'lastAccessUpdated';

export const HomePage = (): ReactElement => {
  const { currentSelectedTenant } = useContext(TenantContext);
  const auth = useAuth();
  const { mutate: updateLastAccess } = useUpdateLastAccess();

  useEffect(() => {
    if (auth.isAuthenticated && !sessionStorage.getItem(SESSION_UPDATED_KEY)) {
      updateLastAccess();
      sessionStorage.setItem(SESSION_UPDATED_KEY, 'true');
    }
  }, [auth.isAuthenticated, updateLastAccess]);

  return (
    <Navigate
      to={
        !currentSelectedTenant?.organizations[0] ||
        currentSelectedTenant.organizations.length > 1
          ? RoutePath.organizations()
          : RoutePath.organizationDetail(
              currentSelectedTenant.organizations[0].toString(),
            )
      }
    />
  );
};
