import {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { SideNavItem, SideNavItemProps, TenantContext } from '..';
import { SideNavProfileAvatar } from './components/SideNavProfileAvatar';
import { ChangeTenantModal } from '../ChangeTenantModal';

interface SideNavProps {
  items: SideNavItemProps[];
  footer?: ReactNode;
}

export const SideNav = ({ items, footer }: SideNavProps): ReactElement => {
  const {
    currentSelectedTenant,
    onTenantIdChange,
    userTenants,
    isLoadingTenantInfo,
  } = useContext(TenantContext);
  const [isChangeTenantModalOpen, setIsChangeTenantModalOpen] = useState(false);
  const handleChangeTenantModalOpen = useCallback(() => {
    setIsChangeTenantModalOpen(true);
  }, []);
  const handleChangeTenantModalClose = useCallback(() => {
    setIsChangeTenantModalOpen(false);
  }, []);

  return (
    <div>
      <div className="flex h-screen w-20 flex-col justify-between bg-primaryBlue text-white">
        <div className="flex flex-1 flex-col">
          <SideNavProfileAvatar
            onChangeTenantModalClick={handleChangeTenantModalOpen}
          />
          <ul className="mt-4 flex flex-1 flex-col bg-primaryBlue">
            {items.map((item: SideNavItemProps) => (
              <SideNavItem
                key={item.label}
                to={item.to}
                label={item.label}
                icon={item.icon}
              />
            ))}
          </ul>
        </div>
        <div className="flex items-center justify-center border-t border-t-plumBlue bg-primaryBlue">
          {footer}
        </div>
      </div>
      <ChangeTenantModal
        isOpen={isChangeTenantModalOpen}
        handleClose={handleChangeTenantModalClose}
        handleSave={onTenantIdChange}
        selectedTenantId={currentSelectedTenant?.id}
        tenants={userTenants}
        isLoading={isLoadingTenantInfo}
      />
    </div>
  );
};
