import { getDropdownArray } from '@/utils/get-dropdown-array.util';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RecurrenceTypes } from '../types/recurrenceTypes';
import { CostType } from '../enums/cost-type.enum';
import { CostLicenseType } from '../enums/cost-license-type.enum';

export const useInfrastructureCostFormOptions = () => {
  const { t } = useTranslation();

  const dropdownCostTypeOptions = useMemo(
    () =>
      getDropdownArray([
        {
          id: CostType.VirtualMachine,
          name: t('cost_type_option_virtual_machine'),
        },
        {
          id: CostType.PhysicalServer,
          name: t('cost_type_option_physical_server'),
        },
        {
          id: CostType.OtherSoftwareLicense,
          name: t('cost_type_option_other_software_license'),
        },
        {
          id: CostType.AutomationSoftware,
          name: t('cost_type_option_automation_software'),
        },
      ]),
    [t],
  );

  const dropdownCostLicenseTypeOptions = useMemo(
    () =>
      getDropdownArray([
        {
          id: CostLicenseType.Development,
          name: t('cost_license_type_option_development'),
        },
        {
          id: CostLicenseType.Production,
          name: t('cost_license_type_option_production'),
        },
      ]),
    [t],
  );

  const dropdownRecurrenceOptions = getDropdownArray(
    Object.keys(RecurrenceTypes).map((recurrenceTypes) => ({
      name: t(recurrenceTypes),
      id: recurrenceTypes,
    })),
  );

  return {
    dropdownCostTypeOptions,
    dropdownCostLicenseTypeOptions,
    dropdownRecurrenceOptions,
  };
};
