/**
 * Current Format:
 *
 * "," -> for decimal places
 *
 * "." -> for thousands
 */
export const formatNumber = (nr: number, decimals = 2) => {
  if (!nr) {
    return '<0,00';
  }
  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    useGrouping: true,
  }).format(nr);
};

export const formatBigNumber = (num: number): string => {
  const roundToTwoDecimals = (value: number): number => {
    return Math.round(value * 100) / 100;
  };

  const formatDecimal = (value: number): string => {
    return value.toFixed(2).replace(/\.?0+$/, '');
  };

  if (num >= 1000000) {
    const millions = roundToTwoDecimals(num / 1000000);
    return formatDecimal(millions) + 'M';
  } else if (num >= 1000) {
    const thousands = roundToTwoDecimals(num / 1000);
    return formatDecimal(thousands) + 'k';
  }
  return formatDecimal(num);
};
