import { Card } from '@/components/Card';
import { Container } from '@/components/Container';
import { PageDetailsHeaderRow } from '@/components/PageDetailsHeaderRow/PageDetailsHeaderRow';
import { StatusCard } from '@/components/StatusCard/StatusCard';
import { TaskModel } from '@/modules/Tasks/domain';
import { LabourWorkload } from '@/modules/Tasks/types/labourWorkLoad';
import { Periodicity } from '@/modules/Tasks/types/periodicity';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditTaskModal } from '../../../TasksPage/components/EditTaskModal';
import { FileCard } from '../FileCard';

interface HeaderRowProps {
  task?: TaskModel;
  handleStatus: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent>,
    id: string,
  ) => void;
  status: boolean;
  isLoading: boolean;
}

export const TaskHeaderRow = ({
  task,
  handleStatus,
  status,
  isLoading,
}: HeaderRowProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const handleModalState = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <div>
      <PageDetailsHeaderRow
        isLoading={!task}
        title={task?.name ?? ''}
        description={
          <span className="font-bold" data-testid="org-comp-section">
            {task?.organization}, {task?.company}, {task?.businessAreaName}
          </span>
        }
        actions={
          <>
            <StatusCard status={status} handleStatus={handleStatus} />
          </>
        }
        onEditClick={handleModalState}
        details={
          <div className="flex flex-col gap-1">
            <Container className="flex-row">
              <Card title={t('task_description')}>
                {<p>{task?.description}</p>}
              </Card>
              <Card title={t('code')}>{<p>{task?.code}</p>}</Card>
              <FileCard
                title={t('business_case')}
                files={task?.supportDocuments}
                isLoading={isLoading}
                id={task?.id}
              ></FileCard>
            </Container>
          </div>
        }
      >
        <Container className="flex-row flex-wrap">
          <Card title={t('task_periodicity')} isLoading={isLoading}>
            {!!task?.periodicityId &&
              t(`periodicity_types.${Periodicity[task.periodicityId]}`)}
          </Card>
          {task?.workloadTypeId === LabourWorkload.TotalTime ? (
            <Card title={t('labour_workload')} isLoading={isLoading}>
              <span className="font-bold">{t('total_time')} </span>
              {task?.workload}
              {t('minutes')}
            </Card>
          ) : (
            <Card title={t('labour_workload')} isLoading={isLoading}>
              {task?.workload}
              {t('minutes')}
              <span className="font-bold"> {t('per_case')}</span>
            </Card>
          )}
        </Container>
      </PageDetailsHeaderRow>
      {isOpen && !!task?.id && (
        <EditTaskModal
          isOpen={isOpen}
          id={task.id}
          handleClose={handleModalState}
        />
      )}
    </div>
  );
};
