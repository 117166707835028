import { z, ZodIssueCode } from 'zod';

export const InfrastructureCostSchema = z
  .object({
    costTypeId: z.number().min(1, { message: 'error_field_required' }),
    costLicenseTypeId: z.number().optional(),
    name: z.string({ required_error: 'error_field_required' }),
    amount: z.string().min(1, { message: 'error_field_required' }),
    amountRecurrence: z.string({ required_error: 'error_field_required' }),
    description: z.string().optional(),
    startDate: z.date({ required_error: 'error_field_required' }),
    endDate: z.date().optional(),
  })
  .superRefine((data, ctx) => {
    const selectedCostTypeId = data.costTypeId;
    const selectedCostLicenseTypeId = data.costLicenseTypeId;

    if (selectedCostTypeId === 6 && !selectedCostLicenseTypeId) {
      ctx.addIssue({
        path: ['costLicenseTypeId'],
        message: 'error_field_required',
        code: ZodIssueCode.custom,
      });
    }
  });

export type FormInfrastructureCostSchemaType = z.infer<
  typeof InfrastructureCostSchema
>;

export interface InfrastructureCostSchemaType
  extends FormInfrastructureCostSchemaType {
  id?: number;
}

export const EditInfrastructureCostSchema = z
  .object({
    costTypeId: z.number().min(1, { message: 'error_field_required' }),
    costLicenseTypeId: z.number().optional(),
    name: z.string({ required_error: 'error_field_required' }),
    amountRecurrence: z.string({ required_error: 'error_field_required' }),
    description: z.string().optional(),
  })
  .superRefine(({ costTypeId, costLicenseTypeId }, context) => {
    if (costTypeId === 6 && !costLicenseTypeId) {
      context.addIssue({
        path: ['costLicenseTypeId'],
        message: 'error_field_required',
        code: ZodIssueCode.custom,
      });
    }
  });

export const AmountSchema = z.object({
  amount: z.string().min(1, { message: 'error_field_required' }),
  startDate: z.preprocess(
    (val) => {
      const date =
        typeof val === 'string' || val instanceof Date
          ? new Date(val)
          : undefined;
      return isNaN(date?.getTime() ?? NaN) ? undefined : date;
    },
    z.date({ required_error: 'error_field_required' }),
  ),
  endDate: z.preprocess((val) => {
    const date =
      typeof val === 'string' || val instanceof Date
        ? new Date(val)
        : undefined;
    return isNaN(date?.getTime() ?? NaN) ? undefined : date;
  }, z.date().optional()),
});

export type FormAmountSchemaType = z.infer<typeof AmountSchema>;

export interface AmountSchemaType extends FormAmountSchemaType {
  id?: number;
}

export type FormEditInfrastructureCostSchemaType = z.infer<
  typeof EditInfrastructureCostSchema
>;

export interface EditInfrastructureCostSchemaType
  extends FormEditInfrastructureCostSchemaType {
  id?: number;
}
