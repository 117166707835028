import { Modal } from '@/components';
import { BusinessAreasKey } from '@/modules/BusinessAreas/queries/keys';
import { CompanyKeys } from '@/modules/Companies/queries';
import { OrganizationKeys } from '@/modules/Organizations/queries/keys';
import { ProcessKey } from '@/modules/Processes/queries';
import { TaskKey, useGetTask, useUpdateTask } from '@/modules/Tasks/queries';
import { TaskSchemaType } from '@/modules/Tasks/types';
import { buildTaskFormData } from '@/modules/Tasks/utils/build-task-form-data';
import { WorkflowKey } from '@/modules/Workflows/queries';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { EditTaskForm } from '../EditTaskForm/EditTaskForm';

interface EditTaskModalProps {
  isOpen: boolean;
  id: number;
  handleClose: () => void;
}

export const EditTaskModal = ({
  isOpen,
  id,
  handleClose,
}: EditTaskModalProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useUpdateTask();

  const { data: task, isLoading: isLoadingTask } = useGetTask(id);

  const handleConfirm = (data: TaskSchemaType) => {
    if (!task) {
      return;
    }
    const taskFormData = buildTaskFormData({
      businessAreaId: data.businessAreaId,
      description: data.taskDescription,
      name: data.taskName,
      periodicity: data.taskPeriodicity,
      workload: data.labourWorkload,
      labourWorkloadType: data.labourWorkloadType,
      workflowId: data.workflowId,
      averageCases: data.averageCases,
    });
    taskFormData.append('id', task.id.toString());

    mutate(taskFormData, {
      onError: () => {
        toast.error(t('error_update_task'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries(TaskKey.ALL);
        queryClient.invalidateQueries(WorkflowKey.ALL);
        queryClient.invalidateQueries(ProcessKey.ALL);
        queryClient.invalidateQueries(BusinessAreasKey.ALL);
        queryClient.invalidateQueries(CompanyKeys.ALL);
        queryClient.invalidateQueries(OrganizationKeys.ALL);
        handleClose();
        toast.success(t('success_update_task'));
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('edit_task')}
      className="h-144 w-128"
      handleClose={handleClose}
    >
      {!isLoadingTask && !!task && (
        <EditTaskForm
          id={id}
          handleClose={handleClose}
          handleOnSubmit={handleConfirm}
          isLoading={isLoading}
          processId={task.processId}
          defaultValues={{
            taskName: task.name,
            averageCases: task.averageCases,
            businessAreaId: task.businessAreaId,
            workflowId: task.workflowId,
            organizationId: task.organizationId,
            companyId: task.companyId,
            taskDescription: task.description,
            labourWorkload: task.workload,
            labourWorkloadType: task.workloadTypeId,
            taskPeriodicity: task.periodicityId,
          }}
        />
      )}
    </Modal>
  );
};
