import {
  useAutomationLabourTimeAutomated,
  useAutomationPerformance,
} from '@/modules/Automations/queries/use-automation-performance';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { LabourTimeAutomatedCard } from '../../LabourTimeAutomatedCard';
import { LabourTimeAutomatedDetails } from '../../LabourTimeAutomatedDetails';

type LabourTimeAutomatedCardProps = {
  automationId: number;
  automationPerformanceDateRange?: DateRange;
};

export const AutomationLabourTimeAutomatedCard = ({
  automationId,
  automationPerformanceDateRange,
}: LabourTimeAutomatedCardProps) => {
  const [dateRange, setDateRange] = useState<DateRange>();
  const [searchInput, setSearchInput] = useState<string>();
  const [isDetailsOpen, setDetailsOpen] = useState<boolean>(false);

  const { data: automationPerformance, isLoading } = useAutomationPerformance(
    automationId,
    automationPerformanceDateRange,
  );

  const {
    data,
    isLoading: isLoadingLabourAutomation,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useAutomationLabourTimeAutomated({
    automationId,
    dateRange,
    filter: searchInput,
    enabled: isDetailsOpen,
  });

  const labourAutomated = useMemo(
    () => data?.pages.flatMap((page) => page) || [],
    [data?.pages],
  );

  return (
    <LabourTimeAutomatedCard
      automationPerformance={automationPerformance}
      isLoading={isLoading}
      setIsDetailsOpen={setDetailsOpen}
      details={
        isDetailsOpen && (
          <LabourTimeAutomatedDetails
            isLoadingLabourAutomation={isLoadingLabourAutomation}
            labourAutomated={labourAutomated}
            onClose={() => setDetailsOpen(false)}
            excludeSearchInput
            fetchNextPage={fetchNextPage}
            dateRange={dateRange}
            setDateRange={setDateRange}
            setSearchInput={setSearchInput}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
          />
        )
      }
    />
  );
};
